import React from 'react';
import { LoadingContainer } from './styled';

const Loading = () => {
  return (
    <LoadingContainer>
      <span>저장중입니다.</span>
      <div className="lds-dual-ring" />
    </LoadingContainer>
  );
};

export default Loading;
