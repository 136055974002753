import { useState } from "react";
import ModalClose from "../../../../../vector/ModalClose";
import ModalPrev from "../../../../../vector/ModalPrev";
import { SliderWrapper } from "./styled";
import RemoveIcon from "../../../../../vector/RemoveIcon";

export default function Crystal2({ crystalToggle2 }) {
  const [hide, setHide] = useState(true);
  const [hide4, setHide4] = useState(true);
  const selectHide = () => {
    setHide((p) => !p);
  };

  const selectHide4 = () => {
    setHide4((p) => !p);
  };

  return (
    <SliderWrapper>
      <section className="modal-wrapper">
        <div className="overlay" onClick={crystalToggle2}></div>
        <div className="modal-wrap">
          <i className="icon-close" onClick={crystalToggle2}>
            <ModalClose />
          </i>
          <h2 className="modal-title">게시글 수정</h2>
          <div className="modal-box">
            <div className="select-wrap">
              <p className="department-title">카테고리</p>
              <article className="cont-select">
                <button onClick={selectHide} className="btn-select">
                  기술/cop
                </button>
                <ul className={hide ? "list-member" : "active list-member"}>
                  <li>
                    <button>기술/cop</button>
                  </li>
                  <li>
                    <button>기술/cop</button>
                  </li>
                </ul>
              </article>
            </div>
            <div className="select-wrap">
              <p className="department-title">제목</p>
              <div className="tit-box">
                <input type="text" placeholder="제목을 입력해주세요." />
              </div>
            </div>
            <div className="name-select-wrap">
              <p className="department-title">이미지 첨부</p>
              <div className="file-wrap">
                <button className="btn-name-select">
                  파일을 첨부해주세요.
                </button>
                <button className="file-btn">파일 첨부</button>
              </div>
            </div>
            <div className="modal-bg">
              <div className="bg-box">
                <i className="icon-remove">
                  <RemoveIcon />
                </i>
              </div>
            </div>
            <div className="board-wrap">
              <p className="board-title">내용</p>
              <textarea
                name=""
                id="board"
                cols="30"
                rows="10"
                placeholder="내용을 입력해주세요. (400자 이내)"
              ></textarea>
            </div>
          </div>
          <div className="gradient"></div>
          <div className="btn-wrap">
            <button onClick={crystalToggle2}>취소</button>
            <button className="yellow-btn">저장</button>
          </div>
        </div>
      </section>
    </SliderWrapper>
  );
}
