export default function ArrowTop() {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 11.998L10.9751 0.999192" stroke="black" stroke-width="2" />
      <path d="M2.68745 1H11V10.1657" stroke="black" stroke-width="2" />
    </svg>
  );
}
