import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-hook-inview';
import { motion } from 'framer-motion';

const LoadInViewStyle = styled.div`
  position:relative;
`;
const LoadInView = ({ children, id }) => {
  const [ref, inView] = useInView({ unobserveOnEnter: true, delay: 3000, rootMargin:'10px', threshold: 0 });
  const [animations, setAnimations] = useState(true);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

    if (mediaQuery.matches) setAnimations(false);
  }, []);

  if (!animations) return children;

  return (
    <LoadInViewStyle ref={ref} id={id} className="load-in-view">
      {inView ? (
        <motion.section
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.7,
            ease: 'easeInOut',
          }}
          css={`
            margin-bottom: 8rem;
          `}
        >
          {children}
        </motion.section>
      ) : (
        <div
          css={`
            visibility: hidden;
          `}
        >
          {children}
        </div>
      )}
    </LoadInViewStyle>
  );
};

export default LoadInView;