import { useEffect, useState } from 'react';
import ModalClose from '../../../../../vector/ModalClose';
import ModalPrev from '../../../../../vector/ModalPrev';
import { Wrapper } from './styled';
import { addPadlet } from '../../../../../api/mentorings';
import { GetUserMe } from '../../../../../api/user';
import { useDispatch } from 'react-redux';
import { AuthSetUserData } from '../../../../../store/auth';

export default function MentoringOn({
	user,
	tmp,
	mentoringon,
	togglePd,
	setTmp,
}) {
	const [padlet, setPadlet] = useState('');
	const [p, setP] = useState(false);
	const Dispatch = useDispatch();

	useEffect(() => {
		if (tmp) {
			setPadlet(tmp);
		} else {
			setPadlet(user?.padlet);
		}
	}, [user]);

	const DoAdd = () => {
		if (!padlet) {
			alert('Padlet 주소를 입력하세요.');
		}
		addPadlet({
			padlet,
		})
			.then((res) => {
				console.log(res);
				setP((p) => !p);
				togglePd();
				setTmp(padlet);
				alert('등록이 완료되었습니다.');
				mentoringon();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Wrapper>
			<section className="modal-wrapper">
				<div className="overlay" onClick={mentoringon}></div>
				<div className="modal-wrap">
					<i className="icon-close" onClick={mentoringon}>
						<ModalClose />
					</i>
					<h2 className="modal-title">멘토링 보드를 연결합니다</h2>
					<div className="input-box">
						<p className="input-title">Padlet</p>
						<input
							value={padlet}
							onChange={(e) => setPadlet(e.target.value)}
							type="text"
							placeholder="Padlet 주소를 입력하세요"
						/>
					</div>
					<div className="btn-wrap">
						<button onClick={mentoringon}>취소</button>
						<button onClick={DoAdd} className="yellow-btn">
							등록
						</button>
					</div>
				</div>
			</section>
		</Wrapper>
	);
}
