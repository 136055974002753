import styled from "styled-components";

export const LoadingContainer = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 120%;
  z-index: 9999;
  top: 0;
  right: 0;
  color: white;
  text-align: center;
  align-items: center;
  padding-top: 50%;
`;

export const Container = styled.div`
  padding: 30px 28px;
  position: relative;
  height: 100%;
  strong {
    display: block;
    font-weight: bold;
    font-size: 17px;
    line-height: 25px;
    color: #111;
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ebebeb;
  }
  p {
    font-size: 15px;
    line-height: 28px;
    color: #000;
    margin-bottom: 10px;
  }
  span {
    display: block;
    font-size: 15px;
    line-height: 28px;
    color: #000;
    margin-bottom: 50px;
  }
  @media ${(props) => props.theme.mobile} {
    /* padding: 50px 20px 0 20px; */
    /* height: calc(100% - 60px); */
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 17px;
  line-height: 25px;
  text-align: center;
  margin-bottom: 30px;
`;

export const InputWrapper = styled.div`
  input,
  select {
    width: 100%;
    background: #fff;
    border: 1px solid #eee;
    box-sizing: border-box;
    border-radius: 40px;
    margin-bottom: 14px;
    padding: 10px 20px;
    font-size: 13px;
    line-height: 19px;
    /* color: #111; */
    ::placeholder {
      color: #a3a3a3;
    }
  }
  select {
    padding: 10px 15px;
    background-image: url(popup-arrow.svg);
    background-repeat: no-repeat;
    background-position: right 20px top 50%;
    appearance: none;
    color: #a3a3a3;
  }

  .file-input {
    visibility: hidden;
    opacity: 0;
    height: 0px;
    padding: 0;
    margin: 0;
  }
  .file-name {
    display: inline;
    font-size: 12px;
    line-height: 17px;
    color: #000000;
  }
  span {
    margin-bottom: 0px;
  }
`;

export const AddFile = styled.label`
  display: inline-block;
  width: 40%;
  height: 39px;
  background: rgba(96, 88, 76, 0.8);
  border-radius: 40px;
  font-weight: 500;
  font-size: 12px;
  line-height: 39px;
  color: #fff;
  text-align: center;
  margin-bottom: 4px;
  margin-right: 10px;
`;

export const FileName = styled.div`
  display: inline;
`;

export const SubmitButton = styled.a`
  display: block;
  color: #000;
  text-decoration: none;
  width: 100%;
  text-align: center;
  height: 56px;
  line-height: 56px;
  background: #ffbc00;
  border-radius: 15px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  button {
    width: 50%;
    height: 60px;
    background: #444;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #fff;
    border: none;
  }
  button:first-child {
    :after {
      display: block;
      position: absolute;
      content: "";
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 50%;
      height: 30px;
      border-right: 1px solid rgba(255, 255, 255, 0.15);
    }
  }
`;
