export default function HubOpen() {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="52" height="52" rx="26" fill="white" />
      <path
        d="M35 32.7501L19 32.7501L19 16.7501M33.6395 18.5615L19.201 33"
        stroke="black"
        stroke-width="2"
      />
    </svg>
  );
}
