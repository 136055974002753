export default function AddPen() {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5 3.99233L12.0078 0.50014C11.8923 0.383452 11.7548 0.290821 11.6033 0.227604C11.4517 0.164386 11.2892 0.131836 11.125 0.131836C10.9608 0.131836 10.7983 0.164386 10.6467 0.227604C10.4952 0.290821 10.3577 0.383452 10.2422 0.50014L0.867188 9.87514C0.750702 9.99074 0.658277 10.1283 0.595253 10.2798C0.532229 10.4313 0.499854 10.5938 0.5 10.758V14.2501C0.5 14.5817 0.631696 14.8996 0.866117 15.134C1.10054 15.3684 1.41848 15.5001 1.75 15.5001H5.24219C5.4063 15.5003 5.56881 15.4679 5.72034 15.4049C5.87186 15.3419 6.0094 15.2494 6.125 15.133L15.5 5.75795C15.7319 5.52262 15.8619 5.20551 15.8619 4.87514C15.8619 4.54477 15.7319 4.22766 15.5 3.99233ZM5.24219 14.2501H1.75V10.758L8.625 3.88295L12.1172 7.37514L5.24219 14.2501ZM13 6.49233L9.50781 3.00014L11.125 1.38295L14.6172 4.87514L13 6.49233Z"
          fill="black"
        />
      </svg>
    </>
  );
}
