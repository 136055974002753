import styled from 'styled-components';

export const ReactSwiperWrapper = styled.section`
	.card-wrapper {
		margin-top: 34px;
		.profile-card-list {
			.profile-card {
				width: 100%;
				padding: 33px 30px;
				background: #ffffff;
				border: 1px solid #e9e9e9;
				border-radius: 20px;
				position: relative;
				cursor: pointer;
				transition: all 0.3;
				&:hover {
					opacity: 0.8;
				}
				.profile-box {
					display: flex;
					gap: 16px;
					.user-img {
						width: 54px;
						height: 54px;
						background-size: cover;
						border-radius: 50%;
						background-repeat: no-repeat;
						background-position: center;
					}
					.mentor-info-box {
						display: flex;
						gap: 8px;
						align-items: center;
					}
					img {
						width: 52px;
					}
					.mentor-name {
						font-weight: 700;
						font-size: 22.5258px;
						line-height: 28px;
					}
					button {
						border: 1px solid #ffbc00;
						background-color: #fff;
						font-weight: 300;
						font-size: 11px;
						line-height: 14px;
						color: #ffbc00;
						padding: 2px 9px;
						height: 18px;
						border-radius: 100px;
					}
					.department-text {
						font-weight: 300;
						font-size: 14.53px;
						line-height: 18px;
						color: #686868;
						margin-top: 9px;
					}
				}
				.period-wrap {
					font-weight: 300;
					font-size: 15px;
					line-height: 19px;
					display: flex;
					justify-content: space-between;
					align-items: end;
					margin-top: 14px;
					.period-text {
						color: #b4b4b4;
					}
					.day {
						color: #000;
						margin-top: 8px;
					}
					.circular-progress-bar {
						width: 74px;
						height: 77px;
						border-radius: 50%;
						border: 2px solid #e9e9e9;
						position: relative;
						span {
							text-align: center;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							font-weight: 500;
							font-size: 14px;
							line-height: 17px;
							color: #ffbc00;
						}
						::after {
							position: absolute;
							content: '';
							top: -3px;
							left: 50%;
							transform: translateX(-50%);
							width: 4px;
							height: 4px;
							border-radius: 50%;
							background-color: #ffbc00;
						}
					}
				}
			}
		}
		.bg {
			width: 98px;
			height: 211px;
			position: absolute;
			z-index: 1;
			top: 0px;
			right: 0;
			background: linear-gradient(
				270deg,
				#ffffff 20.41%,
				rgba(255, 255, 255, 0) 100%
			);
		}
	}
	@media screen and (max-width: 1300px) {
		.card-wrapper {
			margin-top: 34px;
			.profile-card-list {
				.profile-card {
					width: 100%;
					padding: 33px 30px;
					background: #ffffff;
					border: 1px solid #e9e9e9;
					border-radius: 20px;
					position: relative;
					.profile-box {
						.mentor-info-box {
							display: flex;
							gap: 8px;
							align-items: center;
						}
						img {
							width: 52px;
						}
						.mentor-name {
							font-weight: 700;
							font-size: 22.5258px;
							line-height: 28px;
						}
						button {
							border: 1px solid #ffbc00;
							background-color: #fff;
							font-weight: 300;
							font-size: 11px;
							line-height: 14px;
							color: #ffbc00;
							padding: 2px 9px;
							height: 18px;
							border-radius: 100px;
						}
						.department-text {
							font-weight: 300;
							font-size: 14.53px;
							line-height: 18px;
							color: #686868;
							margin-top: 9px;
						}
					}
					.period-wrap {
						font-weight: 300;
						font-size: 15px;
						line-height: 19px;
						display: flex;
						justify-content: space-between;
						align-items: end;
						margin-top: 14px;
						.period-text {
							color: #b4b4b4;
						}
						.day {
							color: #000;
							margin-top: 8px;
						}
						.circular-progress-bar {
							width: 74px;
							height: 77px;
							border-radius: 50%;
							border: 2px solid #e9e9e9;
							position: relative;
							span {
								text-align: center;
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								font-weight: 500;
								font-size: 14px;
								line-height: 17px;
								color: #ffbc00;
							}
							::after {
								position: absolute;
								content: '';
								top: -3px;
								left: 50%;
								transform: translateX(-50%);
								width: 4px;
								height: 4px;
								border-radius: 50%;
								background-color: #ffbc00;
							}
						}
					}
				}
			}
			.bg {
				width: 98px;
				height: 211px;
				position: absolute;
				z-index: 1;
				top: 0px;
				right: 0;
				background: linear-gradient(
					270deg,
					#ffffff 20.41%,
					rgba(255, 255, 255, 0) 100%
				);
			}
		}
	}
	@media ${(props) => props.theme.mobile} {
		.card-wrapper {
			margin-top: 17px;
			max-width: calc(100% - 20px);
			overflow: visible;
			.swiper {
				/* overflow: visible !important; */
			}
			.swiper-slide {
				/* width: 250px !important; */
			}
			.profile-card-list {
				.profile-card {
					padding: 20px;
					border-radius: 12px;

					.profile-box {
						justify-content: flex-start;
						gap: 10px;
						.profile-info-box {
							margin-left: 0px;
						}
						img {
							width: 36px;
							height: 36px;
						}
						.mentor-name {
							font-size: 16px;
							line-height: 20px;
						}
						button {
							display: none;
						}
						.department-text {
							font-size: 12px;
							line-height: 15px;
							margin-top: 4px;
						}
					}
					.icon-close {
						display: none;
					}
					.period-wrap {
						font-size: 12px;
						line-height: 15px;
						margin-top: 14px;
						.day {
							margin-top: 5px;
						}
						.circular-progress-bar {
							width: 50px;
							height: 52px;
							border: 1px solid #e9e9e9;
							span {
								font-size: 12px;
								line-height: 15px;
							}
							::after {
								top: -2px;
							}
						}
					}
				}
			}
			.bg {
				width: 20px;
				background: linear-gradient(
					254.99deg,
					rgba(255, 255, 255, 0.6) 32.7%,
					rgba(255, 255, 255, 0) 52.36%
				);
			}
		}
	}
`;
