import { useEffect, useState } from 'react';
import ModalClose from '../../../../../vector/ModalClose';
import ModalPrev from '../../../../../vector/ModalPrev';
import { Wrapper } from './styled';
import NoneIcon from '../../../../../vector/NoonIcon';
import PlusIcon from '../../../../../vector/PlusIcon';
import RemoveIcon from '../../../../../vector/RemoveIcon';
import Activity from '../Activity';
import { AddMentee, GetActivity } from '../../../../../api/mentorings';
import Empty from '../../../../../components/empty';

export default function Mypageactivity({ mypageacticity, activity }) {
	const [tempData, setTempData] = useState(true);

	// const [show6, setShow6] = useState(false);
	// const activity = () => {
	// 	setShow6((p) => !p);
	// };

	const [activities, setActivities] = useState([]);

	useEffect(() => {
		GetActivity({})
			.then((res) => {
				console.log(res);
				if (res?.data?.code == 200) {
					setActivities(res?.data?.body?.posts);
				} else {
					setActivities(null);
				}
			})
			.then((err) => {
				console.log(err);
			});
	}, []);

	return (
		<>
			<Wrapper>
				<section className="modal-wrapper">
					<div className="overlay" onClick={mypageacticity}></div>
					<div className="modal-wrap">
						<i className="icon-close" onClick={mypageacticity}>
							<ModalClose />
						</i>
						<h2 className="modal-title">
							{/* {tempData ? '[김철수 멘토] 활동 내역' : '멘토링 활동 내역'} */}
							멘토링 활동 내역
						</h2>
						<div className="modal-box">
							<div className="modal-list-box">
								<p className="moadl-text">활동일</p>
								<p className="moadl-text">참여 멘티</p>
							</div>
							<div className="modal-list-wrap">
								{activities?.length ? (
									activities.map((item, key) => (
										<>
											<div className="modal-list">
												<p className="date">{item?.date}</p>
												<p className="name">
													{item?.name?.map((i, k) => {
														if (k + 1 == item?.name?.length) {
															return i;
														} else {
															return i + ', ';
														}
													})}
												</p>
												<i
													onClick={() => alert('준비중입니다.')}
													className="icon-remove"
												>
													<RemoveIcon />
												</i>
											</div>
										</>
									))
								) : (
									<div className="none-box">
										<i className="icon-none">
											<NoneIcon />
										</i>
										<p className="none-text">멘토링 활동 내역이 없습니다.</p>
									</div>
								)}
							</div>

							<div className="modal-bg"></div>
						</div>

						<div className="btn-wrap">
							<button onClick={mypageacticity}>닫기</button>
							<button onClick={activity} className="yellow-btn">
								<i className="icon-plus">
									<PlusIcon />
								</i>
								활동추가
							</button>
						</div>
					</div>
				</section>
			</Wrapper>
			{/* {show6 ? <Activity activity={activity} /> : ''} */}
		</>
	);
}
