export default function Currentcon() {
  return (
    <>
      <svg
        width="46"
        height="46"
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="23" cy="23" r="23" fill="white" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23 0C10.2977 0 0 10.2977 0 23C0 35.7023 10.2977 46 23 46C35.7023 46 46 35.7023 46 23C46 10.2977 35.7023 0 23 0ZM32.9695 19.1109C33.153 18.9011 33.2928 18.6566 33.3805 18.392C33.4682 18.1273 33.5021 17.8478 33.4802 17.5699C33.4583 17.2919 33.381 17.0212 33.2529 16.7735C33.1248 16.5259 32.9485 16.3064 32.7343 16.1279C32.5201 15.9494 32.2724 15.8156 32.0057 15.7342C31.7391 15.6529 31.4588 15.6257 31.1815 15.6543C30.9041 15.6828 30.6353 15.7666 30.3908 15.9006C30.1463 16.0346 29.9311 16.2161 29.7578 16.4345L20.7669 27.2215L16.1146 22.5672C15.7203 22.1863 15.1921 21.9756 14.6439 21.9803C14.0957 21.9851 13.5712 22.205 13.1836 22.5927C12.7959 22.9803 12.576 23.5047 12.5712 24.053C12.5665 24.6012 12.7772 25.1294 13.1581 25.5237L19.4308 31.7965C19.6363 32.0018 19.8822 32.162 20.153 32.267C20.4239 32.372 20.7136 32.4194 21.0037 32.4063C21.2939 32.3931 21.5781 32.3196 21.8383 32.1905C22.0985 32.0614 22.3289 31.8795 22.5149 31.6564L32.9695 19.1109Z"
          fill="#DBDBDB"
        />
      </svg>
    </>
  );
}
