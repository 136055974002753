import { useState } from 'react';
import { IFrameWrapper } from './styled';
import { useRef } from 'react';
import { useEffect } from 'react';

export default function IFeame({ modalToggle, Feame }) {
	const iframeRef = useRef();

	useEffect(() => {
		// setTimeout(() => {
		// 	const message = localStorage.getItem('user_token');
		// 	iframeRef.current.contentWindow.postMessage(message, '*');
		// }, [500]);
	}, []);

	return (
		<IFrameWrapper className={Feame ? 'frame active' : 'frame'}>
			<section className="modal-wrapper">
				{/* {Feame ? <div className="overlay" onClick={feameToggle}></div> : null} */}

				<div className={'frame'}>
					<div className="bar" onClick={modalToggle} />
					<div className="bar2" onClick={modalToggle} />
					{localStorage.getItem('user_token') ? (
						<iframe
							// ref={iframeRef}
							width={'100%'}
							height={'100%'}
							// src={`https://main.d1qfe22mtcn7xe.amplifyapp.com/?userToken=${
							// 	localStorage.getItem('user_token')
							// 		? localStorage.getItem('user_token')
							// 		: ''
							// }`}
							src={`https://esg.kbds.co.kr/?userToken=${
								localStorage.getItem('user_token')
									? localStorage.getItem('user_token')
									: ''
							}`}
							// src={`https://main.d1qfe22mtcn7xe.amplifyapp.com?userToken=${
							// 	localStorage.getItem('user_token')
							// 		? localStorage.getItem('user_token')
							// 		: ''
							// }`}
							// scrolling="no"
						></iframe>
					) : (
						'새로고침 필요'
					)}
				</div>
			</section>
		</IFrameWrapper>
	);
}
