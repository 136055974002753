import { useEffect, useRef, useState } from 'react';
import ModalClose from '../../../../../vector/ModalClose';
import ModalPrev from '../../../../../vector/ModalPrev';
import { Wrapper } from './styled';
import NoneIcon from '../../../../../vector/NoonIcon';
import { useSelector } from 'react-redux';
import { QRCodeCanvas } from 'qrcode.react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { GetUserOther } from '../../../../../api/user';
import CryptoJS from 'crypto-js';

export default function Card2({ modalToggle, activity, name }) {
	const [isOpen, setIsOpen] = useState(false);
	const [isOpen2, setIsOpen2] = useState(false);
	const [isActive, setIsActive] = useState(false);

	const [encryptedText, setEncryptedText] = useState('');

	const encryptData = () => {
		if (user?.ID) {
			const key = 'dnjdlek'; // Replace with your secret key
			const encrypted = CryptoJS.AES.encrypt(user?.ID, key).toString();
			setEncryptedText(encodeURIComponent(encrypted));
		}
	};

	useEffect(() => {
		if (user?.ID) {
			encryptData();
		}
	}, [user?.ID]);

	// useEffect(() => {
	// 	if (localStorage.getItem('user_token')) {
	// 		// 수시로 토큰 체크
	// 		isLogined().then((response) => {
	// 			if (response.data.data.status == 200) {
	// 				alert(1);
	// 			}
	// 		});
	// 	}
	// }, []);

	const params = useParams();
	const user_id = params.id;
	const [p, setP] = useState(false);

	const [user, setUser] = useState();
	useEffect(() => {
		GetUserOther({
			user_id,
		})
			.then((response) => {
				console.log(response.data.body);
				if (response.data.code == 200) {
					setUser(response.data.body);
				}
				setP(true);
			})
			.catch((err) => {
				setP(true);
				console.log(err);
			});
	}, []);

	useEffect(() => {
		document.body.classList.add('card');

		return function cleanup() {
			document.body.classList.remove('card');
		};
	}, []);

	// const user = JSON.parse(localStorage.getItem('user_data'));

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
		setIsActive(!isActive);
	};

	const qrToggle = () => {
		setIsOpen2(!isOpen2);
	};

	const [isOther, setOther] = useState(false);
	console.log(123, user);

	const copy_input = useRef('');

	const CopyLink = (e) => {
		e.preventDefault();
		copy_input.current.select();
		document.execCommand('copy');
		e.target.focus();
		alert('복사완료');
	};
	const close = () => {
		window.close();
		window.opener = window.location.href;
		// window.open('about:blank', '_self').close();
	};

	console.log(user);
	return (
		<>
			{p ? (
				<Wrapper>
					<section className={`modal-wrapper ${isOpen ? 'open' : ''}`}>
						<div className="overlay" onClick={modalToggle}></div>
						<div className={`modal-wrap ${isOpen ? 'open' : ''}`}>
							<i onClick={() => window.close()} className="icon-back">
								<img src={'/icon-close04.svg'} alt="" onClick={modalToggle} />
							</i>
							<div
								className="user"
								style={{
									backgroundImage: `url(${user?.user_avatar_url_original})`,
								}}
							></div>
							<div className={`toggle ${isOpen ? 'open' : ''}`}>
								<div className="toggle-btn2" onClick={toggleDropdown}>
									<img
										src={
											isActive ? '/icon-down-arrow.svg' : '/icon-top-arrow.svg'
										}
										alt=""
									/>
								</div>
								{1 && (
									<div className={`content ${isOpen ? 'open' : ''}`}>
										<div className="flex t">
											<div>
												<a href={`tel:${user?.user_phone}`}>
													<img src="/images/icon-address.svg" alt="" />
													<p>주소록에 추가</p>
												</a>
											</div>
											<div>
												<a
													href={`${user?.user_avatar_url}`}
													download
													target="_blank"
													rel="noreferrer"
												>
													<img src="/images/icon-down.svg" alt="" />
													<p>이미지 저장</p>
												</a>
											</div>
										</div>
										<div className="line"></div>
									</div>
								)}

								<div className="flex">
									<div className="left">
										<h1>{user?.user_name}</h1>
										{/* <span>대리</span> */}
									</div>
									<div className="right">
										<p>{user?.user_department}</p>
									</div>
								</div>
								<div className="info phone">
									<img src="/phone_icon.svg" alt="" />
									{user?.user_phone}
								</div>
								<div className="info mail">
									<img src="/envelope_icon.svg" alt="" />
									{user?.user_email}
								</div>
							</div>

							<div className="footer">
								<div className="logo-box">
									<img src="/card-logo03.png" alt="" />
								</div>
							</div>
							{isOpen2 && (
								<div className="qr-box">
									<div className="overlay"></div>
									<div className="qrcode">
										<div className="qr">
											<p>{user?.user_name}</p>
											{/* <div
											className="code"
											style={{ backgroundImage: `url(/images/qr.png)` }}
										></div> */}
											<QRCodeCanvas
												value={`https://blog.kbds.co.kr/card/${encryptedText}`}
											/>
										</div>
										<div className="close" onClick={qrToggle}>
											<img src="/images/modal-close.svg" alt="" />
										</div>
									</div>
								</div>
							)}
						</div>
					</section>
					<input
						type="text"
						ref={copy_input}
						name="copy_account"
						value={
							encryptedText
								? `https://blog.kbds.co.kr/card/${encryptedText}`
								: ''
						}
					/>
				</Wrapper>
			) : null}
		</>
	);
}
