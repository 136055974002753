import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const useScript = () => {
	const user_data = useSelector((state) => state.auth.user_data);
	// 426ddae6a66b985f0417a1ea56a5975c
	useEffect(() => {
		if (user_data) {
			const as = () => {
				const script = document.createElement('script');
				script.async = true;
				script.innerHTML = `
                Lillo.init('7721bac954bd7460ex532f01zh569o1k0');

                // const router = typeof window !== 'undefined' ? window.location.href : ''; // 현재 url
                // const ref =
                //     typeof document !== 'undefined'
                //         ? typeof document.referrer !== 'undefined'
                //         ? document.referrer
                //         : ''
                //         : ''; // 이전 페이지
                // const title = typeof document !== 'undefined' ? document.title : '';

                // const pageViewData = {
                //     pageUrl: router,
                //     pageRef: ref,
                //     pageTitle: title,
                // };

                // Lillo.pageView(pageViewData); // pageView;

            `;
				console.log('script', script);

				document.body.appendChild(script);

				return () => {
					document.body.removeChild(script);
				};
			};
			setTimeout(() => {
				as();
			}, 0);
		}
	}, [user_data]);

	useEffect(() => {
		// const user_data = JSON.parse(localStorage.getItem('user_data'));

		if (user_data) {
			const signupScript = document.createElement('script');
			signupScript.id = 'lillo-script';
			signupScript.async = true;

			// const scriptElement = document.getElementById('lillo-script');
			// if (scriptElement) {
			// 	return;
			// }

			signupScript.innerHTML = `
                LilloHook.on('init',  function() {
                    console.log(2)
                    if(true) {
                        if(false) {
                            setTimeout(() => LilloHook.trigger('init', 'success'), 2000);
                            console.log(3)

                            return;
                        } else {
                            const data = {
                                userLogin: '${user_data?.user_login}',
                                userName:'${user_data?.user_name}',
                                userEmail:'${user_data?.user_email}',
                            };

                            console.log(data,'아아아');
                            Lillo.signUp(data); // signup
                        };
                    };
                    
                    let url = typeof window !== 'undefined' ? window.location.href : ''; // 현재 url
                    let ref = typeof document !== 'undefined' ? (typeof document.referrer !== 'undefined' ? document.referrer : '') : ''; // 이전 페이지	
                    let title = typeof document !== 'undefined' ? document.title : '';
                        const pageViewData = {
                        pageUrl: url,
                        pageRef: ref,
                        pageTitle: title,
                    }
                    Lillo.pageView(pageViewData); // pageView
                });
        `;
			console.log('signupScript', signupScript);
			document.body.appendChild(signupScript);

			return () => {
				document.body.removeChild(signupScript);
			};
		}
	}, [user_data]);
};

export default useScript;
