import styled from 'styled-components';

export const Wrapper = styled.div`
	display: none;
	.modal-wrapper {
		.modal-wrap {
			position: absolute;
			z-index: 999;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: #fff;
			width: 700px;
			height: 690px;
			overflow-x: hidden;
		}
	}

	@media ${(props) => props.theme.mobile} {
		position: fixed;
		z-index: 1111;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
		.overlay {
			width: 100%;
			height: 100%;
			background-color: #00000030;
		}
		.modal-wrapper {
			width: 100%;
			height: 100%;
			.modal-wrap {
				width: 100%;
				/* height: calc(100vh - 97px); */
				height: 100%;
				top: 0;
				left: 0;
				transform: unset;
				transition: 0.3s ease-in-out;
				/* padding: 0 0 0 22px; */
				overflow-y: auto;

				.top {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 17px 30px 17px 22px;
					margin-bottom: 10px;
					.tit-box {
						display: flex;
						align-items: center;
						gap: 8px;
						h2 {
							font-family: KBFG Text;
							font-size: 16px;
							font-weight: 700;
							line-height: 150%;
						}
					}
				}

				.name-card-wrapper {
					padding: 0px 22px;
					.slick-list {
						overflow: visible;
					}
				}
				.slick-slider {
					min-height: 426px;
					/* height: calc(100vh - 200px); */
					position: relative;
					margin-left: -5px;
					margin-right: -5px;
				}
				.slick-track {
				}
				.slick-slide {
					padding: 0px 5px;
				}
				.slick-list {
					height: 100%;
				}

				.slick-dots li {
					width: unset;
					height: unset;
				}

				.slick-dots li.slick-active button {
					opacity: 1;
				}

				.slick-dots li.slick-active:last-child button {
					opacity: 1;
				}
				.slick-dots {
					position: unset;
					margin-top: 6px;
					button {
						width: 30px;
						height: 4px;
						background-color: #776c61;
						padding: 0;
						border-radius: 30px;
						opacity: 0.2;

						&::before {
							display: none;
						}
					}
				}

				.user-box {
					padding: 26px 30px 38px 30px;
					border: 1px solid rgba(0, 0, 0, 0.04);
					border-radius: 20px;
					/* min-height: 426px; */
					height: 0;
					padding-bottom: 134.82%;
					.logo {
						width: 135px;
						img {
							width: 100%;
						}
					}
					.name-box {
						margin-top: 68px;
						h1 {
							font-family: KBFG Text;
							font-size: 7.2vw;
							font-weight: 700;
							line-height: 140%;
							letter-spacing: 15.08px;
							margin-bottom: 12px;
						}
						.position {
							display: flex;
							gap: 10px;
							p {
								font-family: KBFG Text;
								font-size: 4.1vw;
								opacity: 0.6;
								font-weight: 500;
								line-height: 150%;
							}
							.department {
								position: relative;
								&:after {
									position: absolute;
									content: '';
									width: 1px;
									height: 13px;
									background-color: #666;
									right: -5px;
									top: 3px;
								}
							}
						}
					}
					.address-box {
						margin-top: 74px;
						.address {
							font-family: KBFG Text;
							font-size: 3.8vw;
							font-weight: 500;
							line-height: 150%;
							opacity: 0.5;
							word-break: keep-all;
							margin-bottom: 14px;
						}
						.tell {
							display: flex;
							gap: 30px;
						}
						.flex {
							display: flex;
							gap: 13px;
							align-items: center;
							margin-top: 6px;
							h6 {
								font-family: KBFG Text;
								font-size: 3.8vw;
								font-weight: 700;
								line-height: 150%;
								opacity: 0.4;
							}
							p {
								font-size: 3.8vw;
								font-weight: 500;
								line-height: 150%;
								white-space: nowrap;
							}
						}
						.flex2 {
							gap: 8px;
						}
					}
				}
				.career-box {
					position: relative;
					border-radius: 20px;
					background: linear-gradient(438deg, #776c61 23.62%, #a19487 95.32%);
					border-radius: 20px;
					padding: 26px 30px 38px 30px;
					color: #fff;
					/* height: calc(100vh - 200px); */
					height: 0;
					padding-bottom: 134.82%;
					.top-menu {
						display: flex;
						align-items: center;
						justify-content: space-between;
						.career {
							display: flex;
							align-items: center;
							gap: 8px;
						}
						p {
							font-family: KBFG Text;
							font-size: 3.3vw;
							font-weight: 600;
							line-height: 140%;
						}
					}
					.user-info {
						margin-top: 30px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						border-bottom: 1px solid rgba(255, 255, 255, 0.2);
						padding-bottom: 20px;
						.txt {
							h2 {
								font-family: KBFG Text;
								color: rgba(255, 255, 255, 0.4);
								font-size: 6.1vw;
								font-weight: 600;
								line-height: 140%;
							}
							h3 {
								font-family: KBFG Text;
								font-size: 6.1vw;
								font-weight: 600;
								line-height: 140%;
							}
						}
						.user {
							width: 54px;
							height: 54px;
							background-size: cover;
							background-position: center;
							border-radius: 50%;
						}
					}

					.detail {
						margin-top: 30px;
						h3 {
							font-family: KBFG Text;
							font-size: 3.8vw;
							font-weight: 700;
							line-height: 150%;
							margin-bottom: 8px;
						}
						p {
							font-family: KBFG Text;
							font-size: 3.6vw;
							font-weight: 300;
							line-height: 150%;
							/* max-width: 199px;
							width: 100%; */
							word-break: keep-all;
						}
					}
					.tag-list {
						margin-top: 18px;
						display: flex;
						flex-wrap: wrap;
						gap: 10px;
						max-height: 14.8vw;
						overflow: hidden;
						.tag {
							border-radius: 90px;
							background: rgba(255, 255, 255, 0.14);
							padding: 1.2vw 2.8vw;
							display: inline-block;
							font-family: KBFG Text;
							font-size: 3.3vw;
							font-weight: 500;
							line-height: 150%;
						}
					}
					.share-box {
						padding: 0 18px;
						position: absolute;
						bottom: 18px;
						left: 0;
						width: 100%;
						.share {
							border-radius: 54px;
							background: rgba(0, 0, 0, 0.3);
							display: flex;
							gap: 6px;
							padding: 10px 14px;
							width: 100%;
							span {
								color: #fff;
								font-family: KBFG Text;
								font-size: 12px;
								font-weight: 300;
								line-height: 150%;
								overflow: hidden;
								display: -webkit-box;
								-webkit-line-clamp: 1;
								-webkit-box-orient: vertical;
							}
						}
					}
				}
				.tab-menu {
					display: flex;
					justify-content: center;
					gap: 8px;
					margin-top: 18px;
					.tab {
						width: 30px;
						height: 4px;
						opacity: 0.2;
						background: #776c61;
						border-radius: 30px;
						&.active {
							opacity: 1;
						}
					}
				}

				/* .user {
					width: 100%;
					height: calc(100% - 180px);
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
					position: absolute;
					top: 0;
				} */
			}
			.qr-wrap {
				padding: 0 22px;
				.qr-box {
					margin-top: 20px;
					background: linear-gradient(448deg, #fcaf16 23.62%, #ffd176 95.32%);
					min-height: 340px;
					border-radius: 24px 24px 0px 0px;
					padding: 22px 24px;
					.tit-box {
						display: flex;
						align-items: center;
						gap: 8px;
						color: #fff;
						font-family: KBFG Text;
						font-size: 12px;
						font-weight: 700;
						line-height: 140%;
					}
					.qrcode-box {
						width: 156px;
						height: 156px;
						margin: 40px auto 0 auto;
						canvas {
							width: 156px !important;
							height: 156px !important;
							padding: 8px;
							border-radius: 6px;
							background: #fff;
						}
					}
					.flex {
						margin-top: 16px;
						display: flex;
						justify-content: center;
						gap: 6px;
						align-items: center;
						p {
							font-family: KBFG Text;
							font-size: 12px;
							font-weight: 500;
							line-height: 140%;
						}
					}
				}
			}

			.toggle {
				padding: 30px;
				position: absolute;
				bottom: -20px;
				z-index: 10;
				width: 100%;
				/* transform: translateY(-166px); */
				transition: all 0.3s;
				border-radius: 22px;
				border: 1px solid #eee;
				background: #fff;
				height: 162px;
				&.open {
					height: 290px;
				}
				.content {
					.flex {
						display: flex;
						justify-content: center;
						gap: 76px;
						text-align: center;
						p {
							color: #666;
							margin-top: 10px;
							font-size: 13px;
							font-weight: 400;
							line-height: normal;
							text-decoration: none;
						}
					}
					.line {
						border-top: 1.5px solid #fbfbfb;
						margin: 20px -30px 0 -30px;
						position: relative;
						top: -5px;
					}
				}

				.toggle-btn2 {
					position: absolute;
					top: -20px;
					left: 50%;
					transform: translateX(-50%);
					z-index: 101;
				}
				.flex {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.left {
						display: flex;
						align-items: end;
						gap: 10px;
						h1 {
							font-size: 24px;
							font-weight: 700;
							letter-spacing: 0.48px;
						}
						span {
							font-size: 13px;
							font-weight: 500;
						}
					}
					.right {
						display: flex;
						gap: 8px;
						p {
							display: flex;
							font-size: 13px;
							font-weight: 400;
						}
					}
				}
				.cont {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					padding: 30px;
					overflow: hidden;
					z-index: 100;
					border-radius: 22px;
					background-color: #fff;
					&.open {
					}
				}
				.info {
					color: #999;
					font-size: 14px;
					font-weight: 600;
					letter-spacing: 0.56px;
					img {
						margin-right: 8px;
					}
				}
				.phone {
					margin-top: 14px;
				}
				.mail {
					margin-top: 11px;
				}
			}
		}
	}
	.qr-box {
		.overlay {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			z-index: 100;
			background: rgba(0, 0, 0, 0.8);
		}
		.qrcode {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 100;
			width: 186px;
			height: 283px;
			.qr {
				border-radius: 12px;
				background: #fff6c9;
				width: 186px;
				height: 213px;
				padding: 15px;
				p {
					color: #000;
					font-size: 14px;
					font-weight: 600;
					letter-spacing: 0.28px;
					margin-bottom: 10px;
				}
				canvas {
					border-radius: 6px;
					background: #fff;
					padding: 8px;
					width: 156px !important;
					height: 156px !important;
					background-size: 140px;
					background-repeat: no-repeat;
					background-position: center;
				}
			}
		}
	}
`;
