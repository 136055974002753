import axios from './defaultClient';

const nameSpace = '/ep/v1/daitso';

export const GetMentorings = async (args) => {
	return await axios.get(nameSpace + '/mentorings', {
		params: args,
	});
};
export const GetMentoringsByID = async (args) => {
	return await axios.get(nameSpace + '/mentorings/' + args.term_id, {
		params: args,
	});
};
export const AddMentee = async (args) => {
	return await axios.post(nameSpace + '/mentorings/add/mentee', args);
};
export const AddMentor = async (args) => {
	return await axios.post(nameSpace + '/mentorings/add/mentor', args);
};

export const GetMentees = async (args) => {
	return await axios.get(nameSpace + '/mentorings/mentees', args);
};
export const AddActivity = async (args) => {
	return await axios.post(nameSpace + '/mentorings/add/activity', args);
};
export const GetActivity = async (args) => {
	return await axios.get(nameSpace + '/mentorings/activity', {
		params: args,
	});
};
export const togglePaddlet = async (args) => {
	return await axios.post(nameSpace + '/user/padlet', args);
};

export const addPadlet = async (args) => {
	return await axios.post(nameSpace + '/user/add/padlet', args);
};
