import React from 'react';
import {
    CardWrapper,
    CardStyle,
    CardBadge,
    Title,
    Date,
    Tag,
    Tags,
    Author,
    Avatar
} from './styled'
import LoadInView from '../../components/LoadInView';
const Card = (props) => {
    const item = {
        post_title: props.item ? props.item.post_title : '금융/트랜드',
        cate_name: props.item ? props.item.cate_name : 'KB데이타시스템',
        post_tags: props.item ? props.item.post_tags : '',
        post_date: props.item ? props.item.post_date : '2020.02.01',
        post_image: props.item ? props.item.post_image : '',
        post_author_name: props.item ? props.item.post_author_name : '',
        post_author_img: props.item ? props.item.post_author_img : '',
        views_count: props.item ? props.item.views_count : '',
        event_info: props.item ? props.item.event_info : 0,
    }
    const LinktoPost = () => {
        window.location.href='/story/'+props.item.ID;
    }
    return (
        <CardWrapper width={props.width} onClick={LinktoPost}>
            <LoadInView>
                <CardStyle postImage={item.post_image}>
                    <CardBadge>
                        {item.cate_name}
                    </CardBadge>
                    <Title dangerouslySetInnerHTML={{
                            __html: item.post_title
                        }}>
                    </Title>
                    <Date>
                        {item.post_date}
                    </Date>
                    {/* <Date>
                        조회수 : {item.views_count}
                    </Date> */}

                    
                    <Tags>
                        {item.post_tags ? item.post_tags.map((tag, key) => (<Tag>#{tag.name}</Tag>)) : '' }
                        {item.event_info.title ? (<Tag is_event={true}>{'#'+item.event_info.title} 이벤트</Tag>) : '' }
                    </Tags>
                    <Author>
                        <span>{item.post_author_name}</span>
                        <Avatar image={item.post_author_img} alt=""/>
                    </Author>
                </CardStyle>
            </LoadInView>
        </CardWrapper>
    )
}


export default Card;