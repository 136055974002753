import axios from './defaultClient';

const nameSpace = '/ep/v1/user';
export const GetUser = async (args) => {
	return await axios.get(nameSpace + '/' + args.user_id, {});
};

export const GetUserMe = async (args) => {
	return await axios.get(nameSpace + '/me', {});
};

export const GetUserOther = async (args) => {
	return await axios.get(nameSpace + '/info/' + args.user_id, {});
};

export const PostVolunteer = async (args) => {
	return await axios.post(nameSpace + '/add_volunteer/', args, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
};

export const GetAllUsers = async (args) => {
	return await axios.get(nameSpace + '/all', {});
};
