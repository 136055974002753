import React from 'react';
import { CommonContainer } from '../../../../components/Grid';
import {
  ContentWrapper,
  FigureIcon,
  Blockquote,
  Img,
  PostContent,
} from '../styled';

const SectionContent = props => {
  console.log('SectionContent', props.post_data);
  return (
    <ContentWrapper>
      <CommonContainer className="container-post_content">
        <CommonContainer type="small">
          <div className="download">
            <a download target="_blank" rel="noreferrer" href={props.file}>
              {props.file_title}
            </a>
          </div>
          {props?.post_data?.isSimple ? (
            <PostContent
              dangerouslySetInnerHTML={{
                __html:
                  `<img src="${props.post_image}" />` + props.post_content,
              }}
            ></PostContent>
          ) : (
            <PostContent
              dangerouslySetInnerHTML={{
                __html: props.post_content,
              }}
            ></PostContent>
          )}
        </CommonContainer>
      </CommonContainer>
    </ContentWrapper>
  );
};
export default SectionContent;
