export default function QuestionIcon() {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.3957 19.4376L25.5207 21.3542C24.0207 22.8542 23.0832 24.1251 23.0832 27.2501H18.9165V26.2084C18.9165 23.8959 19.854 21.8126 21.354 20.3126L23.9373 17.6876C24.7082 16.9376 25.1665 15.8959 25.1665 14.7501C25.1665 13.645 24.7275 12.5852 23.9461 11.8038C23.1647 11.0224 22.1049 10.5834 20.9998 10.5834C19.8948 10.5834 18.835 11.0224 18.0536 11.8038C17.2722 12.5852 16.8332 13.645 16.8332 14.7501H12.6665C12.6665 12.5399 13.5445 10.4203 15.1073 8.85752C16.6701 7.29472 18.7897 6.41675 20.9998 6.41675C23.21 6.41675 25.3296 7.29472 26.8924 8.85752C28.4552 10.4203 29.3332 12.5399 29.3332 14.7501C29.3302 16.5068 28.6339 18.1914 27.3957 19.4376ZM23.0832 35.5834H18.9165V31.4167H23.0832M20.9998 0.166748C18.264 0.166748 15.5549 0.705618 13.0273 1.75259C10.4996 2.79956 8.203 4.33414 6.26845 6.26869C2.36144 10.1757 0.166504 15.4747 0.166504 21.0001C0.166504 26.5254 2.36144 31.8245 6.26845 35.7315C8.203 37.666 10.4996 39.2006 13.0273 40.2476C15.5549 41.2945 18.264 41.8334 20.9998 41.8334C26.5252 41.8334 31.8242 39.6385 35.7312 35.7315C39.6382 31.8245 41.8332 26.5254 41.8332 21.0001C41.8332 9.47925 32.4582 0.166748 20.9998 0.166748Z"
        fill="#8A8A8A"
      />
    </svg>
  );
}
