import styled from 'styled-components';

export const ProfileCardWrapper = styled.div`
  width: 664px
  min-width: 664px !important;
  margin: 0 auto;
  display: flex;
  background-color: #fff9d9;
  padding: 34px 48px;
  border-radius: 26px;
  margin-bottom: 24px;
  cursor:pointer;
    @media ${(props) => props.theme.laptop} {
    width: 100%;
    min-width:auto;
    padding: 18px 20px;
    flex-direction: column;
  }
      @media ${(props) => props.theme.tablet} {
    width: 444px;
    min-width:auto;
    padding: 18px 20px;
    flex-direction: column;
  }
  @media ${(props) => props.theme.mobile} {
    width: 100%;
    min-width:auto;
    padding: 18px 20px;
    flex-direction: column;
  }
  .user-card {
    display: flex;
  }

  .user {
    display: flex;
    align-items: center;
    

    .user-thumbnail {
      width: 74px;
      height: 74px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      @media ${(props) => props.theme.laptop} {
        width: 60px;
        height: 60px;
      }
      @media ${(props) => props.theme.mobile} {
        width: 50px;
        height: 50px;
      }
    }

    .user-info {
      display: flex;
      flex-direction: column;
      margin-left: 24px;
         @media ${(props) => props.theme.mobile} {
       margin-left:16px;
      }

      .department {
        color: gray;
        margin-bottom: 10px;
        font-weight: 300;
        font-size: 18px;
        letter-spacing: -0.02em;
        color: #686868;
        @media ${(props) => props.theme.mobile} {
          font-size: 12px;
        }
      }
      .name {
        font-weight: 700;
        font-size: 26px;
        letter-spacing: -0.02em;
        @media ${(props) => props.theme.mobile} {
          font-size: 20px;
        }
      }
      .description {
        color: gray;
        margin-top: 18px;
        font-weight: 300;
        font-size: 18px;
        letter-spacing: -0.02em;
        width:240px;  
        line-height:20px;
        @media ${(props) => props.theme.mobile} {
          font-size: 12px;
          margin-top:8px;
        }
      }
    }
  }

  .user-contact {
    display: flex;
    flex-direction: column;
    justify-content: end;
        @media ${(props) => props.theme.laptop} {
 margin-top: 18px;
  }
    @media ${(props) => props.theme.mobile} {
      margin-top: 16px;
    }
    span {
      font-weight: 300;
      font-size: 16px;
      @media ${(props) => props.theme.mobile} {
        font-size: 12px;
      }
    }

    div {
      display: flex;
      align-items: center;
      text-align: left;
      margin-bottom: 12px;
      @media ${(props) => props.theme.mobile} {
        margin-bottom: 8px;
      }
      .mail-icon {
        margin-right: 28px;
        @media ${(props) => props.theme.mobile} {
          margin-right: 20px;
        }
      }
      .phone-icon {
        margin-right: 26px;
        @media ${(props) => props.theme.mobile} {
          margin-right: 18px;
        }
      }
    }
  }
`;
