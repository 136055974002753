import styled from 'styled-components';
const HeaderWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 59px;
    @media (max-width: 768px) {
      padding-top: 26px;
      font-weight: 700;
      font-size: 22px;
      line-height: 160%;
    }
  }
  .main-page-btn {
    display: flex;
    width: 228px;
    height: 56px;
    margin-left: 22px;
    background-color: black;
    color: white;
    font-weight: 700;
    padding: 0 32px;
    border: none;
    background: #000000;
    border-radius: 24px;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    @media (max-width: 768px) {
      width: 174px;
      border-radius: 50px;

      z-index: 999;
      padding: 12px 20px;
      color: white;
      background: black;

      border: none;
      font-size: 12px;
      font-weight: 700;
      i {
        margin-top: 4px;
        margin-right: 8px;
      }
    }
    :hover {
      background: #000000c1;
      transition: 0.1s ease-out;
    }
    i {
      margin-right: 10px;
    }
  }
`;
export default function Header() {
  return (
    <HeaderWrapper>
      <h1 className="top-title">My Page</h1>
    </HeaderWrapper>
  );
}
