export default function ArrowRightTop() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.229 16L14.2012 1.84534" stroke="black" stroke-width="2" />
      <path
        d="M3.42326 1.84521H14.2334V13.6408"
        stroke="black"
        stroke-width="2"
      />
    </svg>
  );
}
