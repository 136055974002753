import styled from "styled-components";

export const SliderWrapper = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  .overlay {
    width: 100%;
    height: 100%;
    background-color: #00000030;
  }
  .modal-wrapper {
    width: 100%;
    height: 100vh;
    .modal-wrap {
      position: absolute;
      z-index: 999;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 40px 50px;
      background-color: #fff;
      border-radius: 20px;
      .icon-close {
        position: absolute;
        right: 40px;
        cursor: pointer;
        svg {
          width: 17px;
        }
      }
      .modal-title {
        margin-top: 40px;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
      }
      .modal-box {
        margin-top: 30px;
        border-top: 1px solid #e9e9e9;
        height: 488px;
        overflow-y: auto;
        .select-wrap {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          gap: 50px;
          padding: 0 30px;
          margin-top: 30px;
          .department-title {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
          }
          .cont-select {
            width: 420px;
            position: relative;
            .btn-select {
              width: 100%;
              padding: 26px 30px;
              border-radius: 20px;
              cursor: pointer;
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              border: none;
              text-align: left;
              background-image: url(icon-selectarrow.svg);
              background-repeat: no-repeat;
              background-position: 92% 50%;
              background-color: #f7f7f7;
              color: #888;
            }
            .btn-title {
              width: 100%;
              padding: 26px 30px;
              border-radius: 20px;
              cursor: pointer;
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              border: none;
              text-align: left;
              background-color: #f7f7f7;
              color: #888;
            }
            .list-member {
              background-color: #fff;
              border: 1px solid #e0e0e0;
              border-radius: 20px;
              margin-top: 6px;
              position: absolute;
              z-index: 1;
              width: 100%;
              display: none;
              padding-bottom: 10px;
              &.active {
                display: block;
              }
              li {
                padding: 0 10px;
                button {
                  width: 100%;
                  padding: 16px 20px;
                  border-radius: 20px;
                  cursor: pointer;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 20px;
                  border: none;
                  text-align: left;
                  background-color: #fff;
                  margin-top: 0;
                  &:hover {
                    background-color: #f7f7f7;
                  }
                }
                &:first-child {
                  padding-top: 12px;
                }
              }
            }
          }
        }
        .all-box {
          margin-top: 30px;
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          width: 578px;
          .post-card {
            width: calc((100% - 30px) / 2);
            height: 274px;
            background-image: url(post-bgimg.png);
            background-size: cover;
            border-radius: 20px;
            color: #fff;
            .post-text-card {
              padding: 20px;
              .top-btn {
                display: flex;
                justify-content: space-between;
                .categories {
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 24px;
                  background: rgba(255, 255, 255, 0.4);
                  display: inline-block;
                  padding: 2px 10px;
                }
                .more-btn {
                  cursor: pointer;
                  li {
                    width: 3px;
                    height: 3px;
                    background-color: #fff;
                    border-radius: 50%;
                    margin-top: 3px;
                    &:first-child {
                      margin-top: 0;
                    }
                  }
                }
              }
              .post-card-title {
                font-weight: 700;
                font-size: 19px;
                line-height: 27px;
                letter-spacing: 0.02em;
                margin-top: 12px;
              }
              .date {
                font-weight: 500;
                font-size: 12px;
                line-height: 27px;
                letter-spacing: 0.02em;
                color: rgba(255, 255, 255, 0.7);
                margin-top: 2px;
              }
              .tag-box {
                margin-top: 50px;
                .tag-list {
                  display: flex;
                  justify-content: space-between;
                  .tag-name-box {
                    .tag-name {
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 24px;
                    }
                  }
                }
                img {
                  width: 45px;
                  height: 47px;
                }
              }
            }
          }
        }
      }
      .gradient {
        width: 100%;
        height: 40px;
        background: linear-gradient(
          180deg,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        );
        transform: matrix(1, 0, 0, -1, 0, 0);
        position: absolute;
        bottom: 130px;
        left: 0;
      }
      .btn-wrap {
        margin-top: 30px;
        display: flex;
        justify-content: end;
        button {
          padding: 20px 63px;
          border: 1px solid #ffbc00;
          border-radius: 20px;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #ffbc00;
          background-color: #fff;
        }
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
    .modal-wrapper {
      .modal-wrap {
        padding: 30px 20px;
        border-radius: 10px;
        max-width: 300px;
        width: 100%;
        .icon-close {
          display: none;
        }
        .modal-title {
          font-size: 20px;
          line-height: 25px;
          margin-top: 0;
        }
        .modal-box {
          margin-top: 20px;
          height: 353px;
          .select-wrap {
            flex-wrap: wrap;
            gap: 14px;
            padding: 0 0px;
            margin-top: 20px;
            .department-title {
              font-size: 14px;
              line-height: 17px;
            }
            .cont-select {
              .btn-select {
                padding: 19px 20px;
                font-size: 12px;
                line-height: 15px;
                background-position: 95% 50%;
                background-size: 12px;
              }
              .btn-title {
                width: 100%;
                padding: 19px 20px;
                font-size: 12px;
                line-height: 15px;
              }
              .list-member {
                margin-top: 4px;
                &.active {
                  display: block;
                }
                li {
                  padding: 0 8px;
                  button {
                    padding: 16px 20px;
                    font-size: 12px;
                    line-height: 15px;
                    &:hover {
                      background-color: #f7f7f7;
                    }
                  }
                  &:first-child {
                    padding-top: 0px;
                  }
                }
              }
            }
          }
          .all-box {
            margin-top: 20px;
            gap: 10px;
            width: 250px;
            .post-card {
              width: 100%;
              height: 250px;
              border-radius: 8px;
              .post-text-card {
                padding: 20px;
                .top-btn {
                  display: flex;
                  justify-content: space-between;
                  .categories {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 24px;
                    background: rgba(255, 255, 255, 0.4);
                    display: inline-block;
                    padding: 2px 10px;
                  }
                  .more-btn {
                    cursor: pointer;
                    li {
                      width: 3px;
                      height: 3px;
                      background-color: #fff;
                      border-radius: 50%;
                      margin-top: 3px;
                      &:first-child {
                        margin-top: 0;
                      }
                    }
                  }
                }
                .post-card-title {
                  font-weight: 700;
                  font-size: 19px;
                  line-height: 27px;
                  letter-spacing: 0.02em;
                  margin-top: 12px;
                }
                .date {
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 27px;
                  letter-spacing: 0.02em;
                  color: rgba(255, 255, 255, 0.7);
                  margin-top: 2px;
                }
                .tag-box {
                  margin-top: 30px;
                  .tag-list {
                    .tag-name-box {
                      .tag-name {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 24px;
                      }
                    }
                  }
                  img {
                    width: 40px;
                    height: 40px;
                  }
                }
              }
            }
          }
        }
        .gradient {
          height: 20px;
          bottom: 95px;
        }
        .btn-wrap {
          margin-top: 20px;
          button {
            width: 100%;
            padding: 14px 0px;
            border-radius: 14px;
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }
  }
`;
