import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
// import "swiper/css";
import 'swiper/swiper.min.css';
import { PostSwiperWrapper } from './styled';
import { useEffect, useState } from 'react';
import More from '../../Dashboard/mentor/modal/more';
import Crystal2 from '../../Dashboard/mentor/modal/crystal2';
import { useHistory, useParams } from 'react-router-dom';
import {
	DeletePost,
	GetLikePosts,
	GetLivePosts,
	GetUserPosts,
} from '../../../api/story';
import { useSelector } from 'react-redux';
import EditModal from '../../Dashboard/mentor/modal/editmodal';
import EditModal2 from '../../Dashboard/mentor/modal/editmodal2';

const ReactLiveSwiper = ({ livePosts }) => {
	const [editObj, setEditObj] = useState({});
	const [tmp, setTmp] = useState({});
	const [post_data, set_post_data] = useState({
		post_id: 0,
		post_title: '',
		post_content: '',
	});
	const [show7, setShow7] = useState(false);
	const moreToggle = () => {
		setShow7((p) => !p);
	};
	const [show10, setShow10] = useState(false);
	const crystalToggle2 = (args) => {
		console.log(args);
		set_post_data(args);
		setEditObj(args);
		setTmp(args);
		setShow10((p) => !p);
	};

	const [id, setId] = useState(null);
	const close = () => {
		if (id) {
			setId(null);
		}
	};

	const DoDeletePost = (id) => {
		if (window.confirm('글을 삭제하시겠습니까?')) {
			DeletePost({ post_id: id })
				.then((res) => {
					if (res.data.code === '200') {
						alert('삭제되었습니다.');
						window.location.href = '/';
						// window.history.back();
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	return (
		<>
			<PostSwiperWrapper onClick={close}>
				<div className="post-wrapper">
					<Swiper
						spaceBetween={10}
						slidesPerView={1.15}
						onSlideChange={() => console.log('slide change')}
						onSwiper={(swiper) => console.log(swiper)}
						breakpoints={{
							1024: {
								slidesPerView: 4.15,
								spaceBetween: 25,
							},
						}}
					>
						{livePosts?.map((post, idx) => (
							<SwiperSlide
								className="post-card"
								style={{
									position: 'relative',
									backgroundImage: `url(${post.post_image})`,
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'cover',
									width: 274,
									height: 274,
									borderRadius: 20,
								}}
							>
								<div
									className="tns"
									onClick={() => window.open(`/story/${post.ID}`)}
								></div>
								{post.isSimple && post.isMine && post.post_type == 'post' ? (
									<ul
										// onMouseOver={() => setId(post.ID)}
										// onMouseOut={() => setId(null)}
										className="more-btn"
									>
										<li></li>
										<li></li>
										<li></li>
									</ul>
								) : null}
								<div className="crystal-box">
									<ul className="btn-list">
										<li className="crystal-title">
											<button
												className="crystal-btn"
												onClick={() => crystalToggle2(post)}
											>
												수정
											</button>
										</li>
										<li className="delete-title">
											<button
												className="crystal-btn"
												onClick={() => DoDeletePost(post.ID)}
											>
												삭제
											</button>
										</li>
									</ul>
								</div>
								<div className="post-text-card">
									<div className="top-btn">
										<p className="categories">{post.cate_name}</p>
									</div>
									{/* {id === post.ID ? ( */}
									{/* ) : null} */}
									<p
										className="post-card-title"
										dangerouslySetInnerHTML={{ __html: post.post_title }}
									></p>
									<p className="date">{post.post_date}</p>
								</div>
								<div className="tag-box">
									<ul className="tag-list">
										{post?.post_tags
											? post?.post_tags?.map((item, key) => (
													<li>#{item.name}</li>
											  ))
											: ''}{' '}
										{/* <li>#Consult</li>
                    <li className="ing-tag">ing</li> */}
									</ul>
									<img
										src={`${post.post_author_img}`}
										alt="profile thumbnail"
									/>
								</div>
							</SwiperSlide>
						))}

						<div className="post-bg"></div>
					</Swiper>
				</div>
				{id ? <div onClick={close} className="dim" /> : null}
			</PostSwiperWrapper>
			{show7 ? <More moreToggle={moreToggle} /> : ''}
			{show10 ? (
				post_data?.isSimple == 1 ? (
					<EditModal
						post_data={post_data}
						eventToggle={crystalToggle2}
						editObj={editObj}
						setEditObj={setEditObj}
						tmp={tmp}
					/>
				) : null
			) : null}
			{show10 ? (
				post_data?.isSimple == 2 ? (
					<EditModal2
						post_data={post_data}
						eventToggle={crystalToggle2}
						editObj={editObj}
						setEditObj={setEditObj}
						tmp={tmp}
					/>
				) : null
			) : null}
		</>
	);
};

export default ReactLiveSwiper;
