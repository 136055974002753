import SwiperCore, { Navigation } from 'swiper';
import QuestionIcon from '../../../vector/QuestionMark';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { GetUserPosts } from '../../../api/story';
import { MobilePostWrapper, PostWrapper } from '../styled';

export default function ProfilePagePosts({ otherUserInfo }) {
	const [posts, setPosts] = useState([]);
	const params = useParams();
	useEffect(() => {
		GetUserPosts({
			order: 'DESC',
			orderby: 'ID',
			posts_per_page: '12',
			post_author: params.id,
			paged: 1,
		})
			.then((res) => {
				setPosts(res.data.body.posts);
			})
			.catch((err) => console.log('포스트 정보 오류', err));
	}, [params.id]);
	SwiperCore.use([Navigation]);

	const seeAllPosts = () => {
		window.location.href = `/user/${params.id}?tab=2`;
	};

	return (
		<div
			className={
				(otherUserInfo?.role === '' && 'general-post-wrapper') ||
				(otherUserInfo?.role === 'mentor' && '') ||
				(otherUserInfo?.role === 'mentee' && 'post-wrapper')
			}
		>
			<div className="post-title first">
				<h1 className="title">작성 포스트</h1>
			</div>
			<PostWrapper>
				<div className="post-box">
					<div className="left-box">
						<div className="left">
							{posts?.length === 0 ? (
								<div className="written-post">
									<QuestionIcon />
									<span>아직 작성된 포스트가 없습니다.</span>
								</div>
							) : (
								<Swiper slidesPerView={2.5} spaceBetween={20}>
									{posts.map((post, idx) => (
										<SwiperSlide>
											<div
												key={`post-${idx}`}
												className="swiper-card"
												style={{
													backgroundImage: `url(${post.post_image})`,
												}}
												onClick={() => window.open(`/story/${post.ID}`)}
											>
												<div className="post-desc">
													<div className="top">
														<div className="post-info">
															<span>{post.cate_name}</span>
															<span>{post.post_title}</span>
															<span>{post.post_date}</span>
														</div>
													</div>
													<div className="bottom">
														<div className="skills">
															<span>#devops</span>
															<span>#Consulting</span>
														</div>
													</div>
													<div className="author-thumbnail">
														<img
															src={`${post.post_author_img}`}
															alt="profile thumbnail"
														/>
													</div>
												</div>
											</div>
										</SwiperSlide>
									))}
								</Swiper>
							)}
							<button onClick={seeAllPosts} className="see-all">
								모두 보기
							</button>
							<div className="gradient" />
						</div>
					</div>
				</div>
			</PostWrapper>

			<MobilePostWrapper>
				<div className="post-box">
					<div className="left-box">
						<div className="written-post">
							{posts?.length === 0 ? (
								<div className="liked-post">
									<QuestionIcon />
									<span>아직 작성된 포스트가 없습니다.</span>
								</div>
							) : (
								<Swiper slidesPerView={1.5} spaceBetween={20}>
									{posts.map((post, idx) => (
										<SwiperSlide>
											<div
												key={`post-${idx}`}
												className="swiper-card"
												style={{
													backgroundImage: `url(${post.post_image})`,
												}}
												onClick={() => window.open(`/story/${post.ID}`)}
											>
												<div className="post-desc">
													<div className="top">
														<div className="post-info">
															<span>{post.cate_name}</span>
															<span>{post.post_title}</span>
															<span>{post.post_date}</span>
														</div>
													</div>
													<div className="bottom">
														<div className="skills">
															<span>#devops</span>
															<span>#Consulting</span>
														</div>
													</div>
													<div className="author-thumbnail">
														<img
															src={`${post.post_author_img}`}
															alt="profile thumbnail"
														/>
													</div>
												</div>
											</div>
										</SwiperSlide>
									))}
								</Swiper>
							)}

							<div className="gradient" />
							<button onClick={seeAllPosts} className="see-all">
								모두 보기
							</button>
						</div>
						<div className="post-title">
							<h1 className="title">관심 포스트</h1>
						</div>
						<div className="left">
							<div className="liked-post">
								<QuestionIcon />
								<span>아직 관심 포스트가 없습니다.</span>
							</div>
							<button className="see-all">모두 보기</button>
						</div>
					</div>
					<h1 className="comments">내 댓글</h1>
					<div className="right">
						<div className="liked-post">
							<QuestionIcon />
							<span>오픈 준비중입니다.</span>
							<button className="see-alls">모두 보기</button>
						</div>
					</div>
				</div>
			</MobilePostWrapper>
		</div>
	);
}
