import styled from 'styled-components';

export const Wrapper = styled.div`
	position: absolute;
	width: 100vw;
	padding: 100px 18%;
	z-index: 999;
	background: #f3f3f3;
	overflow: hidden;
	.s {
		border: none !important;
		margin-top: 10px !important;
	}
	.see-all {
		background-color: transparent;
		border: none;
		border-top: 1px solid #eeeeee;
		padding-top: 16px;
		margin-top: 30px;
		padding-bottom: 14px;
		width: 100%;
	}

	.see-all-comments {
		background-color: transparent;
		border: none;
		border-top: 1px solid #eeeeee;
		padding-top: 16px;
		padding-bottom: 14px;
		width: 80%;
		position: absolute;
		bottom: 0;
		right: 50%;
		transform: translateX(50%);
	}

	.post-wrapper {
		@media (max-width: 768px) {
		}
		.right-mobile {
			width: 100%;
			height: 100%;
			min-height: 80px;
			margin: 0 auto;
			background-color: white;
			position: relative;
			display: flex !important;
			justify-content: space-between;
			margin-bottom: 16px;
			cursor: pointer;
			align-items: center;
			padding: 0 20px;
			border: none;
			border-radius: 10px;
			@media (min-width: 769px) {
				display: none !important;
			}
			span {
				display: flex;
				align-items: center;
				font-weight: 700;
				font-size: 18px;
				line-height: 22px;
				align-items: center;
				i {
					margin-right: 10px;
				}
			}
			button {
				background-color: transparent;
				border: none;
				color: black;
			}
			@media (max-width: 768px) {
				display: block;
			}
		}
	}

	.general-post-wrapper {
		@media (max-width: 768px) {
			margin-top: 300px;
		}
		.right-mobile {
			width: 100%;
			height: 100%;
			min-height: 80px;
			margin: 0 auto;
			background-color: white;
			position: relative;
			display: flex !important;
			justify-content: space-between;
			margin-bottom: 16px;
			cursor: pointer;
			align-items: center;
			padding: 0 20px;
			border: none;
			border-radius: 10px;
			@media (min-width: 769px) {
				display: none !important;
			}
			span {
				display: flex;
				align-items: center;
				font-weight: 700;
				font-size: 18px;
				line-height: 22px;
				align-items: center;
				i {
					margin-right: 10px;
				}
			}
			button {
				background-color: transparent;
				border: none;
				color: black;
			}
			@media (max-width: 768px) {
				display: block;
			}
		}
	}

	i {
		vertical-align: middle;
	}
	@media (max-width: 1920px) {
		padding: 80px 10%;
	}
	@media (max-width: 1300px) {
		padding: 80px 5%;
	}
	@media (max-width: 768px) {
		padding: 50px 5%;
	}
	.see-alls {
		font-weight: 700;
		font-size: 14px;
		line-height: 17px;
		color: #000000;
		background-color: white;
		border-radius: 40px;
		border: none;
		display: block;
		margin: 0 auto;
		width: 104px;
		position: absolute;
		bottom: 40px;
		left: 50%;
		transform: translateX(-50%);
		:hover {
			color: #9a9a9a;
			transition: 0.1s ease-out;
		}

		@media (max-width: 768px) {
			border-top: 1px solid #eeeeee;
			padding-top: 16px;
			width: 80%;
			border-radius: 0;
			font-weight: 300;
			font-size: 14px;
			line-height: 17px;
			color: #000000;
		}
	}

	h1 {
		font-weight: 700;
		font-size: 48px;
		@media (max-width: 768px) {
			font-size: 24px;
		}
	}
	.first {
		margin-top: 80px !important;
		@media (max-width: 768px) {
			margin-top: 20px !important;
		}
	}
	.post-title {
		position: relative;
		display: flex;
		width: 100%;
		max-width: 1400px;
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 25px;
		align-items: center;
		justify-content: space-between;
		padding-left: 8px;
		padding-right: 20%;

		@media (max-width: 768px) {
			margin-bottom: 10px;
		}

		.padlet-btn {
			background-color: transparent;
			border: none;
			font-weight: 700;
			font-size: 16px;
			line-height: 20px;
			letter-spacing: -0.02em;
			padding: 8px 16px;
			@media (max-width: 768px) {
				font-size: 14px;
			}
			:hover {
				transform: translateX(2px) translateY(-2px);
				transition: 0.1s ease-out;
			}
		}
		.title {
			font-weight: 700;
			font-size: 24px;
			line-height: 34px;

			@media (max-width: 768px) {
				font-size: 20px;
			}
		}
		.new-post {
			position: absolute;
			right: 32%;
			padding-top: 8px;
			background-color: transparent;
			border: none;
			font-weight: 700;
			font-size: 17px;
			line-height: 15px;
			letter-spacing: -0.03em;
			align-items: center;
			i {
				vertical-align: middle;
			}

			@media (max-width: 768px) {
				right: 6%;
				font-size: 12px;
			}
			cursor: pointer;
			span {
				margin-left: 8px;
				padding-bottom: 10px;
			}
			:hover {
				transform: scale(1.04);
				transition: 0.1s ease-out;
			}
		}
		.comments {
			font-weight: 700;
			font-size: 24px;
			line-height: 34px;
			width: 100%;
			position: absolute;
			left: 71%;
			@media (max-width: 768px) {
				display: none;
			}
		}
	}
`;
export const SliderWrapper = styled.div`
	position: relative;
	background-color: white;
	width: 100%;
	max-width: 1400px;
	margin: auto;
	border-radius: 28px;
	margin-top: 24px;
	padding: 50px;
	padding-bottom: 16px;
	padding-right: 0;
	overflow: hidden;
	.no-mentee-info {
		margin: 80px auto;
		width: 400px;
		display: flex;
		flex-direction: column;
		text-align: center;
		i {
			margin-bottom: 25px;
		}
	}

	@media (max-width: 768px) {
		display: none;
	}

	.gradient {
		border-radius: 28px;
		position: absolute;
		right: 0;
		top: 0;
		width: 8%;
		height: 100%;
		z-index: 9;
		background: linear-gradient(
			270deg,
			#ffffff 0%,
			rgba(255, 255, 255, 0) 100%
		);
	}
	.title-box {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.padlet-btn {
			background-color: white;
			border: none;
			font-weight: 700;
			font-size: 16px;
			padding: 8px 16px;
			margin-right: 40px;
			:hover {
				transform: translateX(2px) translateY(-2px);
				transition: 0.1s ease-out;
			}
			i {
				margin-right: 8px;
			}
		}

		.title {
			display: flex;
			margin-bottom: 12px;
			h1 {
				font-weight: 700;
				font-size: 24px;
				line-height: 30px;
				margin-right: 16px;
			}
			span {
				border: 1px solid #ffac00;
				border-radius: 100px;
				padding: 6px 12px;
				font-weight: 500;
				font-size: 12px;
				line-height: 15px;
				letter-spacing: 0.02em;
				color: #ffac00;
			}
		}
	}

	.swiper-card {
		margin-top: 12px;
		width: 100%;
		background: white;
		border: 1px solid #e9e9e9;
		border-radius: 20px;
		padding: 32px 24px;
		display: block;
		overflow: hidden;
		:hover {
			/* opacity: 90%; */
			transition: 0.1s ease-out;
			img {
				/* scale: 1.1; */
				transition: 0.1s ease-out;
			}
		}
		.top,
		.bottom {
			display: flex;
			position: relative;
		}
		.top {
			img {
				width: 50px;
				height: 50px;
				border-radius: 50%;
			}
		}
		.circular-progress-bar {
			position: absolute;
			right: 0;
			top: 4px;
			span {
				color: #ffbc00;
			}
		}
		.progress {
			text-align: center;
			position: absolute;
			padding-left: 18px;
			bottom: 18px;
			right: -8px;
			width: 64px;
			height: 64px;
			border-radius: 50% !important;
			border: 2px solid #999999;
			display: flex;
			align-items: center;
			span {
				color: #ffbc00;
			}
		}

		.person-info {
			span {
				display: block;
				margin-left: 16px;
			}
			span:first-child {
				font-weight: 600;
				font-size: 24px;
				line-height: 28px;
				color: black;
				margin-bottom: 10px;
			}
			span:last-child {
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: #aaaaaa;
			}
		}
		.duration {
			margin-top: 42px;
			span {
				display: block;
				:first-child {
					font-weight: 300;
					font-size: 15px;
					line-height: 19px;
					margin-bottom: 8px;
					color: #aaaaaa;
				}
				:last-child {
					font-weight: 300;
					font-size: 15px;
					line-height: 19px;
					color: black;
				}
			}
		}
	}
`;
export const MobileSliderWrapper = styled.div`
	position: relative;
	background-color: white;
	width: 100%;
	max-width: 1400px;
	margin: auto;
	padding: 32px 18px;
	padding-bottom: 10px;
	padding-right: 0;
	border-radius: 10px;
	overflow: hidden;
	.no-mentee-info {
		margin: 80px auto;
		display: flex !important;
		flex-direction: column !important;
		text-align: center;
		i {
			margin-bottom: 25px;
		}
	}
	.circular-progress-bar {
		position: absolute;
		right: 0;
		top: 4px;
		span {
			color: #ffbc00;
		}
	}

	.gradient {
		background: linear-gradient(
			270deg,
			rgba(255, 255, 255, 0.6) 0%,
			rgba(255, 255, 255, 0) 43.05%
		);
		width: 14%;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 9;
	}

	@media (max-width: 768px) {
		display: block;
		margin-top: 296px;
	}
	@media (min-width: 769px) {
		display: none;
	}
	.title-box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.title {
			display: flex;
			align-items: center;
			h1 {
				font-size: 20px;
			}
			span {
				font-size: 14px;
				padding: 4px 8px;
			}
			.new-mentee {
				position: absolute;
				right: 5%;
				border: none;
				background-color: transparent;
				font-weight: 700;
				font-size: 14px;
				line-height: 22px;
				letter-spacing: -0.03em;
				:hover {
					transform: scale(1.02);
					transition: 0.1s ease-out;
				}
				i {
					margin-right: 4px;
				}
			}
			.mentor-status {
				font-weight: 700;
				font-size: 24px;
				line-height: 30px;
				margin-right: 16px;
			}
			span {
				border: 1px solid #ffac00;
				border-radius: 100px;
				padding: 6px 8px;
				font-weight: 500;
				font-size: 12px;
				line-height: 15px;
				letter-spacing: 0.02em;
				color: #ffac00;
				margin-left: 12px;
			}
		}
		.padlet {
			margin-right: 30px;
			.padlet-btn {
				background-color: transparent;
				border: none;
				font-size: 14px;
				font-weight: 700;
			}
		}
	}

	.swiper-card {
		background-color: white;
		border: 1px solid #e9e9e9;
		margin-top: 12px;
		width: 100%;
		min-width: 240px;
		border-radius: 10px;
		display: block;
		padding: 20px;
		overflow: hidden;
		.close-btn {
			position: absolute;
			right: 40px;
		}
		:hover {
			opacity: 90%;
			transition: 0.1s ease-out;
			img {
				scale: 1.1;
				transition: 0.1s ease-out;
			}
		}
		.top,
		.bottom {
			display: flex;
			position: relative;
		}
		.top {
			img {
				width: 50px;
				height: 50px;
				border-radius: 50%;
			}
		}
		.progress {
			text-align: center;
			position: absolute;
			padding-left: 18px;
			bottom: 0;
			right: 0;
			width: 64px;
			height: 64px;
			border-radius: 50% !important;
			border: 2px solid #999999;
			display: flex;
			align-items: center;
			@media (max-width: 768px) {
				bottom: 12px;
			}
			span {
				color: #ffbc00;
			}
		}

		.person-info {
			span {
				display: block;
				margin-left: 16px;
			}
			span:first-child {
				font-weight: 600;
				font-size: 20px;
				line-height: 28px;
				color: black;
				margin-bottom: 10px;
			}
			span:last-child {
				font-weight: 300;
				font-size: 14px;
				line-height: 18px;
				color: #aaaaaa;
			}
		}
		.duration {
			margin-top: 42px;
			span {
				display: block;
				:first-child {
					font-weight: 300;
					font-size: 15px;
					line-height: 19px;
					margin-bottom: 8px;
					color: #aaaaaa;
				}
				:last-child {
					font-weight: 300;
					font-size: 15px;
					line-height: 19px;
					color: black;
				}
			}
		}
	}
`;

export const PostWrapper = styled.div`
	border-radius: 28px;
	display: flex;
	width: 100%;
	margin: auto;
	max-width: 1400px;

	@media (max-width: 768px) {
		display: none;
	}
	@media (min-width: 769px) {
		display: flex;
	}
	.post-box {
		display: flex;
		width: 100%;
		margin: auto;
		max-width: 1400px;
	}
	.left-box {
		display: block;
		width: 100%;
	}
	.title {
		display: block;
	}
	.left {
		position: relative;
		border-radius: 28px;
		position: relative;
		padding: 32px;
		padding-bottom: 16px;
		padding-right: 0;
		background-color: white;
		.gradient {
			position: absolute;
			right: 0;
			top: 0;
			border-radius: 28px;
			background: linear-gradient(
				270deg,
				#ffffff 0%,
				rgba(255, 255, 255, 0) 100%
			);
			width: 8%;
			height: 100%;
			z-index: 999;
		}
		.see-alls {
			margin-top: 24px;
		}

		.liked-post {
			text-align: center;
			width: 100%;
			margin: auto;
			padding: 120px;
			background-image: url('/dummy-liked.png');
			background-size: cover;
			span {
				margin-top: 16px;
				font-weight: 300;
				font-size: 16px;
				line-height: 20px;
				color: #696969;
				display: block;
			}
		}
		.written-post {
			text-align: center;
			width: 100%;
			margin: auto;
			padding: 120px;
			span {
				margin-top: 16px;
				font-weight: 300;
				font-size: 16px;
				line-height: 20px;
				color: #696969;
				display: block;
			}
		}

		.swiper-card {
			border-radius: 20px;
			color: white;
			display: block;
			overflow: hidden;
			height: 280px;
			min-width: 280px;
			position: relative;
			.post-desc {
				border-radius: 12px;
				height: 280px;
				min-width: 280px;
				padding: 50px 8px;
				padding-bottom: 10px;
				width: 100%;
				height: 100%;
				z-index: 9;
				background-color: rgba(0, 0, 0, 0.3);
			}
			:hover {
				opacity: 90%;
				transition: 0.1s ease-out;
			}
			.top,
			.bottom {
				display: flex;
				position: relative;
			}
			.bottom {
				position: absolute;
				bottom: 8%;
			}
			.author-thumbnail {
				padding-top: 40px;
				position: absolute;
				right: 8%;
				bottom: 8%;
				img {
					width: 50px;
					height: 50px;
					border-radius: 50%;
				}
			}
			.post-info {
				span {
					margin-left: 8px;
				}
				span:first-child {
					background: rgba(255, 255, 255, 0.4);
					padding: 4px 14px;
				}
				span:nth-child(2) {
					display: block;
					font-weight: 700;
					font-size: 19px;
					line-height: 27px;
					margin: 18px 8px;
					margin-top: 24px;
				}
				span:last-child {
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: #e9e9e9;
				}
			}
			.skills {
				margin-top: 42px;
				span {
					display: block;
					font-weight: 300;
					font-size: 15px;
					line-height: 19px;
					margin-bottom: 8px;
					margin-left: 8px;
					color: white;
					font-weight: 300;
					font-size: 15px;
					line-height: 19px;
				}
			}
		}
	}
	.right {
		border-radius: 28px;
		margin-left: 30px;
		width: 30%;
		padding: 12px;
		background-color: white;
		position: relative;

		.liked-post {
			text-align: center;
			width: 100%;
			margin: auto;
			padding: 120px 0;
			span {
				margin-top: 16px;
				font-weight: 300;
				font-size: 16px;
				line-height: 20px;
				color: #696969;
				display: block;
			}
		}

		.article-title {
			align-items: center;
			img {
				width: 28px;
			}
			span {
				margin-left: 8px;
				font-weight: 700;
				font-size: 14px;
				line-height: 18px;
				letter-spacing: -0.03em;
				color: #3d3d3d;
			}
		}
		.comment-box {
			position: relative;
			width: 100%;
			height: 120px;
			max-height: 160px;
			background: rgba(96, 88, 76, 0.88);
			border-radius: 27px 27px 27px 8px !important;
			margin: 16px 0;
			color: white;
			span {
				position: absolute;
				padding-right: 24px;
				font-weight: 300;
				font-size: 15px;
				line-height: 19px;
				color: white;
				i {
					margin-right: 4px;
					margin-top: 4px;
				}
				:first-child {
					left: 8%;
					top: 20%;
				}
				:last-child {
					bottom: 12%;
					right: 0;
				}
			}
		}
	}
`;

export const MobilePostWrapper = styled.div`
	border-radius: 28px;
	display: flex;
	width: 100%;
	margin: auto;
	max-width: 1400px;

	@media (max-width: 768px) {
		display: block;
	}
	@media (min-width: 769px) {
		display: none;
	}
	.comments {
		margin-top: 28px;
		font-size: 20px;
	}
	.post-box {
		display: block;
		width: 100%;
		margin: auto;
		max-width: 1400px;
	}
	.left-box {
		display: block;
		width: 100%;
	}
	.title {
		display: block;
	}
	.written-post {
		position: relative;
		border-radius: 10px;
		padding: 16px;
		padding-right: 0;
		padding-bottom: 10px;
		background-color: white;
		display: block;
		.gradient {
			position: absolute;
			top: 0;
			right: 0;
			width: 14%;
			height: 100%;
			z-index: 9;
			background: linear-gradient(
				270deg,
				rgba(255, 255, 255, 0.6) 0%,
				rgba(255, 255, 255, 0) 43.05%
			);
		}
		:first-child {
			.see-all {
				margin-top: 16px;
				width: 100%;
				padding-top: 12px;
				border-top: 1px solid #eeeeee;
			}
		}
		.see-all {
			width: 100%;
			border-top: 1px solid #eeeeee;
			padding-top: 12px;
		}
		.liked-post {
			text-align: center;
			width: 100%;
			margin: auto;
			padding: 120px 0;
			span {
				display: block;
				font-weight: 300;
				font-size: 16px;
				line-height: 20px;
				color: #696969;
				margin-top: 16px;
			}
		}

		.swiper-card {
			border-radius: 20px;
			color: white;
			display: block;
			overflow: hidden;
			height: 440px;
			max-height: 240px;
			max-width: 330px;
			position: relative;
			.post-desc {
				border-radius: 12px;
				height: 440px;
				padding: 32px 8px;
				padding-bottom: 10px;
				width: 100%;
				height: 100%;
				z-index: 9;
				background-color: rgba(0, 0, 0, 0.3);
			}
			:hover {
				opacity: 90%;
				transition: 0.1s ease-out;
			}
			.top,
			.bottom {
				display: flex;
				position: relative;
			}
			.bottom {
				position: absolute;
				bottom: 4%;
			}
			.author-thumbnail {
				padding-top: 40px;
				position: absolute;
				right: 6%;
				bottom: 6%;
				img {
					width: 50px;
					height: 50px;
					border-radius: 50%;
				}
			}

			.post-info {
				position: relative;
				span {
					margin-left: 8px;
				}
				span:first-child {
					background: rgba(255, 255, 255, 0.4);
					padding: 4px 14px;
					/* color: red; */
				}
				span:nth-child(2) {
					display: block;
					font-weight: 600;
					font-size: 16px;
					letter-spacing: 0.02em;
					line-height: 28px;
					margin-top: 32px;
				}
				span:last-child {
					font-weight: 300;
					font-size: 12px;
					line-height: 26px;
					color: #e9e9e9;
				}
			}
		}
	}
	.left {
		position: relative;
		border-radius: 10px;
		/* margin: 0 8px; */
		padding: 16px;
		padding-right: 0;
		padding-bottom: 10px;
		background-color: white;
		display: block;
		.gradient {
			position: absolute;
			top: 0;
			right: 0;
			width: 14%;
			height: 100%;
			z-index: 9;
			background: linear-gradient(
				270deg,
				rgba(255, 255, 255, 0.6) 0%,
				rgba(255, 255, 255, 0) 43.05%
			);
		}
		:first-child {
			.see-all {
				margin-top: 16px;
				width: 100%;
				padding-top: 12px;
				border-top: 1px solid #eeeeee;
			}
		}
		.see-all {
			width: 100%;
			border-top: 1px solid #eeeeee;
			padding-top: 12px;
		}
		.liked-post {
			text-align: center;
			width: 100%;
			margin: auto;
			padding: 120px 0;
			background-image: url('/dummy-liked.png');
			background-size: cover;
			span {
				display: block;
				font-weight: 300;
				font-size: 16px;
				line-height: 20px;
				color: #696969;
				margin-top: 16px;
			}
		}

		.swiper-card {
			border-radius: 20px;
			color: white;
			display: block;
			overflow: hidden;
			height: 440px;
			max-height: 240px;
			max-width: 330px;
			position: relative;
			.post-desc {
				border-radius: 12px;
				height: 440px;
				padding: 32px 8px;
				padding-bottom: 10px;
				width: 100%;
				height: 100%;
				z-index: 9;
				background-color: rgba(0, 0, 0, 0.3);
			}
			:hover {
				opacity: 90%;
				transition: 0.1s ease-out;
			}
			.top,
			.bottom {
				display: flex;
				position: relative;
			}
			.bottom {
				position: absolute;
				bottom: 4%;
			}
			.author-thumbnail {
				padding-top: 40px;
				position: absolute;
				right: 6%;
				bottom: 6%;
				img {
					width: 50px;
					height: 50px;
					border-radius: 50%;
				}
			}

			.post-info {
				position: relative;
				span {
					margin-left: 8px;
				}
				span:first-child {
					background: rgba(255, 255, 255, 0.4);
					padding: 4px 14px;
					/* color: red; */
				}
				span:nth-child(2) {
					display: block;
					font-weight: 600;
					font-size: 16px;
					letter-spacing: 0.02em;
					line-height: 28px;
					margin-top: 32px;
				}
				span:last-child {
					font-weight: 300;
					font-size: 12px;
					line-height: 26px;
					color: #e9e9e9;
				}
			}
			.skills {
				margin-top: 42px;
				span {
					display: block;
					font-weight: 300;
					font-size: 15px;
					line-height: 19px;
					margin-bottom: 8px;
					margin-left: 8px;
					color: white;
					font-weight: 300;
					font-size: 15px;
					line-height: 19px;
				}
			}
		}
	}
	.right {
		display: block;
		border-radius: 10px;
		margin-top: 16px;
		width: 100%;
		padding: 12px;
		padding-bottom: 2px;
		background-color: white;
		position: relative;
		.see-alls {
			position: absolute;
			bottom: 16px;
		}
		.liked-post {
			text-align: center;
			width: 100%;
			margin: auto;
			padding: 120px 0;
			span {
				display: block;
				font-weight: 300;
				font-size: 16px;
				line-height: 20px;
				color: #696969;
				margin-top: 16px;
			}
		}

		.gradient {
			position: absolute;
			bottom: 8%;
			left: 0;
			width: 100%;
			height: 10%;
			background: linear-gradient(
				180deg,
				rgba(255, 255, 255, 0) 0%,
				#ffffff 100%
			);
		}

		.article-title {
			align-items: center;
			img {
				width: 28px;
			}
			span {
				margin-left: 8px;
				font-weight: 700;
				font-size: 12px;
				line-height: 18px;
				letter-spacing: -0.03em;
				color: #3d3d3d;
			}
		}
		.comment-box {
			position: relative;
			width: 100%;
			height: 120px;
			max-height: 160px;
			background: rgba(96, 88, 76, 0.88);
			border-radius: 27px 27px 27px 8px !important;
			margin: 16px 0;
			color: white;
			span {
				position: absolute;
				padding-right: 24px;
				font-weight: 300;
				font-size: 14px;
				line-height: 19px;
				color: white;
				i {
					margin-right: 4px;
					margin-top: 4px;
				}
				:first-child {
					left: 8%;
					top: 20%;
				}
				:last-child {
					display: flex;
					align-items: center;
					bottom: 12%;
					right: 0;
					font-size: 12px;
				}
			}
		}
	}
`;

export const TopSectionWrapper = styled.div`
	a {
		color: black;
		text-decoration: none;
	}
	.button-wrapper {
		position: absolute;
		right: 0;
		bottom: 50px;
		@media (max-width: 768px) {
			display: none;
		}

		.pc-manual {
			margin-bottom: 15px;
			border: 1px solid #e9e9e9;
			font-weight: 700;
			font-size: 16px;
			line-height: 20px;
			letter-spacing: -0.02em;
			color: #333333;
			padding: 0 42px;
			:hover {
				background: #efefef;
				transition: 0.1s ease-out;
			}
		}
		.profile-edit-btn {
			margin-bottom: 15px;
			border: 1px solid #e9e9e9;
			font-weight: 700;
			font-size: 16px;
			line-height: 20px;
			letter-spacing: -0.02em;
			color: #333333;
			:hover {
				background: #efefef;
				transition: 0.1s ease-out;
			}
		}
		.new-post-btn {
			color: white;
			background: black;
			font-weight: 700;
			font-size: 16px;
			line-height: 22px;
			letter-spacing: -0.03em;
			border: none;
			:hover {
				background: #000000c1;
				transition: 0.1s ease-out;
			}
		}
		button {
			background-color: transparent;
			border: 2px solid black;
			padding: 14px 20px;
			width: 200px;
			height: 62px;
			border-radius: 88px;
			display: flex;
			align-items: center;
			text-align: center;
			margin: 0 auto;
			vertical-align: middle;
			span {
				margin: 0 auto;
			}
			i {
				padding-top: 4px;
				margin-right: 8px;
			}
		}
	}
	display: flex;
	width: 100%;
	max-width: 1400px;
	margin: 30px auto;

	position: relative;
	@media (max-width: 768px) {
		display: block;
		padding-top: 24px;
		margin-top: 0;
	}

	div {
		position: relative;
		border-radius: 28px;
		color: black;
		@media (max-width: 768px) {
			border-radius: 10px;
		}
	}

	span {
		display: block;
		margin: 8px 0;
		strong {
			font-weight: 900;
		}
	}

	.profile {
		position: relative;
		width: 70%;
		background-color: white;
		padding: 50px;
		display: flex;
		flex-direction: column;
		max-height: 308px;
		@media (max-width: 768px) {
			padding: 20px;
			min-height: 190px;
			width: 100%;
			margin: auto;
		}
		.person-info {
			display: flex;
			align-items: center;
			.avatar {
				img {
					width: 80px;
					height: 80px;
					border-radius: 50%;
					@media (max-width: 768px) {
						width: 50px;
						height: 50px;
					}
				}
			}
			.user-data {
				margin-left: 32px;
				@media (max-width: 768px) {
					margin-left: 14px;
					margin-top: 2px;
				}

				.department {
					font-weight: 300;
					font-size: 22px;
					color: #686868;
					@media (max-width: 768px) {
						font-size: 14px;
					}
				}
				.name {
					margin-top: 16px;
					font-size: 32px;
					letter-spacing: -0.02em;
					@media (max-width: 768px) {
						font-size: 20px;
					}
				}
				.user-description {
					position: absolute;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: #686868;
					width: 490px !important;
					@media (max-width: 768px) {
						width: 190px !important;
						font-size: 11px;
						display: flex;
						top: 20px;
						left: 62px;
					}
				}
			}
		}

		.contact_box {
			position: relative;
			display: flex;
			justify-content: space-between;

			@media (max-width: 768px) {
				margin-top: 48px;
			}
			.contact-info {
				padding-top: 200px;
				@media (max-width: 768px) {
					padding-top: 0;
				}
			}

			.contact {
				position: absolute;
				display: block;
				bottom: 50px;
				@media (max-width: 768px) {
					bottom: -42px;
					margin-left: 12px;
				}
				.contact-detail {
					display: flex;
					vertical-align: middle;
					align-items: center;
					i {
						margin-right: 16px;
					}

					span {
						font-weight: 300;
						font-size: 16px;
						line-height: 24px;
						@media (max-width: 768px) {
							line-height: 14px;
							font-size: 14px;
						}
					}
					.tel-number {
						margin-right: 14px !important;
					}
				}
			}
		}
	}

	.right {
		width: 30%;
		margin-left: 30px;
		padding: 40px;
		background-color: #f9dd4a;
		position: relative;
		min-height: 300px;
		cursor: pointer;
		:hover {
			background-color: #f9dc4ac1;
			transition: 0.1s ease-out;
		}
		@media (max-width: 768px) {
			margin-top: 12px;
			margin-right: 0px;
			width: 69%;
			min-width: 100px;
			position: absolute;
			right: 0;
			min-height: 244px;
			padding: 20px;
		}

		.hub-btn {
			background-color: transparent;
			border: none;
			cursor: pointer;
			position: absolute;
			bottom: 40px;
			left: 40px;
			padding-top: 6px;
			@media (max-width: 768px) {
				left: 13px;
				bottom: 15px;
			}
		}
		.link-btn {
			background-color: transparent;
			border: none;
			position: absolute;
			right: 40px;
			bottom: 40px;
			@media (max-width: 768px) {
				right: 10px;
				bottom: 10px;
			}
		}
		h1 {
			font-weight: 700;
			font-size: 27px;
			line-height: 35px;
			letter-spacing: -0.03em;
			@media (max-width: 768px) {
				font-size: 18px;
			}
		}
		span {
			font-weight: 300;
			font-size: 18px;
			line-height: 35px;
			letter-spacing: -0.03em;
			@media (max-width: 768px) {
				font-size: 10px;
			}
		}
	}

	.faq {
		background-color: #736c61;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		min-height: 96px;
		max-height: 96px;
		padding: 28px 40px 28px 34px;
		:hover {
			background: rgba(45, 42, 38, 0.6);
			transition: 0.1s ease-out;
			i {
				transform: translateX(20%);
				transition: 0.1s ease-out;
			}
		}
		@media (max-width: 768px) {
			width: 69%;
			min-height: 70px;
			position: absolute;
			top: 224px;
			z-index: 999;
			align-items: center;
		}
		i:last-child {
			right: 38px;
			@media (max-width: 768px) {
				right: 18px;
			}
		}
		span {
			color: white;
			font-weight: 700;
			font-size: 24px;
			line-height: 30px;
			margin-right: 48%;
			@media (max-width: 768px) {
				position: absolute;
				left: 20px;
				align-items: center;
			}
			i {
				margin-right: 12px;
				@media (max-width: 768px) {
					margin-right: 10px;
				}
			}
		}
		.rightarr-btn {
			position: absolute;
			right: 20px;
		}
	}
	.hub-btn-dnjdlek {
		float: right;
		background-color: transparent;
		border: none;
		:hover {
			transform: translateX(4px) translateY(-4px);
			transition: 0.1s ease-out;
		}
	}

	.right {
		opacity: 1;
		transition: 0.45s;
	}
	.right.hidden {
		opacity: 0;
	}
	.dnjdlek.show {
		opacity: 1;
		z-index: 8989;
	}
	.dnjdlek {
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
		width: 624px;
		height: 548px;
		margin-left: 24px;
		min-width: 330px;
		padding: 32px;
		padding-top: 0;
		background-color: #f9dd4a;
		transition: 0.45s;
		opacity: 0;
		min-height: 300px;
		overflow-y: scroll;
		::-webkit-scrollbar {
			display: none;
		}
		@media (max-width: 768px) {
			margin-top: 12px;
			margin-right: 0px;
			width: 69%;
			min-width: 100px;
			position: absolute;
			right: 0;
			min-height: 270px;
			padding: 24px;
		}
		.modal-head {
			position: sticky;
			top: 0;
			background-color: #f9dd4a;
			z-index: 999999;
			padding-top: 30px;
			border-radius: 0;
			border-bottom: 1px solid #00000033;
		}

		.hub-btn {
			border: none;
			cursor: pointer;
			position: absolute;
			bottom: 20px;
			left: 30px;
			padding-top: 6px;
			@media (max-width: 768px) {
				transform: translateX(-20%);
			}
		}
		.link-btn {
			background-color: transparent;
			border: none;
			position: absolute;
			right: 32px;
			bottom: 20px;
			@media (max-width: 768px) {
				right: 14px;
				bottom: 14px;
			}
		}
		h1 {
			font-weight: 700;
			font-size: 32px;
			line-height: 35px;
			letter-spacing: -0.03em;
			@media (max-width: 768px) {
				font-size: 20px;
			}
		}
		span {
			font-weight: 300;
			font-size: 18px;
			line-height: 35px;
			letter-spacing: -0.03em;
			@media (max-width: 768px) {
				font-size: 16px;
			}
		}
	}
	@media (max-width: 768px) {
		.dnjdlek {
			width: 100% !important;
			top: 214px;
			padding-top: 0;
		}
	}

	.profile-info {
		position: relative;
		width: 70%;
		background-color: white;
		text-align: center;
		display: flex;
		padding: 24px 0;
		justify-content: space-between;
		align-items: center;
		@media (max-width: 768px) {
			flex-direction: column;
			padding: 4px 0;
			width: 28%;
			position: absolute;
			top: -18px;
			min-height: 250px !important;
		}
		div:first-child {
			@media (max-width: 768px) {
				display: none;
			}
		}

		.info-detail {
			.border {
				border-right: 1px solid #eeeeee;
				height: 46px;
				position: absolute;
				left: 100%;
				top: 0;
				@media (max-width: 768px) {
					display: none;
				}
			}
			width: 100%;
			@media (max-width: 768px) {
				padding: 11px 0;
			}
			span:first-child {
				font-weight: 300;
				font-size: 14px;
				letter-spacing: -0.02em;
				color: #666666;
			}
			span:nth-child(2) {
				font-weight: 700;
				font-size: 16px;
				color: #000000;
				@media (max-width: 1600px) {
					font-size: 14px;
					fon-weight: 600;
				}
			}
			span:nth-child(3) {
				font-weight: 300;
				font-size: 14px;
				letter-spacing: -0.02em;
				color: #666666;
				@media (min-width: 769px) {
					display: none;
				}
			}
		}
	}
`;

export const MiddleSectionWrapper = styled.div`
	display: flex;
	width: 100%;
	max-width: 1400px;
	margin: 24px auto;
	position: relative;
	margin-top: 300px;
	@media (max-width: 768px) {
		display: block;
	}
	@media (min-width: 769px) {
		display: flex;
		margin-top: 0;
	}
	.right-mobile {
		width: 100%;
		height: 100%;
		min-height: 80px;
		margin: 0 auto;
		background-color: white;
		position: relative;
		display: flex !important;
		justify-content: space-between;
		margin-bottom: 16px;
		cursor: pointer;
		padding: 0 20px;
		align-items: center;
		border: none;
		border-radius: 10px;
		@media (min-width: 769px) {
			display: none !important;
		}
		span {
			display: flex;
			align-items: center;
			font-weight: 700;
			font-size: 18px;
			line-height: 22px;
			align-items: center;
			i {
				margin-right: 10px;
			}
		}
		button {
			background-color: transparent;
			border: none;
			color: black;
		}
		@media (max-width: 768px) {
			display: block;
		}
	}
	div {
		position: relative;
		border-radius: 28px;
		color: black;
		@media (max-width: 768px) {
			border-radius: 10px;
		}
	}

	span {
		display: block;
		margin: 8px 0;
		strong {
			font-weight: 900;
		}
	}

	.left {
		position: relative;
		background-color: white;
		padding: 30px 20px;
		width: 100%;
		margin: auto;
		padding-bottom: 10px;
		@media (min-width: 769px) {
			width: 70%;
			padding: 40px 70px;
		}

		.title-box {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.title {
				display: flex;
				align-items: center;
				h1 {
					font-weight: 700;
					font-size: 24px;
					@media (max-width: 768px) {
						font-size: 20px;
					}
				}
				span {
					padding: 6px 12px;
					font-weight: 500;
					font-size: 12px;
					color: #ffac00;
					margin-left: 20px;
					border: 1px solid #ffac00;
					border-radius: 100px;
					@media (max-width: 768px) {
						margin-left: 12px;
					}
				}
			}
			.padlet {
				i {
					margin-right: 8px;
					@media (max-width: 768px) {
						display: none;
					}
				}
				.padlet-btn {
					background-color: transparent;
					border: none;
					font-weight: 700;
					font-size: 16px;
					line-height: 20px;
					letter-spacing: -0.02em;
					padding: 8px 16px;
					@media (max-width: 768px) {
						font-size: 14px;
					}
					:hover {
						transform: translateX(2px) translateY(-2px);
						transition: 0.1s ease-out;
					}
				}
			}
		}

		.progress-wrap {
			width: 100%;
		}
		.progress-status {
			text-align: center;
			display: flex;
			align-items: end;
			justify-content: space-between;
			position: relative;
			height: 100%;
			overflow-x: scroll;
			overflow-y: visible;
			position: relative;
			margin-top: 64px;
			.progress-bar {
				position: absolute;
				width: 200%;
				border-bottom: 2px dashed #bebebe;
			}
			.count {
				font-weight: 500;
				font-size: 12px;
				line-height: 12px;
				color: #bebebe;
			}
			.start-date {
				font-weight: 500;
				font-size: 13px;
				line-height: 32px;
				min-width: 100px;
			}
			.progress-box {
				position: relative;
				:last-child {
					.progress-line,
					.unprogress-line,
					.progress-line-junior,
					.unprogress-line-junior {
						display: none;
					}
				}
			}
			.progress-line {
				border-bottom: 2px solid #f9dd4a;
				border-radius: 0;
				width: 100%;
				position: absolute;
				left: 50%;
				bottom: 94px;

				@media (min-width: 769px) {
					width: 110%;
					left: 60%;
				}
				@media (min-width: 1400px) {
					width: 140%;
					left: 60%;
				}
			}
			.unprogress-line {
				border-bottom: 2px dashed #bebebe;
				border-radius: 0;
				width: 100%;
				position: absolute;
				left: 50%;
				bottom: 94px;
				@media (min-width: 769px) {
					width: 110%;
					left: 60%;
				}
				@media (min-width: 1400px) {
					width: 140%;
					left: 60%;
				}
			}
			.progress-line-junior {
				border-bottom: 2px solid #f9dd4a;
				border-radius: 0;
				width: 100%;
				position: absolute;
				left: 50%;
				bottom: 94px;
				@media (min-width: 300px) {
					width: 110%;
					left: 60%;
				}
				@media (min-width: 400px) {
					width: 110%;
					left: 60%;
				}
				@media (min-width: 500px) {
					width: 150%;
					left: 60%;
				}

				@media (min-width: 620px) {
					width: 190%;
					left: 60%;
				}

				@media (min-width: 700px) {
					width: 210%;
					left: 60%;
				}
				@media (min-width: 769px) {
					width: 100%;
					left: 60%;
				}

				@media (min-width: 800px) {
					width: 110%;
					left: 60%;
				}
				@media (min-width: 900px) {
					width: 130%;
					left: 60%;
				}

				@media (min-width: 1000px) {
					width: 160%;
					left: 60%;
				}
				@media (min-width: 1100px) {
					width: 190%;
					left: 60%;
				}
				@media (min-width: 1200px) {
					width: 220%;
					left: 60%;
				}
				@media (min-width: 1400px) {
					width: 240%;
					left: 60%;
				}
				@media (min-width: 1550px) {
					width: 270%;
					left: 60%;
				}
				@media (min-width: 1650px) {
					width: 290%;
					left: 60%;
				}
				@media (min-width: 1920px) {
					width: 280%;
					left: 60%;
				}
				@media (min-width: 2080px) {
					width: 290%;
					left: 60%;
				}
			}
			.unprogress-line-junior {
				border-bottom: 2px dashed #bebebe;
				border-radius: 0;
				width: 100%;
				position: absolute;
				left: 50%;
				bottom: 94px;
				@media (min-width: 300px) {
					width: 110%;
					left: 60%;
				}
				@media (min-width: 400px) {
					width: 110%;
					left: 60%;
				}
				@media (min-width: 500px) {
					width: 150%;
					left: 60%;
				}

				@media (min-width: 620px) {
					width: 190%;
					left: 60%;
				}

				@media (min-width: 700px) {
					width: 210%;
					left: 60%;
				}
				@media (min-width: 769px) {
					width: 100%;
					left: 60%;
				}

				@media (min-width: 800px) {
					width: 110%;
					left: 60%;
				}
				@media (min-width: 900px) {
					width: 130%;
					left: 60%;
				}

				@media (min-width: 1000px) {
					width: 160%;
					left: 60%;
				}
				@media (min-width: 1100px) {
					width: 190%;
					left: 60%;
				}
				@media (min-width: 1200px) {
					width: 220%;
					left: 60%;
				}
				@media (min-width: 1400px) {
					width: 240%;
					left: 60%;
				}
				@media (min-width: 1550px) {
					width: 270%;
					left: 60%;
				}
				@media (min-width: 1650px) {
					width: 290%;
					left: 60%;
				}
				@media (min-width: 1920px) {
					width: 280%;
					left: 60%;
				}
				@media (min-width: 2080px) {
					width: 290%;
					left: 60%;
				}
			}
			.status-complete {
				background: rgba(249, 221, 74, 0.12);
				border-radius: 20px;
				font-weight: 400;
				font-size: 12px;
				line-height: 32px;
				color: #e4c000;
				width: 110px;
			}
			.status-incomplete {
				font-weight: 400;
				font-size: 12px;
				line-height: 32px;
				color: #bebebe;
				width: 110px;
			}
			.completed {
				display: flex;
				flex-direction: column;
				z-index: 1;
			}
			.not-completed {
				display: flex;
				flex-direction: column;
			}
		}
	}

	.right {
		width: 30%;
		margin-left: 30px;
		padding: 40px;
		background-color: white;
		position: relative;
		cursor: pointer;
		min-height: 300px;
		@media (max-width: 768px) {
			display: none;
		}
		:hover {
			background-color: rgba(115, 108, 97, 0.174);
			transition: 0.1s ease-out;
			.hub-btn {
				transform: translateX(10%);
				transition: 0.1s ease-out;
			}
		}
		.hub-btn {
			width: 52px;
			height: 52px;
			border-radius: 50%;
			background: #f7f7f7;
			border: none;
			cursor: pointer;
			position: absolute;
			bottom: 40px;
			left: 40px;
			padding-top: 6px;
			@media (max-width: 768px) {
				transform: translateX(-20%);
			}
		}
		.book-btn {
			background-color: transparent;
			border: none;
			position: absolute;
			right: 40px;
			bottom: 40px;
			@media (max-width: 768px) {
				right: 14px;
				bottom: 14px;
			}
		}
		h1 {
			font-weight: 700;
			font-size: 32px;
			line-height: 35px;
			letter-spacing: -0.03em;
			@media (max-width: 768px) {
				font-size: 20px;
			}
		}
		span {
			font-weight: 300;
			font-size: 18px;
			line-height: 35px;
			letter-spacing: -0.03em;
			@media (max-width: 768px) {
				font-size: 16px;
			}
		}
	}
	.faq {
		background-color: #736c61;
		display: flex;
		align-items: center;
		cursor: pointer;
		min-height: 96px;
		max-height: 96px;
		@media (max-width: 768px) {
			width: 69%;
			min-height: 90px;
			position: absolute;
			top: 260px;
			z-index: 999;
			padding-left: 18px;
		}
		span {
			color: white;
			font-weight: 700;
			font-size: 24px;
			line-height: 30px;
			margin-left: 8px;
			margin-right: 48%;
			@media (max-width: 768px) {
				margin-right: 40%;
			}
		}
		i {
			@media (max-width: 768px) {
				position: absolute;
				right: 20px;
			}
		}
		:hover {
			background: #232323;
			transition: 0.1s ease-out;
			i {
				transform: translateX(20%);
				transition: 0.1s ease-out;
			}
		}
	}
`;

export const MenteeListWrapper = styled.div`
	max-width: 1400px;
	width: 100%;
	margin: 30px auto;
	.mo-mentee-wrap {
		display: none;
	}
	.mentee-wrap {
		margin-top: 30px;
		display: flex;
		justify-content: space-between;
		gap: 30px;
		height: 403px;
		.mentee-left {
			/* max-width: 914px; */
			width: 70%;
			background-color: #fff;
			border-radius: 27px;
			padding: 40px 50px;
			.mentor-top {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.mentor-left {
					display: flex;
					align-items: center;
					gap: 11px;
					padding: 11px 11px 0 11px;
					.mentor-top-title {
						font-weight: 700;
						font-size: 24px;
						line-height: 30px;
						color: #111;
					}
					.mentor-border {
						font-weight: 500;
						font-size: 12px;
						line-height: 15px;
						letter-spacing: 0.02em;
						color: #ffac00;
						padding: 5px 12px;
						border: 1px solid #ffac00;
						border-radius: 100px;
					}
				}
				.mentor-right {
					display: flex;
					align-items: center;
					button {
						background-color: #fff;
						border: none;
						font-weight: 700;
						font-size: 16px;
						line-height: 20px;
						letter-spacing: -0.02em;
						.kbds-arrow {
							svg {
								position: relative;
								right: 11px;
								top: 3px;
							}
						}
					}
				}
			}
			.current-wrap {
				.noti-txt {
					border-radius: 10px;
					background: rgba(255, 0, 0, 0.04);
					padding: 11px 17px;
					display: inline-flex;
					align-items: center;
					gap: 8px;
					margin-top: 32px;
					color: #f00;
					font-size: 12px;
					font-weight: 500;
					line-height: 160%;
					letter-spacing: 0.24px;
					img {
						position: relative;
						top: -1px;
					}
					span {
						text-decoration: underline;
					}
				}
				.current-list {
					display: flex;
					justify-content: space-between;
					padding: 38px 5px 64px 5px;
					text-align: center;
					overflow: hidden;
					li {
						letter-spacing: -0.02em;
						font-size: 12px;
						line-height: 32px;
						position: relative;
						.icon-current {
							svg {
								width: 43px;
								position: relative;
								z-index: 1;
							}
						}
						.round-text {
							font-weight: 500;
							color: #bebebe;
							margin-top: 10px;
						}
						.round-box1 {
							background: rgba(213, 213, 213, 0.12);
							border-radius: 20px;
							width: 71px;
							height: 25px;
							.schedule-text {
								font-weight: 400;
								color: #9f9f9f;
								position: relative;
								top: -4px;
							}
							.complete-text {
								color: #e4c000;
							}
						}
						.complete-box {
							background: rgba(249, 221, 74, 0.12);
						}

						&::after {
							position: absolute;
							content: '';
							top: 22px;
							left: 44px;
							width: 149px;
							height: 1px;
							border-top: 2px dashed #bebebe;
						}
						&:last-child::after {
							display: none;
						}
					}
					.on {
						.icon-current {
							svg {
								path {
									fill: #f9dd4a;
								}
							}
						}
						&::after {
							position: absolute;
							content: '';
							top: 22px;
							left: 44px;
							width: 149px;
							height: 1px;
							border-top: 2px solid #f9dd4a;
						}
					}
				}
				.current-list-three {
					display: flex;
					justify-content: space-between;
					padding: 38px 5px 64px 5px;
					text-align: center;
					overflow: hidden;
					li {
						letter-spacing: -0.02em;
						font-size: 12px;
						line-height: 32px;
						position: relative;
						.icon-current {
							svg {
								width: 71px;
								position: relative;
								z-index: 1;
							}
						}
						.round-text {
							font-weight: 500;
							color: #bebebe;
							margin-top: 10px;
						}
						.round-box1 {
							background: rgba(213, 213, 213, 0.12);
							border-radius: 20px;
							width: 71px;
							height: 25px;
							.schedule-text {
								font-weight: 400;
								color: #9f9f9f;
								position: relative;
								top: -4px;
							}
							.complete-text {
								color: #e4c000;
							}
						}
						.complete-box {
							background: rgba(249, 221, 74, 0.12);
						}
						&::after {
							position: absolute;
							content: '';
							top: 22px;
							left: 44px;
							width: 332px;
							height: 1px;
							border-top: 2px dashed #bebebe;
						}
						&:last-child::after {
							display: none;
						}
					}
					.on {
						.icon-current {
							svg {
								path {
									fill: #f9dd4a;
								}
							}
						}
						&::after {
							position: absolute;
							content: '';
							top: 22px;
							left: 44px;
							width: 332px;
							height: 1px;
							border-top: 2px solid #f9dd4a;
						}
					}
				}
			}
		}
		.mentee-right {
			max-width: 394px;
			width: 100%;
			border-radius: 27px;
			background-color: #fff;
			padding: 40px;
			letter-spacing: -0.03em;
			color: #0e0e0e;
			position: relative;
			.mentee-title {
				font-weight: 700;
				font-size: 28px;
				line-height: 35px;
			}
			.mentee-text {
				font-weight: 300;
				font-size: 18px;
				line-height: 35px;
				margin-top: 12px;
			}

			.manual-icon-box {
				display: flex;
				align-items: end;
				justify-content: space-between;
				padding-top: 140px;
				.manual-arrow-box {
					width: 52px;
					height: 52px;
					background-color: #f7f7f7;
					border-radius: 50%;
					position: relative;
					svg {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}
		}
	}
	@media (max-width: 768px) {
		padding-top: 254px;
		.mentee-wrap {
			display: none;
		}
		.mo-mentee-wrap {
			display: block;
			.mentee-top {
				margin-top: 10px;
				background-color: #fff;
				border-radius: 10px;
				padding: 20px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.manual-box {
					display: flex;
					align-items: center;
					.mentee-title {
						font-weight: 700;
						font-size: 18px;
						line-height: 22px;
						margin-left: 10px;
					}
				}
				.big-arrow {
					svg {
						width: 23px;
						path {
							stroke: #000;
						}
					}
				}
			}
			.mentee-under {
				margin-top: 10px;
				background-color: #fff;
				border-radius: 10px;
				padding: 0;
				.mentor-top {
					padding: 0 20px;
					.mentor-left {
						padding-top: 30px;
						display: flex;
						align-items: center;
						.mentor-top-title {
							font-weight: 700;
							font-size: 20px;
							line-height: 25px;
							color: #111;
							margin-right: 13px;
						}
						.mentor-border {
							font-weight: 500;
							font-size: 12.3px;
							line-height: 15px;
							letter-spacing: 0.02em;
							color: #ffac00;
							border: 1px solid #ffac00;
							padding: 3px 9px;
							border-radius: 81px;
						}
					}
					.mentor-right {
						margin-top: 20px;
						display: flex;
						justify-content: end;
						align-items: center;

						button {
							font-weight: 700;
							font-size: 13px;
							line-height: 16px;
							letter-spacing: -0.02em;
							border: none;
							background-color: transparent;

							.kbds-arrow {
								margin-right: 6px;
								svg {
									position: relative;
									top: 2px;
								}
							}
						}
					}
				}
				.current-wrap {
					margin-top: 10px;
					white-space: nowrap;
					overflow-x: auto;
					&::-webkit-scrollbar {
						display: none;
					}
					.noti-txt-box {
						padding-left: 20px;
						margin-bottom: 11px;
						.noti-txt {
							border-radius: 10px;
							background: rgba(255, 0, 0, 0.04);
							padding: 11px 17px;
							display: inline-flex;
							align-items: flex-start;
							margin-top: 15px;
							color: #f00;
							font-size: 12px;
							font-weight: 500;
							line-height: 160%;
							letter-spacing: 0.24px;

							img {
								position: relative;
								top: 1px;
								width: 16px;
								margin-right: 8px;
							}
							span {
								text-decoration: underline;
							}
						}
					}
					.current-list {
						display: flex;
						gap: 54px;
						justify-content: space-between;
						text-align: center;
						margin-left: -10px;
						li {
							letter-spacing: -0.02em;
							font-size: 12px;
							line-height: 32px;
							position: relative;
							.icon-current {
								svg {
									width: 46px;
									position: relative;
									z-index: 1;
								}
							}
							.round-text {
								font-weight: 500;
								color: #bebebe;
								/* margin-top: 8px; */
							}
							.date {
								font-family: KBFG Display;
								font-weight: 500;
								font-size: 13px;
								letter-spacing: -0.26px;
								margin-top: -4px;
								span {
									color: #888;
								}
							}
							.round-box1 {
								.schedule-text {
									font-weight: 400;
									color: #9f9f9f;
									position: relative;
									top: -4px;
								}
								.complete-text {
									color: #e4c000;
								}
							}
							.complete-box {
								background: rgba(249, 221, 74, 0.12);
								border-radius: 20px;
								/* width: 71px; */
								max-width: 200px;
								width: 100%;
								height: 25px;
								margin: 0 auto;
							}
							&::after {
								position: absolute;
								content: '';
								top: 22px;
								left: 45px;
								width: 140px;
								height: 1px;
								border-top: 2px dashed #bebebe;
							}
							&:last-child::after {
								display: none;
							}
						}
						.on {
							.icon-current {
								svg {
									path {
										fill: #f9dd4a;
									}
								}
							}
							&::after {
								position: absolute;
								content: '';
								top: 22px;
								left: 60px;
								width: 140px;
								height: 1px;
								border-top: 2px solid #f9dd4a;
							}
						}
					}
					.current-list-three {
						display: flex;
						gap: 54px;
						justify-content: space-between;
						text-align: center;
						margin-left: -10px;
						li {
							letter-spacing: -0.02em;
							font-size: 12px;
							line-height: 32px;
							position: relative;
							.icon-current {
								svg {
									width: 46px;
									position: relative;
									z-index: 1;
								}
							}
							.round-text {
								font-weight: 500;
								color: #bebebe;
								/* margin-top: 8px; */
							}
							.date {
								font-family: KBFG Display;
								font-weight: 500;
								font-size: 13px;
								letter-spacing: -0.26px;
								margin-top: -4px;
								span {
									color: #888;
								}
							}
							.round-box1 {
								.schedule-text {
									font-weight: 400;
									color: #9f9f9f;
									position: relative;
									top: -4px;
								}
								.complete-text {
									color: #e4c000;
								}
							}
							.complete-box {
								background: rgba(249, 221, 74, 0.12);
								border-radius: 20px;
								/* width: 71px; */
								max-width: 200px;
								width: 100%;
								height: 25px;
								margin: 0 auto;
							}
							&::after {
								position: absolute;
								content: '';
								top: 22px;
								left: 45px;
								width: 332px;
								height: 1px;
								border-top: 2px dashed #bebebe;
							}
							&:last-child::after {
								display: none;
							}
						}
						.on {
							.icon-current {
								svg {
									path {
										fill: #f9dd4a;
									}
								}
							}
							&::after {
								position: absolute;
								content: '';
								top: 22px;
								left: 60px;
								width: 332px;
								height: 1px;
								border-top: 2px solid #f9dd4a;
							}
						}
					}
				}
				.more-box {
					border-top: 1px solid #eee;
					margin-top: 15px;
					button {
						width: 100%;
						padding: 13px 0;
						font-weight: 300;
						font-size: 14px;
						line-height: 17px;
						background-color: #fff;
						border: none;
					}
				}
				.gradient-box {
					position: relative;
					.bg-l {
						position: absolute;
						z-index: 1;
						top: -220px;
						width: 102px;
						height: 167px;
						background: linear-gradient(
							270deg,
							#ffffff 0%,
							rgba(255, 255, 255, 0) 66.67%
						);
						transform: matrix(-1, 0, 0, 1, 0, 0);
					}
					.bg-r {
						position: absolute;
						z-index: 1;
						top: -220px;
						right: 0;
						width: 102px;
						height: 167px;
						background: linear-gradient(
							270deg,
							#ffffff 0%,
							rgba(255, 255, 255, 0) 66.67%
						);
					}
				}
			}
		}
	}
`;
