import React, {useEffect} from 'react';
import { useParams, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AuthSetToken } from '../store/auth';

const Auth = () => {
    const params = useParams();
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const Dispatch = useDispatch();

    function urldecode(str) {
        return decodeURIComponent((str + '')
            .replace(/%(?![\da-f]{2})/gi, function() {
                return '%25';
            })
            .replace(/\+/g, '%20'));
    }

    useEffect(() => {
        if(params.token) {
            Dispatch(AuthSetToken(params.token)); // 스토어에 토큰 저장
            localStorage.setItem('user_token', params.token); // 로컬스토리지에 토큰 저장
            if(params.redirect) {
                window.location.href=urldecode(params.redirect);
                // console.log(urldecode(params.redirect));
            } else {
                window.location.href='/';
            }
        }
    });
    return (
        <>토큰 저장중입니다.</>
    );
};

export default Auth;