import styled from 'styled-components';

export const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	.overlay {
		width: 100%;
		height: 100%;
		background-color: #00000030;
	}
	.modal-wrapper {
		width: 100%;
		height: 100vh;
		.modal-wrap {
			position: absolute;
			z-index: 1;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			padding: 40px 50px;
			background-color: #fff;
			border-radius: 20px;
			.icon-close {
				position: absolute;
				right: 40px;
				cursor: pointer;
				svg {
					width: 17px;
				}
			}
			.modal-title {
				margin-top: 40px;
				font-weight: 700;
				font-size: 26px;
				line-height: 32px;
			}
			.modal-box {
				margin-top: 30px;
				border-top: 1px solid #e9e9e9;
				p {
					white-space: nowrap;
				}
				.select-wrap {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 50px;
					margin-top: 30px;
					padding: 0 30px;

					.department-title {
						font-weight: 700;
						font-size: 18px;
						line-height: 22px;
					}
					.cont-select {
						width: 420px;
						position: relative;

						.btn-select {
							width: 100%;
							padding: 26px 30px;
							border-radius: 20px;
							cursor: pointer;
							font-weight: 500;
							font-size: 16px;
							line-height: 20px;
							border: none;
							text-align: left;
							/* background-image: url(icon-selectarrow.svg); */
							background-repeat: no-repeat;
							background-position: 92% 50%;
							background-color: #f7f7f7;
							/* color: #888; */
							color: #000;
						}

						.list-member {
							background-color: #fff;
							border: 1px solid #e0e0e0;
							border-radius: 20px;
							margin-top: 6px;
							position: absolute;
							z-index: 1;
							width: 100%;
							display: none;
							padding-bottom: 10px;
							&.active {
								display: block;
							}
							li {
								padding: 0 10px;
								button {
									width: 100%;
									padding: 16px 20px;
									border-radius: 20px;
									cursor: pointer;
									font-weight: 500;
									font-size: 16px;
									line-height: 20px;
									border: none;
									text-align: left;
									background-color: #fff;
									&:hover {
										background-color: #f7f7f7;
									}
								}
								&:first-child {
									padding-top: 12px;
								}
							}
						}
					}
				}
				.name-select-wrap {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 50px;
					margin-top: 30px;
					padding: 0 30px;
					input {
						width: 100%;
						padding: 26px 30px;
						border-radius: 20px;
						cursor: pointer;
						font-weight: 500;
						font-size: 16px;
						line-height: 20px;
						border: none;
						text-align: left;
						background-image: url(../images/popup-search.svg);
						background-repeat: no-repeat;
						background-position: 92% 50%;
						background-color: #f7f7f7;
						box-sizing: border-box;
						&::placeholder {
							color: #888;
						}
					}
					.department-title {
						font-weight: 700;
						font-size: 18px;
						line-height: 22px;
					}
					.cont-select {
						width: 420px;
						position: relative;
						.btn-name-select {
							width: 100%;
							padding: 26px 30px;
							border-radius: 20px;
							cursor: pointer;
							font-weight: 500;
							font-size: 16px;
							line-height: 20px;
							border: none;
							text-align: left;
							background-image: url(icon-selectarrow.svg);
							background-repeat: no-repeat;
							background-position: 92% 50%;
							background-color: #f7f7f7;
							color: #888;
						}
						.btn-name-select {
							width: 100%;
							padding: 26px 30px;
							border-radius: 20px;
							cursor: pointer;
							font-weight: 500;
							font-size: 16px;
							line-height: 20px;
							border: none;
							text-align: left;
							background-image: url(icon-select-search.svg);
							background-repeat: no-repeat;
							background-position: 92% 50%;
							background-color: #f7f7f7;
							color: #888;
						}
						.list-name-member {
							background-color: #fff;
							border: 1px solid #e0e0e0;
							border-radius: 20px;
							margin-top: 6px;
							position: absolute;
							z-index: 1;
							max-width: 420px;
							width: 100%;
							display: none;
							padding-bottom: 10px;
							max-height: 180px;
							height: 180px;
							overflow-y: auto;
							&.active {
								display: block;
							}
							li {
								padding: 0 10px;
								button {
									width: 100%;
									padding: 16px 20px;
									border-radius: 20px;
									cursor: pointer;
									font-weight: 500;
									font-size: 16px;
									line-height: 20px;
									border: none;
									text-align: left;
									background-color: #fff;
									display: flex;
									justify-content: space-between;
									&:hover {
										background-color: #f7f7f7;
									}
								}

								&:first-child {
									padding-top: 12px;
								}
							}
						}
					}
				}
				.input-box {
					width: 420px;
					input {
						padding: 26px 30px;
						border-radius: 20px;
						cursor: pointer;
						font-weight: 500;
						font-size: 16px;
						line-height: 20px;
						border: none;
						text-align: left;
						background-color: #f7f7f7;
						box-sizing: border-box;
						width: 100%;
						&::placeholder {
							color: #888;
						}
					}
				}
			}

			.btn-wrap {
				margin-top: 240px;
				display: flex;
				justify-content: end;
				gap: 10px;
				button {
					padding: 20px 64px;
					border: 1px solid #ffbc00;
					background-color: #fff;
					color: #ffbc00;
					border-radius: 20px;
					font-weight: 700;
					font-size: 18px;
					line-height: 22px;
				}
				.yellow-btn {
					background-color: #ffbc00;
					color: #fff;
				}
			}
			.bg {
				width: 100%;
				height: 40px;
				border-radius: 20px;
				position: absolute;
				z-index: 1;
				bottom: 5px;
				background: linear-gradient(
					180deg,
					#ffffff 0%,
					rgba(255, 255, 255, 0) 100%
				);
				transform: matrix(1, 0, 0, -1, 0, 0);
			}
		}
	}
	@media ${(props) => props.theme.mobile} {
		.modal-wrapper {
			.modal-wrap {
				padding: 30px 20px;
				border-radius: 10px;
				.icon-close {
					display: none;
				}
				.modal-title {
					font-size: 20px;
					line-height: 25px;
					margin-top: 0;
					max-width: 160px;
				}
				.modal-box {
					margin-top: 10px;
					.select-wrap {
						flex-direction: column;
						align-items: flex-start;
						gap: 14px;
						margin-top: 20px;
						padding: 0;
						.department-title {
							font-size: 14px;
							line-height: 17px;
						}
						.cont-select {
							width: 100%;
							.btn-select {
								padding: 19px 20px;
								font-size: 12px;
								line-height: 15px;
								background-position: 95% 50%;
								background-size: 12px;
							}

							.list-member {
								margin-top: 4px;

								li {
									padding: 0 8px;
									button {
										padding: 16px 20px;
										font-size: 12px;
										line-height: 15px;
										&:hover {
											background-color: #f7f7f7;
										}
									}
								}
							}
						}
					}
					.name-select-wrap {
						flex-direction: column;
						align-items: flex-start;
						gap: 14px;
						margin-top: 20px;
						padding: 0;
						input {
							padding: 19px 20px;
							font-size: 12px;
							line-height: 15px;
							background-position: 95% 50%;
							background-size: 12px;
						}
						.department-title {
							font-size: 14px;
							line-height: 17px;
						}
						.cont-select {
							width: 100%;
							.btn-name-select {
								padding: 19px 20px;
								font-size: 12px;
								line-height: 15px;
								background-position: 95% 50%;
								background-size: 12px;
							}
							.btn-name-select {
								padding: 19px 20px;
								font-size: 12px;
								line-height: 15px;
								background-position: 95% 50%;
								background-size: 12px;
							}
							.list-name-member {
								margin-top: 4px;
								padding-bottom: 10px;
								li {
									padding: 0 8px;
									button {
										padding: 16px 20px;
										font-size: 12px;
										line-height: 15px;
									}
									&:first-child {
										padding-top: 12px;
									}
								}
							}
						}
					}
					.input-box {
						width: 100%;
						input {
							padding: 19px 20px;
							font-size: 12px;
							line-height: 15px;
						}
					}
				}
			}
		}
		.btn-wrap {
			margin-top: 53px !important;
			gap: 8px;
			button {
				padding: 14px 0px !important;
				font-size: 12px !important;
				line-height: 15px !important;
				border-radius: 14px !important;
				width: 126px;
			}
		}
	}
`;
