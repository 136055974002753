import { useEffect, useRef, useState } from 'react';
import ModalClose from '../../../../../vector/ModalClose';
import ModalPrev from '../../../../../vector/ModalPrev';
import { SliderWrapper } from './styled';
import { AddMentee } from '../../../../../api/mentorings';
import { GetAllUsers } from '../../../../../api/user';

export default function Modal3({ modalToggle3, user, setP }) {
	const [hide, setHide] = useState(true);
	const [hide2, setHide2] = useState(true);
	const [hide4, setHide4] = useState(true);
	const ref = useRef();
	const selectHide = () => {
		setHide((p) => !p);
	};
	const selectHide2 = () => {
		setHide2((p) => !p);
	};
	const selectHide4 = () => {
		setHide4((p) => !p);
	};

	const [users, setUsers] = useState([]);

	useEffect(() => {
		GetAllUsers()
			.then((res) => {
				console.log(res);
				setUsers(res?.data.body.users);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const [career, setCareer] = useState('');
	const onChangeCareer = (value) => {
		setCareer(value);
		selectHide4();
	};

	const [searchText, setSearchText] = useState('');
	const [options] = useState([
		'Option 1',
		'Option 2',
		'Option 3',
		// Add more options as needed
	]);

	const filteredOptions = users?.filter((option) =>
		option.display_name.toLowerCase().includes(searchText.toLowerCase())
	);

	const handleInputChange = (e) => {
		setSearchText(e.target.value);
	};

	const [mentee, setMentee] = useState({
		name: '',
		login: '',
	});
	const onChangeMentee = (name, login) => {
		setMentee({
			name: name,
			login: login,
		});
		ref.current.value = name;
		selectHide2();
	};

	const DoAdd = () => {
		if (!career) {
			alert('신입/경력을 선택하세요.');
			return;
		}
		if (!mentee?.login) {
			alert('멘티를 선택하세요.');
			return;
		}

		AddMentee({
			career: career,
			mentoringId: user?.mentoringId,
			// menteeId: user?.ID,
			menteeId: mentee?.login,
		})
			.then((res) => {
				console.log(res);
				if (res.data.code == 200) {
					setP((p) => !p);
					alert('멘티가 추가되었습니다.');
					modalToggle3();
				} else {
					alert(res.data.body.message);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<SliderWrapper>
			<section className="modal-wrapper">
				<div className="overlay" onClick={modalToggle3}></div>
				<div className="modal-wrap">
					<i className="icon-close" onClick={modalToggle3}>
						<ModalClose />
					</i>
					<h2 className="modal-title">
						{user?.user_name} 멘토에게 멘티를 추가합니다
					</h2>
					<div className="modal-box">
						<div className="select-wrap">
							<p className="department-title">부서</p>
							<article className="cont-select">
								<button onClick={selectHide} className="btn-select a">
									{user?.user_department}
								</button>
								{/* <ul className={hide ? 'list-member' : 'active list-member'}>
									<li>
										<button>신기술사업부</button>
									</li>
									<li>
										<button>신기술사업부</button>
									</li>
								</ul> */}
							</article>
						</div>
						<div className="select-wrap">
							<p className="department-title">신입/경력</p>
							<article className="cont-select">
								<button
									onClick={selectHide4}
									className={career ? 'active btn-select' : 'btn-select'}
								>
									{career == 'junior' ? '신입(5회)' : null}
									{career == 'senior' ? '경력(3회)' : null}
									{!career ? '신입/경력을 선택하세요' : null}
								</button>
								<ul className={hide4 ? 'list-member' : 'active list-member'}>
									<li onClick={() => onChangeCareer('junior')}>
										<button>신입(5회)</button>
									</li>
									<li onClick={() => onChangeCareer('senior')}>
										<button>경력(3회)</button>
									</li>
								</ul>
							</article>
						</div>
						<div className="name-select-wrap">
							<p className="department-title">멘티 선택</p>
							<article className="cont-select">
								<input
									type="text"
									onFocus={selectHide2}
									// onBlur={selectHide2}
									placeholder="이름을 검색하세요"
									onChange={handleInputChange}
									ref={ref}
									// value={mentee}
								></input>
								{/* <button onClick={selectHide2} className="btn-name-select">
									이름을 검색하세요
								</button> */}
								<ul
									className={
										hide2 ? 'list-name-member' : 'active list-name-member'
									}
								>
									{filteredOptions?.map((item, key) => (
										<li
											key={key}
											onClick={() =>
												onChangeMentee(item?.display_name, item?.user_login)
											}
										>
											<button>
												<p className="name-title">{item?.display_name}</p>
												<p className="number">d{item?.user_login}</p>
											</button>
										</li>
									))}
									<div className="bg"></div>
								</ul>
							</article>
						</div>
					</div>
					<div className="btn-wrap">
						<button onClick={modalToggle3}>취소</button>
						<button onClick={DoAdd} className="yellow-btn">
							추가
						</button>
					</div>
				</div>
			</section>
		</SliderWrapper>
	);
}
