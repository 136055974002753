import React from 'react';
import {StyledButton} from './styled'

const Button = (props) => {
    return (
        <StyledButton href={props.to} disable={props.disable} onClick={props.onClick} type={props.type} color={props.color} icon={props.icon ? props.icon : 'arrow'} size={props.size} iconSize={props.iconSize}>
            <span>{props.children}</span>
            {props.type=='primary' ? (<i></i>) : ''}
        </StyledButton>
    )
}

export default Button;