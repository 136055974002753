import React from 'react';
import { EmptyWrapper } from './styled';

const Empty = () => {
	return (
		<EmptyWrapper>
			<div className="empty-wrap">
				<img src="./images/mdi_question-mark-circle.svg" alt="" />
				<p>졸업자가 없습니다.</p>
			</div>
		</EmptyWrapper>
	);
};

export default Empty;
