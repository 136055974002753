export default function NotCheckedIcon() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="26" cy="25" r="20" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.9998 2.08301C12.8373 2.08301 2.1665 12.3434 2.1665 24.9997C2.1665 37.6559 12.8373 47.9163 25.9998 47.9163C39.1623 47.9163 49.8332 37.6559 49.8332 24.9997C49.8332 12.3434 39.1623 2.08301 25.9998 2.08301ZM36.3305 21.1247C36.5207 20.9156 36.6656 20.6721 36.7564 20.4084C36.8473 20.1447 36.8825 19.8662 36.8598 19.5892C36.8371 19.3123 36.757 19.0425 36.6243 18.7958C36.4915 18.549 36.3088 18.3303 36.0869 18.1525C35.8649 17.9746 35.6082 17.8413 35.3319 17.7602C35.0555 17.6792 34.7651 17.6521 34.4778 17.6806C34.1904 17.709 33.9118 17.7925 33.6585 17.926C33.4051 18.0595 33.1821 18.2404 33.0025 18.458L23.6858 29.2059L18.865 24.5684C18.4564 24.1889 17.9091 23.9789 17.341 23.9837C16.7729 23.9884 16.2294 24.2075 15.8277 24.5938C15.426 24.9801 15.1981 25.5026 15.1932 26.0488C15.1883 26.5951 15.4067 27.1213 15.8013 27.5143L22.3013 33.7643C22.5142 33.9688 22.7691 34.1285 23.0497 34.2331C23.3304 34.3377 23.6306 34.385 23.9313 34.3719C24.2319 34.3587 24.5265 34.2855 24.7961 34.1569C25.0657 34.0282 25.3044 33.847 25.4972 33.6247L36.3305 21.1247Z"
        fill="#DBDBDB"
      />
    </svg>
  );
}
