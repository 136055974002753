export default function PlusIcon() {
  return (
    <>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 7H14" stroke="white" stroke-width="2" />
        <path d="M7 0L7 14" stroke="white" stroke-width="2" />
      </svg>
    </>
  );
}
