import BlackRightArrow from '../../../vector/BlackRightArrow';
import BookIcon from '../../../vector/BookIcon';
import RightArr from '../../../vector/RightArr';
import SmallBookIcon from '../../../vector/SmallBookIcon';
import { MiddleSectionWrapper } from '../styled';
import JuniorMonitoring from './JuniorMonitoring';
import SeniorMonitoring from './SeniorMonitoring';

export default function MenteeSection({ otherUserInfo }) {
  return (
    <MiddleSectionWrapper>
      <button
        onClick={() => {
          window.open(
            'https://padlet.com/kbdsstarters/kbds-wiki-b7w3ma8sfhcw44kf'
          );
        }}
        className="right-mobile"
      >
        <span>
          <i>
            <SmallBookIcon />
          </i>
          KBDS WIKI
        </span>
        <button>
          <i>
            <BlackRightArrow />
          </i>
        </button>
      </button>
      <div className="left">
        <div className="progress-wrap">
          <div className="title-box">
            <div className="title">
              <h1>멘토링 현황</h1>
              <span>멘티</span>
            </div>
          </div>
          {otherUserInfo?.mentoring?.career === 'junior' ? (
            <JuniorMonitoring otherUserInfo={otherUserInfo} />
          ) : (
            <SeniorMonitoring otherUserInfo={otherUserInfo} />
          )}
        </div>
      </div>

      <div
        onClick={() =>
          window.open('https://padlet.com/kbdsstarters/b7w3ma8sfhcw44kf')
        }
        className="right"
      >
        <h1>KBDS WIKI</h1>
        <span>KB데이터시스템의 다양한 채널들을 소개합니다.</span>
        <button className="hub-btn">
          <i className="right-arr">
            <RightArr />
          </i>
        </button>
        <button className="book-btn">
          <BookIcon />
        </button>
      </div>
    </MiddleSectionWrapper>
  );
}
