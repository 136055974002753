export default function BigArrow() {
  return (
    <>
      <svg
        width="37"
        height="32"
        viewBox="0 0 37 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.5498 1.54199L33.9558 15.948L19.5498 30.3539"
          stroke="white"
          stroke-width="3"
        />
        <path d="M0 15.9922L34 15.9922" stroke="white" stroke-width="3" />
      </svg>
    </>
  );
}
