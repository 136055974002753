import React, { useEffect, useState } from 'react';
import MainContainer from '../../components/MainContainer';
import { Wrapper } from './styled';
import Pagination from '../../components/Pagination';
import { useHistory } from 'react-router-dom';
import { GetGroup, GetGroupPosts } from '../../api/story';
import { useParams } from 'react-router-dom';
import { MediaQuery, mobileQuery } from '../../components/Grid';

const Group = () => {
	const history = useHistory();

	const [group, setGroup] = useState([]);
	const params = useParams();
	const isMobile = MediaQuery(mobileQuery);

	useEffect(() => {
		GetGroup({ ID: params?.ID })
			.then((res) => {
				console.log(res.data.body.result);
				setGroup(res.data.body.result);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const [items, setItems] = useState([]);
	const [total, setTotal] = useState(0);
	const [paged, setPaged] = useState(1);

	let posts_per_page = 12;

	useEffect(() => {
		GetGroupPosts({
			ID: params?.ID,
			posts_per_page: posts_per_page,
			paged: paged,
		})
			.then((res) => {
				console.log(res.data.body.result);
				setItems(res.data.body.result);
				setTotal(res.data.body.total);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [paged]);

	const onPaging = (page) => {
		console.log(page);
		setPaged(page);
		history.push(window.location.pathname + `?paged=${page}`);
		// window.scrollTo({ top: document.querySelector('#post_archive').offsetTop });
	};

	return (
		<MainContainer>
			<Wrapper count={group?.member_count}>
				<div className="inner_">
					<div className="top">
						<h2>Social Club Page</h2>
						<div className="btn" onClick={() => history.push('/feed')}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="29"
								height="28"
								viewBox="0 0 29 28"
								fill="none"
							>
								<path
									d="M7.45831 24.4196C6.82014 24.4196 6.27402 24.1926 5.81995 23.7385C5.36511 23.2837 5.1377 22.7372 5.1377 22.099V11.6562C5.1377 11.2888 5.22008 10.9407 5.38484 10.612C5.54883 10.2832 5.77586 10.0125 6.06594 9.79975L13.0278 4.57838C13.2405 4.42367 13.4629 4.30764 13.6949 4.23029C13.927 4.15293 14.1687 4.11426 14.4201 4.11426C14.6715 4.11426 14.9133 4.15293 15.1453 4.23029C15.3774 4.30764 15.5998 4.42367 15.8125 4.57838L22.7743 9.79975C23.0644 10.0125 23.2918 10.2832 23.4566 10.612C23.6206 10.9407 23.7026 11.2888 23.7026 11.6562V22.099C23.7026 22.7372 23.4755 23.2837 23.0215 23.7385C22.5666 24.1926 22.0201 24.4196 21.382 24.4196H16.7407V16.2975H12.0995V24.4196H7.45831Z"
									fill="white"
								/>
							</svg>
							{isMobile ? '다it소' : '다it소 메인으로 가기'}
						</div>
					</div>
					<div className="box">
						<div
							className="thumbnail"
							style={{ backgroundImage: `url(${group?.thumbnail})` }}
						/>
						<div className="right">
							<div className="title">{group?.title}</div>
							<p>{group?.content}</p>
							<div className="flex">
								<div className="fl">
									<div className="authors">
										<div
											className="avatar leader"
											style={{
												backgroundImage: `url(${group?.small_group_leader?.avatar})`,
											}}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="14"
												height="14"
												viewBox="0 0 14 14"
												fill="none"
											>
												<circle cx="7" cy="7" r="7" fill="#FFBC00" />
												<path
													d="M11.1993 5.125C11.1993 5.47016 10.9385 5.75 10.6169 5.75C10.6133 5.75 10.6103 5.74802 10.6067 5.74796L9.87105 10.0901C9.83062 10.3266 9.63842 10.5 9.41273 10.5H4.599C4.3739 10.5 4.18082 10.3272 4.14064 10.0895L3.40518 5.74844C3.40154 5.74844 3.39848 5.75 3.38174 5.75C3.0601 5.75 2.79932 5.47016 2.79932 5.125C2.79932 4.77984 3.0732 4.5 3.38174 4.5C3.69028 4.5 3.96416 4.77984 3.96416 5.125C3.96416 5.26559 3.9129 5.38891 3.83986 5.49328L5.14492 6.61359C5.37658 6.8125 5.72007 6.73147 5.85271 6.44672L6.6914 4.64672C6.52682 4.53656 6.41034 4.34906 6.41034 4.125C6.41034 3.77984 6.68408 3.5 7.00587 3.5C7.32766 3.5 7.57519 3.77984 7.57519 4.125C7.57519 4.34906 7.45924 4.53656 7.29417 4.64688L8.13286 6.44688C8.26552 6.73156 8.60913 6.8125 8.84065 6.61375L10.1457 5.49344C10.0854 5.38906 10.0345 5.25156 10.0345 5.125C10.0345 4.77969 10.2951 4.5 10.6169 4.5C10.9387 4.5 11.1993 4.77969 11.1993 5.125Z"
													fill="white"
												/>
											</svg>
										</div>
										{group?.small_group_members?.length
											? group?.small_group_members
													.slice(0, 5)
													.map((item, key) => (
														<div
															className={`avatar avatar-${key + 1}`}
															onClick={() => window.open(`/${item?.ID}`)}
															style={{
																backgroundImage: `url(${item?.avatar})`,
															}}
														/>
													))
											: null}
										<div className="count">
											<span>+{group?.member_count}명</span> 참여중
										</div>
									</div>
									{/* <div>+12명 참여중</div> */}
								</div>
								{isMobile ? null : (
									<a className="padlet" href={group?.padlet} target="_blank">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="32"
											height="32"
											viewBox="0 0 32 32"
											fill="none"
										>
											<path
												d="M11.249 22.2158L24.2437 7.9222"
												stroke="#333333"
												stroke-width="2.00348"
											/>
											<path
												d="M13.4474 7.92188H24.2764V19.8332"
												stroke="#333333"
												stroke-width="2.00348"
											/>
										</svg>
										패들릿으로 가기
									</a>
								)}
							</div>
						</div>
					</div>
					{isMobile ? (
						<div className="in">
							<div className="static">
								<div className="left">
									<p>참여 멤버 수</p>
									<span>{group?.member_count}명</span>
								</div>
								<div>
									<p>등록 포스트</p>
									<span>{total}개</span>
								</div>
							</div>
							<div className="static padlet">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="10"
									height="10"
									viewBox="0 0 10 10"
									fill="none"
								>
									<path
										d="M1 9L8.98005 1.00007"
										stroke="#333333"
										stroke-width="1.5"
									/>
									<path
										d="M2.34996 1H9V7.66661"
										stroke="#333333"
										stroke-width="1.5"
									/>
								</svg>
								패들릿
							</div>
						</div>
					) : (
						<div className="static">
							<div className="left">
								<p>참여 멤버 수</p>
								<span>{group?.member_count}명</span>
							</div>
							<div>
								<p>등록 포스트</p>
								<span>{total}개</span>
							</div>
						</div>
					)}

					<div className="post">
						<div className="t">포스트</div>
						<div className="list">
							{items?.length
								? items.map((item, key) => (
										<div
											className="card"
											style={{ backgroundImage: `url(${item?.thumbnail})` }}
											onClick={() => window.open(`/story/${item?.ID}`)}
										>
											<div className="relative">
												<p>{group?.title}</p>
												<div>{item?.postTitle}</div>
												{/* <span>오리엔테이션 및 연습게임 모임 후 공유</span> */}
											</div>
										</div>
								  ))
								: null}
						</div>
						<Pagination
							onPaging={onPaging}
							current={paged}
							max={total >= 12 ? Math.floor(total / 12) : 1}
						/>
					</div>
				</div>
			</Wrapper>
		</MainContainer>
	);
};

export default Group;
