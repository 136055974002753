import styled from "styled-components";

export const SearchWrapper = styled.div`
  margin-top: 200px;
  @media ${(props) => props.theme.mobile} {
    margin-top: 50px;
  }
`;

export const Path = styled.div`
  font-family: "KBFG Text";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: #ffac00;
  text-align: center;
  margin-bottom: 32px;
  span {
    color: #999999;
    :after {
      content: ">";
      margin: 0 14px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 25px;
    padding-left: 0px;
    span {
      font-size: 15px;
      line-height: 19px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 25px;
    span {
      font-size: 15px;
      line-height: 19px;
    }
  }
`;

export const Title = styled.h3`
  text-align: center;
  font-family: "KBFG Display";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 69px;
  text-align: center;
  color: #000000;
  margin-bottom: 40px;
  span {
    color: #ffbc00;
  }
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
    font-size: 40px;
    margin-bottom: 20px;
  }
`;

export const SearchCount = styled.div`
  font-family: "KBFG Display";
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 26px;
  text-align: center;
  color: #888888;
  margin-bottom: 50px;
  margin-top: 120px;
  span {
    font-family: "KBFG Display";
    color: #ffbc00;
  }
  @media ${(props) => props.theme.tablet} {
    margin-bottom: 83px;
    font-size: 18px;
    line-height: 22px;
  }
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 43px;
    margin-top: 60px;
  }
`;

export const BadgeSection = styled.div`
  /* max-height: 90px; */
  overflow: hidden;
  label {
    margin-right: 12px;
    margin-bottom: 12px;
  }
  .registration-btn {
    display: flex;
    justify-content: end;
    margin-top: 8px;
    .registr-btn {
      background-color: #000;
      color: #fff;
      border: none;
      border-radius: 48px;
      padding: 15px 25px;
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.03em;
      height: 52px;
      .icon-pen {
        margin-right: 6px;
        svg {
          width: 16px;
        }
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
    .registration-btn {
      .registr-btn {
        border-radius: 50px;
        padding: 10px 16px;
        font-size: 12px;
        line-height: 15px;
        height: 38px;
        .icon-pen {
          margin-right: 4px;
          svg {
            width: 14px;
          }
        }
      }
    }
  }
`;
