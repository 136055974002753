import styled from 'styled-components';

export const TopButtonStyle = styled.div`
  .scrollTop {
    position: fixed;
    width: 50px;
    right: 20px;
    bottom: 60px;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: 0.4s;
    opacity: 0.5;
    border: 1px solid #eee;
    background: #fff;
    background-image: url(${(props) => props.theme.image_url}/common/TopButton.png);
    background-size: 25px;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .scrollTop:hover {
    opacity: 1;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
`;
