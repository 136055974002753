import styled from 'styled-components';

export const SliderWrapper = styled.div`
	position: fixed;
	z-index: 1000000001;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	.overlay {
		width: 100%;
		height: 100%;
		background-color: #00000030;
	}
	.modal-box > div p {
		flex: 0 0 auto;
	}
	.cate {
		width: 100%;
		border-radius: 20px;
		padding: 26px 30px;
		background-color: #f7f7f7;
		color: #888;
		border: none;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		cursor: pointer;
	}
	.link {
		.title {
			font-weight: 700;
			font-size: 26px;
			line-height: 32px;
			margin-top: 40px;
			margin-bottom: 80px;
		}
		.url {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 22px;
			margin-bottom: 46px;
			border-radius: 10px;
			background-color: #e9e9e9;
			padding: 10px 20px;
			span {
				width: calc(100% - 100px);
				text-overflow: ellipsis;
				overflow: hidden;
			}
			.copy {
				display: inline-block;
				padding: 12px 14px;
				border: 1px solid #ffbc00;
				border-radius: 20px;
				font-weight: 700;
				font-size: 15px;
				line-height: 22px;
				color: #fff;
				background-color: #ffbc00;
				transition: 0.3s;
				cursor: pointer;
				:hover {
					opacity: 0.7;
				}
			}
		}
		.wr {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 0 20px;
		}
		.target {
			font-size: 15px;
			cursor: pointer;
			transition: 0.3s;
			:hover {
				text-decoration: underline;
				opacity: 0.7;
			}
		}
		.btn {
			display: inline-block;
			padding: 12px 20px;
			border: 1px solid #ffbc00;
			border-radius: 20px;
			font-weight: 700;
			font-size: 16px;
			line-height: 22px;
			color: #fff;
			background-color: #ffbc00;
			cursor: pointer;
			transition: 0.3s;
			:hover {
				opacity: 0.7;
			}
		}
		input {
			display: none;
		}
	}
	.modal-wrapper {
		width: 100%;
		height: 100vh;
		.modal-wrap {
			position: absolute;
			max-width: 700px;
			width: 100%;
			z-index: 999;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			padding: 40px 50px;
			background-color: #fff;
			border-radius: 20px;

			.icon-close {
				position: absolute;
				right: 40px;
				cursor: pointer;
				svg {
					width: 17px;
				}
			}
			.modal-title {
				margin-top: 40px;
				font-weight: 700;
				font-size: 26px;
				line-height: 32px;
			}
			.modal-box {
				margin-top: 30px;
				border-top: 1px solid #e9e9e9;
				height: 488px;
				overflow-y: auto;
				&::-webkit-scrollbar {
					display: none;
				}
				.select-wrap {
					display: flex;
					justify-content: space-between;
					align-items: baseline;
					gap: 50px;
					padding: 0 30px;
					margin-top: 30px;
					.department-title {
						font-weight: 700;
						font-size: 18px;
						line-height: 22px;
					}
					.tit-box {
						width: 420px;
						cursor: pointer;
						input {
							width: 360px;
							border-radius: 20px;
							padding: 26px 30px;
							background-color: #f7f7f7;
							color: #888;
							border: none;
							font-weight: 500;
							font-size: 16px;
							line-height: 20px;
						}
					}
					.cont-select {
						width: 420px;
						position: relative;
						.btn-select {
							width: 100%;
							padding: 26px 30px;
							border-radius: 20px;
							cursor: pointer;
							font-weight: 500;
							font-size: 16px;
							line-height: 20px;
							border: none;
							text-align: left;
							background-image: url(/icon-selectarrow.svg);
							background-repeat: no-repeat;
							background-position: 92% 50%;
							background-color: #f7f7f7;
							color: #888;
							&.active {
								color: #000;
							}
						}
						.btn-title {
							width: 100%;
							padding: 26px 30px;
							border-radius: 20px;
							cursor: pointer;
							font-weight: 500;
							font-size: 16px;
							line-height: 20px;
							border: none;
							text-align: left;
							background-color: #f7f7f7;
							color: #888;
						}
						.list-member {
							background-color: #fff;
							border: 1px solid #e0e0e0;
							border-radius: 20px;
							margin-top: 6px;
							position: absolute;
							z-index: 1;
							width: 100%;
							display: none;
							padding-bottom: 10px;
							&.active {
								display: block;
							}
							li {
								padding: 0 10px;
								button {
									width: 100%;
									padding: 16px 20px;
									border-radius: 20px;
									cursor: pointer;
									font-weight: 500;
									font-size: 16px;
									line-height: 20px;
									border: none;
									text-align: left;
									background-color: #fff;
									margin-top: 0;
									&:hover {
										background-color: #f7f7f7;
									}
								}
								&:first-child {
									padding-top: 12px;
								}
							}
						}
					}
				}
				.tag-wrap {
					display: flex;
					justify-content: space-between;
					align-items: baseline;
					gap: 50px;
					padding: 0 30px;
					margin-top: 30px;
					.tag-title {
						font-weight: 700;
						font-size: 18px;
						line-height: 22px;
					}
					.tag-box {
						width: 420px;
						cursor: pointer;
						position: relative;
						.ct {
							position: relative;
							p {
								position: absolute;
								right: 10px;
								top: 12px;
								padding: 14px 16px;
								font-weight: 700;
								font-size: 16px;
								line-height: 22px;
								color: #fff;
								background-color: #ffbc00;
								border-radius: 20px;
							}
						}
						input {
							width: 360px;
							border-radius: 20px;
							padding: 26px 30px;
							background-color: #f7f7f7;
							color: #888;
							border: none;
							font-weight: 500;
							font-size: 16px;
							line-height: 20px;
						}
						.tag-name {
							border: 1px solid #ffbc00;
							display: inline-block;
							padding: 12px 17px;
							background-color: #fff;
							border-radius: 52px;
							margin-left: 12px;
							margin-top: 10px;
							.tag-close {
								margin-left: 6px;
							}
						}

						.tag-wrapper {
							/* position: absolute;
              top: 50%;
              transform: translateY(-50%);
              margin-left: 20px; */
							.tag-name {
								border: 1px solid #ffbc00;
								display: inline-block;
								padding: 12px 17px;
								background-color: #fff;
								border-radius: 52px;
								margin-left: 12px;
							}
						}
					}
					.cont-select {
						width: 420px;
						position: relative;
						.tag-btn {
							width: 100%;
							padding: 26px 30px;
							border-radius: 20px;
							cursor: pointer;
							font-weight: 500;
							font-size: 16px;
							line-height: 20px;
							border: none;
							text-align: left;
							background-color: #f7f7f7;
							color: #888;
						}
					}
				}
				.board-wrap {
					display: flex;
					justify-content: space-between;

					margin-top: 30px;
					.board-title {
						font-weight: 700;
						font-size: 18px;
						line-height: 22px;
						padding-left: 30px;
						padding-top: 17px;
					}
					#board {
						background-color: #f7f7f7;
						border: none;
						border-radius: 20px;
						padding: 26px 30px;
						font-weight: 500;
						font-size: 16px;
						line-height: 20px;
						color: #888;
						margin-right: 30px;
						width: 360px;
						height: 182px;
						margin-bottom: 20px;
						resize: none;
					}
				}

				.name-select-wrap {
					display: flex;
					justify-content: flex-start;
					align-items: baseline;
					padding: 0 30px;
					margin-top: 30px;

					.department-title {
						font-weight: 700;
						font-size: 18px;
						line-height: 22px;
						flex: 0 1 120px;
					}
					.file-wrap {
						display: flex;
						gap: 10px;
						input::file-selector-button {
							display: none;
						}

						span {
							display: inline-block;
							padding: 26px 30px;
							background-color: #f7f7f7;
							border-radius: 20px;
							font-weight: 500;
							font-size: 16px;
							line-height: 20px;
							color: #888;
							border: none;
						}
						button {
							padding: 25px 30px;
							background-color: #f7f7f7;
							border-radius: 20px;
							font-weight: 500;
							font-size: 16px;
							line-height: 20px;
							color: #888;
							border: none;
						}
						.submit {
							width: 130px;

							background-color: #ffbc00;
							color: #fff;
							text-align: center;
						}
						#ff {
							display: none;
						}
						.label {
							cursor: pointer;
						}

						.btn-name-select {
							width: 280px;
							text-align: left;
							left: 140px;
							cursor: default;
						}
						.file-btn {
							background-color: #ffbc00;
							color: #fff;
							width: 130px;
							left: 65px;
						}
					}
					.modal-img {
						position: relative;
						width: auto;
						height: auto;
						border-radius: 20px;
						/* margin-top: 10px; */
						:hover {
							.icon-remove {
								display: block;
							}
							:after {
								position: absolute;
								display: block;
								content: '';
								z-index: 1;
								width: 100%;
								height: 100%;
								top: 0;
								left: 0;
								border-radius: 20px;
								background-color: rgba(0, 0, 0, 0.2);
							}
						}
						.preview-img {
							width: 72px;
							height: 72px;
							background-color: #e0e0e0;
							border-radius: 20px;
						}
						.icon-remove {
							display: none;
							position: absolute;
							right: -8px;
							top: -8px;
							z-index: 3;
							cursor: pointer;
							svg {
								padding: 3px;
								background-color: #fff;
								border-radius: 50%;
								transition: 0.3s;
								opacity: 1;
								:hover {
									/* opacity: 0.7; */
								}
							}
						}
					}
				}
				/* .modal-bg {
          display: none;
          margin-top: 10px;
          margin-left: 145px;
          position: relative;
          .bg-box {
            width: 280px;
            height: 280px;
            background-color: #e0e0e0;
            border-radius: 20px;
            position: relative;
            .icon-remove {
              cursor: pointer;
              svg {
                position: absolute;
                right: 20px;
                top: 20px;
              }
            }
          }
        } */
			}
			.gradient {
				width: 100%;
				height: 40px;
				background: linear-gradient(
					180deg,
					#ffffff 0%,
					rgba(255, 255, 255, 0) 100%
				);
				transform: matrix(1, 0, 0, -1, 0, 0);
				position: absolute;
				bottom: 130px;
				left: 0;
			}
			.btn-wrap {
				margin-top: 30px;
				margin-left: 97px;
				display: flex;
				gap: 10px;

				justify-content: end;
				button {
					padding: 20px 63px;
					border: 1px solid #ffbc00 !important;
					border-radius: 20px !important;
					font-weight: 700 !important;
					font-size: 18px !important;
					line-height: 22px !important;
					color: #ffbc00 !important;
					background-color: #fff;
				}
				.yellow-btn {
					background-color: #ffbc00 !important;
					color: #fff !important;
				}
			}
		}
	}
	@media ${(props) => props.theme.mobile} {
		.modal-wrapper {
			.modal-wrap {
				padding: 30px 20px;
				border-radius: 10px;
				max-width: 300px;
				width: 100%;
				.icon-close {
					display: none;
				}
				.link {
					.title {
						margin-top: 10px;
						font-size: 17px;
						margin-bottom: 40px;
					}
					.url {
						font-size: 14px;
						margin-bottom: 30px;
						.copy {
							padding: 8px 10px;
							font-size: 15px;
						}
					}
					.target {
						font-size: 14px;
					}
					.btn {
						padding: 8px 16px;
						font-size: 14px;
					}
				}
				.modal-title {
					font-size: 20px;
					line-height: 25px;
					margin-top: 0;
				}
				.modal-box {
					margin-top: 20px;
					height: 353px;
					.select-wrap {
						flex-wrap: wrap;
						gap: 14px;
						padding: 0 0px;
						margin-top: 20px;
						.department-title {
							font-size: 14px;
							line-height: 17px;
						}
						.cont-select {
							.btn-select {
								padding: 19px 20px;
								font-size: 12px;
								line-height: 15px;
								background-position: 95% 50%;
								background-size: 12px;
								margin-top: 0;
							}
							.btn-title {
								width: 100%;
								padding: 19px 20px;
								font-size: 12px;
								line-height: 15px;
								margin-top: 0;
							}
							.list-member {
								margin-top: 4px;
								&.active {
									display: block;
								}
								li {
									padding: 0 8px;
									button {
										padding: 16px 20px;
										font-size: 12px;
										line-height: 15px;
										&:hover {
											background-color: #f7f7f7;
										}
									}
									&:first-child {
										padding-top: 10px;
									}
								}
							}
						}
						.tit-box {
							width: 420px;
							cursor: pointer;
							input {
								width: 220px;
								padding: 19px 20px;
								font-size: 12px;
								line-height: 15px;
								margin-top: 0;
							}
						}
					}
					.tag-wrap {
						flex-wrap: wrap;
						gap: 14px;
						padding: 0 0px;
						margin-top: 20px;
						.tag-title {
							font-size: 14px;
							line-height: 17px;
						}
						.tag-box {
							.ct {
								p {
									right: 12px;
									top: 11px;
									padding: 6px 10px 6px 11px;
									font-weight: 600;
									font-size: 13px;
									line-height: 19px;
									border: 1px solid #ffbc00;
									border-radius: 20px;
									display: inline-table;
								}
							}
							input {
								width: 220px;
								padding: 19px 20px;
								font-size: 12px;
								line-height: 15px;
								margin-top: 0;
							}
							.tag-name {
								padding: 6px 13px;
								margin-left: 12px;
								margin-top: 10px;
								font-weight: 500;
								font-size: 12px;
								line-height: 15px;
								.tag-close {
									margin-left: 4px;
									position: relative;
									top: 1px;
									svg {
										width: 7px;
									}
								}
							}
						}
					}

					.board-wrap {
						flex-wrap: wrap;
						margin-top: 20px;
						.board-title {
							font-size: 14px;
							line-height: 17px;
							padding-left: 0px;
						}
						#board {
							padding: 19px 20px;
							font-size: 12px;
							line-height: 15px;
							margin-right: 0px;
							height: 140px;
							margin-top: 14px;
						}
					}

					.name-select-wrap {
						flex-wrap: wrap;
						padding: 0 0px;
						gap: 14px;
						margin-top: 20px;
						.department-title {
							font-size: 14px;
							line-height: 17px;
							flex: 0 1 100%;
						}
						.file-wrap {
							gap: 8px;
							.submit {
								display: block;
								width: auto;
								padding: 19px 20px;
								font-size: 12px;
								line-height: 15px;
								margin-top: 0;
							}
							button {
								margin-top: 0;
								padding: 19px 20px;
								border-radius: 20px;
								font-size: 12px;
								line-height: 15px;
							}
							.btn-name-select {
								width: 164px;
								left: 82px;
							}
							.file-btn {
								width: 88px;
								left: 44px;
							}
						}
						.modal-img {
							width: 53px;
							height: 53px;
							.preview-img {
								width: 53px;
								height: 53px;
							}
							.icon-remove {
								cursor: pointer;
								svg {
									width: 14px;
									right: 16px;
									top: 16px;
								}
							}
						}
					}
					.modal-bg {
						/* display: none; */
						margin-top: 8px;
						margin-left: 0;
						.bg-box {
							width: 164px;
							height: 164px;
							.icon-remove {
								svg {
									width: 14px;
									right: 16px;
									top: 16px;
								}
							}
						}
					}
				}
				.gradient {
					height: 20px;
					bottom: 92px;
					left: 0;
				}
				.btn-wrap {
					margin-top: 20px;
					margin-left: 0px;
					gap: 8px;
					button {
						padding: 14px 0px;
						font-size: 12px !important;
						line-height: 15px !important;
						width: 126px !important;
						left: 24% !important;
						margin-top: 0 !important;
					}
				}
			}
		}
	}
`;
