import styled from 'styled-components';

export const Wrapper = styled.div`
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;

	.overlay {
		width: 100%;
		height: 100%;
		background-color: #00000030;
	}
	.modal-wrapper {
		width: 100%;
		height: 100vh;
		.modal-wrap {
			position: absolute;
			z-index: 999;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			padding: 30px 50px;
			background-color: #fff;
			border-radius: 20px;
			width: 700px;
			height: 690px;
			.icon-close {
				position: absolute;
				right: 40px;
				cursor: pointer;
				svg {
					width: 17px;
				}
			}
			.modal-title {
				margin-top: 40px;
				font-weight: 700;
				font-size: 26px;
				line-height: 32px;
			}
			.modal-box {
				position: relative;

				input[type='date'] {
					position: relative;
					max-width: 420px;
					width: 100%;
					height: 72px;
					background-color: #f7f7f7;
					border-radius: 20px;
					padding-left: 30px;
					box-sizing: border-box;
					border: none;
					background-image: url(../icon-calendar.svg);
					background-repeat: no-repeat;
					background-position: top 50% right 30px;
					cursor: pointer;
					font-size: 16px;
					font-weight: 500;
					line-height: normal;
					&::placeholder {
						color: #888;
					}
				}

				input[type='date']::-webkit-clear-button,
				input[type='date']::-webkit-inner-spin-button {
					display: none;
				}
				input[type='date']::-webkit-calendar-picker-indicator {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: transparent;
					color: transparent;
					cursor: pointer;
				}
				input[type='date']::before {
					content: attr(data-placeholder);
					width: 100%;
				}
				input[type='date']:valid::before {
					display: none;
				}
				input[data-placeholder]::before {
					color: red;
					font-size: 14px;
				}

				.modal-list-box {
					display: flex;
					border-top: 1px solid #e9e9e9;
					font-weight: 700;
					font-size: 18px;
					line-height: 22px;
					justify-content: space-between;
					align-items: center;
					margin-top: 30px;
					padding-top: 30px;
					.moadl-text {
						padding-left: 30px;
					}
					.date-box {
						display: flex;
						align-items: center;
						justify-content: space-between;
						background-color: #f7f7f7;
						border-radius: 20px;
						width: 420px;
						padding: 25px 30px;
						.date-text {
							font-weight: 500;
							font-size: 16px;
							line-height: 20px;
							color: #888;
						}
					}
				}
				.participation-mentee {
					display: flex;
					justify-content: space-between;
					/* align-items: baseline; */
					margin-top: 30px;

					.mentee-text-box {
						.moadl-text {
							font-weight: 700;
							font-size: 18px;
							line-height: 22px;
							padding-left: 30px;
						}
					}
					.mentee-box {
						height: 276px;
						overflow-y: auto;
						&::-webkit-scrollbar {
							display: none;
						}
						.modal-list {
							cursor: pointer;
							display: flex;
							justify-content: space-between;
							padding: 26px 40px;
							border-radius: 20px;
							background-color: #f7f7f7;
							font-weight: 500;
							font-size: 16px;
							line-height: 20px;
							margin-bottom: 12px;
							width: 420px;
							.name-box {
								display: flex;
								gap: 14px;
							}
							.icon-modal-plus {
								cursor: pointer;
							}
							.icon-checked {
								cursor: pointer;
							}
							.name {
								text-align: center;
								display: flex;
								justify-content: center;
								width: 100%;
							}
						}
					}
				}
				.none-box {
					text-align: center;
					padding: 120px 0 129px 0;
					.none-text {
						font-weight: 300;
						font-size: 18px;
						line-height: 22px;
						color: #888888;
						margin-top: 20px;
					}
				}
				.modal-bg {
					width: 600px;
					height: 40px;
					background: linear-gradient(
						180deg,
						#ffffff 0%,
						rgba(255, 255, 255, 0) 100%
					);
					transform: matrix(1, 0, 0, -1, 0, 0);
					position: absolute;
					bottom: 0;
				}
			}
		}
	}
	.btn-wrap {
		display: flex;
		gap: 10px;
		justify-content: end;

		button {
			border: 1px solid #ffbc00;
			border-radius: 20px;
			background-color: #fff;
			padding: 20px 0px;
			font-weight: 700;
			font-size: 18px;
			line-height: 22px;
			color: #ffbc00;
			width: 160px;
			margin-top: 10px;
			cursor: pointer;
			transition: all 0.3s;
			&:hover {
				opacity: 0.8;
			}
		}
		.yellow-btn {
			background-color: #ffbc00;
			color: #fff;
		}
	}
	@media ${(props) => props.theme.mobile} {
		.modal-wrapper {
			width: 100%;
			height: 100vh;
			.modal-wrap {
				border-radius: 10px;
				width: 300px;
				height: 520px;
				padding: 30px 20px;
				.icon-close {
					display: none;
				}
				.modal-title {
					font-size: 20px;
					line-height: 25px;
					margin-top: 0;
				}
				.modal-box {
					input[type='date'] {
						width: 100%;
						height: 52px;
						padding-left: 20px;
						font-size: 12px;
						font-weight: 500;
						line-height: normal;
						margin-top: 14px;
					}
					.modal-list-box {
						margin-top: 10px;
						padding-top: 14px;
						font-size: 14px;
						line-height: 17px;
						flex-wrap: wrap;
						.moadl-text {
							padding-left: 0px;
						}
						.date-box {
							padding: 19px 20px;
							margin-top: 14px;
							.date-text {
								font-size: 12px;
								line-height: 15px;
							}
							.date-icon {
								svg {
									width: 18px;
								}
							}
						}
					}
					.participation-mentee {
						flex-wrap: wrap;
						margin-top: 20px;
						.mentee-text-box {
							.moadl-text {
								font-size: 14px;
								line-height: 17px;
								padding-left: 0px;
								margin-bottom: 14px;
							}
						}
						.mentee-box {
							height: 219px;
							.modal-list {
								padding: 19px 20px !important;
								align-items: center;
								font-size: 12px;
								line-height: 15px;
								margin-bottom: 8px;
								width: 260px;
								.name-box {
									gap: 10px;
									align-items: center;
									svg {
										width: 14px;
										position: relative;
										top: 2px;
									}
								}
							}
						}
					}
					.none-box {
						text-align: center;
						padding: 120px 0 129px 0;
						.none-text {
							font-weight: 300;
							font-size: 18px;
							line-height: 22px;
							color: #888888;
							margin-top: 20px;
						}
					}
					.modal-bg {
						width: 260px;
						height: 20px;
						bottom: 20px;
					}
				}
				.btn-wrap {
					gap: 8px;
					button {
						padding: 14px 0px;
						font-size: 12px;
						line-height: 15px;
						width: 126px;
						margin-top: 0px;
						border-radius: 14px;
					}
				}
			}
		}
	}
`;
