import { useState } from 'react';
import ModalClose from '../../../../../vector/ModalClose';
import ModalPrev from '../../../../../vector/ModalPrev';
import { Wrapper } from './styled';
import NoneIcon from '../../../../../vector/NoonIcon';

export default function Modal1({ modalToggle, activity, name }) {
	const [tempData, setTempData] = useState(true);

	console.log(88, activity);

	return (
		<Wrapper>
			<section className="modal-wrapper">
				<div className="overlay" onClick={modalToggle}></div>
				<div className="modal-wrap">
					<i className="icon-close" onClick={modalToggle}>
						<ModalClose />
					</i>
					<h2 className="modal-title">[{name} 멘토] 활동 내역</h2>
					{activity?.length ? (
						<div className="modal-box">
							<div className="modal-list-box">
								<p className="moadl-text">활동일</p>
								<p className="moadl-text">참여 멘티</p>
							</div>
							<div className="modal-list-wrap modal-list-wrap2">
								{activity?.length ? (
									activity.map((item, key) => (
										<>
											<div className="modal-list">
												<p className="date">{item?.date}</p>
												<p className="name">
													{item?.name?.map((i, k) => {
														if (k + 1 == item?.name?.length) {
															return i;
														} else {
															return i + ', ';
														}
													})}
												</p>
											</div>
										</>
									))
								) : (
									<div className="none-box">
										<i className="icon-none">
											<NoneIcon />
										</i>
										<p className="none-text">멘토링 활동 내역이 없습니다.</p>
									</div>
								)}

								<div className="modal-bg"></div>
							</div>
						</div>
					) : (
						<div className="modal-box">
							<div className="modal-list-box">
								<p className="moadl-text">활동일</p>
								<p className="moadl-text">참여 멘티</p>
							</div>
							<div className="none-box">
								<i className="icon-none">
									<NoneIcon />
								</i>
								<p className="none-text">멘토링 활동 내역이 없습니다.</p>
							</div>
						</div>
					)}
					<div className="btn-wrap mo-btn-wrap">
						{/* <div className="arrow-wrap">
              <i className="icon-prev">
                <ModalPrev />
              </i>
              <i className="icon-next">
                <ModalPrev />
              </i>
            </div> */}
						<button onClick={modalToggle} className="close-btn">
							닫기
						</button>
					</div>
				</div>
			</section>
		</Wrapper>
	);
}
