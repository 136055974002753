import styled from 'styled-components';

export const CardWrapper = styled.div`
	width: 100%;
	vertical-align: top;
	height: 100%;
	${(props) => `width:` + props.width + `;`}
	display: inline-block;
	padding: 0 20px;
	cursor: pointer;
	box-sizing: border-box;
	@media ${(props) => props.theme.mobile} {
		width: 100%;
		height: auto;
	}
`;
export const ProfileCardWrapper = styled.div`
	padding: 0 300px;
	@media ${(props) => props.theme.mobile} {
		width: 100%;
		padding: 0 16px;
	}
`;

export const CardBadge = styled.div``;

export const Title = styled.div``;

export const Date = styled.div``;

export const Tag = styled.div`
	${(props) => (props.is_event ? 'color: yellow !important;' : '')}
`;
export const Tags = styled.div``;
export const Avatar = styled.div`
	background-image: url(${(props) => props.image});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: inline-block;
	vertical-align: middle;
	transition: 0.5s;
`;

export const Author = styled.div`
	display: inline-block;
	vertical-align: middle;
	position: absolute;
	right: 35px;
	bottom: 40px;
	z-index: 9;
	border-radius: 50px;
	transition: 0.5s;
	span {
		opacity: 0;
		margin-right: 10px;
		color: #fff;
		line-height: 34px;
		height: 30px;
		display: inline-block;
		vertical-align: middle;
		transition: 0.5s;
		font-size: 14px;
	}
	div:hover > & {
		${Avatar} {
		}
		span {
			opacity: 1;
		}
	}
	@media ${(props) => props.theme.tablet} {
		right: 25px;
		bottom: 25px;
	}
`;
export const CardStyle = styled.div`
	box-sizing: border-box;
	position: relative;
	height: 388px;
	margin: 20px 0px 40px 0;
	padding: 48px 41px 33px 41px;
	text-align: left !important;
	${(props) =>
		!props.postImage ? `border: 1px solid #eee; background:#fff;` : ''}
	overflow:hidden;
	transition: 0.3s;
	img {
		position: absolute;
		right: 35px;
		bottom: 50px;
	}
	:before {
		display: block;
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: 0.6s;
		background-image: url(${(props) => props.postImage});
		background-repeat: no-repeat;
		background-size: cover;
		transform: scale(1.15);
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0)
			${(props) => (!props.postImage ? `display:none;` : '')};
	}
	:after {
		display: block;
		position: absolute;
		content: '';
		background: rgba(0, 0, 0, 0.3);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: 0.4s;
		${(props) => (!props.postImage ? `display:none;` : '')}
	}
	:hover {
		transform: translateY(-10px);
		:after {
			/* background: rgba(255, 255, 255, 0); */
		}
		:before {
			transform: scale(1.3);
			z-index: -1;
		}
	}
	${CardBadge} {
		position: relative;
		z-index: 1;
		display: inline-block;
		padding: 2px 20px;
		font-family: 'KBFG Display';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 34px;
		text-align: center;
		color: #ffffff;
		background: rgba(255, 255, 255, 0.4);
		${(props) => (!props.postImage ? `color:#000;background:#f7f7f7;` : '')}
	}
	${Title} {
		position: relative;
		z-index: 1;
		margin-top: 18px;
		font-family: 'KBFG Display';
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 38px;
		letter-spacing: 0.02em;
		color: #ffffff;
		word-break: break-all;
		text-align: left !important;
		${(props) => (!props.postImage ? `color: #000` : '')}
	}
	${Date} {
		position: relative;
		z-index: 1;
		font-family: 'KBFG Display';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 20px;
		letter-spacing: 0.02em;
		color: rgba(255, 255, 255, 0.7);
		text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
		${(props) => (!props.postImage ? `color: #000; text-shadow:none;` : '')}
	}
	${Tags} {
		position: absolute;
		z-index: 1;
		bottom: 40px;
	}
	${Tag} {
		font-family: 'KBFG Display';
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 24px;
		display: flex;
		align-items: center;
		color: #ffffff;
		${(props) => (!props.postImage ? `color: #000; text-shadow:none;` : '')}
	}
	@media ${(props) => props.theme.tablet} {
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		height: 0;
		margin-bottom: 10px;
		padding-bottom: 100%;
		padding-top: 25px;
		padding-left: 25px;
		padding-right: 25px;
		display: block;
		position: relative;
		img {
			position: absolute;
			width: 100px;
			height: 100px;
			top: 50%;
			left: 50%;
			margin-top: -50px;
			margin-left: -50px;
		}
		${CardBadge} {
			padding: 0px 10px;
			font-size: 12px;
			line-height: 30px;
		}
		${Title} {
			margin-top: 14px;
			font-size: 20px;
			line-height: 30px;
		}
		${Tags} {
			bottom: 20px;
		}
		${Tag} {
			font-size: 13px;
		}
	}
	@media ${(props) => props.theme.mobile} {
	}
`;

export const CardSection = styled.div`
	margin: 30px -20px 70px -20px;
`;
