import styled from 'styled-components';

export const Wrapper = styled.div`
	background-color: #f7f7f7;
	padding-bottom: 80px;
	.mo-info,
	.mo-hide {
		display: none;
	}
	.user-img {
		width: 68px;
		height: 68px;
		border-radius: 50%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	.empty {
		padding: 20px;
		color: dimgray;
	}
	.mentor-empty {
		margin-top: 30px;
		background-color: #fff;
		border-radius: 27px;
		height: 300px;
		padding: 32px 30px;
		color: dimgray;
		width: 100%;
	}
	.add-btn {
		padding: 19px 38px;
		color: #fff;
		border: none;
		background-color: #000;
		border-radius: 20px;
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		cursor: pointer;
		transition: all 0.3s;
		&:hover {
			opacity: 0.8;
		}
		.icon-plus {
			margin-right: 8px;
		}
	}
	.wrapper {
		max-width: 1600px;
		width: 100%;
		margin: 0 auto;
		padding: 0 30px;
		box-sizing: border-box;
	}
	.top-menu-wrap {
		padding-top: 180px;
		.top-menu-box {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.top-title {
				font-weight: 700;
				font-size: 47px;
				line-height: 59px;
			}
			.select-box {
				display: flex;
				justify-content: end;
				gap: 20px;
				width: 60%;
				.cont-select {
					max-width: 158px;
					width: 100%;
					position: relative;
					.btn-select {
						width: 100%;
						padding: 26px 30px;
						border-radius: 20px;
						cursor: pointer;
						font-weight: 500;
						font-size: 16px;
						line-height: 20px;
						border: none;
						text-align: left;
						background-image: url(../icon-selectarrow.svg);
						background-repeat: no-repeat;
						background-position: 80% 50%;
						background-color: #fff;
						color: #888;
					}
					.list-member {
						background-color: #fff;
						border: 1px solid #e0e0e0;
						border-radius: 20px;
						margin-top: 6px;
						position: absolute;
						z-index: 1;
						width: 100%;
						display: none;
						padding-bottom: 10px;
						&.active {
							display: block;
						}
						li {
							padding: 0 10px;
							button {
								width: 100%;
								padding: 16px 20px;
								border-radius: 20px;
								cursor: pointer;
								font-weight: 500;
								font-size: 16px;
								line-height: 20px;
								border: none;
								text-align: left;
								background-color: #fff;
								display: flex;
								justify-content: space-between;
								&:hover {
									background-color: #f7f7f7;
								}
							}
							&:first-child {
								padding-top: 12px;
							}
						}
						/* .bg {
              width: 100%;
              height: 40px;
              border-radius: 20px;
              position: absolute;
              z-index: 0;
              top: 20px;
              background: linear-gradient(
                180deg,
                #ffffff 0%,
                rgba(255, 255, 255, 0) 100%
              );
            } */
					}
				}
				.department-select {
					max-width: 260px;
					width: 100%;
					position: relative;
					.btn-select {
						width: 100%;
						padding: 26px 30px;
						border-radius: 20px;
						cursor: pointer;
						font-weight: 500;
						font-size: 16px;
						line-height: 20px;
						border: none;
						text-align: left;
						background-image: url(icon-selectarrow.svg);
						background-repeat: no-repeat;
						background-position: 88% 50%;
						background-color: #fff;
						color: #888;
					}
					.list-member {
						background-color: #fff;
						border: 1px solid #e0e0e0;
						border-radius: 20px;
						margin-top: 6px;
						position: absolute;
						z-index: 1;
						width: 100%;
						display: none;
						padding-bottom: 10px;
						&.active {
							display: block;
						}
						li {
							padding: 0 10px;
							button {
								width: 100%;
								padding: 16px 20px;
								border-radius: 20px;
								cursor: pointer;
								font-weight: 500;
								font-size: 16px;
								line-height: 20px;
								border: none;
								text-align: left;
								background-color: #fff;
								display: flex;
								justify-content: space-between;
								&:hover {
									background-color: #f7f7f7;
								}
							}
							&:first-child {
								padding-top: 12px;
							}
						}
					}
				}
			}
		}
	}
	.saas-wrap {
		padding-top: 130px;
	}
	.saas-top-menu {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.saas-title {
			font-weight: 700;
			font-size: 32px;
			line-height: 40px;
		}
		.btn-box {
			font-weight: 500;
			font-size: 18px;
			line-height: 22px;
			display: flex;
			gap: 20px;
			.cont-select {
				width: 200px;
				position: relative;
				.btn-select {
					width: 100%;
					padding: 20px 30px;
					border-radius: 20px;
					cursor: pointer;
					border: none;
					text-align: left;
					background-image: url(icon-selectarrow.svg);
					background-repeat: no-repeat;
					background-position: 80% 50%;
					background-color: #fff;
					font-weight: 500;
					font-size: 18px;
					line-height: 22px;
				}
				.list-member {
					background-color: #fff;
					border: 1px solid #e0e0e0;
					border-radius: 20px;
					margin-top: 6px;
					position: absolute;
					z-index: 1;
					width: 100%;
					display: none;
					padding-bottom: 10px;
					&.active {
						display: block;
					}
					li {
						padding: 0px 10px;
						button {
							width: 100%;
							padding: 16px 20px;
							border-radius: 20px;
							cursor: pointer;
							font-weight: 500;
							font-size: 16px;
							line-height: 20px;
							border: none;
							text-align: left;
							background-color: #fff;
							display: flex;
							justify-content: space-between;
							&:hover {
								background-color: #f7f7f7;
							}
						}
						&:first-child {
							padding-top: 12px;
						}
					}
				}
			}
		}
	}
	.saas-list-wrap {
		margin-top: 30px;
		background-color: #fff;
		border-radius: 27px;
		display: flex;
		gap: 40px;
		.mentor-info {
			padding: 50px 0 50px 40px;
			max-width: 302px;
			width: 100%;

			img {
				width: 68px;
			}
			.name {
				margin-top: 30px;
				font-weight: 700;
				font-size: 26px;
				line-height: 32px;
			}
			.department {
				font-weight: 300;
				font-size: 16px;
				line-height: 20px;
				margin-top: 12px;
				color: #686868;
			}
			.activit {
				margin-top: 120px;
				.activit-box {
					background: rgba(249, 221, 74, 0.12);
					border-radius: 20px;
					display: inline-flex;
					align-items: center;
					justify-content: space-between;
					padding: 20px 26px;
					max-width: 302px;
					width: 100%;
					.activit-title {
						font-weight: 500;
						font-size: 16px;
						line-height: 20px;
					}
					.number {
						font-weight: 300;
						font-size: 16px;
						line-height: 20px;
						span {
							color: #ffbc00;
							font-weight: 700;
							font-size: 22px;
							line-height: 27px;
							margin-right: 6px;
						}
					}
				}
				.breakdown {
					margin-top: 10px;
					display: flex;
					gap: 12px;
					button {
						border: none;
						border-radius: 20px;
						font-weight: 500;
						font-size: 16px;
						line-height: 20px;
						width: 50%;
						height: 60px;
						.icon-arrow-top {
							margin-right: 8px;
						}
					}
					.yellow-btn {
						background-color: #f9dd4a;
					}
					.white-btn {
						background: #ffffff;
						border: 1px solid #e9e9e9;
					}
				}
			}
		}
		.saas-list {
			width: 100%;
			.info-list {
				display: flex;
				gap: 20px;
				flex-wrap: wrap;
				padding: 30px 30px 30px 0;
				a {
					display: block;
					text-decoration: none;
					color: #000;
					width: calc((100% - 40px) / 3);
					transition: all 0.3s;
					&:hover {
						opacity: 0.8;
					}
				}
				li {
					/* width: calc((100% - 40px) / 3); */
					padding: 33px 30px;
					background: #ffffff;
					border: 1px solid #e9e9e9;
					border-radius: 20px;
					min-height: 211px;
					.profile-box {
						display: flex;
						gap: 15px;
						align-items: center;

						.user-img {
							width: 52px;
							height: 52px;
							background-size: cover;
							border-radius: 50%;
							background-repeat: no-repeat;
							background-position: center;
						}
						.profile-info-box {
							/* margin-left: -50px; */
						}
						.mentor-info-box {
							display: flex;
							gap: 8px;
							align-items: center;
						}
						img {
							width: 52px;
						}
						.mentor-name {
							font-weight: 700;
							font-size: 22.5258px;
							line-height: 28px;
						}
						button {
							border: 1px solid #ffbc00;
							background-color: #fff;
							font-weight: 300;
							font-size: 11px;
							line-height: 14px;
							color: #ffbc00;
							padding: 2px 9px;
							height: 18px;
							border-radius: 100px;
						}
						.department-text {
							font-weight: 300;
							font-size: 14.53px;
							line-height: 18px;
							color: #686868;
							margin-top: 9px;
						}
					}
					.period-wrap {
						font-weight: 300;
						font-size: 15px;
						line-height: 19px;
						display: flex;
						justify-content: space-between;
						align-items: end;
						margin-top: 14px;
						.period-text {
							color: #b4b4b4;
						}
						.day {
							color: #000;
							margin-top: 8px;
						}
						.circular-progress-bar {
							width: 74px;
							height: 77px;
							border-radius: 50%;
							border: 2px solid #e9e9e9;
							position: relative;
							span {
								text-align: center;
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								font-weight: 500;
								font-size: 14px;
								line-height: 17px;
								color: #ffbc00;
							}
							::after {
								position: absolute;
								content: '';
								top: -3px;
								left: 50%;
								transform: translateX(-50%);
								width: 4px;
								height: 4px;
								border-radius: 50%;
								background-color: #ffbc00;
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1200px) {
		.saas-list-wrap {
			gap: 20px;
			.mentor-info {
				padding: 50px 0 50px 20px;
			}
			.saas-list {
				width: 100%;
				.info-list {
					gap: 20px;
					padding: 30px 30px 30px 0;
					a {
						width: calc((100% - 40px) / 2);
					}
					li {
						padding: 33px 30px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 900px) {
		.top-menu-wrap {
			.top-menu-box {
				.top-title {
					font-size: 38px;
				}
			}
		}
		.saas-list-wrap {
			gap: 20px;
			.saas-list {
				width: 100%;
				.info-list {
					gap: 20px;
					padding: 30px 30px 30px 0;
					a {
						width: 100%;
					}
				}
			}
		}
	}

	@media ${(props) => props.theme.mobile} {
		.mentor-empty {
			margin-top: 30px;
			background-color: #fff;
			border-radius: 27px;
			height: 120px;
			padding: 5px 30px;
			color: dimgray;
		}

		padding-bottom: 80px;
		.mo-none {
			display: none;
		}
		.mo-hidden {
			display: none;
		}
		.add-btn {
			padding: 14px 20px;
			border-radius: 14px;
			font-size: 12px;
			line-height: 15px;
			/* width: 32%; */
			.icon-plus {
				margin-right: 6px;
				svg {
					width: 10px;
					height: 10px;
				}
			}
		}
		.top-menu-wrap {
			padding-top: 53px;
			.top-menu-box {
				flex-direction: column;
				align-items: flex-start;
				.top-title {
					font-size: 22px;
					line-height: 160%;
				}
				.select-box {
					gap: 10px;
					margin-top: 10px;
					justify-content: space-between;
					width: 100%;

					.cont-select {
						max-width: 100%;
						width: 44%;

						.btn-select {
							padding: 17px 20px;
							border-radius: 16px;
							font-weight: 500;
							font-size: 12px;
							line-height: 15px;
							background-size: 12px;
							background-position: 85% 50%;
						}
						.list-member {
							background-color: #fff;
							border: 1px solid #e0e0e0;
							border-radius: 20px;
							margin-top: 6px;
							position: absolute;
							z-index: 1;
							width: 100%;
							display: none;
							&.active {
								display: block;
							}
							li {
								padding: 0 10px;
								button {
									font-size: 12px;
									line-height: 15px;
								}
							}
						}
					}
					.department-select {
						max-width: 100%;
						width: 56%;

						.btn-select {
							padding: 17px 20px;
							border-radius: 16px;
							font-weight: 500;
							font-size: 12px;
							line-height: 15px;
							background-size: 12px;
							background-position: 85% 50%;
						}
						.list-member {
							background-color: #fff;
							border: 1px solid #e0e0e0;
							border-radius: 20px;
							margin-top: 6px;
							position: absolute;
							z-index: 1;
							width: 100%;
							display: none;
							&.active {
								display: block;
							}
							li {
								padding: 0 10px;
								button {
									font-size: 12px;
									line-height: 15px;
								}
							}
						}
					}
				}
			}
		}
		.saas-wrap {
			padding-top: 40px;
		}
		.saas-top-menu {
			/* flex-direction: column; */
			.saas-title {
				font-size: 20px;
				line-height: 160%;
			}
			.btn-box {
				font-size: 12px;
				line-height: 15px;
				gap: 10px;
				margin-top: 10px;
				height: 42px;
				justify-content: space-between;
				.cont-select {
					.btn-select {
						padding: 14px 20px;
						border-radius: 16px;
						font-weight: 500;
						font-size: 12px;
						line-height: 15px;
						background-size: 12px;
						background-position: 85% 50%;
					}
					.list-member {
						background-color: #fff;
						border: 1px solid #e0e0e0;
						border-radius: 20px;
						margin-top: 6px;
						position: absolute;
						z-index: 1;
						width: 100%;
						display: none;
						&.active {
							display: block;
						}
						li {
							padding: 0 10px;
							button {
								font-size: 12px;
								line-height: 15px;
							}
						}
					}
				}
			}
		}
		.mo-info {
			display: block;
			border-radius: 10px;
			margin-top: 30px;
			background-color: #fff;
			.mentor-info-wrap {
				display: flex;
				gap: 14px;
			}
			.mentor-info {
				padding: 30px 20px;

				img {
					width: 48px;
				}
				.name {
					margin-top: 0px;
					font-size: 20px;
					line-height: 25px;
				}
				.department {
					font-size: 12px;
					line-height: 15px;
					margin-top: 6px;
				}
				.activit {
					margin-top: 35px;
					.activit-box {
						display: flex;
						justify-content: space-between;
						align-items: center;
						background: rgba(249, 221, 74, 0.12);
						border-radius: 14px;
						padding: 15px 20px;
						.activit-title {
							font-weight: 500;
							font-size: 14px;
							line-height: 17px;
						}
						.number {
							font-size: 14px;
							line-height: 17px;
							span {
								color: #ffbc00;
								font-weight: 700;
								font-size: 20px;
								line-height: 25px;
								margin-right: 4px;
							}
						}
					}
					.breakdown {
						margin-top: 10px;
						display: flex;
						justify-content: space-between;
						gap: 10px;
						button {
							border: none;
							border-radius: 14px;
							font-size: 14px;
							line-height: 17px;
							width: 50%;
							height: 46px;
							.icon-arrow-top {
								margin-right: 6px;
								svg {
									width: 8px;
								}
							}
						}
						.yellow-btn {
							background-color: #f9dd4a;
						}
						.white-btn {
							background: #ffffff;
							border: 1px solid #e9e9e9;
						}
					}
					.mo-mentee {
						display: block;
						margin-top: 16px;
						border-top: 1px solid #eeeeee;
						position: relative;
						padding-bottom: 25px;
						button {
							margin-top: 13px;
							border: none;
							background-color: #fff;
							font-size: 14px;
							line-height: 17px;
							position: absolute;
							left: 50%;
							transform: translateX(-50%);
							.icon-mentee {
								margin-left: 6px;
							}
						}
					}
				}
			}
		}

		.saas-list-wrap {
			margin-top: 20px;
			border-radius: 10px;
			display: flex;
			flex-direction: column;
			gap: 0;
			.mentor-info-wrap {
				display: flex;
				gap: 10px;
				align-items: center;
			}
			.mentor-info {
				padding: 30px 20px 0px 20px;
				max-width: 100%;
				img {
					width: 48px;
				}
				.name {
					margin-top: 0px;
					font-size: 20px;
					line-height: 25px;
				}
				.department {
					font-size: 12px;
					line-height: 15px;
					margin-top: 6px;
				}
				.activit {
					margin-top: 35px;
					.activit-box {
						border-radius: 14px;
						padding: 15px 20px;
						max-width: 100%;
						.activit-title {
							font-size: 14px;
							line-height: 17px;
						}
						.number {
							font-size: 14px;
							line-height: 17px;
							span {
								font-size: 20px;
								line-height: 25px;
								margin-right: 4px;
							}
						}
					}
					.breakdown {
						gap: 10px;
						justify-content: space-between;
						button {
							border-radius: 14px;
							font-size: 14px;
							line-height: 17px;
							height: 46px;
							.icon-arrow-top {
								margin-right: 6px;
								svg {
									width: 8px;
								}
							}
						}
					}
				}
			}
			.saas-list {
				.info-list {
					gap: 10px;
					flex-wrap: wrap;
					padding: 0 20px;
					a {
						width: 100%;
					}
					li {
						padding: 20px 20px;
						border-radius: 12px;
						min-height: 138px;
						.profile-box {
							justify-content: flex-start;

							.profile-info-box {
								margin-left: 10px;
							}
							.mentor-info-box {
								display: flex;
								gap: 8px;
								align-items: center;
							}
							img {
								width: 52px;
							}
							.mentor-name {
								font-size: 16px;
								line-height: 20px;
							}
							button {
								display: none;
							}
							.department-text {
								font-size: 12px;
								line-height: 15px;
								color: #aaa;
								margin-top: 4px;
							}
							.icon-close {
								display: none;
							}
						}
						.period-wrap {
							font-size: 12px;
							line-height: 15px;
							margin-top: 6px;
							.day {
								margin-top: 5px;
							}
							.circular-progress-bar {
								width: 50px;
								height: 52px;
								border: 1px solid #e9e9e9;
								span {
									font-size: 12px;
									line-height: 15px;
								}
								::after {
									top: -2px;
								}
							}
						}
					}
				}
				.mo-hide {
					display: block;
					margin-top: 16px;
					width: 100%;
					.btn-box {
						border-top: 1px solid #eeeeee;
						margin: 0 30px;
					}
					button {
						border: none;
						position: relative;
						left: 50%;
						transform: translate(-50%);
						background-color: #fff;
						padding-bottom: 14px;
						font-weight: 300;
						font-size: 14px;
						margin-top: 13px;
						.icon-mentee {
							margin-left: 6px;
							svg {
								transform: rotate(180deg);
							}
						}
					}
				}
			}
		}
	}
`;
