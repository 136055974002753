export default function CrystalIcon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2882 1.89355C13.871 1.89355 14.4455 1.93638 15.005 2.01874L15.8485 4.55366C16.5874 4.76285 17.2897 5.06098 17.9406 5.43652L20.3648 4.27694C21.2232 4.93085 21.987 5.70171 22.6313 6.5648L21.4192 8.94819C21.7861 9.60045 22.075 10.3021 22.2726 11.04L24.813 11.918C24.953 12.9837 24.9435 14.0634 24.7848 15.1266L22.2294 15.9633C22.02 16.6888 21.7211 17.3858 21.3395 18.0387L22.5084 20.4435C21.8483 21.2953 21.0721 22.052 20.2021 22.6918L17.7995 21.4894C17.142 21.8534 16.4346 22.14 15.6908 22.336L14.8058 24.8561C13.7315 24.995 12.643 24.9856 11.5713 24.8281L10.7278 22.2932C9.99647 22.0854 9.29378 21.7889 8.63569 21.4103L6.21149 22.5699C5.35282 21.9151 4.59004 21.1451 3.94503 20.2821L5.15713 17.8987C4.78688 17.2389 4.50022 16.5363 4.30368 15.8068L1.76326 14.9273C1.62344 13.8621 1.63294 12.7829 1.79149 11.7203L4.34685 10.8836C4.55773 10.1506 4.85826 9.45386 5.23683 8.80818L4.06791 6.40338C4.72798 5.55158 5.50417 4.79491 6.37421 4.15506L8.77682 5.35746C9.43434 4.99344 10.1417 4.70684 10.8855 4.51084L11.7722 1.99074C12.2687 1.9265 12.7751 1.89355 13.2882 1.89355Z"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.2879 18.3642C16.0389 18.3642 18.2691 16.1519 18.2691 13.4228C18.2691 10.6938 16.0389 8.48145 13.2879 8.48145C10.5368 8.48145 8.30664 10.6938 8.30664 13.4228C8.30664 16.1519 10.5368 18.3642 13.2879 18.3642Z"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
