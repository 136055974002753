export default function CheckedIcon() {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="9" stroke="#45C231" stroke-width="2" />
        <path
          d="M6 10L8.82843 12.8284L14.4853 7.17157"
          stroke="#45C231"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </>
  );
}
