import styled from 'styled-components';

export const Wrapper = styled.div`
	display: none;
	@media screen and (max-width: 768px) {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0;
		visibility: hidden;
		z-index: 100111;
		transition: 0.2s;
		display: block;
		.overlay {
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.4);
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			visibility: hidden;
			z-index: -1;
			transition: 0.2s;
		}
		.container {
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translate(-50%, 100%);
			width: 100%;
			z-index: 100;
			background-color: #fff;
			transition: 0.2s;
			/* overflow: ${(props) => (props.auto ? props.auto : `auto`)}; */
		}
		.inner {
			padding: 0 22px;
		}
		.inner2 {
			padding: 0 10px;
			background-color: #fff;
			height: 70px;
		}

		.modal-wrap {
			width: 100%;
			height: 100vh;
			transition: 0.3s ease-in-out;
			padding: 60px 0 0 0;
			.top {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 17px 30px 17px 22px;
				margin-bottom: 5px;
				.tit-box {
					display: flex;
					gap: 8px;
					h2 {
						font-family: KBFG Text;
						font-size: 16px;
						font-weight: 700;
						line-height: 150%;
					}
				}
			}
			.name-card-wrapper {
				padding: 0px 22px;
				.slick-list {
					overflow: visible;
				}
			}
			.slick-slider {
				min-height: 426px;
				/* height: calc(100vh - 200px); */
				position: relative;
				margin-left: -5px;
				margin-right: -5px;
			}
			.slick-track {
			}
			.slick-slide {
				padding: 0px 5px;
				/* padding-right: 5px; */
				/* padding-left: 22px; */
				&:last-child {
					/* padding-right: 22px; */
					/* padding-left: 5px; */
				}
			}
			.slick-list {
				height: 100%;
			}

			.slick-dots li {
				width: unset;
				height: unset;
			}
			.slick-dots li.slick-active button {
				opacity: 1;
			}
			.slick-dots {
				position: unset;
				margin-top: 6px;
				button {
					width: 30px;
					height: 4px;
					background-color: #776c61;
					padding: 0;
					border-radius: 30px;
					opacity: 0.2;
					&::before {
						display: none;
					}
				}
			}

			.user-box {
				padding: 26px 30px 38px 30px;
				border: 1px solid rgba(0, 0, 0, 0.04);
				border-radius: 20px;
				/* min-height: 426px; */
				height: 0;
				padding-bottom: 127.18%;
				.logo {
					width: 135px;
					img {
						width: 100%;
					}
				}
				.name-box {
					margin-top: 68px;
					h1 {
						font-family: KBFG Text;
						font-size: 7.2vw;
						font-weight: 700;
						line-height: 140%;
						letter-spacing: 15.08px;
						margin-bottom: 12px;
					}
					.position {
						display: flex;
						gap: 10px;
						p {
							font-family: KBFG Text;
							font-size: 4.1vw;
							opacity: 0.6;
							font-weight: 500;
							line-height: 150%;
						}
						.department {
							position: relative;
							&:after {
								position: absolute;
								content: '';
								width: 1px;
								height: 13px;
								background-color: #666;
								right: -5px;
								top: 3px;
							}
						}
					}
				}
				.address-box {
					margin-top: 74px;
					.address {
						font-family: KBFG Text;
						font-size: 3.8vw;
						font-weight: 500;
						line-height: 150%;
						opacity: 0.5;
						word-break: keep-all;
						margin-bottom: 14px;
					}
					.tell {
						display: flex;
						gap: 30px;
					}
					.flex {
						display: flex;
						gap: 13px;
						align-items: center;
						margin-top: 6px;
						h6 {
							font-family: KBFG Text;
							font-size: 3.8vw;
							font-weight: 700;
							line-height: 150%;
							opacity: 0.4;
						}
						p {
							font-size: 3.8vw;
							font-weight: 500;
							line-height: 150%;
							white-space: nowrap;
						}
					}
				}
			}
			.career-box {
				position: relative;
				border-radius: 20px;
				background: linear-gradient(438deg, #776c61 23.62%, #a19487 95.32%);
				border-radius: 20px;
				padding: 26px 30px 38px 30px;
				color: #fff;
				/* height: calc(100vh - 200px); */
				height: 0;
				padding-bottom: 127.18%;

				.top-menu {
					display: flex;
					align-items: center;
					gap: 8px;
					p {
						font-family: KBFG Text;
						font-size: 3.3vw;
						font-weight: 600;
						line-height: 140%;
					}
				}
				.user-info {
					margin-top: 48px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					border-bottom: 1px solid rgba(255, 255, 255, 0.2);
					padding-bottom: 20px;
					.txt {
						h2 {
							font-family: KBFG Text;
							color: rgba(255, 255, 255, 0.4);
							font-size: 6.1vw;
							font-weight: 600;
							line-height: 140%;
						}
						h3 {
							font-family: KBFG Text;
							font-size: 6.1vw;
							font-weight: 600;
							line-height: 140%;
						}
					}
					.user {
						width: 54px;
						height: 54px;
						background-size: cover;
						background-position: center;
						border-radius: 50%;
					}
				}
				/* .txtbox {
					position: absolute;
					bottom: 57px;
					left: 30px;
					padding-right: 30px;
				} */
				.detail {
					margin-top: 30px;
					h3 {
						font-family: KBFG Text;
						font-size: 3.8vw;
						font-weight: 700;
						line-height: 150%;
						margin-bottom: 8px;
					}
					p {
						font-family: KBFG Text;
						font-size: 3.6vw;
						font-weight: 300;
						line-height: 150%;
						max-width: 199px;
						width: 100%;
						word-break: keep-all;
					}
				}
				.tag-list {
					margin-top: 18px;
					display: flex;
					flex-wrap: wrap;
					gap: 10px;
					.tag {
						border-radius: 90px;
						background: rgba(255, 255, 255, 0.14);
						padding: 4px 10px;
						display: inline-block;
						font-family: KBFG Text;
						font-size: 3.3vw;
						font-weight: 500;
						line-height: 150%;
					}
				}
			}
			.tab-menu {
				display: flex;
				justify-content: center;
				gap: 8px;
				margin-top: 18px;
				.tab {
					width: 30px;
					height: 4px;
					opacity: 0.2;
					background: #776c61;
					border-radius: 30px;
					&.active {
						opacity: 1;
					}
				}
			}
		}

		.title {
			display: flex;
			gap: 8px;
			font-size: 15px;
			font-weight: 700;
			line-height: normal;
		}
		.close-btn {
			position: absolute;
			top: -14px;
			right: 0px;
			transform: translateY(-100%);
			cursor: pointer;
			padding: 6px;
		}
		.toggle {
			padding: 30px;
			position: relative;
			z-index: 10;
			transition: all 0.3s;
			border-radius: 20px 20px 0 0;
			/* height: 162px; */
			&.open {
				transition: all 0.3s;
				/* height: 290px; */
			}
			.content {
				padding-bottom: 20px;
				.flex {
					display: flex;
					justify-content: center;
					gap: 76px;
					text-align: center;
					p {
						color: #666;
						margin-top: 10px;
						font-size: 13px;
						font-weight: 400;
						line-height: normal;
						text-decoration: none;
					}
				}
				.line {
					border-top: 1.5px solid #fbfbfb;
					margin: 20px -30px 0 -30px;
					position: relative;
					top: -5px;
				}
			}

			.flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				.left {
					display: flex;
					align-items: end;
					gap: 10px;
					h1 {
						font-size: 24px;
						font-weight: 700;
						letter-spacing: 0.48px;
					}
					span {
						font-size: 13px;
						font-weight: 500;
					}
				}
				.right {
					p {
						display: flex;
						gap: 8px;
						font-size: 13px;
						font-weight: 400;
					}
				}
			}
			.cont {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				/* padding: 0px 37px 0px 33px; */
				overflow: hidden;
				z-index: 100;
				border-radius: 20px 20px 0px 0px;
				background: linear-gradient(438deg, #776c61 23.62%, #a19487 95.32%);
				box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.04);
				color: #fff;
				height: 66px;
				.top {
					display: flex;
					justify-content: space-between;
					align-items: center;
					position: relative;
					top: 50%;
					transform: translateY(-50%);
					.name-box {
						display: flex;
						gap: 14px;
						align-items: center;
						.userimg {
							width: 34px;
							height: 34px;
							border-radius: 50%;
							background-size: cover;
							background-position: center;
							background-repeat: no-repeat;
						}
						h2 {
							font-family: KBFG Text;
							font-size: 14px;
							font-weight: 700;
							line-height: 140%;
						}
					}
					.address-book {
						display: flex;
						gap: 8px;
						align-items: center;
						width: 50%;
						justify-content: center;
						/* &:after {
							position: absolute;
							content: '';
							width: 1px;
							height: 24px;
							background-color: rgba(255, 255, 255, 0.4);
							right: 0;
						} */
						h2 {
							font-family: KBFG Text;
							font-size: 12px;
							font-weight: 700;
							line-height: 140%;
						}
					}
					.bar {
						width: 1px;
						height: 24px;
						background-color: rgba(255, 255, 255, 0.4);
						position: relative;
					}
					.arrow-box {
						display: flex;
						gap: 6px;
						align-items: center;

						h4 {
							font-family: KBFG Text;
							font-size: 12px;
							font-weight: 500;
							line-height: 140%;
						}
					}
					.img-box {
						display: flex;
						gap: 8px;
						align-items: center;
						width: 50%;
						justify-content: center;
						h4 {
							font-family: KBFG Text;
							font-size: 12px;
							font-weight: 700;
							line-height: 140%;
						}
					}
				}
			}
			.cont2 {
				bottom: -10px;
				padding: 0px 24px 0px 20px;
			}
		}

		// 홈에서
		${(props) =>
			props.home
				? `
      @media (max-width: 500px) {
      .container {
        max-width: 100vw;
      }
      
    }
    @media (max-width: 460px) {
      .container {
        // max-width: initial;
      }
      
    }

  
  `
				: ``}

		${(props) =>
			props.active
				? `
      opacity: 1;
      visibility: visible;
      transition: 0.2s;
      z-index: 100011;
      .overlay {
        opacity: 1;
        visibility: visible;
        z-index: 50;
      }
      .container {
        transform: translate(-50%, 0%);
      }
    `
				: ``}
	}
`;
