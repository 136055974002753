import React from 'react';
import { PopupWrapper,
    Overlay,
    PopupInner,
    PopupClose,
	PopupButton,
 } from './styled';


const Popup = ({show, onClose, btn, full, middle, width, height, button, radius, small, children, bgColor, btnHandle, btnTitle, btnImage, left, top}) => {

    return (
        <PopupWrapper show={show}>
            <Overlay show={show} onClick={onClose} bgColor={bgColor}/>
            <PopupInner show={show} full={full} width={width} height={height} middle={middle} small={small} radius={radius} left={left} top={top}>
                {children}
            </PopupInner>
        </PopupWrapper>
    );
};

export default Popup;