import styled from 'styled-components';

export const Wrapper = styled.div`
	.modal-wrapper {
		position: relative;
		z-index: 1;
		top: -274px;
		width: 273px;
		height: 274px;
		border: 1px solid blue;
		.modal-wrap {
			position: absolute;
			top: 12px;
			right: 12px;
			.btn-list {
				border-radius: 20px;
				width: 80px;
				background-color: #fff;
				li {
					button {
						font-weight: 500 !important;
						font-size: 12px !important;
						line-height: 15px !important;
						padding: 16px 20px;
						border-radius: 16px;
						margin-top: 0 !important;
						&:hover {
							background-color: #f7f7f7;
							color: #ff0000;
						}
					}
				}
				.crystal-title {
					button {
						margin-top: 8px !important;
					}
				}
				.delete-title {
					button {
						margin-bottom: 8px !important;
					}
				}
			}
		}
	}

	@media ${(props) => props.theme.mobile} {
		.modal-wrapper {
			top: -250px;
			width: 250px;
			height: 250px;
			.modal-wrap {
				top: 8px;
				right: 8px;
			}
		}
	}
`;
