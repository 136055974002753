import { useState } from "react";
import { Wrapper } from "./styled";
import Crystal from "../crystal";

export default function More({ moreToggle }) {
  const [tempData, setTempData] = useState(true);
  const [show8, setShow8] = useState(false);
  const crystalToggle = () => {
    setShow8((p) => !p);
  };

  return (
    <>
      <Wrapper>
        <section className="modal-wrapper">
          <div className="modal-wrap">
            <ul className="btn-list">
              <li className="crystal-title">
                <button onClick={crystalToggle}>수정</button>
              </li>
              <li className="delete-title">
                <button>삭제</button>
              </li>
            </ul>
          </div>
        </section>
      </Wrapper>
      {show8 ? <Crystal crystalToggle={crystalToggle} /> : ""}
    </>
  );
}
