import { useEffect, useRef, useState } from 'react';
import ModalClose from '../../../../../vector/ModalClose';
import ModalPrev from '../../../../../vector/ModalPrev';
import { SliderWrapper } from './styled';
import RemoveIcon from '../../../../../vector/RemoveIcon';
import { GetEvents, insertPost } from '../../../../../api/story';
import { LoadingContainer } from '../../../../Story/Popup/styled';
import Loading from '../../../../../components/Loading/Loading';
import { useHistory } from 'react-router-dom';

export default function Eventmodal({ eventToggle }) {
	const [hide, setHide] = useState(true);
	const [hide4, setHide4] = useState(true);
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState(null);
	const inputRef = useRef(null);
	const [depart, setDepart] = useState(4898);
	const [departName, setDepartName] = useState('회사소식');
	const [eventList, setEventList] = useState([]);

	const changeDepart = (depart, name) => {
		setDepart(depart);
		setDepartName(name);
		selectHide();
	};

	useEffect(() => {
		GetEvents({})
			.then((res) => {
				console.log(res);
				if (res.data.code == 200) {
					setEventList(res.data.body.result);
				} else {
					setEventList(null);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const handleClearInput = () => {
		inputRef.current.value = '';
	};

	const selectHide = () => {
		setHide((p) => !p);
	};

	const selectHide4 = () => {
		setHide4((p) => !p);
	};
	//태그 입력
	const [tags, setTags] = useState([]);
	const [event, setEvent] = useState('');
	const [eventID, setEventID] = useState('');
	const [inputValue, setInputValue] = useState('');
	const deleteTags = (i) => {
		console.log('i', i);
		const re = tags.splice(i, 1);
		setTags(Array.from(tags));
	};

	const doAddTag = (e) => {
		if (inputValue) {
			e.preventDefault();
			addTag(inputValue);

			setInputValue('');
			// handleClearInput();
		}
	};
	const handleKeyDown = (e) => {
		console.log(e.target.value);
		console.log(999);
		if (e.key === 'Enter' && e.target.value) {
			e.preventDefault();
			addTag(e.target.value);
			setInputValue('');
			// handleClearInput();
		}
	};

	const removeThumbnail = () => {
		setThumbnail('');
		setPreview('');
	};

	const addTag = (tag) => {
		tags.push(tag);
		console.log('tags', tags);
		setTags(tags);
	};

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		setFile(file);
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setPreview(reader.result);
			};
			reader.readAsDataURL(file);
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		// 파일을 서버로 업로드하고 처리하는 로직
	};

	const [thumbnail, setThumbnail] = useState('');
	const [preview, setPreview] = useState('');
	const [title, setTitle] = useState('');
	const [content, setContent] = useState('');
	const [category, setCategory] = useState('그룹사사업본부');
	const [resultUrl, setResultUrl] = useState('');
	const history = useHistory();

	const CopyLink = (e) => {
		e.preventDefault();
		ref.current.select();
		document.execCommand('copy');
		e.target.focus();
		alert('글 주소 링크가 복사되었습니다.');
	};

	const uploadFile = (e) => {
		console.log(e.target.files);
		setThumbnail(e.target.files);
		if (e.target.files) {
			setPreview(URL.createObjectURL(e.target.files[0]));
		} // background-image 일떄
	};

	const ref = useRef();
	const [success, setSuccess] = useState(false);

	const submit = () => {
		if (!depart) {
			alert('카테고리를 입력해주세요.');
			return;
		}
		if (!title) {
			alert('제목을 입력해주세요.');
			return;
		}
		if (!thumbnail) {
			alert('썸네일을 첨부해주세요.');
			return;
		}
		if (!content) {
			alert('내용을 입력해주세요.');
			return;
		}

		setLoading(true);

		const fd = new FormData();
		fd.append('title', title);
		fd.append('content', content);

		fd.append('tag', [tags]);
		fd.append('category', category);
		fd.append('depart', depart);
		fd.append('eventID', eventID);

		Array.from(thumbnail).map((thumbnail, key) => {
			fd.append('thumbnail', thumbnail);
		});

		insertPost(fd)
			.then((response) => {
				if (response.data.code == '200') {
					alert('간편 작성이 등록되었습니다.');
					setResultUrl(response.data.body.url);
					setLoading(false);
					window.location.reload();
					// setSuccess(true);
				} else {
					alert(response.data.body.message);
					setLoading(false);
				}
			})
			.catch((err) => {
				alert('서버 요청 오류!');
				setLoading(false);
			});
	};
	const doCancel = () => {
		if (window.confirm('간편 작성을 취소하시겠습니까?')) {
			eventToggle();
		}
	};

	return (
		<SliderWrapper>
			<section className="modal-wrapper">
				<div
					className="overlay"
					onClick={() => {
						success ? eventToggle() : doCancel();
					}}
				></div>
				<div className="modal-wrap">
					<i className="icon-close" onClick={doCancel}>
						<ModalClose />
					</i>
					{false ? (
						<div className="link">
							<p className="title">글 작성이 완료되었습니다.</p>
							<p className="url">
								<span>{resultUrl ? resultUrl : 'asjdlasjksajldassdak'}</span>
								<p className="copy" onClick={CopyLink}>
									링크 복사
								</p>
							</p>
							<div className="wr">
								<p
									className="target"
									onClick={() => window.open(resultUrl, '_blank')}
								>
									새 창으로 열기
								</p>
								<p className="btn" onClick={eventToggle}>
									완료
								</p>
							</div>
							<input
								type="text"
								ref={ref}
								name="copy_account"
								value={resultUrl ? resultUrl : ''}
							/>
						</div>
					) : (
						<>
							<h2 className="modal-title">게시글 작성</h2>
							<div className="modal-box">
								<div className="select-wrap">
									<p className="department-title">카테고리</p>
									<article className="cont-select">
										<button onClick={selectHide} className="btn-select active">
											{departName}
										</button>
										<ul className={hide ? 'list-member' : 'active list-member'}>
											<li>
												<button onClick={() => changeDepart(4898, '회사소식')}>
													회사소식
												</button>
												<button
													onClick={() => changeDepart(4867, '그룹사사업본부')}
												>
													그룹사사업본부
												</button>
												<button
													onClick={() => changeDepart(4899, '은행사업본부')}
												>
													은행사업본부
												</button>
												<button
													onClick={() => changeDepart(4900, '경영지원본부')}
												>
													경영지원본부
												</button>
												{/* <button
													onClick={() => changeDepart(4901, 'DX사업본부')}
												>
													DX사업본부
												</button> */}
												<button
													onClick={() => changeDepart(4902, '신성장사업본부')}
												>
													신성장사업본부
												</button>
											</li>
										</ul>
										{/* <select
                  onChange={e => setCategory(e.target.value)}
                  className="cate"
                >
                  <option value="">선택없음</option>
                  <option value="106745">[카드IT운영부] 나의 마키 소개</option>
                  <option value="103998">
                    다잇소 스타톡 프로필 등록 이벤트
                  </option>
                  <option value="103673">
                    워라밸 원데이클래스 와인코칭 프로그램 참여
                  </option>
                  <option value="103650">우리동네를 소개합니다</option>
                  <option value="103649">ESG 아이디어 공모</option>
                </select> */}
									</article>
								</div>
								<div className="select-wrap">
									<p className="department-title">이벤트 명</p>
									<article className="cont-select">
										<button onClick={selectHide4} className="btn-select active">
											{event ? event : '선택없음'}
										</button>
										<ul
											className={hide4 ? 'list-member' : 'active list-member'}
										>
											{eventList?.length
												? eventList.map((item, key) => (
														<li>
															<button
																onClick={() => {
																	setEvent(item?.postTitle);
																	setEventID(item?.ID);
																	selectHide4();
																}}
															>
																{item?.postTitle}
															</button>
														</li>
												  ))
												: null}
										</ul>
									</article>
								</div>
								{/* <div className="tag-wrap">
									<p className="tag-title">이벤트 명</p>
									<div className="tag-box">
										<div className="tag-wrapper"></div>
										<div className="ct">
											<input
												type="text"
												placeholder="띄어쓰기 없이 정확히 입력해주세요."
												value={inputValue}
												onKeyPress={handleKeyDown}
												onChange={(event) => setInputValue(event.target.value)}
											/>
											<p onClick={doAddTag}>추가</p>
										</div>
										{tags.map((tag, key) => (
											<div
												onClick={() => deleteTags(key)}
												className="tag-name"
												key={key}
											>
												{tag}
												<i className="tag-close">
													<svg
														width="10"
														height="10"
														viewBox="0 0 10 10"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M9 1L1 9"
															stroke="#FFBC00"
															stroke-width="1.5"
															stroke-linecap="round"
															stroke-linejoin="round"
														/>
														<path
															d="M1 1L9 9"
															stroke="#FFBC00"
															stroke-width="1.5"
															stroke-linecap="round"
															stroke-linejoin="round"
														/>
													</svg>
												</i>
											</div>
										))}
									</div>
								</div> */}
								<div className="select-wrap">
									<p className="department-title">제목</p>
									<div className="tit-box">
										<input
											onChange={(e) => setTitle(e.target.value)}
											type="text"
											placeholder="제목을 입력해주세요."
										/>
									</div>
								</div>
								<div className="name-select-wrap">
									<p className="department-title">썸네일 첨부</p>
									<div>
										<div className="file-wrap">
											{/* <button className="btn-name-select" type="button">
                    {file ? file.name : '파일을 첨부해주세요.'}
                  </button> */}
											<label className="label" htmlFor="ff">
												<span className="submit">파일 첨부</span>
												<input
													id="ff"
													name="ff"
													type="file"
													accept="image/*"
													onChange={uploadFile}
												/>
											</label>
											{preview && (
												<div className="modal-img">
													<img
														src={preview}
														className="preview-img"
														alt="미리보기 이미지"
													/>
													<i onClick={removeThumbnail} className="icon-remove">
														<RemoveIcon />
													</i>
												</div>
											)}
										</div>
										{/* {preview && (
                  <div className="modal-img">
                    <img
                      src={preview}
                      className="preview-img"
                      alt="미리보기 이미지"
                    />
                    <i className="icon-remove">
                      <RemoveIcon />
                    </i>
                  </div>
                )} */}
									</div>
								</div>
								{/* <div className="modal-bg">
              <div className="bg-box">
                <i className="icon-remove">
                  <RemoveIcon />
                </i>
              </div>
            </div> */}
								<div className="board-wrap">
									<p className="board-title">내용</p>
									<textarea
										name=""
										id="board"
										cols="30"
										rows="10"
										maxLength={400}
										placeholder="내용을 입력해주세요. (400자 이내)"
										onChange={(e) => setContent(e.target.value)}
									></textarea>
								</div>
							</div>
							<div className="gradient"></div>
							<div className="btn-wrap">
								<button onClick={doCancel}>취소</button>
								<button onClick={submit} className="yellow-btn">
									저장
								</button>
							</div>
						</>
					)}
				</div>
			</section>
			{loading ? <Loading /> : null}
		</SliderWrapper>
	);
}
