import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { TagGetPosts } from '../../../api/story';
import Card from '../../../components/Card';
import { CommonContainer } from '../../../components/Grid';
import LoadInView from '../../../components/LoadInView';
import MainContainer from '../../../components/MainContainer';
import Pagination from '../../../components/Pagination';
import { CardSection } from '../components/styled';
import { Path, TagCount, Title } from './styled';

const TagPage = props => {
  const [post_list, update_post_list] = useState(0);
  const [current_paged, set_current_paged] = useState(0);
  const [total_page, set_total_page] = useState(0);
  const [list_tags, set_list_tags] = useState(0);
  const [paged, set_paged] = useState(1);
  const params = useParams();
  useEffect(() => {
    set_paged(1);
    TagGetPosts({
      tag_slug: params.tag_slug,
      posts_per_page: '12',
      paged: 1,
    })
      .then(response => {
        console.log(response);
        if (response.data.body.paged) {
          set_total_page(response.data.body.total);
          set_current_paged(response.data.body.paged);
          update_post_list(response.data.body.posts);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [params.id]);
  useEffect(() => {
    if (paged > 1) {
      TagGetPosts({
        tag_slug: params.tag_slug,
        posts_per_page: '12',
        paged: paged,
      })
        .then(response => {
          console.log(response);
          if (response.data.body.paged) {
            set_total_page(response.data.body.total);
            set_current_paged(response.data.body.paged);
            update_post_list(response.data.body.posts);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [paged]);
  const onPaging = page => {
    console.log(page);
    set_paged(page);
    window.scrollTo({ top: document.querySelector('#post_archive').offsetTop });
  };
  return (
    <MainContainer>
      <CommonContainer>
        <Path>
          <span>홈</span>
          <span>Story</span>
          Tag
        </Path>
        <Title>
          Tag <span>#{params.tag_slug}</span>
        </Title>
        <TagCount>
          총 <span>{total_page ? total_page : ''}</span>개의 포스트가 있습니다.
        </TagCount>
        <CardSection>
          {post_list
            ? post_list.map((post, key) => (
                <Card item={post} width="33.3333%" />
              ))
            : '로딩'}
        </CardSection>
        <LoadInView>
          <Pagination
            onPaging={onPaging}
            current={current_paged}
            max={total_page >= 12 ? Math.floor(total_page / 12) : 1}
          />
        </LoadInView>
      </CommonContainer>
    </MainContainer>
  );
};

export default TagPage;
