import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import QuestionIcon from '../../../vector/QuestionMark';
import { MobileSliderWrapper, SliderWrapper } from '../styled';

export default function MentorSection({ otherUserInfo, mentees }) {
	SwiperCore.use([Navigation]);
	return (
		<>
			<SliderWrapper>
				<div className="title-box">
					<div className="title">
						<h1>멘토링 현황</h1>
						<span>멘토</span>
					</div>
				</div>
				{mentees?.length ? (
					<Swiper
						className="swiper-container"
						slidesPerView={3.2}
						spaceBetween={20}
					>
						{mentees.slice(0, 3).map((mentee, idx) => (
							<SwiperSlide key={`mentee-${idx}`}>
								<div className="swiper-card">
									<div className="top">
										<img src={mentee?.avatar} alt="profile thumbnail" />
										<div className="person-info">
											<span>{mentee?.name}</span>
											<span>{mentee?.depart}</span>
										</div>
									</div>

									<div className="bottom">
										<div className="duration">
											<span>참여기간</span>
											<span>{`${mentee?.startDate} ~ ${mentee?.endDate}`}</span>
										</div>
										<div className="circular-progress-bar">
											<CircularProgressbarWithChildren
												value={
													(mentee?.stamp /
														(mentee?.career == 'junior' ? 5 : 3)) *
													100
												}
												strokeWidth={5}
												styles={{
													root: { height: '50px', width: '50px' },
													path: {
														stroke: '#FFBC00',
														strokeLinecap: 'butt',
														transition: 'stroke-dashoffset 0.5s ease 0s',
													},

													trail: {
														stroke: '#E9E9E9',
													},
													text: {
														fill: '#333333',
														fontSize: '18px',
													},
												}}
											>
												<span>
													{mentee?.stamp ? mentee?.stamp : 0}/
													{mentee?.career == 'junior' ? 5 : 3}
												</span>
											</CircularProgressbarWithChildren>
										</div>
									</div>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				) : (
					<div className="no-mentee-info">
						<i>
							<QuestionIcon />
						</i>
						<span>아직 등록된 멘티가 없습니다.</span>
					</div>
				)}
				<div className="gradient" />
				<button className="s see-all"></button>
			</SliderWrapper>
			<MobileSliderWrapper>
				<div className="title-box">
					<div className="title">
						<h1>멘토링 현황</h1>
						<span>멘토</span>
					</div>
				</div>
				{otherUserInfo.mentees[0]?.name ? (
					<Swiper
						className="swiper-container"
						slidesPerView={1.1}
						spaceBetween={10}
					>
						{otherUserInfo.mentees?.map((mentee, idx) => (
							<SwiperSlide>
								<div className="swiper-card" key={`mentee-${idx}`}>
									<div className="top">
										<img src={mentee.avatar} alt="profile thumbnail" />
										<div className="person-info">
											<span>{mentee.name}</span>
											<span>경영기획부</span>
										</div>
									</div>

									<div className="bottom">
										<div className="duration">
											<span>참여기간</span>
											<span>{`${mentee.startDate} ~ ${mentee.endDate}`}</span>
										</div>

										<div className="circular-progress-bar">
											<CircularProgressbarWithChildren
												value={(mentee.stamp / 5) * 100}
												strokeWidth={5}
												styles={{
													root: { height: '66px', width: '66px' },
													path: {
														stroke: '#FFBC00',
														strokeLinecap: 'butt',
														transition: 'stroke-dashoffset 0.5s ease 0s',
													},

													trail: {
														stroke: '#E9E9E9',
													},
													text: {
														fill: '#333333',
														fontSize: '18px',
													},
												}}
											>
												<span>{`${mentee.stamp}/5`}</span>
											</CircularProgressbarWithChildren>
										</div>
									</div>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				) : (
					<div className="no-mentee-info">
						<i>
							<QuestionIcon />
						</i>
						<span> 멘토링 정보가 없습니다.</span>
					</div>
				)}

				<div className="gradient" />
				<button className="see-all">모두 보기</button>
			</MobileSliderWrapper>
		</>
	);
}
