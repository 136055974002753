import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import LoadInView from '../../../components/LoadInView';
import MainContainer from '../../../components/MainContainer';
import SectionComment from './components/SectionComment';
import SectionContent from './components/SectionContent';
import SectionTitle from './components/SectionTitle';
import SectionUser from './components/SectionUser';
import SectionRelatedPosts from './components/SectionRelatedPosts';
import { DeletePost, GetPost, InsertLiked } from '../../../api/story';
import EditModal from '../../Dashboard/mentor/modal/editmodal';
import Cardpopup from './cardpopup';
import BottomSheet from '../../../components/BottomSheet';

const Single = (props) => {
	const [errorMsg, SeterrorMsg] = useState('');
	const [post_data, set_post_data] = useState({
		post_id: 0,
		post_title: '',
		post_content: '',
	});
	const [editObj, setEditObj] = useState({});
	const [tmp, setTmp] = useState({});
	const params = useParams();

	console.log('params', params);

	useEffect(() => {
		setActive(params?.id ? true : false);
		if (params?.id) {
			document.body.classList.add('card');
			return function cleanup() {
				document.body.classList.remove('card');
			};
		}
	}, []);

	console.log(333, post_data);

	useEffect(() => {
		if (post_data.post_id != params.id) {
			GetPost({ post_id: params.id })
				.then((response) => {
					console.log(response);
					if (response.data.code == '200') {
						const post_data_ = response.data.body;
						console.log(post_data_);
						set_post_data({
							post_id: post_data_.ID,
							post_title: post_data_.post_title,
							post_content: post_data_.post_content,
							post_tags: post_data_.post_tags,
							post_image: post_data_.post_image,
							post_author: post_data_.post_author,
							post_author_name: post_data_.post_author_name,
							post_author_img: post_data_.post_author_img,
							user_phone: post_data_.user_phone,
							user_email: post_data_.user_email,
							user_department: post_data_.user_department,
							post_date: post_data_.post_date,
							file: post_data_.file,
							file_title: post_data_.file_title,
							is_author: post_data_.is_author,
							views_count: post_data_.views_count,
							is_liked: post_data_.is_liked,
							cate_name: post_data_.cate_name,
							liked_count: post_data_.liked_count,
							isSimple: post_data_.isSimple,
						});
						setEditObj(post_data_);
						setTmp(post_data_);
					} else {
						SeterrorMsg('게시물을 찾을 수 없습니다.');
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	});

	const [show, setShow] = useState(false);
	const [isLiked, setIsLiked] = useState(false);
	const [likedCount, setLikedCount] = useState(0);

	const doLiked = (e) => {
		e.preventDefault();
		InsertLiked({ post_id: params.id })
			.then((res) => {
				setIsLiked(res.data.body.is_liked);
				setLikedCount(res.data.body.liked_count);
				console.log(res);
				setShow(true);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const [active, setActive] = useState(false);
	const toggle = () => {
		setActive((p) => !p);
	};
	const doDeletePost = () => {
		if (window.confirm('글을 삭제하시겠습니까?')) {
			DeletePost({ post_id: params.id })
				.then((res) => {
					if (res.data.code === '200') {
						alert('삭제되었습니다.');
						window.location.href = '/cate/4850';
						// window.history.back();
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	const [show10, setShow10] = useState(false);
	const eventToggle = () => {
		setShow10((p) => !p);
	};

	return (
		<MainContainer>
			{/* <button onClick={toggle}>전자명함</button> */}
			{/* <BottomSheet toggle={toggle} active={1} user={post_data} /> */}
			<LoadInView>
				<SectionTitle
					item={{
						post_tags: post_data.post_tags,
						post_title: post_data.post_title,
						post_image: post_data.post_image,
						post_author: post_data.post_author,
						post_author_name: post_data.post_author_name,
						post_author_img: post_data.post_author_img,
						post_date: post_data.post_date,
						views_count: post_data.views_count,
						is_liked: post_data.is_liked,
						liked_count: post_data.liked_count,
					}}
					doLiked={doLiked}
					show={show}
					isLiked={isLiked}
					likedCount={likedCount}
				/>
			</LoadInView>
			<LoadInView>
				<SectionContent
					post_content={post_data.post_content}
					file={post_data.file}
					post_data={post_data}
					cate_name={post_data.cate_name}
					file_title={post_data.file_title}
					post_image={post_data.post_image}
				/>
			</LoadInView>
			<LoadInView>
				<SectionComment
					post_id={params.id}
					is_liked={post_data.is_liked}
					liked_count={post_data.liked_count}
					is_author={post_data.is_author}
					cate_name={post_data.cate_name}
					doLiked={doLiked}
					show={show}
					isLiked={isLiked}
					doDeletePost={doDeletePost}
					eventToggle={eventToggle}
					likedCount={likedCount}
					editObj={editObj}
					isSimple={post_data.isSimple}
					setEditObj={setEditObj}
				/>
			</LoadInView>
			<LoadInView>
				<SectionUser
					item={{
						ID: post_data.post_id,
						post_author: post_data.post_author,
						post_author_name: post_data.post_author_name,
						post_author_img: post_data.post_author_img,
					}}
				/>
			</LoadInView>
			<LoadInView>
				<SectionRelatedPosts
					item={{
						ID: post_data.post_id,
					}}
				/>
			</LoadInView>

			{show10 ? (
				<EditModal
					post_data={post_data}
					eventToggle={eventToggle}
					editObj={editObj}
					setEditObj={setEditObj}
					tmp={tmp}
				/>
			) : (
				''
			)}
		</MainContainer>
	);
};

export default Single;
