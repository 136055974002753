import styled from 'styled-components';

export const HeaderWrapper = styled.div`
	position: relative;
	box-sizing: border-box;
	height: 95px;
	padding: 34px 42px;
	padding-bottom: 0px;
	background: #000;

	.main-logo {
		svg path {
			fill: #fff;
		}
	}
	@media ${(props) => props.theme.mobile} {
		padding: 34px 20px;
	}
`;

export const Logo = styled.div`
	position: absolute;
	top: 18px;
	left: 56px;
	svg {
		width: 78px;
		height: 52px;
	}
`;

export const HeaderMenu = styled.div`
	text-align: center;
	& > ul {
		display: inline-block;
		& > li {
			float: left;
			padding: 0 30px;
			position: relative;
			@media ${(props) => props.theme.tablet} {
				padding: 0px 20px;
			}
			@media (max-width: 800px) {
				padding: 0px 15px;
			}
		}
		& > li > a {
			position: relative;
			font-family: 'KBFG Display';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 22px;
			text-align: center;
			color: #fff;
			text-decoration: none;
			transition: 0.3s;
			:focus {
				outline: none;
			}
			:before {
				transition: 0.3s;
				display: block;
				position: absolute;
				content: '';
				background: #ffdc00;
				border-radius: 50%;
				height: 6px;
				width: 6px;
				left: 50%;
				margin-left: -3px;
				opacity: 0;
				bottom: 0px;
			}
		}
		&.sub > li > a,
		&:hover > li > a,
		&:hover .sub > li.active > a {
			color: #fff;
		}
		&.sub > li.active > a,
		&:hover .sub > li.active:hover > a,
		&:hover > li:hover > a {
			color: #fff;
			:before {
				display: block;
				position: absolute;
				content: '';
				background: #ffdc00;
				opacity: 1;
				bottom: -10px;
			}
		}
	}
	@media (max-width: 900px) {
		ul {
			margin-right: 60px;
		}
	}
	@media ${(props) => props.theme.mobile} {
		display: none;
	}
`;

export const WriteButton = styled.a`
	position: absolute;
	top: 23px;
	right: 115px;
	background: #eee;
	border: 1px solid #eee;
	box-sizing: border-box;
	border-radius: 23px;
	padding: 13px 37px;
	font-family: 'KBFG Display';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 19px;
	color: #000;
	text-decoration: none;
	transition: 0.5s;
	:hover {
		background-color: #ffdc00;
		border-color: #ffdc00;
	}
	:before {
		display: inline-block;
		position: relative;
		margin-right: 10px;
		content: '';
		top: 1px;
		width: 13px;
		height: 13px;
		background: url(/arrow-plus.png);
	}
	@media ${(props) => props.theme.laptop} {
		top: 25px;
		right: 105px;
		padding: 10px 16px;
	}
	@media ${(props) => props.theme.tablet} {
		top: 25px;
		right: 100px;
		padding: 10px 10px;
	}
	@media ${(props) => props.theme.mobile} {
		/* display: none; */
		text-indent: -9999px;
		width: 41px;
		border-radius: 50%;
		right: 10px;
		:before {
			position: absolute;
			right: 3px;
			top: 13px;
		}
	}
`;

export const Avatar = styled.div`
	position: relative;
	background-image: url(${(props) => props.url});
	background-size: cover;
	position: absolute;
	top: 22px;
	right: 42px;
	width: 46px;
	height: 46px;
	border-radius: 50%;
	cursor: pointer;
	@media ${(props) => props.theme.mobile} {
		right: 62px;
	}
`;

export const Dropdown = styled.ul`
	font-size: 1rem;
	position: absolute;
	z-index: 1200;
	top: 140%;
	right: 0;
	float: left;
	min-width: 160px;
	margin: 5px 0 0;
	padding: 5px 0;
	list-style: none;
	text-align: left;
	color: #000;
	border: 1px solid #e0e0e0;
	border-radius: 0.215rem;
	background-color: #fff;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	z-index: 9999999999;
	li:first-child {
		background-color: #ffac00;
		color: black;
		border-radius: 18px;
		padding-top: 12px;
		text-align: center;
	}
	strong {
	}

	li:nth-of-type(1) {
		margin: 0 15px;
		margin-top: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid #eee;
	}

	li:nth-of-type(n + 2) {
		width: calc(100% - 10px);
		margin: 2px 5px;
		padding: 0.429rem 0.929rem;
	}
	li a {
		text-decoration: none;
		color: #666;
		transition: 0.5s;
		:hover {
			opacity: 0.6;
		}
	}
	transition: 0.6s;
	${(props) => {
		if (props.isopen) {
			return `
                visibility: visible;
                transform: scale(1);
                transform-origin: 100% 0%; 
            `;
		} else {
			return `
                visibility: hidden;
                transform: scale(0);
                transform-origin: 100% 0%;
            `;
		}
	}}
`;

export const Hamburger = styled.div`
	display: none;
	position: relative;
	margin-right: 0px;
	float: left;
	top: 0px;
	z-index: 2121;

	div {
		float: left;
		width: 30px;
		height: 20px;
		position: relative;
		cursor: pointer;

		& > i {
			border-radius: 1px;
			display: block;
		}
		& > .l {
			/* border: 1px solid white; */
			width: 100%;
			height: 2px;
			background: #fff;
			position: absolute;
			top: 0px;
			transition: 0.3s;
		}
		& > i:last-child {
			bottom: 0px;
			top: auto;
			width: 70%;
		}
		& > .s {
			position: absolute;
			top: 5px;
			/* border: 1px solid #000; */
			width: 60%;
			height: 2px;
			margin-top: 4px;
			margin-bottom: 4px;
			background: #fff;
			transition: 0.3s;
		}

		${(props) => {
			if (props.isopen) {
				return `
                    & > .s {
                        display:none;
                    }
                    & > .l {
                        background:#000;
                        width: 150%;
                    }
                    & > i:first-child {
                        transform:rotate(-45deg);
                        top:10px;
                        left: -5px;
                        width: 100%;
                    }
                    & > i:last-child {
                        transform:rotate(45deg);
                        top:10px;
                        left: -5px;
                        bottom: auto;
                        width: 100%;
                    }
                `;
			} else {
				if (props.color == 'white') {
					return `
                        @media ${(props) => props.theme.mobile} {
                            .top & > .s,
                            .top & > .l {
                                background: #fff;
                            }

                        }
                    `;
				}
			}
		}}
	}
	@media ${(props) => props.theme.mobile} {
		display: block;
		${(props) => {
			if (props.isopen) {
				return `
                display:block;
                position: fixed;
                top: 34px;
                right: 20px;
                `;
			} else {
				return `
                  
                `;
			}
		}}
	}
`;

export const SideMenu = styled.div`
	width: 100%;
	height: 100%;
	transition: 0.4s;
	position: fixed;
	top: 0;
	padding: 140px 70px;
	z-index: 9999999999999;
	background: #fff;
	box-sizing: border-box;
	button {
		position: absolute;
		top: 10%;
		right: 10%;
		background-color: transparent;
		border: none;
		i {
			width: 400px;
			height: 400px;
		}
	}
	${(props) => {
		if (props.isopen) {
			return `
                right:0px;
            `;
		} else {
			return `
                right:-400px;
            `;
		}
	}}
	ul {
		li {
			position: relative;
			margin-bottom: 30px;
			:before {
				transition: 0.3s;
				display: block;
				position: absolute;
				content: '';
				background: #ffdc00;
				border-radius: 50%;
				height: 6px;
				width: 6px;
				left: 50%;
				margin-left: -3px;
				opacity: 0;
				top: -15px;
				left: -3px;
			}
		}
		a {
			position: relative;
			font-family: 'KBFG Display', sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 25px;
			line-height: 35px;
			text-align: center;
			color: #1a1a1a;
			text-decoration: none;
			transition: 0.3s;
			:focus {
				outline: none;
			}
		}
		.write-menu a {
			background: #000;
			padding: 7px 15px;
			color: #fff;
			width: 100%;
			display: block;
			border-radius: 50px;
			font-size: 16px;
		}
		.sub li a,
		&:hover li a,
		&:hover .sub li.active a {
			color: #999;
		}

		.sub li.active a,
		&:hover .sub li.active:hover a,
		&:hover li:hover a {
			color: #000;
		}

		&:hover .sub li.active:hover,
		.sub li.active,
		li:hover {
			:before {
				display: block;
				position: absolute;
				content: '';
				background: #ffdc00;
				opacity: 1;
				top: -3px;
			}
		}
		&:hover .sub li.active {
			:before {
				display: block;
				position: absolute;
				content: '';
				background: #ffdc00;
				opacity: 0;
				bottom: 0px;
			}
		}
	}
	${(props) => (props.isopen ? `display:block` : `display:none`)}
`;
