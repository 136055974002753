import styled from 'styled-components';
const HeaderWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 59px;
    @media (max-width: 768px) {
      padding-top: 26px;
      font-weight: 700;
      font-size: 22px;
      line-height: 160%;
    }
  }
`;
export default function ProfilePageHeader() {
  return (
    <HeaderWrapper>
      <h1 className="top-title">Profile Page</h1>
    </HeaderWrapper>
  );
}
