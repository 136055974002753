import styled from 'styled-components';

//StoryHeader//

export const StoryHeaderWrapper = styled.div`
	box-sizing: border-box;
	height: 400px;
	background: #ffdc00;
`;

export const StoryLogo = styled.h2`
	position: absolute;
	top: 44px;
	left: 226px;
	font-family: Noto Sans KR;
	font-style: normal;
	font-weight: 300;
	font-size: 36px;
	line-height: 52px;
	color: #444444;
`;

export const StoryHeaderMenu = styled.div`
	float: right;
	margin-top: 42px;
	margin-right: 223px;
	li {
		float: left;
		margin-left: 60px;
	}
	a {
		font-family: 'KBFG Display';
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 40px;
		text-align: center;
		color: #444444;
		:focus {
			outline: none;
		}
	}
	button {
		background: #ffffff;
		border: none;
		border-radius: 23px;
		width: 157px;
		height: 46px;
		font-family: 'KBFG Display';
		font-style: normal;
		font-weight: 500;
		font-size: 19px;
		line-height: 24px;
		text-align: center;
		color: #444444;
	}
`;

export const StoryTitle = styled.div`
	padding-top: 220px;
	font-family: 'KBFG Display';
	font-style: normal;
	font-weight: 500;
	font-size: 48px;
	line-height: 60px;
	color: #444444;
`;

//StoryHeader//

//TagHeader//

export const TagHeaderWrapper = styled.div`
	box-sizing: border-box;
	height: 400px;
	background: #ffdc00;
`;

export const TagLogo = styled.h2`
	position: absolute;
	top: 44px;
	left: 226px;
	img {
		width: 209px;
	}
`;

export const TagHeaderMenu = styled.div`
	float: right;
	margin-top: 42px;
	margin-right: 223px;
	li {
		float: left;
		margin-left: 60px;
	}
	a {
		font-family: 'KBFG Display';
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 40px;
		text-align: center;
		color: #444444;
		:focus {
			outline: none;
		}
	}
	button {
		background: #ffffff;
		border: none;
		border-radius: 23px;
		width: 157px;
		height: 46px;
		font-family: 'KBFG Display';
		font-style: normal;
		font-weight: 500;
		font-size: 19px;
		line-height: 24px;
		text-align: center;
		color: #444444;
	}
`;

export const TagTitle = styled.div`
	padding-top: 220px;
	font-family: 'KBFG Display';
	font-style: normal;
	font-weight: 500;
	font-size: 48px;
	line-height: 60px;
	color: #444444;
`;

//TagHeader//

//StoryBox//

export const StoryBackground = styled.div`
	position: relative;
	height: 615px;
	width: 100%;
	padding: 131px 0px;
	margin-bottom: 64px;
	background-image: url(${(props) => (props.imageUrl ? props.imageUrl : '')});
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	transition: 0.3s;

	@media ${(props) => props.theme.tablet} {
		height: 500px;
	}
	@media ${(props) => props.theme.mobile} {
		height: 560px;
		padding: 85px 0px;
		:after {
			display: block;
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.55);
			top: 0px;
			left: 0px;
		}
		& > div {
			z-index: 2;
		}
		& > div:first-child {
			position: relative;
		}
	}
`;
export const ProgressBar = styled.div`
	position: absolute;
	width: 100%;
	height: 10px;
	background: rgba(255, 255, 255, 0.3);
	bottom: 0px;
	left: 0px;
	div {
		width: ${(props) => props.percent}%;
		height: 10px;
		background: rgba(255, 255, 255, 0.7);
		.slick-active > div & {
			animation: timeline 7s;
			-webkit-animation-timing-function: linear;
			animation-timing-function: linear;
			@keyframes timeline {
				0% {
					width: 0px;
				}
				100% {
					width: 100%;
				}
			}
		}
	}
`;
export const StoryBox = styled.div`
	height: 344px;
`;

//StoryBox//

//StoryBox//

export const StorySliderWrapper = styled.div`
	position: relative;
	overflow: hidden;
	height: 615px;
	max-width: 1800px;
	/* border-radius: 50px; */
	margin: 0px auto;
	margin-bottom: 0px;
	:after {
		content: '';
		display: block;
		clear: both;
	}
	@media ${(props) => props.theme.mobile} {
		height: 615px;
	}
`;
export const Avatar = styled.div`
	background-image: url(${(props) => props.url});
	background-size: cover;
	position: absolute;
	bottom: 48px;
	right: 60px;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	@media ${(props) => props.theme.laptop} {
	}
	@media ${(props) => props.theme.tablet} {
	}
	@media ${(props) => props.theme.mobile} {
		bottom: 38px;
		right: 30px;
	}
`;
export const StoryBoxWrapper = styled.div`
	width: 500px;
	position: relative;
	box-sizing: border-box;
	/* border-radius: 50px; */
	padding: 50px 65px;
	background: rgba(255, 255, 255, 0.96);
	cursor: pointer;
	transition: 0.3s;
	:hover {
		background: rgba(255, 255, 255, 0.8);
	}
	label {
		margin-right: 10px;
	}
	@media ${(props) => props.theme.tablet} {
		width: 480px;
		padding: 40px 50px;
		background: rgba(255, 255, 255, 0.92);
		label {
		}
	}
	@media ${(props) => props.theme.mobile} {
		width: 100%;
		padding: 60px 0 0 0;
		background: initial;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
		text-align: left;
		:hover {
			background: initial;
		}
		label {
			position: relative;
			border: none;
			height: auto;
			font-family: 'KBFG Display';
			font-size: 18px;
			font-weight: 500;
			line-height: 22px;
			letter-spacing: 0.36px;
			text-align: left;
			color: #fff;
			margin: 0 0 32px 0;
			padding: 0;
			:after {
				position: absolute;
				display: block;
				content: '';
				left: 0;
				bottom: -15px;
				width: 20px;
				height: 2px;
				background-color: #fff;
			}
		}
		.tag {
			display: none;
		}
		.avatar {
			display: none;
		}
	}
`;

export const BoxTitle = styled.div`
	margin-top: 24px;
	margin-bottom: 50px;
	font-family: 'KBFG Display';
	font-style: normal;
	font-weight: 600;
	font-size: 28px;
	line-height: 52px;
	color: #000000;
	@media ${(props) => props.theme.tablet} {
		font-size: 28px;
		line-height: 42px;
		margin-top: 10px;
		margin-bottom: 66px;
		font-weight: 600;
	}
	@media ${(props) => props.theme.mobile} {
		font-size: 22px;
		line-height: 30px;
		min-height: 68px;
		word-break: keep-all;
		margin-top: 0;
		margin-bottom: 30px;
		font-size: 24px;
		line-height: 1.42;
		color: #fff;
		padding-right: 40px;
	}
`;

//StoryBox//

//Tab//

export const TabStyle = styled.ul`
	display: flex;
	position: relative;
	margin-top: 0px;
	padding: 0 50px;
	padding-top: 20px;
	background: #f7f7f7;
	/* border: 1px solid #E5E5E5; */
	margin-bottom: 40px;
	li {
		display: inline-block;
		text-align: center;
		width: 100%;
		padding-bottom: 12px;
	}
	a {
		font-family: 'KBFG Display';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 34px;
		padding-bottom: 18px;
		text-align: center;
		color: #888888;
		text-decoration: none;
		:focus {
			outline: none;
		}
	}
	div {
		visibility: hidden;
		line-height: 10px;
	}
	li.active a {
		color: #ffbc00;
		border-bottom: 1px solid #ffbc00;
	}
	@media ${(props) => props.theme.tablet} {
		li {
			margin-right: 60px;
			margin-bottom: 50px;
		}
		a {
			font-size: 18px;
			line-height: 25px;
		}
	}
	@media ${(props) => props.theme.mobile} {
		margin-top: 0;
		margin-bottom: 0px;
		padding: 15px 0px;
		li {
			margin-right: 10px;
			padding-bottom: 0px;
			margin-bottom: 0px;
		}
		a {
			font-size: 13px;
			line-height: 18px;
			height: 18px;
			padding-bottom: 8px;
		}
		div {
			margin-bottom: -30px;
		}
	}
`;

//Tab//

export const BadgeSection = styled.div`
	max-height: 92px;
	overflow: hidden;
	label {
		margin-right: 12px;
		margin-bottom: 12px;
	}
`;

export const CardSection = styled.div`
	margin: 0px -20px 70px -20px;
	max-width: none;
	@media ${(props) => props.theme.tablet} {
		margin: 0px -20px 70px -20px;
		& > div {
		}
	}
`;

//slick//

export const settings = {
	infinite: true,
	autoplay: false,
	speed: 1000,
	arrows: true,
	slidesToShow: 1,
	rows: 1,
	slidesToScroll: 1,
	dots: 0, // 스크롤바 아래 점으로 페이지네이션 여부
	autoplaySpeed: 2000, // 자동 스크롤 시 다음으로 넘어가는데 걸리는 시간 (ms)
	pauseOnHover: true,
};

export const SlickWrap = styled.div`
	margin: 45px auto;

	.slick-arrow {
		z-index: 2;
	}
	.slick-initialized .slick-slide {
		display: block;
	}
	.slick-list {
		overflow: visible;
		padding: 0px !important;
	}

	.slick-prev:before {
		opacity: 1;
		color: black;
	}
	.slick-next:before {
		opacity: 1;
		color: black;
	}
	.slick-slide:not(.slick-active) {
		opacity: 0.4;
	}
`;

//slick//

export const MoreButton = styled.div`
	position: relative;
	border: 1px solid #fff;
	font-family: 'KBFG Display';
	font-size: 13px;
	font-weight: 500;
	line-height: 38px;
	letter-spacing: -0.39px;
	text-align: left;
	color: #fff;
	width: 110px;
	height: 38px;
	border-radius: 2px;
	padding: 0 0 0 13px;
	:after {
		display: block;
		position: absolute;
		content: '';
		top: 11px;
		right: 16px;
		width: 13px;
		height: 13px;
		background-image: url('/right-arrow-half.png');
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
	}
`;
