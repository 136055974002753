import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState } from 'react';
import MainContainer from '../../../components/MainContainer';
import Header from '../../../components/daitsoHeader';
import CloseBtnIcon from '../../../vector/IconCloseBtn';
import ArrowTop from '../../../vector/LinkBtn';
import MenteeArrow from '../../../vector/MenteeArrow';
import PlusIcon from '../../../vector/PlusIcon';
import { Wrapper } from './styled';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { ModalClose, StopBtn } from "../../../../util/svgImages";
import Modal1 from './modal/modal1';
import { set } from 'date-fns';
import Modal2 from './modal/modal2';
import { GetMentorings } from '../../../api/mentorings';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import NoneIcon from '../../../vector/NoonIcon';

export default function Test5({ otherUserInfo }) {
	const user = useSelector((state) => state.auth.user_data);
	const params = useParams();
	useEffect(() => {
		if (user) {
			if (user?.ID && user?.user_role == 'administrator') {
				console.log('ok');
			} else {
				if (user?.dashboard_auth == 'is_depart') {
					console.log('ok');
				} else {
					console.log(33, user?.ID);
					console.log(33, user?.user_role);

					window.location.href = '/';
				}
			}
		}
	}, [user]);

	const [show, setShow] = useState(false);
	const modalToggle = () => {
		setShow((p) => !p);
	};

	const [show2, setShow2] = useState(false);
	const [depart, setDepart] = useState('');
	const modalToggle2 = (depart) => {
		setDepart(depart);
		setShow2((pptato) => !pptato);
	};

	const [hide, setHide] = useState(true);
	const [hide2, setHide2] = useState(true);
	const [hide3, setHide3] = useState(true);
	const selectHide = () => {
		setHide((p) => !p);
	};
	const selectHide2 = () => {
		setHide2((p) => !p);
	};
	const selectHide3 = () => {
		setHide3((p) => !p);
	};

	const [year, setYear] = useState(2024);
	const [department, setDepartment] = useState('');
	const [items, setItems] = useState([]);
	const [p, setP] = useState(false);

	useEffect(() => {
		GetMentorings({
			year: year,
			department: department,
		})
			.then((res) => {
				if (res.data.code == 200) {
					setItems(res.data.body.result);
				}
				console.log(res);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [p, year]);

	const [isMenteeVisible, setMenteeVisible] = useState(false);

	const toggleMentee = () => {
		setMenteeVisible(!isMenteeVisible);
	};

	const [activeIndex, setActiveIndex] = useState(null);

	const toggleAccordion = (index) => {
		if (activeIndex === index) {
			// Clicking on the active item closes it
			setActiveIndex(null);
		} else {
			// Clicking on a closed item opens it
			setActiveIndex(index);
		}
	};

	const [activity, setActivity] = useState();
	const onChangeActivity = (activity, name) => {
		setActivity(activity);
		setName(name);
		modalToggle();
	};

	const [name, setName] = useState('');

	let found = items.find((e) => e.title === params.ID);
	let result = [found];

	return (
		<>
			<MainContainer>
				<Wrapper>
					<div className="wrapper">
						<section className="top-menu-wrap">
							<div className="top-menu-box">
								<h1 className="top-title">멘토링 대시보드</h1>
								<div className="select-box">
									<article className="cont-select">
										<button onClick={selectHide} className="btn-select">
											{year}년
										</button>
										<ul className={hide ? 'list-member' : 'active list-member'}>
											{/* <li>
												<button>2021년</button>
											</li>
											<li>
												<button>2022년</button>
											</li> */}
											<li
												onClick={() => {
													setYear(2024);
													setHide(true);
												}}
											>
												<button>2024년</button>
											</li>
											<li
												onClick={() => {
													setYear(2023);
													setHide(true);
												}}
											>
												<button>2023년</button>
											</li>
											<div className="bg"></div>
										</ul>
									</article>

									{/* <article className="department-select">
										<button onClick={selectHide2} className="btn-select">
											부서를 선택하세요
										</button>
										<ul
											className={hide2 ? 'list-member' : 'active list-member'}
										>
											<li>
												<button>신기술사업부</button>
											</li>
											<li>
												<button>신기술사업부</button>
											</li>
											<li>
												<button>신기술사업부</button>
											</li>
											<div className="bg"></div>
										</ul>
									</article> */}
								</div>
							</div>
						</section>
					</div>
					<div className="wrapper">
						{result?.length
							? result?.map((item, key) => (
									<>
										<section className="saas-wrap">
											<div className="saas-top-menu">
												<h1 className="saas-title">{item?.title}</h1>
												{/* <div className="btn-box">
													<article className="cont-select">
														<button
															onClick={selectHide3}
															className="btn-select"
														>
															멘토링 진행중
														</button>
														<ul
															className={
																hide3 ? 'list-member' : 'active list-member'
															}
														>
															<li>
																<button>멘토링 진행중</button>
															</li>
															<li>
																<button>멘토링 진행중</button>
															</li>
															<div className="bg"></div>
														</ul>
													</article>
													<button onClick={modalToggle2} className="add-btn">
														<i className="icon-plus">
															<PlusIcon />
														</i>
														멘토추가
													</button>
												</div> */}
												<button
													onClick={() => modalToggle2(item?.title)}
													className="add-btn"
												>
													<i className="icon-plus">
														<PlusIcon />
													</i>
													멘토추가
												</button>
											</div>
											{item?.posts?.length ? (
												item?.posts?.map((i, k) => (
													<div className="saas-list-wrap">
														<div className="mentor-info">
															<div className="mentor-info-wrap">
																<div
																	className="user-img"
																	style={{
																		backgroundImage: `url(${i?.mentorAvatar})`,
																	}}
																></div>
																{/* <img src="profile-img.png" alt="" /> */}
																<div className="mentor-info-desc">
																	<h1 className="name">{i?.mentorName}</h1>
																	<p className="department">
																		{i?.mentorDepart}
																	</p>
																</div>
															</div>
															<div className="activit">
																<div className="activit-box">
																	<p className="activit-title">멘토링 활동</p>
																	<p className="number">
																		<span>
																			{i?.activity_num ? i?.activity_num : 0}
																		</span>
																		회
																	</p>
																</div>
																<div className="breakdown">
																	<button
																		onClick={() =>
																			onChangeActivity(
																				i?.activity,
																				i?.mentorName
																			)
																		}
																		className="yellow-btn"
																	>
																		<i className="icon-arrow-top">
																			<ArrowTop />
																		</i>
																		멘토링 내역
																	</button>
																	<button
																		onClick={() => window.open(i?.padlet)}
																		className="white-btn"
																	>
																		<i className="icon-arrow-top">
																			<ArrowTop />
																		</i>
																		Padlet
																	</button>
																</div>
															</div>
														</div>

														<div className="saas-list">
															<div className="mo-hidden">
																<ul className="info-list">
																	{i?.mentees?.length
																		? i?.mentees?.map((a, b) => (
																				<Link to="/">
																					<li>
																						<div className="profile-box">
																							<div
																								className="user-img"
																								style={{
																									backgroundImage: `url(${a?.avatar})`,
																								}}
																							></div>

																							<div className="profile-info-box">
																								<div></div>
																								<div className="mentor-info-box">
																									<h2 className="mentor-name">
																										{a?.name}
																									</h2>
																									{/*   */}
																								</div>
																								<p className="department-text">
																									{a?.depart}
																									{/* {item.title} */}
																								</p>
																							</div>
																							<i className="icon-close">
																								<CloseBtnIcon />
																							</i>
																						</div>
																						<div className="period-wrap">
																							<div className="period-box">
																								<p className="period-text">
																									참여기간
																								</p>
																								<p className="day">
																									{a?.startDate} ~ {a?.endDate}
																								</p>
																							</div>
																							{/* <div className="circular-progress-bar">
																									<span>
																										{a?.stamp ? a?.stamp : 0}/5
																									</span>
																								</div> */}

																							<CircularProgressbarWithChildren
																								value={
																									(a?.stamp /
																										(a?.career == 'junior'
																											? 5
																											: 3)) *
																									100
																								}
																								strokeWidth={5}
																								styles={{
																									root: {
																										height: '66px',
																										width: '66px',
																									},
																									path: {
																										stroke: '#FFBC00',
																										strokeLinecap: 'butt',
																										transition:
																											'stroke-dashoffset 0.5s ease 0s',
																									},

																									trail: {
																										stroke: '#E9E9E9',
																									},
																									text: {
																										fill: '#333333',
																										fontSize: '18px',
																									},
																								}}
																							>
																								<span>
																									{a?.stamp ? a?.stamp : 0}/
																									{a?.career == 'junior'
																										? 5
																										: 3}
																								</span>
																							</CircularProgressbarWithChildren>
																						</div>
																					</li>
																				</Link>
																		  ))
																		: null}
																	{/* {Array.apply(
																			null,
																			Array(6 - i?.mentees?.length)
																		).map((i) => (
																			<Link>
																				<li
																					style={{
																						backgroundColor: '#F7F7F7',
																						border: 'none',
																					}}
																				></li>
																			</Link>
																		))} */}
																	{i?.mentees?.length ? (
																		Array.apply(
																			null,
																			Array(6 - i?.mentees?.length)
																		).map((i) => (
																			<Link>
																				<li
																					style={{
																						backgroundColor: '#F7F7F7',
																						border: 'none',
																					}}
																				></li>
																			</Link>
																		))
																	) : (
																		<div className="mentor-empty">
																			<div className="none-box mentor-none">
																				<i className="icon-none">
																					<NoneIcon />
																				</i>
																				<p className="none-text">
																					현재 등록된 멘티가 없습니다.
																				</p>
																			</div>
																		</div>
																	)}
																</ul>
															</div>
															<div className="mo-hide">
																{i?.ID == activeIndex && (
																	<div className="pc-hidden">
																		<ul className="info-list">
																			{i?.mentees?.length ? (
																				i?.mentees?.map((a, b) => (
																					<Link to="/">
																						<li>
																							<div className="profile-box">
																								<div
																									className="user-img"
																									style={{
																										backgroundImage: `url(${a?.avatar})`,
																									}}
																								></div>

																								<div className="profile-info-box">
																									<div></div>
																									<div className="mentor-info-box">
																										<h2 className="mentor-name">
																											{a?.name}
																										</h2>
																										<button>
																											{i?.mentorName} 멘토
																										</button>
																									</div>
																									<p className="department-text">
																										{a?.depart}
																									</p>
																								</div>
																								<i className="icon-close">
																									<CloseBtnIcon />
																								</i>
																							</div>
																							<div className="period-wrap">
																								<div className="period-box">
																									<p className="period-text">
																										참여기간
																									</p>
																									<p className="day">
																										{a?.startDate} ~{' '}
																										{a?.endDate}
																									</p>
																								</div>
																								<CircularProgressbarWithChildren
																									value={
																										(a?.stamp /
																											(a?.career == 'junior'
																												? 5
																												: 3)) *
																										100
																									}
																									strokeWidth={5}
																									styles={{
																										root: {
																											height: '52px',
																											width: '52px',
																										},
																										path: {
																											stroke: '#FFBC00',
																											strokeLinecap: 'butt',
																											transition:
																												'stroke-dashoffset 0.5s ease 0s',
																										},

																										trail: {
																											stroke: '#E9E9E9',
																										},
																										text: {
																											fill: '#333333',
																											fontSize: '18px',
																										},
																									}}
																								>
																									<span>
																										{a?.stamp ? a?.stamp : 0}/
																										{a?.career == 'junior'
																											? 5
																											: 3}
																									</span>
																								</CircularProgressbarWithChildren>
																							</div>
																						</li>
																					</Link>
																				))
																			) : (
																				<div className="empty">
																					현재 등록된 멘티가 없습니다.
																				</div>
																			)}
																			{/* {Array.apply(
																					null,
																					Array(6 - i?.mentees?.length)
																				).map((i) => (
																					<Link>
																						<li
																							style={{
																								backgroundColor: '#F7F7F7',
																								border: 'none',
																							}}
																						></li>
																					</Link>
																				))} */}
																		</ul>
																	</div>
																)}
																<div className="btn-box">
																	<button
																		onClick={() => toggleAccordion(i?.ID)}
																	>
																		{i?.ID == activeIndex
																			? '멘티숨기기'
																			: '멘티보기'}

																		<i className="icon-mentee">
																			<img
																				src={
																					isMenteeVisible
																						? '../mentee-arrow-active.svg'
																						: '../mentee-arrow.svg'
																				}
																				alt=""
																			/>
																		</i>
																	</button>
																</div>
															</div>
														</div>
													</div>
												))
											) : (
												<div className="mentor-empty">
													<div className="none-box mentor-none">
														<i className="icon-none">
															<NoneIcon />
														</i>
														<p className="none-text">
															현재 등록된 멘토가 없습니다.
														</p>
													</div>
												</div>
											)}
										</section>
									</>
							  ))
							: null}
					</div>
				</Wrapper>
			</MainContainer>
			{show ? (
				<Modal1 name={name} modalToggle={modalToggle} activity={activity} />
			) : (
				''
			)}
			{show2 ? (
				<Modal2 setP={setP} modalToggle2={modalToggle2} depart={depart} />
			) : (
				''
			)}
		</>
	);
}
