import React, { useEffect, useState } from 'react';
import { GetUser } from '../../../../api/user';
import Badge from '../../../../components/Badge';
import { CommonContainer } from '../../../../components/Grid';
import {
	Avatar,
	PostBadge,
	PostSubTitle,
	PostTitle,
	TitleImage,
	TitleWrapper,
} from '../styled';
import Likeicon from '../../../../vector/LikeIcon';
import { InsertLiked } from '../../../../api/story';
import { useParams } from 'react-router-dom';
import Sharpopup from '../../../Dashboard/mentor/modal/sharpopup';
import Cardpopup from '../cardpopup';
import BottomSheet from '../../../../components/BottomSheet';

const SectionTitle = (props) => {
	const [user_data, set_user_data] = useState({ ID: null });
	const { id } = useParams();
	useEffect(() => {
		if (user_data.ID != props.item.post_author) {
			GetUser({
				user_id: props.item.post_author,
			})
				.then((response) => {
					if (response.data.code == '200') {
						set_user_data(response.data.body);
					}
				})
				.catch((response) => {
					console.log(response);
				});
		}
	}, [props.item.post_author, user_data.ID]);

	console.log(13, user_data);

	const goToProfile = (user) => {
		if (user.user_role === 'delete') {
			alert('퇴직자입니다.');
			return;
		} else {
			window.location.href = `/${user_data.ID}`;
		}
	};

	const [show, setShow] = useState(false);
	const modalToggle = () => {
		setShow((p) => !p);
	};
	const [active, setActive] = useState(false);
	const toggle = () => {
		setActive((p) => !p);
	};
	return (
		<>
			<TitleWrapper>
				<CommonContainer>
					<PostBadge>
						{props?.item.post_tags
							? props?.item.post_tags.map((tag, key) => (
									<Badge
										type="primary"
										size="medium"
										onClick={(e) => (window.location.href = '/tag/' + tag.slug)}
									>
										#{tag.name}
									</Badge>
							  ))
							: ''}
					</PostBadge>
					<PostTitle>
						<h2
							dangerouslySetInnerHTML={{ __html: props?.item.post_title }}
						></h2>
						<div className="views-box">
							<p>{props?.item.post_date}</p>
							{/* <p className="views">조회수 : {props?.item.views_count}</p> */}
						</div>
					</PostTitle>
					<PostSubTitle>
						{user_data.ID ? (
							<>
								<div className="top-menu-box">
									<div className="name-like-box">
										<div
											className="name"
											onClick={() => goToProfile(user_data)}
										>
											<Avatar image={props?.item.post_author_img} alt="" />
											<span>{props?.item.post_author_name}</span>
										</div>
										<div className="like-box">
											<button
												className={
													props.show
														? props.isLiked
															? 'like-btn active'
															: 'like-btn'
														: props?.item.is_liked
														? 'like-btn active'
														: 'like-btn'
												}
												onClick={props.doLiked}
											>
												<i className="icon-like">
													<Likeicon />
												</i>
												{props.show
													? props.likedCount
													: props?.item.liked_count
													? props?.item.liked_count
													: 0}
											</button>
										</div>
										{/* <div className="Sharing" onClick={modalToggle}>
											<img src="/images/icon-down02.svg" alt="" />
										</div> */}
									</div>
								</div>
							</>
						) : null}
					</PostSubTitle>
				</CommonContainer>
				<CommonContainer type="full">
					<TitleImage bgImage={props?.item.post_image} />
				</CommonContainer>
			</TitleWrapper>
			{show ? (
				<Sharpopup
					modalToggle={modalToggle}
					user={user_data}
					user_data={user_data}
				/>
			) : (
				''
			)}
		</>
	);
};

export default SectionTitle;
