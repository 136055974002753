import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  .overlay {
    width: 100%;
    height: 100%;
    background-color: #00000030;
  }
  .modal-wrapper {
    width: 100%;
    height: 100vh;
    .modal-wrap {
      position: absolute;
      z-index: 999;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 40px 50px;
      background-color: #fff;
      border-radius: 20px;
      max-width: 700px;
      width: 100%;
      .icon-close {
        position: absolute;
        right: 40px;
        cursor: pointer;
        svg {
          width: 17px;
        }
      }
      .modal-title {
        margin-top: 40px;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
      }
      .input-box {
        border-top: 1px solid #e9e9e9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        padding: 30px 0;
        .input-title {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
        }
        input {
          padding: 25px 30px;
          background-color: #f7f7f7;
          border-radius: 20px;
          max-width: 420px;
          width: 100%;
          border: none;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          ::placeholder {
            color: #888888;
          }
        }
      }
      .btn-wrap {
        display: flex;
        justify-content: end;
        gap: 10px;
        button {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          padding: 20px 64px;
          border: 1px solid #ffbc00;
          border-radius: 20px;
          background-color: #fff;
          color: #ffbc00;
        }
        .yellow-btn {
          background-color: #ffbc00;
          color: #fff;
        }
      }
    }
  }

  @media ${(props) => props.theme.mobile} {
    .modal-wrapper {
      width: 100%;
      height: 100vh;
      .modal-wrap {
        padding: 30px 20px;
        border-radius: 10px;
        max-width: 300px;
        width: 100%;
        .icon-close {
          display: none;
        }
        .modal-title {
          margin-top: 0px;
          font-size: 20px;
          line-height: 25px;
        }
        .input-box {
          margin-top: 10px;
          padding: 20px 0;
          flex-wrap: wrap;
          .input-title {
            font-size: 14px;
            line-height: 17px;
          }
          input {
            margin-top: 14px;
            padding: 19px 20px;
            font-size: 12px;
            line-height: 15px;
          }
        }
        .btn-wrap {
          gap: 8px;
          button {
            font-size: 12px;
            line-height: 15px;
            padding: 14px 0;
            border-radius: 14px;
            max-width: 126px;
            width: 100%;
          }
          .yellow-btn {
            background-color: #ffbc00;
            color: #fff;
          }
        }
      }
    }
  }
`;
