export default function Mokbdsarrow() {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 11L10.0687 1.00051" stroke="black" stroke-width="1.5" />
      <path d="M2.53258 1H10.0898V9.33291" stroke="black" stroke-width="1.5" />
    </svg>
  );
}
