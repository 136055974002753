export default function PenIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1293 5.4787L15.4663 0.853077C15.3121 0.698516 15.1285 0.575821 14.9262 0.492085C14.7239 0.408349 14.5068 0.365234 14.2876 0.365234C14.0683 0.365234 13.8513 0.408349 13.6489 0.492085C13.4466 0.575821 13.263 0.698516 13.1088 0.853077L0.590872 13.2709C0.435335 13.424 0.311925 13.6061 0.227772 13.8069C0.143619 14.0076 0.100391 14.2228 0.100587 14.4402V19.0658C0.100587 19.5049 0.276433 19.9261 0.589442 20.2366C0.902451 20.5471 1.32698 20.7215 1.76964 20.7215H6.43257C6.6517 20.7217 6.86869 20.6788 7.07102 20.5954C7.27334 20.5119 7.45699 20.3895 7.61134 20.2352L20.1293 7.81738C20.4389 7.50567 20.6124 7.08564 20.6124 6.64804C20.6124 6.21044 20.4389 5.79041 20.1293 5.4787ZM6.43257 19.0658H1.76964V14.4402L10.9495 5.33383L15.6124 9.95945L6.43257 19.0658ZM16.7911 8.79011L12.1282 4.16448L14.2876 2.02242L18.9505 6.64804L16.7911 8.79011Z"
        fill="white"
      />
    </svg>
  );
}
