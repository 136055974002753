import BulbIcon from '../../../vector/BulbIcon';
import CheckIcon from '../../../vector/CheckIcon';
import NotCheckedIcon from '../../../vector/UncheckIcon';

export default function SeniorMonitoring({ otherUserInfo }) {
  return (
    <div className="progress-status">
      <div className="progress-box">
        <div className="completed">
          <i>{otherUserInfo?.mentoring?.stamp > 0 ? <BulbIcon /> : null}</i>
          <i>
            {otherUserInfo?.mentoring?.stamp > 0 ? (
              <CheckIcon />
            ) : (
              <NotCheckedIcon />
            )}
          </i>
        </div>
        <span className="count">1회차</span>
        <span
          className={
            otherUserInfo?.mentoring?.stamp > 0
              ? 'status-complete'
              : 'status-incomplete'
          }
        >
          {otherUserInfo?.mentoring?.stamp > 0 ? 'complete' : '예정'}
        </span>
        <div
          className={
            otherUserInfo?.mentoring?.stamp <= 1
              ? 'unprogress-line-junior'
              : 'progress-line-junior'
          }
        />
      </div>
      <div className="progress-box">
        <div className="completed">
          <i>{otherUserInfo?.mentoring?.stamp > 1 ? <BulbIcon /> : null}</i>
          <i>
            {otherUserInfo?.mentoring?.stamp > 1 ? (
              <CheckIcon />
            ) : (
              <NotCheckedIcon />
            )}
          </i>
        </div>
        <span className="count">2회차</span>
        <span
          className={
            otherUserInfo?.mentoring?.stamp > 1
              ? 'status-complete'
              : 'status-incomplete'
          }
        >
          {otherUserInfo?.mentoring?.stamp > 1 ? 'complete' : '예정'}
        </span>
        <div
          className={
            otherUserInfo?.mentoring?.stamp <= 2
              ? 'unprogress-line-junior'
              : 'progress-line-junior'
          }
        />
      </div>
      <div className="progress-box">
        <div className="completed">
          <i>{otherUserInfo?.mentoring?.stamp > 2 ? <BulbIcon /> : null}</i>
          <i>
            {otherUserInfo?.mentoring?.stamp > 2 ? (
              <CheckIcon />
            ) : (
              <NotCheckedIcon />
            )}
          </i>
        </div>
        <span className="count">3회차</span>
        <span
          className={
            otherUserInfo?.mentoring?.stamp > 2
              ? 'status-complete'
              : 'status-incomplete'
          }
        >
          {otherUserInfo?.mentoring?.stamp > 2 ? 'complete' : '예정'}
        </span>
        <div
          className={
            otherUserInfo?.mentoring?.stamp <= 3
              ? 'unprogress-line-junior'
              : 'progress-line-junior'
          }
        />
      </div>
    </div>
  );
}
