export default function MobilePhoneIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8639 22C7.1167 22 0 14.8833 0 6.13615C0.00330756 4.63576 0.55135 3.18761 1.54223 2.06097C2.53312 0.934317 3.89942 0.205827 5.3871 0.0109392C5.76634 -0.03111 6.14912 0.0487358 6.47992 0.238898C6.81072 0.429059 7.07235 0.71965 7.22687 1.06853L9.44119 6.2353C9.55606 6.50349 9.60211 6.79612 9.57518 7.08662C9.54824 7.37712 9.44917 7.6563 9.28696 7.8988L7.45821 10.697C8.28631 12.3776 9.65078 13.7343 11.336 14.5528L14.1012 12.713C14.3434 12.5497 14.6233 12.4509 14.9144 12.4258C15.2055 12.4008 15.4981 12.4503 15.7647 12.5698L20.9315 14.7731C21.2803 14.9276 21.5709 15.1893 21.7611 15.5201C21.9513 15.8509 22.0311 16.2337 21.9891 16.6129C21.7942 18.1006 21.0657 19.4669 19.939 20.4578C18.8124 21.4486 17.3642 21.9967 15.8639 22ZM5.60743 1.76257C4.54428 1.89924 3.56739 2.41873 2.85967 3.22379C2.15196 4.02884 1.76194 5.06425 1.76265 6.13615C1.76557 9.87512 3.25216 13.4601 5.89602 16.104C8.53988 18.7478 12.1249 20.2344 15.8639 20.2373C16.9358 20.2381 17.9712 19.848 18.7762 19.1403C19.5813 18.4326 20.1008 17.4557 20.2374 16.3926L15.0707 14.1893L12.3165 16.029C12.0631 16.1966 11.7707 16.2957 11.4676 16.317C11.1646 16.3382 10.8612 16.2808 10.5869 16.1502C8.53447 15.1574 6.87426 13.5049 5.87183 11.4571C5.73966 11.1838 5.68035 10.8809 5.69966 10.5779C5.71896 10.2748 5.81621 9.98194 5.98199 9.72755L7.82176 6.92934L5.60743 1.76257Z"
        fill="black"
      />
    </svg>
  );
}
