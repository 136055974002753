export default function RightArr() {
  return (
    <>
      <svg
        width="15"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 13.8652L7.58 7.28957L0.999999 0.713915"
          stroke="black"
          stroke-width="2"
        />
      </svg>
    </>
  );
}
