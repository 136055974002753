import styled from "styled-components";

export const HeaderStyle = styled.div`
  position: relative;
  width: 100%;
  height: 97px;
  @media ${(props) => props.theme.tablet} {
    ${(props) =>
      props.is_home
        ? `
            position: absolute;
            z-index: 99999;`
        : ""}
  }
`;

export const HeaderSection = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  background: #fff;
  height: 97px;
  // background: none;
  transition: 0.4s ease;
  &.hide {
    transform: translateY(-97px);
  }
  @media ${(props) => props.theme.tablet} {
    &.top {
      background: transparent;
    }
  }
`;

export const HeaderWrapper = styled.div`
  position: relative;
  z-index: 3;
  box-sizing: border-box;
  height: 97px;
  padding-left: 50px;
  padding-right: 50px;
  /* overflow:hidden; */
  & > a {
    z-index: 99;
  }
  .shape {
    display: block;
    height: 0px;
    width: 100%;
    left: 0px;
    top: 87px;
    overflow: hidden;
    transition: 0.3s;
    position: absolute;
    &:after {
      content: "";
      display: block;
      width: 470px;
      height: 470px;
      position: absolute;
      left: -100px;
      top: -260px;
      background-image: url(${(props) =>
        props.theme.image_url}/common/star.png);
      z-index: 999;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      animation: rotation 8s infinite linear;
    }
    &:before {
      content: "";
      display: block;
      width: 270px;
      height: 270px;
      position: absolute;
      top: 200px;
      left: 600px;
      background-image: url(${(props) =>
        props.theme.image_url}/common/star_.png);
      z-index: 999;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      /* animation: rotation 6s infinite linear; */
    }

    @keyframes rotation {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(359deg);
      }
    }
  }
  &:after {
    margin-left: -50px;
    margin-right: -50px;
    display: block;
    transition: 0.3s;
    position: relative;
    content: "";
    // width: 100%;
    max-width: 200%;
    height: 0px;
    /* height: 350px; */
    top: 60%;
    left: 0px;
    background: #ffbc00;
    z-index: 99999;
    opacity: 1;
  }
  &:hover {
    transition: 0.6s;
    .shape {
      display: block;
      height: 300px;
    }
    .sub_ul {
      display: block;
      opacity: 1;
      height: auto;
      top: 130%;
    }
    &:after {
      transition: 0.3s;
      position: relative;
      content: "";
      // width: 100%;
      height: 300px;
      top: 90%;
      background: #ffbc00;
      z-index: 1;
    }
  }

  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
    ${(props) =>
      props.is_home
        ? `
            position:absolute;
            top:0px;
            left:0px;
            width:100%;`
        : ""}
  }
  @media ${(props) => props.theme.mobile} {
    padding-left: 0px;
    padding-right: 0px;
    .shape {
      display: none;
    }

    &:hover {
      .shape {
        display: none;
      }
      .sub_ul {
        display: none;
      }
      &:after {
        display: none;
      }
    }
  }
`;

export const Logo = styled.div`
  position: absolute;
  margin-top: 40px;
  margin-left: 0px;
  svg {
    width: 190px;
    height: auto;
  }
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
    & svg {
      width: 169px;
      height: auto;
      g > path:not(:first-child) {
        ${(props) => (props.type == "white" ? `fill: #000;` : ``)}
      }
    }
    .top & svg {
      g > path:not(:first-child) {
        ${(props) => (props.type == "white" ? `fill: #fff;` : ``)}
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin-left: 20px;
    margin-top: 36px;
  }
`;

export const HeaderMenu = styled.div`
  float: right;
  // margin-top: 41px;
  margin-right: 0px;
  & > ul {
    display: inline-block;
    margin-right: 67px;
    & > li {
      float: left;
      padding: 0 67px;
      position: relative;
    }
    & > li > a {
      position: relative;
      font-family: "KBFG Display", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #1a1a1a;
      text-decoration: none;
      transition: 0.3s;
      :focus {
        outline: none;
      }
      :before {
        transition: 0.3s;
        display: block;
        position: absolute;
        content: "";
        background: #ffdc00;
        border-radius: 50%;
        height: 6px;
        width: 6px;
        left: 50%;
        margin-left: -3px;
        opacity: 0;
        bottom: 0px;
      }
    }
    &.sub > li > a,
    &:hover > li > a,
    &:hover .sub > li.active > a {
      color: #999;
    }

    &.sub > li.active > a,
    &:hover .sub > li.active:hover > a,
    &:hover > li:hover > a {
      color: #000;
      :before {
        display: block;
        position: absolute;
        content: "";
        background: #ffdc00;
        opacity: 1;
        bottom: -10px;
      }
    }

    &:hover .sub & > li.active:hover,
    &.sub & > li.active,
    & > li:hover {
    }

    &:hover .sub & > li.active {
      :before {
        display: block;
        position: absolute;
        content: "";
        background: #ffdc00;
        opacity: 0;
        bottom: 0px;
      }
    }
  }
  img {
    width: 18px;
    height: 18px;
    position: relative;
    bottom: 2px;
    margin-left: 10px;
  }
  .sub_ul {
    transition: 0.6s;
    position: absolute;
    opacity: 0;
    padding-top: 45px;
    width: 100%;
    height: 0px;
    overflow: hidden;
    text-align: center;
    left: 0px;
    top: 0px;
    z-index: 2;

    li {
      margin-bottom: 25px;
    }
    a {
      font-family: "KBFG Text";
      text-decoration: none;
      font-size: 15px;
      line-height: 20px;
      font-weight: normal;
      color: #333;
      transition: 0.4s;
      :hover {
        opacity: 0.7;
        text-decoration: underline;
        color: #333;
      }
    }
  }

  @media ${(props) => props.theme.laptop} {
    & > ul {
      margin-right: 50px;
      & > li {
        padding: 0 50px;
      }
    }
  }

  @media ${(props) => props.theme.tablet} {
    & > ul {
      margin-right: 30px;
      & > li {
        padding: 0 30px;
      }
    }
  }

  @media ${(props) => props.theme.tablet} {
    .top & > ul {
      & > li > a {
        ${(props) => (props.header_type == "white" ? `color: #fff;` : ``)}
      }
      &.sub li a,
      &:hover li a,
      &:hover .sub li.active a {
        ${(props) => (props.header_type == "white" ? `color: #fff;` : ``)}
      }
      &.sub li.active a,
      &:hover .sub li.active:hover a,
      &:hover li:hover a {
        ${(props) => (props.header_type == "white" ? `color: #fff;` : ``)}
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin-right: 20px;
    margin-top: 35px;
    ul {
      display: none;
    }
  }
`;
export const SearchToggle = styled.a`
  margin-top: 37px;
  display: inline-block;
  position: relative;
  z-index: 30;
  path {
  }
  svg {
    width: 24px;
    height: 28px;
    cursor: pointer;
    margin-right: 5px;
  }
  @media ${(props) => props.theme.tablet} {
    .top & path {
      ${(props) => (props.search_type == "white" ? `fill: #fff;` : ``)}
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: -4px;
    svg {
      height: 28px;
      margin-right: 20px;
    }
  }
`;

export const Hamburger = styled.div`
  display: none;
  position: fixed;
  margin-right: 0px;
  float: right;
  /* position:fixed; */
  left: 0px;
  top: 0px;
  z-index: 999999;

  div {
    float: left;
    width: 30px;
    height: 20px;
    position: relative;
    cursor: pointer;

    & > i {
      border-radius: 1px;
      display: block;
    }
    & > .l {
      /* border: 1px solid white; */
      width: 100%;
      height: 2px;
      background: #000;
      position: absolute;
      top: 0px;
      transition: 0.3s;
    }
    & > i:last-child {
      bottom: 0px;
      top: auto;
      width: 70%;
    }
    & > .s {
      position: absolute;
      top: 5px;
      /* border: 1px solid #000; */
      width: 60%;
      height: 2px;
      margin-top: 4px;
      margin-bottom: 4px;
      background: #000;
      transition: 0.3s;
    }

    ${(props) => {
      if (props.isopen) {
        return `
                    & > .s {
                        display:none;
                    }
                    & > .l {
                        background:#000;
                        width: 150%;
                    }
                    & > i:first-child {
                        transform:rotate(-45deg);
                        top:10px;
                        left: -5px;
                        width: 100%;
                    }
                    & > i:last-child {
                        transform:rotate(45deg);
                        top:10px;
                        left: -5px;
                        bottom: auto;
                        width: 100%;
                    }
                `;
      } else {
        if (props.color == "white") {
          return `
                        @media ${(props) => props.theme.mobile} {
                            .top & > .s,
                            .top & > .l {
                                background: #fff;
                            }

                        }
                    `;
        }
      }
    }}
  }
  @media ${(props) => props.theme.mobile} {
    display: block;
  }
`;

export const SideMenu = styled.div`
  width: 100%;
  height: 100%;
  transition: 0.4s;
  position: fixed;
  top: 0px;
  padding: 140px 70px;
  z-index: 99999;
  background: #fff;
  box-sizing: border-box;

  ${(props) => {
    if (props.isopen) {
      return `
                right:0px;
            `;
    } else {
      return `
                right:-400px;
            `;
    }
  }}
  ul {
    li {
      position: relative;
      margin-bottom: 30px;
      :before {
        transition: 0.3s;
        display: block;
        position: absolute;
        content: "";
        background: #ffdc00;
        border-radius: 50%;
        height: 6px;
        width: 6px;
        left: 50%;
        margin-left: -3px;
        opacity: 0;
        top: -15px;
        left: -3px;
      }
    }
    a {
      position: relative;
      font-family: "KBFG Display", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 35px;
      line-height: 45px;
      text-align: center;
      color: #1a1a1a;
      text-decoration: none;
      transition: 0.3s;
      :focus {
        outline: none;
      }
    }
    .sub li a,
    &:hover li a,
    &:hover .sub li.active a {
      color: #999;
    }

    .sub li.active a,
    &:hover .sub li.active:hover a,
    &:hover li:hover a {
      color: #000;
    }

    &:hover .sub li.active:hover,
    .sub li.active,
    li:hover {
      :before {
        display: block;
        position: absolute;
        content: "";
        background: #ffdc00;
        opacity: 1;
        top: -3px;
      }
    }
    &:hover .sub li.active {
      :before {
        display: block;
        position: absolute;
        content: "";
        background: #ffdc00;
        opacity: 0;
        bottom: 0px;
      }
    }
  }
  ${(props) => (props.isopen ? `display:block` : `display:none`)}
`;

export const SearchBarWrapper = styled.div``;

export const SearchBar = styled.div`
  width: 100%;
  height: 210px;
  position: fixed;
  transition: 0.5s ease;
  padding-top: 70px;
  text-align: center;
  background: #fff;
  box-sizing: border-box;
  z-index: 9999999999;
  ${(props) => {
    if (props.searchopen) {
      return `
                top: 0px;
            `;
    } else {
      return `
                top: -500px;
            `;
    }
  }}
  input {
    position: relative;
    font-family: "KBDS Display";
    font-size: 20px;
    font-weight: 600;
    color: #000;
    transition: 0.7s ease;
    width: 60%;
    height: 50px;
    border: none;
    border-bottom: 4px solid #ffdc00;
    ${(props) => {
      if (props.searchopen) {
        return `
                    width: 60%;
                `;
      } else {
        return `
                    width: 20%;
                `;
      }
    }}
  }
  & > div {
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: calc(50% - 20px);
    right: 20%;
    cursor: pointer;
    div:nth-of-type(1) {
      display: inline-block;
      transform: rotate(45deg);
      background: #000;
      width: 2px;
      height: 100%;
      margin-right: -2px;
    }
    div:nth-of-type(2) {
      display: inline-block;
      transform: rotate(-45deg);
      background: #000;
      width: 2px;
      height: 100%;
    }
  }
  input::placeholder {
    position: relative;
    font-family: "KBDS Display";
    font-size: 20px;
    font-weight: 600;
    color: #000;
  }
  @media ${(props) => props.theme.mobile} {
    input {
      ${(props) => {
        if (props.searchopen) {
          return `
                        width:80%;
                    `;
        } else {
          return `
                        width: 20%;
                    `;
        }
      }}
    }
    & > div {
      right: 10%;
    }
  }
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
  top: 0px;
  left: 0px;
  ${(props) => {
    if (props.searchopen) {
      return `
                display:block;
            `;
    } else {
      return `
                display:none;
            `;
    }
  }}
`;
