export default function NoneIcon() {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.3952 19.4378L25.5202 21.3545C24.0202 22.8545 23.0827 24.1253 23.0827 27.2503H18.916V26.2087C18.916 23.8962 19.8535 21.8128 21.3535 20.3128L23.9369 17.6878C24.7077 16.9378 25.166 15.8962 25.166 14.7503C25.166 13.6453 24.727 12.5854 23.9456 11.804C23.1642 11.0226 22.1044 10.5837 20.9994 10.5837C19.8943 10.5837 18.8345 11.0226 18.0531 11.804C17.2717 12.5854 16.8327 13.6453 16.8327 14.7503H12.666C12.666 12.5402 13.544 10.4206 15.1068 8.85777C16.6696 7.29497 18.7892 6.41699 20.9994 6.41699C23.2095 6.41699 25.3291 7.29497 26.8919 8.85777C28.4547 10.4206 29.3327 12.5402 29.3327 14.7503C29.3297 16.5071 28.6334 18.1916 27.3952 19.4378M23.0827 35.5837H18.916V31.417H23.0827M20.9994 0.166992C18.2635 0.166992 15.5544 0.705862 13.0268 1.75284C10.4992 2.79981 8.20251 4.33438 6.26796 6.26893C2.36095 10.1759 0.166016 15.475 0.166016 21.0003C0.166016 26.5257 2.36095 31.8247 6.26796 35.7317C8.20251 37.6663 10.4992 39.2008 13.0268 40.2478C15.5544 41.2948 18.2635 41.8337 20.9994 41.8337C26.5247 41.8337 31.8237 39.6387 35.7307 35.7317C39.6378 31.8247 41.8327 26.5257 41.8327 21.0003C41.8327 9.47949 32.4577 0.166992 20.9994 0.166992Z"
        fill="#D7D7D7"
      />
    </svg>
  );
}
