import styled from 'styled-components';

export const Wrapper = styled.div`
	background-color: #f7f7f7;
	position: relative;
	.overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		top: -95px;
		/* top: 0; */
		left: 0;
		/* z-index: 10000000000; */
		z-index: 99999999999999998;
		background-color: rgba(0, 0, 0, 0.3);
	}
	.in {
		max-width: 330px;
		width: 100%;
	}
	.esg {
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: 182px;
		border-radius: 28px;
		background: #fff;
		margin-bottom: 30px;
		padding: 28px 36px 20px 36px;

		.fx {
			display: flex;
			/* gap: 0 11px; */
			/* justify-content: space-between; */
			align-items: center;
			margin-bottom: 18px;
		}
		.esg-left {
			flex: 0 1 100%;
			max-width: 110px;
		}
		.percent {
			border-radius: 2px 16px 16px 16px;
			background: #000;
			color: #fff;
			text-align: center;
			padding: 10px 16px 14px 16px;
			font-family: Noto Sans KR;
			font-size: 18px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			letter-spacing: -0.36px;
			max-width: 93px;
			margin-left: auto;
		}
		p {
			color: #000;
			font-family: KBFG Display;
			font-size: 18px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
		img {
			width: 28px;
			height: auto;
			margin-right: 11px;
		}
		.esg-img {
			flex: 100px 0 1;
			width: 100%;
			height: auto;
			max-width: 110px;
		}
	}

	.group-wrapper {
		min-height: 164px;
		margin-top: 16px;
		/* padding: 0 30px; */
		.swiper {
			padding: 0 30px;
			.swiper-button-prev {
				background-image: url('/images/icon-left.svg');
				background-size: 10px;
				background-repeat: no-repeat;
				background-position: center;
				left: 1.5px;
				&:after {
					display: none;
				}
			}
			.swiper-button-next {
				background-image: url('/images/icon-left.svg');
				background-size: 10px;
				background-repeat: no-repeat;
				background-position: center;
				transform: rotate(180deg);
				right: 1.5px;
				&:after {
					display: none;
				}
			}
		}
	}
  .cop {
    .group-card {

    } 
  }
	.group-card {
    

		position: relative;
		border-radius: 20px;
		padding: 30px;
		background-color: gray;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		cursor: pointer;
		overflow: hidden;

		&:after {
			position: absolute;
			display: block;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0; 
			background-color: rgba(0, 0, 0, 0.3);
			z-index: 1;
		}

		.ir {
			position: relative;
			z-index: 2;
		}

		p {
			width: fit-content;
			height: 28px;
			color: #fff;
			font-family: 'KBFG Display';
			font-size: 12px;
			font-style: normal;
			font-weight: 300;
			line-height: 28px;
			text-align: center;
			background: rgba(255, 255, 255, 0.4);
			margin-bottom: 16px;
      padding: 0 10px;
		}
		.title {
			color: #fff;
			font-family: 'KBFG Display';
			font-size: 19px;
			font-style: normal;
			font-weight: 700;
			line-height: 140%; /* 26.6px */
			letter-spacing: 0.38px;
			margin-bottom: 11.5px;
		}
		.flex {
			display: flex;
			align-items: center;
			gap: 0 8px;
		}
		.avatar {
			display: inline-block;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
			display: inline-block;
			color: #fff;
			font-family: 'KBFG Text';
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			letter-spacing: -0.28px;
		}
		b {
			color: #ffbc00;
		}
	}

	.mo-mentee-wrap {
		display: none;
	}
	.esg-btn.pc {
		display: block;
	}
	.esg-btn.mo {
		display: none;
	}
	.esg-btn-wrap {
		position: fixed;
		bottom: 100px;
		left: 21px;
		z-index: 1;

		&:hover .h-text {
			opacity: 1;
		}
		cursor: pointer;
		.h-text {
			color: #fff;
			text-align: center;
			font-size: 12px;
			font-weight: 500;
			line-height: normal;
			border-radius: 16px;
			background: #000;
			padding: 12px 14px;
			position: relative;
			left: -10px;
			bottom: 12px;
			opacity: 0;
			transition: all 0.3s;
			&:after {
				content: '';
				position: absolute;
				background-image: url(/icon-triangle.svg);
				width: 12px;
				height: 10px;
				top: 38px;
				left: 50%;
				transform: translateX(-50%);
				background-repeat: no-repeat;
			}
		}
	}
	.mo-block-wrap {
		display: none;
	}
	.mo-comment-box {
		display: none;
	}
	.mo-comment-bg-box {
		display: none;
	}
	.swiper {
		z-index: 0;
	}
	.add-btn {
		display: none;
	}

	/*  */
	.mentee-wrap {
		margin-top: 30px;
		display: flex;
		justify-content: space-between;
		gap: 30px;
		height: 403px;
		.mentee-left {
			max-width: 888px;
			width: 100%;
			background-color: #fff;
			border-radius: 27px;
			padding: 40px 50px;
			.mentor-top {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.mentor-left {
					display: flex;
					align-items: center;
					gap: 11px;
					padding: 11px 11px 0 11px;
					.mentor-top-title {
						font-weight: 700;
						font-size: 24px;
						line-height: 30px;
						color: #111;
					}
					.mentor-border {
						font-weight: 500;
						font-size: 12px;
						line-height: 15px;
						letter-spacing: 0.02em;
						color: #ffac00;
						padding: 5px 12px;
						border: 1px solid #ffac00;
						border-radius: 100px;
					}
				}
				.mentor-right {
					display: flex;
					align-items: center;
					button {
						background-color: #fff;
						border: none;
						font-weight: 700;
						font-size: 16px;
						line-height: 20px;
						letter-spacing: -0.02em;
						.kbds-arrow {
							svg {
								position: relative;
								right: 11px;
								top: 3px;
							}
						}
					}
				}
			}
			.current-wrap {
				margin-top: 42px;
				.noti-txt {
					border-radius: 10px;
					background: rgba(255, 0, 0, 0.04);
					padding: 11px 17px;
					display: inline-flex;
					align-items: center;
					gap: 8px;
					margin-top: -10px;
					color: #f00;
					font-size: 12px;
					font-weight: 500;
					line-height: 160%;
					letter-spacing: 0.24px;
					img {
						position: relative;
						top: -1px;
					}
					span {
						text-decoration: underline;
					}
				}
				.current-list {
					display: flex;
					justify-content: space-between;
					padding: 38px 5px 64px 5px;
					text-align: center;
					overflow: hidden;
					li {
						position: relative;
						letter-spacing: -0.02em;
						font-size: 12px;
						line-height: 32px;
						position: relative;

						/* background-color: #fff; */
						.icon-current {
							svg {
								width: 43px;
								position: relative;
								z-index: 1;
							}
						}
						.round-text {
							font-weight: 500;
							color: #bebebe;
							margin-top: 10px;
						}
						.round-box1 {
							background: rgba(213, 213, 213, 0.12);
							border-radius: 20px;
							width: 71px;
							height: 25px;
							.schedule-text {
								font-weight: 400;
								color: #9f9f9f;
								position: relative;
								top: -4px;
							}
							.complete-text {
								color: #e4c000;
							}
						}
						.complete-box {
							background: rgba(249, 221, 74, 0.12);
						}

						&::after {
							position: absolute;
							content: '';
							top: 22px;
							left: 44px;
							width: 149px;
							height: 1px;
							border-top: 2px dashed #bebebe;
						}
						&:last-child::after {
							display: none;
						}
					}
					.on {
						.icon-current {
							svg {
								path {
									fill: #f9dd4a;
								}
							}
						}
						&::after {
							position: absolute;
							content: '';
							top: 22px;
							left: 44px;
							width: 149px;
							height: 1px;
							border-top: 2px solid #f9dd4a;
						}
					}
				}
				.current-list-three {
					display: flex;
					justify-content: space-between;
					padding: 38px 5px 64px 5px;
					text-align: center;
					overflow: hidden;
					li {
						letter-spacing: -0.02em;
						font-size: 12px;
						line-height: 32px;
						position: relative;
						.icon-current {
							svg {
								width: 71px;
								position: relative;
								z-index: 1;
							}
						}
						.round-text {
							font-weight: 500;
							color: #bebebe;
							margin-top: 10px;
						}
						.round-box1 {
							background: rgba(213, 213, 213, 0.12);
							border-radius: 20px;
							width: 71px;
							height: 25px;
							.schedule-text {
								font-weight: 400;
								color: #9f9f9f;
								position: relative;
								top: -4px;
							}
							.complete-text {
								color: #e4c000;
							}
						}
						.complete-box {
							background: rgba(249, 221, 74, 0.12);
						}
						&::after {
							position: absolute;
							content: '';
							top: 22px;
							left: 44px;
							width: 332px;
							height: 1px;
							border-top: 2px dashed #bebebe;
						}
						&:last-child::after {
							display: none;
						}
					}
					.on {
						.icon-current {
							svg {
								path {
									fill: #f9dd4a;
								}
							}
						}
						&::after {
							position: absolute;
							content: '';
							top: 22px;
							left: 44px;
							width: 332px;
							height: 1px;
							border-top: 2px solid #f9dd4a;
						}
					}
				}
			}
		}
		.mentee-right {
			max-width: 330px;
			width: 100%;
			border-radius: 27px;
			background-color: #fff;
			padding: 40px;
			letter-spacing: -0.03em;
			color: #0e0e0e;
			position: relative;
			.mentee-title {
				font-weight: 700;
				font-size: 28px;
				line-height: 35px;
			}
			.mentee-text {
				font-weight: 300;
				font-size: 18px;
				line-height: 35px;
				margin-top: 12px;
				word-break: keep-all;
			}

			.manual-icon-box {
				display: flex;
				align-items: end;
				justify-content: space-between;
				padding-top: 140px;
				.manual-arrow-box {
					width: 52px;
					height: 52px;
					background-color: #f7f7f7;
					border-radius: 50%;
					position: relative;
					svg {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}
		}
	}
	/*  */
	.my-wrapper {
		max-width: 1308px;
		width: 100%;
		margin: 0 auto;
		padding: 190px 30px;
		box-sizing: border-box;

		.top-wrap {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.my-title {
				font-weight: 700;
				font-size: 48px;
				line-height: 60px;
			}
			button {
				padding: 21px 27px;
				background-color: #000;
				color: #fff;
				border-radius: 18px;
				border: none;
				font-weight: 700;
				font-size: 19px;
				line-height: 24px;
				display: flex;
				align-items: center;
				gap: 13px;
				height: 66px;
				transition: all 0.3s;
				&:hover {
					opacity: 0.8;
				}
			}
		}
		.profile-wrap {
			margin-top: 40px;
			display: flex;
			gap: 32px;
			/* height: 308px; */
			.left-box {
				max-width: 888px;
				width: 100%;
				background: #ffffff;
				border-radius: 28px;
				padding: 50px;
				position: relative;
				.profile-box {
					.pro-wrap {
						display: flex;
						gap: 29px;
						img {
							width: 80px;
							height: 80px;
							border-radius: 50%;
						}
						.avatar {
							width: 80px;
							height: 80px;
							border-radius: 50%;
						}
						.profile-text-box {
							.profile-department {
								font-weight: 300;
								font-size: 22px;
								line-height: 43px;
								letter-spacing: -0.02em;
								color: #686868;
							}
							.profile-hello {
								font-weight: 300;
								font-size: 38px;
								line-height: 43px;
								letter-spacing: -0.02em;
								margin-bottom: 10px;
								span {
									font-weight: 700;
								}
							}
							.user-description {
								/* margin-top: 16px; */
								font-weight: 300;
								font-size: 16px;
								line-height: 18px;
								color: rgb(104, 104, 104);
								display: block;
							}
						}
					}
					.my-wrap {
						display: flex;
						justify-content: space-between;
						align-items: end;
						.my-info-box {
							/* margin-top: 50px; */
							a {
								color: #000;
								text-decoration: none;
							}
							.email {
								display: flex;
								font-weight: 300;
								font-size: 18px;
								line-height: 22px;
							}
							.phone {
								display: flex;
								margin-top: 20px;
							}
							.list-title {
								color: #868686;
							}
							.list-text {
								text-indent: 30px;
							}
							.mail-text {
								padding-left: 5px;
								color: #000;
								text-decoration: none;
							}
						}
						.btn-wrap {
							display: flex;
							flex-direction: column;
							gap: 11px;
							margin-top: -43px;
							padding-left: 30px;
							button {
								padding: 20px 43px;
								font-weight: 700;
								font-size: 17.605px;
								line-height: 22px;
								letter-spacing: -0.03em;
								border: 1.5px solid #e9e9e9;
								border-radius: 90px;
								background-color: #fff;
								display: flex;
								align-items: center;
								gap: 8px;
								height: 62px;
								transition: all 0.3s;
								&:hover {
									opacity: 0.8;
								}
							}
							.black-btn {
								background-color: #000;
								color: #fff;
								border: 1.5px solid #000;
								transition: all 0.3s;
								&:hover {
									opacity: 0.8;
								}
							}
						}
					}
				}
			}

			.right-box {
				position: relative;
				background-color: #f9dd4a;
				border-radius: 28px;
				max-width: 330px;
				width: 100%;
				transition: all 0.3s;
				padding: 40px;
				cursor: pointer;

				.close-btn {
					position: absolute;
					top: 34px;
					right: 20px;
					svg {
						width: 40px;
						height: 40px;
					}
				}
				.icon-under-arrow {
					transition: 0.4s;
				}
				&:hover {
					.icon-under-arrow {
						transform: translateY(-8px);
					}
				}
				.right-text-box {
					letter-spacing: -0.03em;
					line-height: 35px;
					.right-title {
						font-weight: 700;
						font-size: 28px;
					}
					.right-text {
						font-weight: 300;
						font-size: 18px;
						margin-top: 12px;
						word-break: keep-all;
					}
					.icon-box {
						display: flex;
						justify-content: space-between;
						align-items: center;
						position: absolute;
						bottom: 40px;
						left: 0;
						width: 100%;
						padding: 0 40px;
						.icon-under-arrow {
							width: 52px;
							height: 52px;
							border-radius: 50%;
							background-color: #fff;
							position: relative;
							svg {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
							}
						}
					}
				}
			}
		}
		.right-box.t {
			height: 96px;
			padding: 0;
			svg {
				width: 39px;
			}
			.flex {
				display: flex;
				align-items: center;
				height: 96px;
				padding: 0 36px;
				justify-content: space-between;
				div {
					display: flex;
					align-items: center;
					color: #000;
					font-family: KBFG Display;
					font-size: 24px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
					svg {
						margin-right: 13px;
					}
				}
				img {
					width: 24px;
					height: auto;
				}
			}
		}
		.under-box {
			margin-top: 30px;
			display: flex;
			gap: 32px;
			text-align: center;
			.point-list {
				display: flex;
				gap: 60px;
				padding: 27px 51px 24px 51px;
				max-width: 888px;
				width: 100%;
				height: 96px;
				background-color: #fff;
				border-radius: 28px;
				li {
					position: relative;
					margin: 0 auto;
					/* top: 12px; */
					&:after {
						position: absolute;
						content: '';
						top: 0;
						left: 120px;
						width: 1px;
						height: 45px;
						background-color: #eee;
					}
					&:last-child::after {
						display: none;
					}
					.point-title {
						font-weight: 300;
						font-size: 14px;
						line-height: 17px;
						letter-spacing: -0.02em;
						color: #666666;
					}
					.point-text {
						font-weight: 700;
						font-size: 16px;
						line-height: 20px;
						margin-top: 8px;
					}
				}
			}
			.faq-box {
				background: #736c61;
				border-radius: 28px;
				padding: 40px 36px;
				max-width: 330px;
				width: 100%;
				height: 96px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				cursor: pointer;
				&:hover .big-arrow {
					margin-right: -10px;
				}
				.big-arrow {
					transition: all 0.3s;
				}
				.fap-icon-box {
					display: flex;
					align-items: center;
					gap: 13px;
					.faq-title {
						font-weight: 700;
						font-size: 24px;
						line-height: 30px;
						color: #fff;
						position: relative;
					}
				}
			}
		}
		.mentor-wrap {
			margin-top: 30px;
			background-color: #fff;
			border-radius: 28px;
			padding: 39px 30px 30px 30px;
			.mentor-top {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				.mentor-left {
					display: flex;
					align-items: center;
					gap: 11px;
					padding: 11px 11px 0 11px;
					.mentor-top-title {
						font-weight: 700;
						font-size: 24px;
						line-height: 30px;
						color: #111;
					}
					.mentor-border {
						font-weight: 500;
						font-size: 12px;
						line-height: 15px;
						letter-spacing: 0.02em;
						color: #ffac00;
						padding: 5px 12px;
						border: 1px solid #ffac00;
						border-radius: 100px;
					}
				}
				.mentor-right {
					display: flex;
					align-items: center;

					button {
						background-color: #fff;
						border: none;
						font-weight: 700;
						font-size: 16px;
						line-height: 20px;
						letter-spacing: -0.02em;

						.kbds-arrow {
							svg {
								position: relative;
								right: 11px;
								top: 3px;
							}
						}
					}
					.kb-btn {
						transition: all 0.3s;
						&:hover {
							opacity: 0.8;
						}
					}
					.onbtn-box {
						display: flex;
					}
					.on-btn {
						margin-left: 0px;
						margin-right: 5px;
						margin-top: 3px;
						color: #aaa;
					}
					/* .wrap {
            .button {
              position: relative;
              width: 32px;
              height: 20px;
              border-radius: 20px;
              background-color: red;
              .inside {
                position: absolute;
                top: 50%;
                left: 5px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                transform: translateY(-50%);
                background-color: blue;
                transition: all 0.3s;
              }
            }
          }
          input:checked ~ label .button {
            background-color: yellow;
          }
          input:checked ~ label .button .inside {
            left: 75px;
          } */
					.switch-button {
						position: relative;
						display: inline-block;
						width: 32px;
						height: 20px;
						input {
							opacity: 0;
							width: 0px;
							height: 0px;
							/* width: 32px;
              height: 20px;
              position: relative;
              z-index: 10; */
							:checked + .onoff-switch {
								background-color: red;
							}
							:checked + .onoff-switch::before {
								transform: translateX(13px);
							}
						}
						.onoff-switch {
							position: absolute;
							cursor: pointer;
							top: 0;
							left: 0;
							bottom: 0;
							right: 0;
							border-radius: 62px;
							background-color: #ffbc00;
							transition: all 0.3s;
							&::before {
								position: absolute;
								content: '';
								width: 18px;
								height: 18px;
								background-color: #fff;
								border-radius: 50%;
								right: 1px;
								top: 1px;
								transition: all 0.3s;
							}
						}
					}
				}
			}
			.second-box {
				display: flex;
				justify-content: space-between;
				margin-top: 26px;
				.ing-box {
					display: flex;
					align-items: center;
					padding-left: 11px;
					.ing-title {
						font-weight: 300;
						font-size: 15px;
						line-height: 19px;
						color: #111;
					}
					.ing-btn {
						padding: 7px 12px 4px 12px;
						background: rgba(255, 188, 0, 0.12);
						border-radius: 100px;
						margin-left: 10px;
						button {
							font-weight: 300;
							font-size: 12px;
							line-height: 15px;
							letter-spacing: 0.02em;
							border: none;
							background-color: transparent;
							position: relative;
							top: -1px;
							font-family: KBFG Display;
						}
					}
				}
				.add-box {
					display: flex;
					align-items: center;
					button {
						font-weight: 700;
						font-size: 17px;
						line-height: 22px;
						letter-spacing: -0.03em;
						border: none;
						background-color: #fff;
						.icon-add-pen {
							margin-right: 4px;
						}
					}
				}
			}
		}
		.live-wrap {
			margin-top: 40px;
			background-color: #fff;
			border-radius: 27px;
			padding: 0px 0 22px 30px;
			.live-top-menu {
				display: flex;
				justify-content: space-between;
				align-items: baseline;
				margin-bottom: 16px;
				margin-right: 40px;
				padding-top: 20px;
				.live-title {
					font-weight: 700;
					font-size: 24px;
					line-height: 30px;
					color: #111;
				}
				.registr-btn {
					background-color: #000;
					color: #fff;
					font-weight: 700;
					font-size: 17px;
					line-height: 22px;
					letter-spacing: -0.03em;
					border-radius: 48px;
					padding: 15px 25px;
					border: none;
					display: flex;
					gap: 8px;
					height: 52px;
					transition: all 0.3s;
					&:hover {
						opacity: 0.8;
					}
				}
			}
			.post-line {
				border-top: 1px solid #eeeeee;
				margin-right: 30px;
				margin-top: 18px;
			}
			button {
				font-weight: 300;
				font-size: 14px;
				line-height: 17px;
				margin-top: 13px;
				position: relative;
				left: 50%;
				transform: translateX(-50%);
				background-color: #fff;
				border: none;
				color: #000;
			}
		}
		.post-title-wrap {
			margin-top: 40px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.post-title-box {
				display: flex;
				justify-content: space-between;
				align-items: center;
				max-width: 823px;
				width: 100%;
				button {
					font-weight: 700;
					font-size: 17px;
					line-height: 22px;
					letter-spacing: -0.03em;
					background-color: transparent;
					border: none;
					.icon-add-pen {
						margin-right: 4px;
					}
				}
			}
			.comment-tit-box {
				max-width: 395px;
				width: 100%;
			}
			.post-title {
				font-weight: 700;
				font-size: 28px;
				line-height: 35px;
			}
		}
		.post-wrap {
			display: flex;
			justify-content: space-between;
			margin-top: 20px;
			.post-box {
				max-width: 823px;
				width: 100%;
				.writing-wrap {
					background-color: #fff;
					border-radius: 27px;
					padding: 30px 0 20px 30px;
					.none-box {
						text-align: center;
						padding: 95px 0;
						.none-text {
							font-weight: 300;
							font-size: 16px;
							line-height: 20px;
							margin-top: 20px;
							color: #888888;
						}
					}
					.post-line {
						border-top: 1px solid #eeeeee;
						margin-right: 30px;
						margin-top: 18px;
					}
					button {
						font-weight: 300;
						font-size: 14px;
						line-height: 17px;
						margin-top: 13px;
						position: relative;
						left: 50%;
						transform: translateX(-50%);
						background-color: #fff;
						border: none;
						color: #000;
					}
				}
			}
			.comment-wrap {
				background: #ffffff;
				border-radius: 27px;
				max-width: 395px;
				width: 100%;
				height: 859px;
				padding: 30px;
				position: relative;
				overflow-y: auto;
				&::-webkit-scrollbar {
					display: none;
				}
				.no-open {
					position: relative;
					top: 50%;
					transform: translateY(-50%);
					i {
						display: block;
						text-align: center;
						margin-top: 30px;
					}
					.none-text {
						display: block;
						font-weight: 300;
						font-size: 16px;
						line-height: 20px;
						margin-top: 20px;
						color: #888888;
						text-align: center;
					}
				}
				.comment-list {
					.comment-box {
						background: rgba(79, 79, 79, 0.88);
						border-radius: 27px 27px 27px 8px;
						padding: 26px 22px 17px 24px;
						color: #fff;
						.comment {
							font-weight: 300;
							font-size: 15px;
							line-height: 19px;
							margin-bottom: 18px;
						}
						.date {
							font-weight: 300;
							font-size: 12px;
							line-height: 15px;
							text-align: right;
							.icon-time {
								svg {
									position: relative;
									top: 2.5px;
									right: 6px;
								}
							}
						}
					}
					.comment-user {
						margin-top: 15px;
						margin-bottom: 33px;
						display: flex;
						align-items: center;
						gap: 6px;
						&:last-child {
							margin-bottom: 0;
						}
						img {
							width: 27px;
						}
						.opinion {
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
							font-weight: 700;
							font-size: 14px;
							line-height: 18px;
							letter-spacing: -0.03em;
						}
					}
				}
				.comment-bg-box {
					position: relative;
					.comment-bg {
						width: 100%;
						height: 75px;
						position: absolute;
						bottom: 0px;
						right: 0;
						background: linear-gradient(
							180deg,
							rgba(255, 255, 255, 0) 0%,
							#ffffff 100%
						);

						button {
							font-weight: 700;
							font-size: 14px;
							line-height: 17px;
							padding: 12px 27px;
							background-color: #fff;
							border-radius: 40px;
							border: none;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}
				}
			}
			.interest-wrap {
				margin-top: 40px;

				.interest-title {
					font-weight: 700;
					font-size: 28px;
					line-height: 35px;
				}
				.interest-bg {
					background-color: #fff;
					border-radius: 27px;
					padding: 30px 0px 20px 30px;
					margin-top: 20px;
					/* height: 379px; */
					.none-box {
						text-align: center;
						padding: 90px 0;
						.none-text {
							font-weight: 300;
							font-size: 16px;
							line-height: 20px;
							margin-top: 20px;
							color: #888888;
						}
					}
				}
				.post-line {
					border-top: 1px solid #eeeeee;
					margin-top: 18px;
					margin-right: 30px;
				}
				button {
					font-weight: 300;
					font-size: 14px;
					line-height: 17px;
					margin-top: 13px;
					position: relative;
					left: 50%;
					transform: translateX(-50%);
					background-color: #fff;
					border: none;
				}
			}
		}
		.club-wrap {
			margin-top: 30px;
			display: flex;
			gap: 30px;

			.club-box {
				width: calc((100% - 60px) / 3);
				background-color: #fff;
				border-radius: 27px;
				padding: 36px 42px 0 42px;
        min-height: 276px;

				&.group {
					padding: 36px 0 0 0;
					.club-title {
						padding-left: 42px;
						span {
							color: #c7c7c7;
							svg {
								transform: translateY(-8px);
								margin: 0 10px;
							}
						}
					}
				}

				&.blog {
					background-color: #736c61;
					color: #fff;
					background-image: url('/images/blog-icon.png');
					background-size: 60%;
					background-position: 77% 72%;
					background-repeat: no-repeat;
					cursor: pointer;
					transition: 0.2s;
					&:hover {
						opacity: 0.9;
					}
					svg {
						margin-left: 10px;
					}
				}

				.club-title {
					font-weight: 700;
					font-size: 24px;
					line-height: 30px;
				}
				.club-list {
					display: flex;
					justify-content: space-between;
					margin-top: 20px;
					img {
						width: 75px;
					}
					.club-name {
						margin-top: 15px;
						font-weight: 500;
						font-size: 13px;
						line-height: 16px;
						color: #777777;
					}
				}
				.allmore-line {
					border-top: 1px solid #eeeeee;
					/* margin-top: 20px; */
				}
				button {
					width: 100%;
					font-weight: 300;
					font-size: 14px;
					line-height: 17px;
					border: none;
					background-color: transparent;
					margin: 22px 0;
				}
				.no-open {
					padding: 25px 0 32px 0;
					text-align: center;
					.none-text {
						font-weight: 300;
						font-size: 16px;
						line-height: 20px;
						color: #888888;
						margin-top: 20px;
					}
				}
			}
		}
		.none-club-wrap {
			position: relative;
			.none-club-box {
				max-width: 396px;
				width: 100%;
				height: 292px;
				border-radius: 27px;
				background-color: #fff;
				opacity: 0.8;
				position: absolute;
				top: -292px;
				.no-open {
					padding: 90px 0;
					text-align: center;
					.none-text {
						font-weight: 300;
						font-size: 16px;
						line-height: 20px;
						margin-top: 20px;
						color: #888888;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1300px) {
		.my-wrapper {
			padding: 190px 20px;

			.under-box {
				.point-list {
					display: flex;
					gap: 0px;

					li {
						width: calc((100% - 0px) / 5);
						border-right: 1px solid #eee;
						&:after {
							display: none;
						}
						&:last-child {
							border-right: none;
						}
						.point-title {
						}
						.point-text {
						}
					}
				}
			}

			.post-title-wrap {
				.post-title-box {
					width: 70%;
				}
			}
			.post-wrap {
				gap: 30px;
				.post-box {
					max-width: 823px;
					width: 70%;
				}
				.comment-wrap {
					max-width: 395px;
					width: 30%;
				}
			}

			.none-club-wrap {
				.none-club-box {
					width: calc((100% - 60px) / 3);
				}
			}
		}
	}

	@media screen and (max-width: 1100px) {
		.esg {
			padding: 28px 16px 20px 16px;
		}
		.my-wrapper {
			.profile-wrap {
				.left-box {
					padding: 30px;
					.profile-box {
						.my-wrap {
							.btn-wrap {
								button {
									padding: 20px 10px;
									width: 150px;
								}
								.black-btn {
								}
							}
						}
					}
				}
				.right-box {
					padding: 0;
					.icon-box {
						bottom: 20px !important;
					}
				}
			}
			.under-box {
				.point-list {
					padding: 20px;
				}
			}
		}
		.mentor-top {
			align-items: baseline !important;
		}
		.mentor-right {
			margin-top: 36px !important;
		}
		.mentee-left {
			padding: 40px 30px !important;
		}
		.mentee-right {
			padding: 40px 30px !important;
			.mentee-title {
				font-size: 22px !important;
				line-height: 25px !important;
			}
			.mentee-text {
				font-size: 16px !important;
				line-height: 24px !important;
			}
		}
	}

	@media ${(props) => props.theme.mobile} {
		.cop {
			/* display: none; */
		}
		.mo-comment-box {
			display: none !important;
		}
		.comment-wrap {
			display: none;
		}
		.club-box.blog {
			background-size: 35% !important;
			background-position: 100% 72% !important;
		}
		.esg-btn.pc {
			display: none;
		}
		.esg-btn.mo {
			display: block;
		}
		.esg {
			display: flex;
			justify-content: space-between;
			width: 100%;
			height: 110px;
			border-radius: 28px;
			background: #fff;
			margin-bottom: 8px;
			padding: 14px 20px;
			align-items: center;
			.fx {
				display: flex;
				/* gap: 0 11px; */
				/* justify-content: space-between; */
				align-items: center;
				margin-bottom: 14px;
			}
			.esg-left {
				flex: 0 1 100%;
				max-width: 69px;
			}
			.esg-img {
				margin: 0;
				flex: 64px 0 0;
				object-fit: contain;
				width: 64px;
				height: 81px;
			}
			.percent {
				border-radius: 2px 16px 16px 16px;
				background: #000;
				color: #fff;
				text-align: center;
				padding: 0px 11px 2px 12px;
				color: #fff;
				text-align: center;
				font-family: Noto Sans KR;
				font-size: 13px;
				font-style: normal;
				font-weight: 700;
				line-height: 26px;
				height: 28px;
				letter-spacing: -0.26px;
				max-width: initial;
				margin-left: auto;
			}
			p {
				color: #000;
				font-family: KBFG Display;
				font-size: 18px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
			}
			img {
				width: 22px;
				height: auto;
				margin-right: 8px;
			}
			/* .esg-img {
				flex: 55px 0 0;
				width: 55px;
				height: 62px;
			} */
		}

		.mo-block-wrap {
			display: block;
		}
		.add-btn {
			display: block;
		}
		.my-wrapper {
			padding: 50px 30px;
			.top-wrap {
				align-items: end;
				.my-title {
					font-size: 22px;
					line-height: 160%;
				}
				button {
					padding: 6px 17px;
					border-radius: 18px;
					font-size: 12px;
					line-height: 15px;
					gap: 8px;
					height: 38px;
					.icon-home {
						svg {
							width: 14px;
							position: relative;
							top: 1px;
						}
					}
				}
			}
			.mo-none-box {
				display: none;
			}
			.mo-pro-box {
				margin-top: 24px;
				background: #ffffff;
				border-radius: 10px;
				padding: 20px;
				position: relative;
				height: 157px;
				.pro-wrap {
					.profile-department {
						font-weight: 300;
						font-size: 12px;
						line-height: 21px;
						letter-spacing: -0.02em;
						color: #686868;
					}
					.profile-hello {
						font-weight: 300;
						font-size: 20px;
						line-height: 21px;
						letter-spacing: -0.02em;
						margin-top: 6px;
						span {
							font-weight: 700;
						}
					}
					.right-icon {
						position: absolute;
						top: 17px;
						right: 14px;
						button {
							width: 40px;
							height: 40px;
							border-radius: 50%;
							background-color: #fff;
							border: 1px solid #e9e9e9;
							position: relative;
							.icon-crtstal {
								svg {
									width: 16px;
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
								}
							}
						}
					}
					.business-card {
						margin-top: 10px;
						button {
							border: none;
							padding: 0;
						}
					}
				}
			}
			.my-info-box {
				margin-top: 30px;
				a {
					color: #000;
					text-decoration: none;
				}
				.email {
					display: flex;
				}
				.phone {
					display: flex;
					margin-top: 6px;
				}
			}
			.list-title {
				font-weight: 300;
				font-size: 12px;
				line-height: 15px;
				color: #868686;
			}
			.list-text {
				font-weight: 300;
				font-size: 12px;
				line-height: 15px;
				text-indent: 11px;
			}
			.mail-text {
				padding-left: 8px;
			}
		}
		.mo-under-box {
			margin-top: 12px;
			.under-wrap {
				width: 100%;
				display: flex;
				gap: 10px;
				.point-list {
					background-color: #fff;
					border-radius: 10px;
					padding: 20px 21px;
					width: 36%;
					height: 250px;
					li {
						text-align: center;
						margin-top: 19px;
						&:first-child {
							margin-top: 0;
						}
						.point-title {
							font-weight: 300;
							font-size: 11px;
							line-height: 14px;
							letter-spacing: -0.02em;
							color: #666666;
						}
						.point-text {
							margin-top: 5px;
							font-weight: 700;
							font-size: 12px;
							line-height: 15px;
						}
						.under-point {
							font-weight: 300;
							font-size: 10px;
							line-height: 12px;
							color: #9f9f9f;
						}
						.acc-title {
							margin-top: 2px;
							font-weight: 300;
							font-size: 10px;
							line-height: 12px;
							color: #9f9f9f;
						}
					}
				}
				.right-box {
					position: relative;
					width: 64%;
					&:hover {
						opacity: 1;
					}
					.right-text-box {
						background: #f9dd4a;
						border-radius: 10px;
						padding: 20px;
						letter-spacing: -0.03em;
						position: relative;
						height: 60px;
						svg {
							width: 28px;
						}
						.flex {
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: space-between;
							div {
								display: flex;
								align-items: center;
								color: #000;
								/* font-family: KBFG Display;
									font-size: 24px;
									font-style: normal;
									font-weight: 700;
									line-height: normal; */
								svg {
									margin-right: 10px;
								}
							}
							img {
								width: 18px;
								height: auto;
							}
						}
						.arvector {
							width: 18px;
						}
						.right-title {
							height: 21px;
							display: flex;
							justify-content: space-between;
							font-weight: 700;
							font-size: 17px;
							line-height: 21px;
						}
						.close-btn {
							display: inline-block;
							svg {
								width: 20px;
								height: 20px;
							}
						}
						.right-text {
							margin-top: 8px;
							font-weight: 300;
							font-size: 11px;
							line-height: 160%;
						}
					}
					.add-box {
						position: absolute;
						right: 15px;
						button {
							font-weight: 700;
							font-size: 12px;
							line-height: 15px;
							letter-spacing: -0.03em;
							border: none;
							background-color: transparent;

							.icon-add-pen {
								svg {
									width: 14px;
									position: relative;
									top: 3px;
									right: 6px;
								}
							}
						}
					}
					.icon-box {
						display: flex;
						justify-content: space-between;
						align-items: end;
						margin-top: 50px;
						.icon-under-arrow {
							width: 32px;
							height: 32px;
							background-color: #fff;
							border-radius: 50%;
							position: relative;
							svg {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
							}
						}
						.icon-sauare {
							svg {
								width: 32px;
								height: 32px;
							}
						}
					}
					.faq-box {
						background: #736c61;
						border-radius: 10px;
						margin-top: 10px;
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 20px;
						height: 60px;
						.menubook-icon {
							svg {
								width: 27px;
								position: relative;
								top: 1px;
							}
						}
						.big-arrow {
							svg {
								width: 23px;
							}
						}
						.fap-icon-box {
							display: flex;
							align-items: center;
							.faq-title {
								font-weight: 700;
								font-size: 18px;
								line-height: 22px;
								color: #fff;
								margin-left: 10px;
							}
						}
					}
				}
			}
		}

		/*  */
		.mentor-wrap {
			margin-top: 10px !important;
			border-radius: 10px !important;
			padding: 30px 0px 30px 10px !important;
			.mentor-top {
				flex-wrap: wrap !important;
				padding-left: 10px !important;
				padding-right: 20px !important;
				.mentor-left {
					gap: 10px !important;
					padding: 0 !important;
					.mentor-top-title {
						font-size: 20px !important;
						line-height: 25px !important;
					}
					.mentor-border {
						font-size: 12px !important;
						line-height: 15px !important;
						padding: 3px 9px !important;
					}
					.add-box {
						position: absolute !important;
						right: 50px !important;
						button {
							font-weight: 700 !important;
							font-size: 12px !important;
							line-height: 15px !important;
							letter-spacing: -0.03em !important;
							border: none !important;
							background-color: transparent !important;
							.comment-wrap {
								.icon-add-pen {
									svg {
										width: 14px !important;
										position: relative;
										top: 3px;
										right: 6px;
									}
								}
							}
						}
					}
				}
				.mentor-right {
					margin-top: 19px !important;
					width: 100%;
					justify-content: end;

					button {
						font-size: 13px !important;
						line-height: 16px !important;
						width: 100% !important;
						white-space: nowrap !important;

						.kbds-arrow {
							svg {
								right: 7px !important;
								top: 3px !important;
								width: 8px !important;
							}
						}
					}
				}
			}
			.second-box {
				margin-top: 20px !important;
				padding-left: 15px !important;
				.ing-box {
					padding-left: 0px !important;

					.ing-title {
						font-size: 12px !important;
						line-height: 15px !important;
					}
					.ing-btn {
						padding: 3px 10px !important;
						margin-left: 8px !important;
						button {
							top: 0px !important;
						}
						.ing-text {
							font-size: 11px !important;
							line-height: 14px !important;
						}
					}
				}
				.add-box {
					display: none !important;
				}
			}
		}
		.live-wrap {
			margin-top: 20px !important;
			border-radius: 10px !important;
			padding: 14px 0 14px 20px !important;
			button {
				left: 45% !important;
			}
			.live-top-menu {
				margin-bottom: 16px;
				margin-right: 20px !important;
				padding-top: 0px !important;
				.live-title {
					font-size: 20px !important;
					line-height: 25px !important;
				}
				.registr-btn {
					font-size: 12px !important;
					line-height: 15px !important;
					border-radius: 50px !important;
					padding: 11px 16px !important;
					gap: 6px !important;
					height: 38px !important;
					margin: 0 !important;
					align-items: center;
					.icon-pen {
						svg {
							width: 15px !important;
						}
					}
				}
			}
		}
		.post-title-wrap {
			margin-top: 20px !important;
			.post-title-box {
				button {
					font-size: 12px !important;
					line-height: 15px !important;

					.icon-add-pen {
						margin-right: 2px;
						svg {
							width: 13px;
							position: relative;
							top: 2px;
						}
					}
				}
			}
			.post-title {
				font-size: 20px !important;
				line-height: 160% !important;
			}
			.mo-post-title {
				display: none;
			}
		}
		.post-wrap {
			flex-wrap: wrap;
			margin-top: 10px;
			.post-box {
				max-width: 823px;
				width: 100% !important;
				.writing-wrap {
					background-color: #fff;
					border-radius: 10px !important;
					padding: 20px 0 14px 20px !important;
					.post-line {
						border-top: 1px solid #eeeeee;
						margin-right: 30px;
						margin-top: 18px;
					}
					.all-btn {
						font-weight: 300;
						font-size: 14px;
						line-height: 17px;
						margin-top: 13px;
						position: relative;
						left: 45%;
						transform: translateX(-50%);
						background-color: #fff;
						border: none;
					}
				}
			}
			.mo-comment-box {
				display: block;
				.mo-comment-title {
					font-weight: 700;
					font-size: 20px;
					line-height: 160%;
					margin-top: -10px;
				}
			}
			.comment-wrap {
				border-radius: 10px !important;
				height: unset !important;
				padding: 20px 20px 14px 20px !important;
				max-width: unset !important;
				width: 100% !important;
				margin-left: 0 !important;
				margin-top: -20px !important;
				.comment-list {
					.comment-box {
						border-radius: 20px 20px 20px 4px !important;
						padding: 20px;
						.comment {
							font-size: 14px;
							line-height: 17px;
							margin-bottom: 28px;
						}
					}
					.comment-user {
						margin-top: 10px;
						margin-bottom: 16px;
						.opinion {
							font-size: 12px;
							line-height: 15px;
						}
					}
				}
				.comment-bg-box {
					display: none;
				}
				.mo-comment-bg-box {
					display: block;
					border-top: 1px solid #eeeeee;
					margin-top: 17px;
					button {
						margin-top: 13px;
						font-weight: 300;
						font-size: 14px;
						line-height: 17px;
						color: #000000;
						background-color: #fff;
						border: none;
						position: relative;
						left: 50%;
						transform: translateX(-50%);
					}
				}
				.no-open {
					padding: 70px 0;
					.icon-none {
						margin-top: 0;
					}
				}
			}
			.interest-wrap {
				margin-top: 20px !important;
				.interest-title {
					font-size: 20px !important;
					line-height: 160% !important;
				}
				.interest-bg {
					border-radius: 10px !important;
					padding: 20px 0 14px 20px !important;
					margin-top: 10px !important;
					.none-box {
						padding: 61px 0 96px 0 !important;
					}
				}
				button {
					font-size: 14px;
					line-height: 17px;
					color: #000000;
					margin-top: 15px;
					left: 45%;
				}
			}
		}
		.club-wrap {
			margin-top: 20px;
			flex-wrap: wrap;
			gap: 10px;
			.club-box {
				width: 100% !important;
				background-color: #fff;
				border-radius: 10px !important;
				padding: 30px 20px 14px 20px !important;

				&.group {
					padding: 26px 0 20px 0 !important;
					
					.club-title {
						padding-left: 20px !important;
					}
					.swiper {
						padding: 0 20px;
					}
				}

				.club-title {
					font-size: 20px !important;
					line-height: 25px !important;
				}
				.club-list {
					margin-top: 30px;
					img {
						width: 67px;
					}
					.club-name {
						font-size: 12px;
						line-height: 15px;
					}
				}
				.allmore-line {
					border-top: 1px solid #eeeeee;
					margin-top: 40px;
				}
				button {
					font-size: 14px;
					line-height: 17px;
					color: #000000;
					margin: 13px 0 0 0;
				}
				.no-open {
					padding: 33px 0 53px 0;
				}
			}
		}
	}
	.none-club-wrap {
		position: relative;
		.none-club-box {
			width: 100% !important;
			height: 275px !important;
			border-radius: 10px !important;
			top: -922px !important;
			.no-open {
				padding: 90px 0;
				text-align: center;
				.none-text {
					font-weight: 300;
					font-size: 16px;
					line-height: 20px;
					margin-top: 20px;
					color: #888888;
				}
			}
		}
	}
	.mentee-wrap {
		display: none;
	}
	.group-wrapper {
		min-height: 164px;
		.swiper {
			.swiper-button-prev {
				background-image: url('/images/icon-left.svg');
				background-size: 8px;
				background-repeat: no-repeat;
				background-position: center;
				left: -2px;
				&:after {
					display: none;
				}
			}
			.swiper-button-next {
				background-image: url('/images/icon-left.svg');
				background-size: 8px;
				background-repeat: no-repeat;
				background-position: center;
				transform: rotate(180deg);
				right: -2px;
				&:after {
					display: none;
				}
			}
	}


  .group-card.cop {
    padding: 71px 24px 0 24px;

    p {
			font-size: 12px;
			margin-bottom: 16px;
		}
    .title {
      font-size: 18px;
    }

  } 


	.group-card {
		width: 100%;
		padding: 24px;
		min-height: 164px;
		border-radius: 10px;
	
		p {
			font-size: 16px;
			margin-bottom: 10px;
		}
		.flex {
			position: absolute;
			bottom: 24px;
			left: 24px;
			z-index: 2;
		}
	}
	.mo-mentee-wrap {
		display: block;
		.mentee-top {
			margin-top: 10px;
			background-color: #fff;
			border-radius: 10px;
			padding: 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.manual-box {
				display: flex;
				align-items: center;
				.mentee-title {
					font-weight: 700;
					font-size: 18px;
					line-height: 22px;
					margin-left: 10px;
				}
			}
			.big-arrow {
				svg {
					width: 23px;
					path {
						stroke: #000;
					}
				}
			}
		}
		.mentee-under {
			margin-top: 10px;
			background-color: #fff;
			border-radius: 10px;
			padding: 0;
			.mentor-top {
				padding: 0 20px;
				.mentor-left {
					padding-top: 30px;
					display: flex;
					align-items: center;
					.mentor-top-title {
						font-weight: 700;
						font-size: 20px;
						line-height: 25px;
						color: #111;
						margin-right: 13px;
					}
					.mentor-border {
						font-weight: 500;
						font-size: 12.3px;
						line-height: 15px;
						letter-spacing: 0.02em;
						color: #ffac00;
						border: 1px solid #ffac00;
						padding: 3px 9px;
						border-radius: 81px;
					}
				}
				.mentor-right {
					margin-top: 20px;
					display: flex;
					justify-content: end;
					align-items: center;

					button {
						font-weight: 700;
						font-size: 13px;
						line-height: 16px;
						letter-spacing: -0.02em;
						border: none;
						background-color: transparent;

						.kbds-arrow {
							margin-right: 6px;
							svg {
								position: relative;
								top: 2px;
							}
						}
					}
				}
			}
			.current-wrap {
				margin-top: 10px;
				white-space: nowrap;
				overflow-x: auto;
				&::-webkit-scrollbar {
					display: none;
				}
				.noti-txt-box {
					padding-left: 20px;
					margin-bottom: 11px;
					.noti-txt {
						border-radius: 10px;
						background: rgba(255, 0, 0, 0.04);
						padding: 11px 17px;
						display: inline-flex;
						align-items: flex-start;
						margin-top: 15px;
						color: #f00;
						font-size: 12px;
						font-weight: 500;
						line-height: 160%;
						letter-spacing: 0.24px;

						img {
							position: relative;
							top: 1px;
							width: 16px;
							margin-right: 8px;
						}
						span {
							text-decoration: underline;
						}
					}
				}
				.current-list {
					display: flex;
					gap: 54px;
					justify-content: space-between;
					text-align: center;
					margin-left: -10px;
					li {
						letter-spacing: -0.02em;
						font-size: 12px;
						line-height: 32px;
						position: relative;
						.icon-current {
							svg {
								width: 46px;
								position: relative;
								z-index: 1;
							}
						}
						.round-text {
							font-weight: 500;
							color: #bebebe;
							/* margin-top: 8px; */
						}
						.date {
							font-family: KBFG Display;
							font-weight: 500;
							font-size: 13px;
							letter-spacing: -0.26px;
							margin-top: -4px;
							span {
								color: #888;
							}
						}
						.round-box1 {
							.schedule-text {
								font-weight: 400;
								color: #9f9f9f;
								position: relative;
								top: -4px;
							}
							.complete-text {
								color: #e4c000;
							}
						}
						.complete-box {
							background: rgba(249, 221, 74, 0.12);
							border-radius: 20px;
							/* width: 71px; */
							max-width: 200px;
							width: 100%;
							height: 25px;
							margin: 0 auto;
						}
						&::after {
							position: absolute;
							content: '';
							top: 22px;
							left: 45px;
							width: 140px;
							height: 1px;
							border-top: 2px dashed #bebebe;
						}
						&:last-child::after {
							display: none;
						}
					}
					.on {
						.icon-current {
							svg {
								path {
									fill: #f9dd4a;
								}
							}
						}
						&::after {
							position: absolute;
							content: '';
							top: 22px;
							left: 60px;
							width: 140px;
							height: 1px;
							border-top: 2px solid #f9dd4a;
						}
					}
				}
				.current-list-three {
					display: flex;
					gap: 54px;
					justify-content: space-between;
					text-align: center;
					margin-left: -10px;
					li {
						letter-spacing: -0.02em;
						font-size: 12px;
						line-height: 32px;
						position: relative;
						.icon-current {
							svg {
								width: 46px;
								position: relative;
								z-index: 1;
							}
						}
						.round-text {
							font-weight: 500;
							color: #bebebe;
							/* margin-top: 8px; */
						}
						.date {
							font-family: KBFG Display;
							font-weight: 500;
							font-size: 13px;
							letter-spacing: -0.26px;
							margin-top: -4px;
							span {
								color: #888;
							}
						}
						.round-box1 {
							.schedule-text {
								font-weight: 400;
								color: #9f9f9f;
								position: relative;
								top: -4px;
							}
							.complete-text {
								color: #e4c000;
							}
						}
						.complete-box {
							background: rgba(249, 221, 74, 0.12);
							border-radius: 20px;
							/* width: 71px; */
							max-width: 200px;
							width: 100%;
							height: 25px;
							margin: 0 auto;
						}
						&::after {
							position: absolute;
							content: '';
							top: 22px;
							left: 45px;
							width: 332px;
							height: 1px;
							border-top: 2px dashed #bebebe;
						}
						&:last-child::after {
							display: none;
						}
					}
					.on {
						.icon-current {
							svg {
								path {
									fill: #f9dd4a;
								}
							}
						}
						&::after {
							position: absolute;
							content: '';
							top: 22px;
							left: 60px;
							width: 332px;
							height: 1px;
							border-top: 2px solid #f9dd4a;
						}
					}
				}
			}
			.more-box {
				border-top: 1px solid #eee;
				margin-top: 15px;
				button {
					width: 100%;
					padding: 13px 0;
					font-weight: 300;
					font-size: 14px;
					line-height: 17px;
					background-color: #fff;
					border: none;
				}
			}
			.gradient-box {
				position: relative;
				.bg-l {
					position: absolute;
					z-index: 1;
					top: -220px;
					width: 102px;
					height: 167px;
					background: linear-gradient(
						270deg,
						#ffffff 0%,
						rgba(255, 255, 255, 0) 66.67%
					);
					transform: matrix(-1, 0, 0, 1, 0, 0);
				}
				.bg-r {
					position: absolute;
					z-index: 1;
					top: -220px;
					right: 0;
					width: 102px;
					height: 167px;
					background: linear-gradient(
						270deg,
						#ffffff 0%,
						rgba(255, 255, 255, 0) 66.67%
					);
				}
			}
		}
	}
`;
