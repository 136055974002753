import styled from 'styled-components';

export const Wrapper = styled.div`
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	.overlay {
		width: 100%;
		height: 100%;
		background-color: #00000030;
	}
	.modal-wrapper {
		width: 100%;
		height: 100vh;
		.modal-wrap {
			position: absolute;
			z-index: 999;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			padding: 30px 50px;
			background-color: #fff;
			border-radius: 20px;
			width: 700px;
			height: 690px;
			.icon-close {
				position: absolute;
				right: 40px;
				cursor: pointer;
				svg {
					width: 17px;
				}
			}
			.modal-title {
				margin-top: 40px;
				font-weight: 700;
				font-size: 26px;
				line-height: 32px;
			}
			.modal-box {
				position: relative;
				.modal-list-wrap {
					height: 330px;
					overflow-y: auto;
					&::-webkit-scrollbar {
						display: none;
					}
				}
				.modal-list-wrap2 {
					height: 310px;
					overflow-y: auto;
					&::-webkit-scrollbar {
						display: none;
					}
				}
				.modal-list-box {
					display: flex;
					margin-top: 30px;
					border-top: 1px solid #e9e9e9;
					font-weight: 700;
					font-size: 18px;
					line-height: 22px;
					.moadl-text {
						padding: 30px 104px;
					}
				}
				.modal-list {
					display: flex;
					padding: 26px 40px;
					border-radius: 20px;
					justify-content: space-between;
					background-color: #f7f7f7;
					font-weight: 500;
					font-size: 16px;
					line-height: 20px;
					margin-bottom: 12px;
					width: 600px;
					.date {
						width: 120px;
					}
					.name {
						text-align: center;
						display: flex;
						justify-content: center;
						/* width: 100%; */
						flex: 0 1 336px;
					}
					.icon-remove {
						cursor: pointer;
					}
				}
				.none-box {
					text-align: center;
					padding: 120px 0 149px 0;
					.none-text {
						font-weight: 300;
						font-size: 18px;
						line-height: 22px;
						color: #888888;
						margin-top: 20px;
					}
				}
				.modal-bg {
					display: none;
					width: 600px;
					height: 40px;
					background: linear-gradient(
						180deg,
						#ffffff 0%,
						rgba(255, 255, 255, 0) 100%
					);
					transform: matrix(1, 0, 0, -1, 0, 0);
					position: absolute;
					bottom: 0;
				}
			}
		}
	}
	.btn-wrap {
		display: flex;
		gap: 10px;
		justify-content: end;
		align-items: center;
		position: absolute;
		right: 50px;
		bottom: 50px;
		margin-top: 30px;
		button {
			border: 1px solid #ffbc00;
			border-radius: 20px;
			background-color: #fff;
			padding: 20px 0px;
			font-weight: 700;
			font-size: 18px;
			line-height: 22px;
			color: #ffbc00;
			width: 160px;
			transition: all 0.3s;
			cursor: pointer;
			&:hover {
				opacity: 0.8;
			}
		}
		.yellow-btn {
			background-color: #ffbc00;
			color: #fff;
			.icon-plus {
				margin-right: 7px;
			}
		}
	}
	@media ${(props) => props.theme.mobile} {
		.modal-wrapper {
			width: 100%;
			height: 100vh;
			.modal-wrap {
				padding: 30px 20px;
				border-radius: 10px;
				width: 300px;
				height: 520px;
				.icon-close {
					display: none;
				}
				.modal-title {
					font-size: 20px;
					line-height: 25px;
					margin-top: 0;
				}
				.modal-box {
					.modal-list-box {
						margin-top: 10px;
						font-size: 14px;
						line-height: 17px;
						.moadl-text {
							padding: 20px 0 20px 53px;
						}
					}
					.modal-list {
						padding: 15px 20px;
						font-size: 12px;
						line-height: 15px;
						margin-bottom: 8px;
						width: 260px;
						.name {
							margin-left: 16px;
							max-width: 124px;
							width: 100%;
						}
					}
					.none-box {
						text-align: center;
						padding: 80px 0 84px 0;
						.none-text {
							font-size: 16px;
							line-height: 20px;
						}
					}
					.modal-bg {
						width: 260px;
					}
				}
			}
		}
		.btn-wrap {
			margin-top: 60px;
			width: 100%;
			.arrow-wrap {
				display: none;
			}
			button {
				border-radius: 14px;
				padding: 14px 64px;
				font-size: 12px;
				line-height: 15px;
				right: 20px;
				bottom: 20px;
			}
			.close-btn {
				width: 100%;
				box-sizing: border-box;
			}
		}
		.mo-btn-wrap {
			left: 0;
			padding: 0 20px;
		}
		.btn-wrap {
			bottom: 20px;
			right: 20px;
			.arrow-wrap {
				display: none;
			}
			button {
				border-radius: 14px;
				padding: 14px 0px;
				font-size: 12px;
				line-height: 15px;
				width: 126px;
				.icon-plus {
					svg {
						width: 10px;
						position: relative;
						top: 2px;
					}
				}
			}
		}
	}
`;
