import styled from 'styled-components';

export const Path = styled.div`
    font-family: KBFG Text;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #FFAC00;
    margin-top: 74px;
    span{
        color: #999999;
        :after{
            content: ">";
            margin: 0 14px;
        }
    }
`;

export const Title = styled.h3`
    text-align: center;
    font-family: KBFG Display;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    color: #000000;
    margin-top: 63px;
    margin-bottom: 47px;
    span{
        color: #FFBC00;
    }
`;

export const TagCount = styled.div`
    font-family: KBFG Display;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #888888;
    margin-bottom: 113px;
    span{
        font-family: KBFG Display;
        color: #FFBC00;
    }
`;