import styled from 'styled-components';

export const PopupWrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9990;
    ${props => {
        if(props.show) {
            return `
                display: block;
            `
        } else {
            return `
                display: none;
            `
        }
    }}
    
`;

export const Overlay = styled.div`
    width: 100%;
    height: 100%;
	background-color: ${props => props.bgColor ? props.bgColor : `rgba(0, 0, 0, 0.4)`};
    /* background: rgba(0,0,0,0.4); */
    z-index: 50;
    position: absolute;
    top: 0;
    left: 0;
    ${props => {
        if(props.show) {
            return `
                display: block;
            `
        } else {
            return `
                display: none;
            `
        }
    }}
`;

export const PopupInner = styled.div`
    max-width: 348px;
    width: ${props => props.width ? props.width : `100%`};
    height: ${props => props.height ? props.height : `450px`};
    position: relative;
    z-index: 100;
    top: ${props => props.top ? props.top : `50%`};
    left: ${props => props.left ? props.left : `50%`};
    transform: translateX(-50%) translateY(-50%);
    background: #fff;
    border-radius: ${props => props.radius ? props.radius : `30px`};
    overflow-y: auto;
	@media ${props => props.theme.tablet}{
		max-width: 730px;
	}
    @media ${props => props.theme.mobile}{
		max-width: 405px;
        width: calc(100% - 40px);
        height: 450px;
        border-radius: 20px;
        ${props => props.full ? `
            max-width: 100vw;
            width: 100vw;
            height: 100%;
            border-radius: 0;
        ` : ``}
        ${props => props.middle ? `
			overflow-y: hidden;
       		height: 500px;
        `: ``}
		${props => props.small ? `
			height: 450px;
		` : ``}
    }
    ${props => props.full ? `
        max-width: 100vw;
        width: 100vw;
        height: 100%;
        border-radius: 0;
    ` : ``}
`;

export const PopupClose = styled.div`
    position:relative;
    z-index: 100;
    cursor: pointer;
    .icon-close{
        position: absolute;
        top: 17px;
        right: 17px;
        color: #111;
    }
    ${props => props.full ? `
        display: none;
    ` : ``}
    @media ${props => props.theme.mobile}{
        display: none;
    }
`;


export const PopupButton = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	a{
		width: 50%;
		cursor: pointer;
	}
	a:nth-of-type(1){
		background-color: #898989;
		color: #fff;
	}
	svg{
		margin-right: 5px;
		vertical-align: sub;
	}
    ${props => props.btn ? `` : `
        display: none;
    `}
	.button{
		a{
			width: 100%;
			background-color: #6a36ff;
		}
	}
    
    @media ${props => props.theme.mobile}{
        a{
            font-size: 14px;
            height: 50px;
            line-height: 50px;
        }
    }
`;