export default function ManualIcon() {
  return (
    <>
      <svg
        width="60"
        height="68"
        viewBox="0 0 60 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M58 2V66H10C7.87827 66 5.84344 65.1571 4.34315 63.6569C2.84285 62.1566 2 60.1217 2 58V10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2H58Z"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M58 50H10C7.87827 50 5.84344 50.8429 4.34315 52.3431C2.84285 53.8434 2 55.8783 2 58M18 18H42"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
}
