export default function HubOpen() {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="52"
        y="52"
        width="52"
        height="52"
        rx="26"
        transform="rotate(-180 52 52)"
        fill="white"
      />
      <path
        d="M17 19.2499H33V35.2499M18.3605 33.4385L32.799 19"
        stroke="black"
        stroke-width="2"
      />
    </svg>
  );
}
