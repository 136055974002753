export default function CloseBtnIcon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.47217 6.47266L19.4162 19.4167" stroke="#E9E9E9" />
      <path d="M19.4165 6.47266L6.47248 19.4167" stroke="#E9E9E9" />
    </svg>
  );
}
