import styled from 'styled-components';
import QuestionIcon from '../../../vector/QuestionMark';

const BoxWrapper = styled.div`
  justify-content: space-between;
  margin: auto;
  border-radius: 28px;
  display: flex;
  width: 100%;
  max-width: 1400px;
  margin: 30px auto;
  @media (max-width: 768px) {
    display: block;
  }
  .see-all {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 0;
  }

  .box {
    border-radius: 28px;
    padding: 32px;
    padding-bottom: 24px;
    width: 33%;
    background-color: white;
    text-align: center;
    padding-bottom: 0;
    @media (max-width: 768px) {
      border-radius: 10px;
    }
    :not(:first-child):not(:last-child) {
      margin-left: 30px;
      margin-right: 30px;
    }
    @media (max-width: 768px) {
      :not(:first-child):not(:last-child) {
        margin-left: 0px;
        margin-right: 0px;
      }
      margin-top: 10px;

      width: 100%;
    }
    .no-info {
      margin-bottom: 14px;
      background-image: url('/dummy-clubs.png');
      background-size: cover;
      span {
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        color: #696969;
        display: block;
      }
    }
    span:last-child {
      margin: 14px 0;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
    }
    .title {
      text-align: left;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      margin: 8px 0;
      margin-bottom: 24px;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
    .clubs {
      display: flex;
      justify-content: space-between;
      text-align: center;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 12px;
      margin-bottom: 12px;

      .club {
        span {
          display: block;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #777777;
        }
      }
    }
  }
`;
export default function Clubs() {
  return (
    <>
      <BoxWrapper>
        <div className="box">
          <h1 className="title">동호회</h1>
          <div className="no-info">
            <QuestionIcon />
            <span>오픈 준비중입니다.</span>
          </div>

          <button className="see-all">모두 보기</button>
        </div>

        <div className="box">
          <h1 className="title">COP</h1>
          <div className="no-info">
            <QuestionIcon />
            <span>오픈 준비중입니다.</span>
          </div>
          <button className="see-all">모두 보기</button>
        </div>

        <div className="box">
          <h1 className="title">소모임</h1>
          <div className="no-info">
            <QuestionIcon />
            <span>오픈 준비중입니다.</span>
          </div>
          <button className="see-all">모두 보기</button>
        </div>
      </BoxWrapper>
    </>
  );
}
