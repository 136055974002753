import styled from 'styled-components';

export const PostSwiperWrapper = styled.section`
	.writing-card {
		padding: 30px;
		color: #fff;
		position: relative;
		overflow: hidden;
		cursor: pointer;
		transition: all 0.3s;
		&:hover {
			opacity: 0.8;
		}
		:after {
			display: block;
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba(0, 0, 0, 0.3);
			z-index: 1;
		}
		.more-btn {
			position: absolute;
			cursor: pointer;
			padding: 10px 20px;
			transform: translate(20px, -10px);
			z-index: 40;
			top: 30px;
			right: 30px;
			:hover {
				& + .crystal-box {
					display: block;
				}
			}
			li {
				width: 3px;
				height: 3px;
				background-color: #fff;
				border-radius: 50%;
				margin-top: 3px;
				&:first-child {
					margin-top: 0;
				}
			}
		}
		.crystal-box {
			position: absolute;
			top: 30px;
			right: 30px;
			z-index: 30;
			/* border: 2px solid blue; */
			display: none;
			:hover {
				display: block;
			}
			.btn-list {
				background-color: #fff;
				border-radius: 20px;
				width: auto;
				height: auto;
				padding: 8px 6px;
				li {
					&:first-child {
						/* padding-top: 6px; */
					}
					.crystal-btn {
						padding: 12px 20px;
						margin-top: 0 !important;
						border-radius: 16px;
						font-weight: 500 !important;
						font-size: 12px !important;
						line-height: 15px !important;
						:hover {
							background-color: #f7f7f7;
							color: #ff0000;
						}
					}
				}
			}
		}
	}

	.dim {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
	}
	.tns {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 30;
	}
	.post-card {
		padding: 30px;
		color: #fff;
		position: relative;
		overflow: hidden;
		cursor: pointer;
		:after {
			display: block;
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba(0, 0, 0, 0.3);
			z-index: 1;
		}

		.more-btn {
			position: absolute;
			cursor: pointer;
			padding: 10px 20px;
			transform: translate(20px, -10px);
			z-index: 40;
			top: 30px;
			right: 30px;
			:hover {
				& + .crystal-box {
					display: block;
				}
			}
			li {
				width: 3px;
				height: 3px;
				background-color: #fff;
				border-radius: 50%;
				margin-top: 3px;
				&:first-child {
					margin-top: 0;
				}
			}
		}
	}
	.crystal-box {
		position: absolute;
		top: 30px;
		right: 30px;
		z-index: 30;
		/* border: 2px solid blue; */
		display: none;
		:hover {
			display: block;
		}
		.btn-list {
			background-color: #fff;
			border-radius: 20px;
			width: auto;
			height: auto;
			padding: 8px 6px;
			li {
				:hover {
					.crystal-btn {
						background-color: #f7f7f7;
						color: #ff0000;
					}
				}
				&:first-child {
					/* padding-top: 6px; */
				}
				.crystal-btn {
					padding: 12px 20px;
					margin-top: 0 !important;
					border-radius: 16px;
					font-weight: 500 !important;
					font-size: 12px !important;
					line-height: 15px !important;
				}
			}
		}
	}
	.post-text-card {
		position: relative;
		z-index: 20;
		.top-btn {
			display: flex;
			justify-content: space-between;
			.categories {
				font-weight: 500;
				font-size: 12px;
				line-height: 24px;
				background: rgba(255, 255, 255, 0.4);
				display: inline-block;
				padding: 2px 10px;
			}
		}
		.post-card-title {
			font-weight: 700;
			font-size: 19px;
			line-height: 27px;
			letter-spacing: 0.02em;
			margin-top: 20px;
		}
		.date {
			font-weight: 500;
			font-size: 12px;
			line-height: 27px;
			letter-spacing: 0.02em;
			color: rgba(255, 255, 255, 0.7);
			margin-top: 2px;
		}
	}
	.tag-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: absolute;
		/* max-width: 216px; */
		width: 100%;
		bottom: 16px;
		padding-right: 60px;
		z-index: 5;
		.tag-list {
			li {
				font-weight: 500;
				font-size: 12px;
				line-height: 24px;
			}
		}
		img {
			width: 45px;
			height: 45px;
			border-radius: 50%;
		}
	}
	.post-bg {
		width: 100px;
		height: 274px;
		position: absolute;
		z-index: 1;
		top: 0;
		right: -1px;
		background: linear-gradient(
			270deg,
			#ffffff 0%,
			rgba(255, 255, 255, 0) 100%
		);
	}
	@media ${(props) => props.theme.mobile} {
		.writing-card {
			height: 250px !important;
		}
		.post-card {
			padding: 20px;
			height: 250px !important;
		}
		.post-text-card {
			.post-card-title {
				font-size: 16px;
				line-height: 160%;
				margin-top: 30px;
			}
			.date {
				font-size: 12px;
				line-height: 27px;
			}
		}
		.tag-box {
			max-width: 210px;
			bottom: 20px;
			.tag-list {
				.ing-tag {
					display: none;
				}
			}
			img {
				width: 40px;
				height: 40px;
			}
		}
		.post-bg {
			width: 60px;
			height: 274px;
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			background: linear-gradient(
				270deg,
				#ffffff 0%,
				rgba(255, 255, 255, 0) 100%
			);
		}
	}
`;
