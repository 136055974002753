export default function BookIcon() {
  return (
    <>
      <svg
        width="61"
        height="69"
        viewBox="0 0 61 69"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M59 2.49951V66.8329H10.7499C8.61714 66.8329 6.57171 65.9857 5.0636 64.4776C3.5555 62.9695 2.70825 60.924 2.70825 58.7913V10.5412C2.70825 8.4084 3.5555 6.36297 5.0636 4.85486C6.57171 3.34676 8.61714 2.49951 10.7499 2.49951H59Z"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M59 50.7497H10.7499C8.61714 50.7497 6.57171 51.597 5.0636 53.1051C3.5555 54.6132 2.70825 56.6586 2.70825 58.7914M18.7916 18.583H42.9166"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
}
