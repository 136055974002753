import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GetMenteeInfo, GetUserPosts } from '../../../api/story';
import { GetUserOther } from '../../../api/user';
import HubModal from '../../../components/Popup/CommunicationHub';
import FaqIcon from '../../../vector/FaqIcon';
import HubClose from '../../../vector/HubClose';
import HubOpen from '../../../vector/HubOpen';
import LinkBtn from '../../../vector/LinkSquare';
import MailIcon from '../../../vector/MailIcon';
import PhoneIcon from '../../../vector/PhoneIcon';
import RightArrow from '../../../vector/RightArrow';
import { MenteeListWrapper, TopSectionWrapper } from '../styled';
import Currentcon from '../../../vector/Current';
import ArrowRightTop from '../../../vector/ArrowRightTop';
import ManualArrowIcon from '../../../vector/ManualArrow';
import ManualIcon from '../../../vector/Manual';
import Mokbdsarrow from '../../../vector/MoKbsdArrow';
import MoManual from '../../../vector/MoManual';
import BigArrow from '../../../vector/BigArrow';

export default function ProfilePageTopSection() {
	const [hubModal, setHubModal] = useState(false);
	const [menteeInfo, setMenteeInfo] = useState();
	const [show, setShow] = useState(false);
	const [otherUserInfo, setOtherUserInfo] = useState();
	const [posts, setPosts] = useState([]);
	const [loading, setLoading] = useState(true);
	const user = useSelector((state) => state.auth.user_data);
	const params = useParams();
	useEffect(() => {
		GetUserOther({
			user_id: params.id,
		})
			.then((res) => setOtherUserInfo(res.data.body))
			.catch((err) => console.log(err));
	}, [params.id]);

	useEffect(() => {
		GetUserPosts({
			order: 'DESC',
			orderby: 'ID',
			posts_per_page: '12',
			post_author: params.id,
			paged: 1,
		})
			.then((res) => {
				setPosts(res.data.body.posts);
			})
			.catch((err) => console.log('포스트 정보 오류', err))
			.finally(() => setLoading(false));
	}, [params.id]);

	useEffect(() => {
		GetMenteeInfo().then((res) => {
			setMenteeInfo(res.data.body?.result);
		});
	}, []);

	return (
		<>
			<TopSectionWrapper>
				<div className="profile">
					<div className="person-info">
						<div className="avatar">
							<img
								src={
									otherUserInfo?.user_avatar_url
										? otherUserInfo?.user_avatar_url
										: 'https://secure.gravatar.com/avatar/?s=96&d=mm&r=g'
								}
								alt=""
							/>
						</div>
						{otherUserInfo?.user_role === 'delete' ? null : (
							<div className="user-data">
								<span className="department">
									{otherUserInfo?.user_department}
								</span>
								<span className="name">
									<strong>{otherUserInfo?.user_name}</strong>
								</span>
								<span className="user-description">
									{otherUserInfo?.description}
								</span>
							</div>
						)}
					</div>
					{otherUserInfo?.ID === '1' ||
					otherUserInfo?.user_role === 'delete' ? null : (
						<div className="contact_box">
							<div className="contact-info">
								{loading ? null : (
									<div className="contact">
										<div className="contact-detail">
											<i>
												<MailIcon />
											</i>
											<span>
												<a href={`mailto:${otherUserInfo?.user_email}`}>
													{otherUserInfo?.user_email
														? otherUserInfo?.user_email
														: '등록된 메일이 없습니다.'}
												</a>
											</span>
										</div>

										<div className="contact-detail">
											<i>
												<PhoneIcon />
											</i>
											<span>
												<a href={`tel:${otherUserInfo?.user_phone}`}>
													{otherUserInfo?.user_phone
														? otherUserInfo?.user_phone
														: '등록된 번호가 없습니다.'}
												</a>
											</span>
										</div>
									</div>
								)}
							</div>
						</div>
					)}
				</div>
				<div className={show ? 'right hidden' : 'right'}>
					<h1>커뮤니케이션 HUB</h1>
					<span>KB데이터시스템의 다양한 채널들을 소개합니다.</span>
					<button className="hub-btn" onClick={() => setShow((p) => !p)}>
						<i className="right-arr">
							<HubOpen />
						</i>
					</button>
					<button className="link-btn">
						<LinkBtn />
					</button>
				</div>
				<div className={show ? 'dnjdlek show' : 'dnjdlek'}>
					<div className="modal-head">
						<h1>
							커뮤니케이션 HUB
							<button
								className="hub-btn-dnjdlek"
								onClick={() => setShow((p) => !p)}
							>
								<HubClose />
							</button>
						</h1>
						<span>KB데이터시스템의 다양한 채널들을 소개합니다.</span>
					</div>
					<HubModal disabled={!show} />
				</div>
			</TopSectionWrapper>

			<TopSectionWrapper>
				<div className="profile-info">
					<div className="info-detail">
						<span>누적 포인트</span>
						<span>{otherUserInfo ? otherUserInfo?.user_point_total : ''}p</span>
						<div className="border" />
					</div>
					<div className="info-detail">
						<span>현재 포인트</span>
						<span>{otherUserInfo ? otherUserInfo?.user_point : ''}p</span>
						<span>
							(누: {otherUserInfo ? otherUserInfo?.user_point_total : ''}p)
						</span>
						<div className="border" />
					</div>
					<div className="info-detail">
						<span>봉사시간</span>
						<span>{`${
							otherUserInfo ? otherUserInfo?.volunteerTime : ''
						}시간`}</span>
						<div className="border" />
					</div>
					<div className="info-detail">
						<span>작성 포스트</span>
						<span>{`${posts ? posts.length : ''}개`}</span>
						<div className="border" />
					</div>
					<div className="info-detail">
						<span>관심 포스트</span>
						<span>{`${
							otherUserInfo ? otherUserInfo?.interestPost?.length : ''
						}개`}</span>
					</div>
				</div>

				<div
					onClick={() =>
						window.open(
							'https://padlet.com/kbdsstarters/padlet-kcsy6xfx48npdldb'
						)
					}
					className="right faq"
				>
					<span>
						<i>
							<FaqIcon />
						</i>
						FAQ
					</span>
					<i className="rightarr-btn">
						<RightArrow />
					</i>
				</div>
			</TopSectionWrapper>
			<MenteeListWrapper>
				{/* <div className="mentee-wrap">
					<div className="mentee-left">
						<div className="mentor-top">
							<div className="mentor-left">
								<h1 className="mentor-top-title">멘토링 현황</h1>
								<div className="mentor-border">멘티</div>
							</div>
							<div className="mentor-right">
								<button>
									<i className="kbds-arrow">
										<ArrowRightTop />
									</i>
									패들릿으로 가기
								</button>
							</div>
						</div>
						<div className="current-wrap">
							<div className="noti-txt">
								<img src="../images/icon-park-outline_attention.svg" alt="" />
								<p>
									멘토링 활동이 저조합니다. 멘토링 활동은
									<span>1개월 1회 이상</span>
									권장합니다.
								</p>
							</div> */}
				{/* <ul className="current-list">
										<li className="on">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">1회차</p>
											<div className="round-box1 complete-box">
												<p className="schedule-text complete-text">complete</p>
											</div>
										</li>
										<li className="on">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">2회차</p>
											<div className="round-box1 complete-box">
												<p className="schedule-text complete-text">complete</p>
											</div>
										</li>
										<li>
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">3회차</p>
											<div className="round-box1">
												<p className="schedule-text">예정</p>
											</div>
										</li>
										<li className="">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">4회차</p>
											<div className="round-box1">
												<p className="schedule-text">예정</p>
											</div>
										</li>
										<li className="">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">5회차</p>
											<div className="round-box1">
												<p className="schedule-text">예정</p>
											</div>
										</li>
									</ul> */}
				{/* <ul className="current-list-three">
								<li className="on">
									<i className="icon-current">
										<Currentcon />
									</i>
									<p className="round-text">1회차</p>
									<div className="round-box1 complete-box">
										<p className="schedule-text complete-text">complete</p>
									</div>
								</li>
								<li className="on">
									<i className="icon-current">
										<Currentcon />
									</i>
									<p className="round-text">2회차</p>
									<div className="round-box1 complete-box">
										<p className="schedule-text complete-text">complete</p>
									</div>
								</li>
								<li>
									<i className="icon-current">
										<Currentcon />
									</i>
									<p className="round-text">3회차</p>
									<div className="round-box1">
										<p className="schedule-text">예정</p>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div className="mentee-right">
						<h1 className="mentee-title">신규입사자 매뉴얼</h1>
						<p className="mentee-text">KBDS의 다양한 채널들을 소개합니다.</p>

						<div className="manual-icon-box">
							<div className="manual-arrow-box">
								<i className="manual-arrow">
									<ManualArrowIcon />
								</i>
							</div>
							<i className="manual-icon">
								<ManualIcon />
							</i>
						</div>
					</div>
				</div> */}
				{/* 모바일 */}
				{/* <div className="mo-mentee-wrap">
					<div className="mentee-top">
						<div className="manual-box">
							<i className="mo-manual-icon">
								<MoManual />
							</i>
							<h1 className="mentee-title">신규입사자 매뉴얼</h1>
						</div>
						<i className="big-arrow">
							<BigArrow />
						</i>
					</div>
					<div className="mentee-under">
						<div className="mentor-top">
							<div className="mentor-left">
								<h1 className="mentor-top-title">멘토링 현황</h1>
								<div className="mentor-border">멘티</div>
							</div>
							<div className="mentor-right">
								<button>
									<i className="kbds-arrow">
										<Mokbdsarrow />
									</i>
									패들릿으로 가기
								</button>
							</div>
						</div>
						<div className="current-wrap">
							<div className="noti-txt-box">
								<div className="noti-txt">
									<img src="../images/icon-park-outline_attention.svg" alt="" />
									<p>
										멘토링 활동이 저조합니다.
										<br /> 멘토링 활동은
										<span>1개월 1회 이상</span>권장합니다.
									</p>
								</div>
							</div> */}
				{/* <ul className="current-list">
										<li className="on">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">1회차</p>
											<p className="date">
												2022.01.14 <span>(수)</span>
											</p>
											<div className="round-box1 complete-box">
												<p className="schedule-text complete-text">complete</p>
											</div>
										</li>
										<li className="on">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">2회차</p>
											<p className="date">
												2022.03.14 <span>(수)</span>
											</p>
											<div className="round-box1 complete-box">
												<p className="schedule-text complete-text">complete</p>
											</div>
										</li>
										<li>
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">3회차</p>
											<div className="round-box1">
												<p className="schedule-text">예정</p>
											</div>
										</li>
										<li className="">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">4회차</p>
											<div className="round-box1">
												<p className="schedule-text">예정</p>
											</div>
										</li>
										<li className="">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">5회차</p>
											<div className="round-box1">
												<p className="schedule-text">예정</p>
											</div>
										</li>
									</ul> */}
				{/* <ul className="current-list-three">
								<li className="on">
									<i className="icon-current">
										<Currentcon />
									</i>
									<p className="round-text">1회차</p>
									<p className="date">
										2022.01.14 <span>(수)</span>
									</p>
									<div className="round-box1 complete-box">
										<p className="schedule-text complete-text">complete</p>
									</div>
								</li>
								<li className="on">
									<i className="icon-current">
										<Currentcon />
									</i>
									<p className="round-text">2회차</p>
									<p className="date">
										2022.03.14 <span>(수)</span>
									</p>
									<div className="round-box1 complete-box">
										<p className="schedule-text complete-text">complete</p>
									</div>
								</li>
								<li>
									<i className="icon-current">
										<Currentcon />
									</i>
									<p className="round-text">3회차</p>
									<div className="round-box1">
										<p className="schedule-text">예정</p>
									</div>
								</li>
							</ul>
						</div>
						<div className="more-box">
							<button>모두 보기</button>
						</div>
						<div className="gradient-box">
							<div className="bg-l"></div>
							<div className="bg-r"></div>
						</div>
					</div>
				</div> */}
			</MenteeListWrapper>
		</>
	);
}
