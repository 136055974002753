import axios from './defaultClient';

const nameSpace = '/ep/v1/daitso/story';
export const GetPosts = async (args) => {
	return await axios.get(nameSpace + '/', {
		params: {
			order: args.order,
			orderby: args.orderby,
			cate: args.cate ? args.cate : '',
			category_name: args.category_name ? args.category_name : '',
			posts_per_page: args.posts_per_page,
			paged: args.paged,
		},
	});
};
export const SearchGetPosts = async (args) => {
	return await axios.get(nameSpace + '/', {
		params: {
			s: args.keyword,
			posts_per_page: args.posts_per_page,
			paged: args.paged,
		},
	});
};
export const insertPost = async (args) => {
	return await axios.post(nameSpace, args, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
};
export const updateStory = async (args) => {
	return await axios.post(nameSpace + '/patch', args, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
};
export const SearchGetUsers = async (args) => {
	return await axios.get('/ep/v1/users/search/', {
		params: {
			keyword: args.keyword,
		},
	});
};
export const TagGetPosts = async (args) => {
	return await axios.get(nameSpace + '/', {
		params: {
			tag: args.tag_slug,
			posts_per_page: args.posts_per_page,
			paged: args.paged,
		},
	});
};
export const GetRelatedPosts = async (args) => {
	return await axios.get(nameSpace + '/related', {
		params: {
			post_id: args.post_id,
			posts_per_page: args.posts_per_page,
			paged: args.paged,
		},
	});
};
export const GetUserRelatedPosts = async (args) => {
	return await axios.get(nameSpace + '/user/related', {
		params: {
			post_id: args.post_id,
			post_author: args.post_author,
			posts_per_page: args.posts_per_page,
			paged: args.paged,
		},
	});
};
export const GetUserPosts = async (args) => {
	return await axios.get(nameSpace + '/user', {
		params: {
			post_author: args.post_author,
			posts_per_page: args.posts_per_page,
			paged: args.paged,
		},
	});
};
export const GetLivePosts = async (args) => {
	return await axios.get(nameSpace + '/live', {
		params: {
			post_author: args.post_author,
			posts_per_page: args.posts_per_page,
			paged: args.paged,
		},
	});
};
export const GetLikePosts = async (args) => {
	return await axios.get(nameSpace + '/like', {
		params: {
			post_author: args.post_author,
			posts_per_page: args.posts_per_page,
			paged: args.paged,
		},
	});
};
export const GetPost = async (args) => {
	return await axios.get(nameSpace + '/' + args.post_id, {});
};

export const GetComments = async (args) => {
	return await axios.get(nameSpace + '/comments/' + args.post_id, {});
};
export const InsertComment = async (args) => {
	return await axios.post(nameSpace + '/comments/', {
		post_id: args.post_id,
		content: args.content,
		reply_user_id: args.reply_user_id,
	});
};
export const InsertLiked = async (args) => {
	return await axios.post(nameSpace + '/liked/' + args.post_id, args);
};
export const DeleteComment = async (args) => {
	return await axios.delete(nameSpace + '/comments/', {
		params: {
			comment_ID: args.comment_ID,
		},
	});
};

export const DeletePost = async (args) => {
	return await axios.delete(nameSpace + '/', {
		params: {
			post_id: args.post_id,
		},
	});
};

export const GetMenteeInfo = async () => {
	return await axios.get('/ep/v1/daitso/mentorings/me');
};

export const GetMyMenteeList = async () => {
	return await axios.get('/ep/v1/daitso/mentorings/mentee');
};

export const GetEvents = async (args) => {
	return await axios.get('/ep/v1/daitso/events', {
		params: args,
	});
};

export const GetGroups = async (args) => {
	return await axios.get('/ep/v1/small_groups', {
		params: args,
	});
};

export const GetGroup = async (args) => {
	return await axios.get('/ep/v1/small_groups/' + args.ID, {
		params: args,
	});
};

export const GetGroupPosts = async (args) => {
	return await axios.get('/ep/v1/small_groups/posts/' + args.ID, {
		params: args,
	});
};
