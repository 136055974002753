import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SearchGetUsers } from '../../api/story';
import MailIcon from '../../vector/MailIcon';
import MobileMailIcon from '../../vector/MobileMailIcon';
import MobilePhoneIcon from '../../vector/MobilePhoneIcon';
import PhoneIcon from '../../vector/PhoneIcon';
import { MediaQuery, mobileQuery } from '../Grid';
import { ProfileCardWrapper } from './styled';

export default function ProfileCard() {
  const isMobile = MediaQuery(mobileQuery);
  const params = useParams();
  const [usersInfo, setUsersInfo] = useState([]);
  useEffect(() => {
    SearchGetUsers({
      keyword: params.keyword,
    }).then((res) => setUsersInfo(res.data.body.result));
  }, [params.keyword]);

  const goToProfile = (user) => {
    if (user.user_role === 'delete') {
      alert('퇴직자입니다.');
      return;
    } else {
      window.location.href = `/${user.ID}`;
    }
  };

  return (
    <>
      {usersInfo.map((user, idx) => (
        <ProfileCardWrapper
          onClick={() => goToProfile(user)}
          key={`user-${idx}`}
        >
          <div className="user">
            <img
              className="user-thumbnail"
              src={
                user.user_avatar_url
                  ? user.user_avatar_url
                  : 'https://secure.gravatar.com/avatar/?s=96&d=mm&r=g'
              }
              alt=""
            />
            <div className="user-info">
              <span className="department">{user.user_department}</span>
              <span className="name">{user.user_name}</span>
              <span className="description">{user.description}</span>
            </div>
          </div>
          <div className="user-contact">
            <div>
              <i className="mail-icon">
                {isMobile ? <MobileMailIcon /> : <MailIcon />}
              </i>
              <span>
                {user.user_email ? user.user_email : '등록된 메일이 없습니다.'}
              </span>
            </div>
            <div>
              <i className="phone-icon">
                {isMobile ? <MobilePhoneIcon /> : <PhoneIcon />}
              </i>
              <span>
                {user.user_phone ? user.user_phone : '등록된 번호가 없습니다.'}
              </span>
            </div>
          </div>
        </ProfileCardWrapper>
      ))}
    </>
  );
}
