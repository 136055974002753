import styled from 'styled-components';

export const Wrapper = styled.div`
	position: fixed;
	z-index: 100111;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	.overlay {
		width: 100%;
		height: 100%;
		background-color: #00000030;
	}
	.modal-wrapper {
		width: 100%;
		height: 100vh;
		.modal-wrap {
			position: absolute;
			z-index: 999;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: #fff;
			width: 700px;
			height: 690px;
			.icon-back {
				position: relative;
				top: 20px;
				left: 30px;
				cursor: pointer;
			}
			.modal-title {
				margin-top: 40px;
				font-weight: 700;
				font-size: 26px;
				line-height: 32px;
			}
			.modal-box {
				position: relative;
				.modal-list-wrap {
					height: 330px;
					overflow-y: auto;
					&::-webkit-scrollbar {
						display: none;
					}
				}
				.modal-list-wrap2 {
					height: 310px;
					overflow-y: auto;
					&::-webkit-scrollbar {
						display: none;
					}
				}
				.modal-list-box {
					display: flex;
					margin-top: 30px;
					border-top: 1px solid #e9e9e9;
					font-weight: 700;
					font-size: 18px;
					line-height: 22px;
					.moadl-text {
						padding: 30px 104px;
					}
				}
			}
		}
	}

	@media ${(props) => props.theme.mobile} {
		.modal-wrapper {
			width: 100%;
			height: 100vh;
			.modal-wrap {
				width: 100%;
				height: calc(100vh - 97px);
				top: 0;
				left: 0;
				transform: unset;
				transition: all 0.3s;
				.icon-back {
					position: relative;
					top: 20px;
					left: 30px;
					cursor: pointer;
				}
				.user {
					width: 100%;
					height: 0;
					padding-bottom: 100%;
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
					margin-top: 37px;
				}
			}
			.toggle {
				padding: 30px;
				position: relative;
				z-index: 10;
				transform: translateY(-20px);
				transition: all 0.3s;
				border-radius: 22px;
				border: 1px solid #eee;
				background: #fff;
				height: auto;
				&.open {
					transform: translateY(-148px);
					transition: all 0.3s;
					height: auto;
				}

				.content.open {
					.flex.t {
						height: 120px;
						overflow: hidden;
					}
				}
				.content {
					padding-bottom: 20px;
					.flex.t {
						height: 0;
						overflow: hidden;
						transition: 0.3s;
					}
					.flex {
						display: flex;
						justify-content: center;
						gap: 76px;
						text-align: center;
						p {
							color: #666;
							margin-top: 10px;
							font-size: 13px;
							font-weight: 400;
							line-height: normal;
							text-decoration: none;
						}
					}
					.line {
						border-top: 1.5px solid #fbfbfb;
						margin: 20px -30px 0 -30px;
					}
				}

				.toggle-btn2 {
					position: absolute;
					top: -20px;
					left: 50%;
					transform: translateX(-50%);
				}
				.flex {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.left {
						display: flex;
						align-items: end;
						gap: 10px;
						h1 {
							font-size: 24px;
							font-weight: 700;
							letter-spacing: 0.48px;
						}
						span {
							font-size: 13px;
							font-weight: 500;
						}
					}
					.right {
						color: #ffbc00;
						font-size: 12px;
						font-weight: 500;
						padding: 5px 10px;
						border-radius: 8px;
						background: rgba(255, 188, 0, 0.12);
					}
				}

				.info {
					color: #999;
					font-size: 14px;
					font-weight: 600;
					letter-spacing: 0.56px;
					img {
						margin-right: 8px;
					}
				}
				.phone {
					margin-top: 14px;
				}
				.mail {
					margin-top: 11px;
				}
			}
			.footer {
				background-color: #000;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 100%;
				height: 97px;
				.logo-box {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 97px;
					position: relative;
					top: 8px;
					img {
						width: 140px;
					}
				}
			}
		}
	}
	.qr-box {
		.overlay {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			z-index: 100;
			background: rgba(0, 0, 0, 0.8);
		}
		.qrcode {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 100;
			width: 186px;
			height: 283px;
			.qr {
				border-radius: 12px;
				background: #fff6c9;
				width: 186px;
				height: 213px;
				padding: 15px;
				p {
					color: #000;
					font-size: 14px;
					font-weight: 600;
					letter-spacing: 0.28px;
					margin-bottom: 10px;
				}
				canvas {
					border-radius: 6px;
					background: #fff;
					padding: 8px;
					width: 156px !important;
					height: 156px !important;
					background-size: 140px;
					background-repeat: no-repeat;
					background-position: center;
				}
			}
			.close {
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 50px;
				height: 50px;
				border-radius: 80px;
				border: 1px solid #eee;
				background: #f7f7f7;
				box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.08);
				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
	}
`;
