import { useState } from "react";
import ModalClose from "../../../../../vector/ModalClose";
import ModalPrev from "../../../../../vector/ModalPrev";
import { SliderWrapper } from "./styled";
import RemoveIcon from "../../../../../vector/RemoveIcon";

export default function Viewall({ viewallToggle }) {
  const [hide, setHide] = useState(true);

  const selectHide = () => {
    setHide((p) => !p);
  };

  return (
    <SliderWrapper>
      <section className="modal-wrapper">
        <div className="overlay" onClick={viewallToggle}></div>
        <div className="modal-wrap">
          <i className="icon-close" onClick={viewallToggle}>
            <ModalClose />
          </i>
          <h2 className="modal-title">작성 포스트</h2>
          <div className="modal-box">
            <div className="select-wrap">
              <p className="department-title">카테고리</p>
              <article className="cont-select">
                <button onClick={selectHide} className="btn-select">
                  전체
                </button>
                <ul className={hide ? "list-member" : "active list-member"}>
                  <li>
                    <button>일반</button>
                  </li>
                  <li>
                    <button>간편등록</button>
                  </li>
                </ul>
              </article>
            </div>
            <div className="all-box">
              <div className="post-card">
                <div className="post-text-card">
                  <div className="top-btn">
                    <p className="categories">금융/트랜드</p>
                    <ul className="more-btn">
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                  <p className="post-card-title">
                    IT트랜드캐치업 킥오프 마이데이터 발표자료 공유
                  </p>
                  <p className="date">2020. 04. 28</p>
                  <div className="tag-box">
                    <div className="tag-list">
                      <div className="tag-name-box">
                        <p className="tag-name">#DevOps</p>
                        <p className="tag-name">#Consulting</p>
                      </div>
                      <div className="tag-img-box">
                        <img src="post-card-img.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="post-card">
                <div className="post-text-card">
                  <div className="top-btn">
                    <p className="categories">금융/트랜드</p>
                    <ul className="more-btn">
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                  <p className="post-card-title">
                    IT트랜드캐치업 킥오프 마이데이터 발표자료 공유
                  </p>
                  <p className="date">2020. 04. 28</p>
                  <div className="tag-box">
                    <div className="tag-list">
                      <div className="tag-name-box">
                        <p className="tag-name">#DevOps</p>
                        <p className="tag-name">#Consulting</p>
                      </div>
                      <div className="tag-img-box">
                        <img src="post-card-img.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="post-card">
                <div className="post-text-card">
                  <div className="top-btn">
                    <p className="categories">금융/트랜드</p>
                    <ul className="more-btn">
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                  <p className="post-card-title">
                    IT트랜드캐치업 킥오프 마이데이터 발표자료 공유
                  </p>
                  <p className="date">2020. 04. 28</p>
                  <div className="tag-box">
                    <div className="tag-list">
                      <div className="tag-name-box">
                        <p className="tag-name">#DevOps</p>
                        <p className="tag-name">#Consulting</p>
                      </div>
                      <div className="tag-img-box">
                        <img src="post-card-img.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="post-card">
                <div className="post-text-card">
                  <div className="top-btn">
                    <p className="categories">금융/트랜드</p>
                    <ul className="more-btn">
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                  <p className="post-card-title">
                    IT트랜드캐치업 킥오프 마이데이터 발표자료 공유
                  </p>
                  <p className="date">2020. 04. 28</p>
                  <div className="tag-box">
                    <div className="tag-list">
                      <div className="tag-name-box">
                        <p className="tag-name">#DevOps</p>
                        <p className="tag-name">#Consulting</p>
                      </div>
                      <div className="tag-img-box">
                        <img src="post-card-img.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gradient"></div>
          </div>
          <div className="btn-wrap">
            <button>닫기</button>
          </div>
        </div>
      </section>
    </SliderWrapper>
  );
}
