import React, {useState} from 'react';
import {CommonContainer, MediaQuery, mobileQuery} from '../../components/Grid';
import {TopButtonStyle} from './styled';

const ScrollArrow = () =>{
    const isMobile = MediaQuery(mobileQuery);
    const [showScroll, setShowScroll] = useState(false)

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 100){
        setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 100){
        setShowScroll(false)
        }
    };

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  window.addEventListener('scroll', checkScrollTop)

  return (
        <>
            {isMobile ? (
                <TopButtonStyle>
                    <CommonContainer>
                        <div className="scrollTop" onClick={scrollTop} style={{height: 50, display: showScroll ? 'flex' : 'none'}}>
                            
                        </div>
                    </CommonContainer>
                </TopButtonStyle>) : ''
            }    
       </>
  );
}

export default ScrollArrow;