import styled from 'styled-components';

export const Wrapper = styled.div`
	background: #f7f7f7;

	.inner_ {
		max-width: 1288px;
		width: 100%;
		margin: 0 auto;
		padding: 140px 20px 50px 20px;
		/* padding: 190px 20px 200px 20px; */
	}

	.top {
		display: flex;
		justify-content: space-between;
		gap: 0 20px;
		margin: 0 0 40px 0;
		display: none;

		h2 {
			color: #000;
			font-family: 'KBFG Display';
			font-size: 48px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
		.btn {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 0 11.31px;
			color: #fff;
			font-family: 'KBFG Display';
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			border-radius: 24.366px;
			background: #000;
			max-width: 228px;
			width: 100%;
			height: 57.435px;
			cursor: pointer;
		}
	}

	.box {
		display: flex;
		gap: 0 60px;
		/* justify-content: space-between; */
		border-radius: 28px;
		background: #fff;
		margin-bottom: 30px;
		padding: 30px 50px 30px 30px;

		.thumbnail {
			flex: 0 1 432px;
			width: 432px;
			height: 248px;
			border-radius: 20px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
		.right {
			flex: 0 1 calc(100% - 432px);
			.title {
				padding-top: 30px;
				color: #000;
				font-family: 'KBFG Display';
				font-size: 32px;
				font-style: normal;
				font-weight: 700;
				line-height: 43.335px; /* 135.423% */
				letter-spacing: -0.64px;
				margin-bottom: 16px;
			}
			p {
				color: #666;
				font-family: 'KBFG Display';
				font-size: 18px;
				font-style: normal;
				font-weight: 300;
				line-height: normal;
				margin-bottom: 54px;
			}
			.flex {
				display: flex;
				justify-content: space-between;
				gap: 0 20px;

				.fl {
					display: flex;
					align-items: center;
					.count {
						${(props) =>
							props.count
								? `
						transform: translateX(calc(${-1 * (props.count - 1)}px));
`
								: ``}
						color: #000;
						font-family: 'KBFG Text';
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						letter-spacing: -0.28px;
						span {
							color: #ffbc00;
						}
					}
				}

				.authors {
					display: flex;
					.avatar {
						position: relative;
						width: 28px;
						height: 28px;
						background-position: center;
						background-size: cover;
						background-repeat: no-repeat;
						border-radius: 50%;
						cursor: pointer;
					}

					.avatar.leader {
						width: 34px;
						height: 34px;
						z-index: 6;

						svg {
							position: absolute;
							left: 0;
							bottom: 0;
						}
					}

					align-self: flex-end;
					align-items: center;

					.avatar-1 {
						transform: translateX(-6px);
						z-index: 5;
					}
					.avatar-2 {
						transform: translateX(-12px);
						z-index: 4;
					}
					.avatar-3 {
						transform: translateX(-18px);
						z-index: 3;
					}
					.avatar-4 {
						transform: translateX(-24px);
						z-index: 2;
					}
					.avatar-5 {
						transform: translateX(-30px);
						z-index: 1;
					}
				}
				.padlet {
					display: flex;
					align-items: center;
					justify-content: center;
					max-width: 200px;
					width: 100%;
					height: 62px;
					gap: 0 4px;
					border-radius: 90.733px;
					border: 1.354px solid #e9e9e9;
					color: #333;
					font-family: 'KBFG Display';
					font-size: 16px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
					letter-spacing: -0.32px;
				}
			}
		}
	}

	.static {
		display: flex;
		padding: 27px 20px 24px 20px;
		border-radius: 28px;
		background: #fff;
		margin-bottom: 40px;
		div {
			flex: 0 1 50%;
			text-align: center;
			p {
				color: #666;
				text-align: center;
				font-family: 'KBFG Display';
				font-size: 14px;
				font-style: normal;
				font-weight: 300;
				line-height: normal;
				letter-spacing: -0.28px;
				margin-bottom: 8px;
			}
			span {
				color: #000;
				text-align: center;
				font-family: 'KBFG Display';
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
			}
			&.left {
				border-right: 1px solid #eee;
			}
		}
	}

	.post {
		.t {
			color: #000;
			font-family: 'KBFG Display';
			font-size: 28px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			margin-bottom: 20px;
		}

		.list {
			display: flex;
			flex-wrap: wrap;
			gap: 25px;
			border-radius: 27.085px;
			padding: 30px;
			background: #fff;
			margin-bottom: 24px;

			.card {
				position: relative;
				flex: 0 1 calc((100% - 75px) / 4);
				min-height: 278px;
				border-radius: 20px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				padding: 30px;
				overflow: hidden;
				transition: 0.4s;
				cursor: pointer;

				&::before {
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background: inherit;
					transition: inherit;
				}
				&:hover::before {
					transform: scale(1.4);
				}

				&:hover {
					transform: translateY(-12px);
				}

				.relative {
					position: relative;
					z-index: 1;
				}

				&:after {
					position: absolute;
					display: block;
					content: '';
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					background: rgba(0, 0, 0, 0.3);
				}

				p {
					width: fit-content;
					height: 28px;
					padding: 0 10px;
					color: #fff;
					font-family: 'KBFG Display';
					font-size: 12px;
					font-style: normal;
					font-weight: 300;
					line-height: 28px;
					background: rgba(255, 255, 255, 0.4);
					margin-bottom: 20px;
				}
				div {
					color: #fff;
					font-family: 'KBFG Display';
					font-size: 19px;
					font-style: normal;
					font-weight: 700;
					line-height: 140%; /* 26.6px */
					letter-spacing: 0.38px;
					margin-bottom: 8px;
				}
				span {
					display: block;
					color: #fff;
					font-family: 'KBFG Display';
					font-size: 12px;
					font-style: normal;
					font-weight: 500;
					line-height: 140%; /* 16.8px */
				}
			}
		}
	}

	@media (max-width: 1000px) {
		.post {
			.list {
				.card {
					position: relative;
					flex: 0 1 calc((100% - 50px) / 3);
				}
			}
		}
	}
	@media ${(props) => props.theme.mobile} {
		.inner_ {
			padding: 41px 30px 60px 30px;
		}
		.post {
			.list {
				padding: 20px;
				border-radius: 10px;

				.card {
					position: relative;
					flex: 0 1 100%;
					border-radius: 10px;
					padding: 20px;
					p {
						height: 28px;
						padding: 0 10px;
						color: #fff;
						font-family: 'KBFG Display';
						font-size: 12px;
						font-style: normal;
						font-weight: 500;
						line-height: 140%; /* 16.8px */
						margin-bottom: 30px;
					}
					div {
						color: #fff;
						font-family: 'KBFG Display';
						font-size: 19px;
						font-style: normal;
						font-weight: 700;
						line-height: 28px;
						letter-spacing: 0.38px;
						margin-bottom: 8px;
					}
				}
			}
		}
		.top {
			margin: 0 0 24px 0;

			h2 {
				color: #000;
				font-family: 'KBFG Display';
				font-size: 22px;
				font-style: normal;
				font-weight: 700;
				line-height: 160%; /* 35.2px */
			}
			.btn {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 0 6px;
				color: #fff;
				font-family: 'KBFG Display';
				font-size: 12px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				border-radius: 24.366px;
				background: #000;
				max-width: 92px;
				width: 100%;
				height: 38px;
				cursor: pointer;
				svg {
					width: 20px;
					height: 20px;
				}
			}
		}

		.box {
			display: block;
			padding: 0;
			border-radius: 10px;
			margin-bottom: 10px;

			.thumbnail {
				width: 100%;
				height: 166px;
				border-radius: 10px 10px 0px 0px;
			}
			.right {
				flex: 0 1 calc(100% - 432px);
				padding: 24px 20px;

				.title {
					font-size: 20px;
					padding: 0;
					margin-bottom: 12px;
				}
				p {
					color: #000;
					font-family: 'KBFG Display';
					font-size: 12px;
					font-style: normal;
					font-weight: 300;
					line-height: 140%; /* 16.8px */
					letter-spacing: -0.36px;
					margin-bottom: 10px;
				}

				.flex {
					.fl {
						.count {
							transform: translateX(-10px);
							font-size: 12px;
							span {
								color: #ffbc00;
							}
						}
					}

					.authors {
						display: flex;
						.avatar {
							position: relative;
							width: 20px;
							height: 20px;
							background-position: center;
							background-size: cover;
							background-repeat: no-repeat;
							border-radius: 50%;
							cursor: pointer;
						}

						.avatar.leader {
							width: 24px;
							height: 24px;
							z-index: 6;

							svg {
								position: absolute;
								left: 0;
								bottom: 0;
								width: 12px;
								height: 12px;
							}
						}

						align-self: flex-end;
						align-items: center;

						.avatar-1 {
							transform: translateX(-4px);
							z-index: 5;
						}
						.avatar-2 {
							transform: translateX(-8px);
							z-index: 4;
						}
						.avatar-3 {
							transform: translateX(-12px);
							z-index: 3;
						}
						.avatar-4 {
							transform: translateX(-16px);
							z-index: 2;
						}
						.avatar-5 {
							transform: translateX(-20px);
							z-index: 1;
						}
					}
					.padlet {
						display: flex;
						align-items: center;
						justify-content: center;
						max-width: 200px;
						width: 100%;
						height: 62px;
						gap: 0 4px;
						border-radius: 90.733px;
						border: 1.354px solid #e9e9e9;
						color: #333;
						font-family: 'KBFG Display';
						font-size: 16px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
						letter-spacing: -0.32px;
					}
				}
			}
		}
		.in {
			display: flex;
			gap: 0 10px;
			.static {
				width: 100%;
				padding: 18px 10px 16px 10px;
				border-radius: 10px;
				margin-bottom: 20px;

				div {
					p {
						color: #666;
						font-family: 'KBFG Display';
						font-size: 11px;
						font-style: normal;
						font-weight: 300;
						line-height: normal;
						letter-spacing: -0.22px;
						margin-bottom: 5px;
					}
					span {
						color: #000;
						text-align: center;
						font-family: 'KBFG Display';
						font-size: 12px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
					}
					&.left {
						border-right: 1px solid #eee;
					}
				}
			}
			.padlet {
				display: flex;
				align-items: center;
				gap: 0 10px;
				justify-content: center;
				padding: 0;
				flex: 1 0 90px;
				color: #333;
				font-family: 'KBFG Text';
				font-size: 12px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				letter-spacing: -0.24px;
			}
		}
		.post {
			.t {
				color: #000;
				font-family: 'KBFG Display';
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				line-height: 160%; /* 32px */
				margin-bottom: 10px;
			}
		}
	}
`;
