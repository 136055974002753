import styled from 'styled-components';

export const LoadingContainer = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 999898989899898899;
  top: 0;
  right: 0;
  color: white;
  text-align: center;
  align-items: center;
  font-size: 24px;
  padding-top: 45vh;
  span {
    padding-right: 10px;
  }

  .lds-dual-ring {
    display: inline-block;
    width: 24px;
    height: 24px;
  }
  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 24px;
    height: 24px;
    /* margin: 8px; */
    border-radius: 50%;
    border: 4px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
