import styled from 'styled-components';

export const Wrapper = styled.div`
	position: fixed;
	z-index: 100111;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	.overlay {
		width: 100%;
		height: 100%;
		background-color: #00000040;
	}
	.copy_account {
		position: absolute;
		left: -300px;
		bottom: -300px;
		z-index: -1;
	}
	.modal-wrapper {
		width: 100%;
		height: 100vh;
		.modal-wrap {
			position: absolute;
			z-index: 999;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			padding: 26px 15px 15px 15px;
			background-color: #fff;
			border-radius: 22px;
			width: 300px;
			height: 236px;
			.modal-title {
				text-align: center;
				font-size: 18px;
				font-weight: 700;
				margin-bottom: 24px;
			}
			.flex {
				display: flex;
				justify-content: center;
				gap: 76px;
				text-align: center;
				p {
					color: #666;
					margin-top: 10px;
					font-size: 13px;
					font-weight: 400;
					line-height: normal;
					text-decoration: none;
				}
			}
			.close-btn {
				margin-top: 20px;
				border-radius: 30px;
				background: #000;
				width: 100%;
				height: 46px;
				color: #fff;
				text-align: center;
				font-size: 14px;
				font-weight: 500;
				line-height: 46px;
				letter-spacing: -0.28px;
			}
		}
	}
	.qr-box {
		.overlay {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			z-index: 1000;
			background: rgba(0, 0, 0, 0.8);
		}
		.qrcode {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 1000;
			width: 186px;
			height: 283px;
			.qr {
				border-radius: 12px;
				background: #fff6c9;
				width: 186px;
				height: 213px;
				padding: 15px;
				p {
					color: #000;
					font-size: 14px;
					font-weight: 600;
					letter-spacing: 0.28px;
					margin-bottom: 10px;
				}
				canvas {
					border-radius: 6px;
					background: #fff;
					padding: 8px;
					width: 156px !important;
					height: 156px !important;
					/* background-size: 140px;
					background-repeat: no-repeat;
					background-position: center; */
				}
			}
			.close {
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 50px;
				height: 50px;
				border-radius: 80px;
				border: 1px solid #eee;
				background: #f7f7f7;
				box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.08);
				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
	}

	@media ${(props) => props.theme.mobile} {
	}
`;
