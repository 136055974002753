export default function CheckIcon() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.5 0.0830078C10.5906 0.0830078 0.125 10.3434 0.125 22.9997C0.125 35.6559 10.5906 45.9163 23.5 45.9163C36.4094 45.9163 46.875 35.6559 46.875 22.9997C46.875 10.3434 36.4094 0.0830078 23.5 0.0830078ZM33.632 19.1247C33.8186 18.9156 33.9606 18.6721 34.0498 18.4084C34.1389 18.1447 34.1733 17.8662 34.1511 17.5892C34.1288 17.3123 34.0503 17.0425 33.9201 16.7958C33.7899 16.549 33.6107 16.3303 33.393 16.1525C33.1754 15.9746 32.9236 15.8413 32.6526 15.7602C32.3816 15.6792 32.0967 15.6521 31.8149 15.6806C31.533 15.709 31.2598 15.7925 31.0113 15.926C30.7629 16.0595 30.5441 16.2404 30.368 16.458L21.2305 27.2059L16.5024 22.5684C16.1016 22.1889 15.5648 21.9789 15.0077 21.9837C14.4505 21.9884 13.9175 22.2075 13.5235 22.5938C13.1295 22.9801 12.906 23.5026 12.9012 24.0488C12.8964 24.5951 13.1105 25.1213 13.4976 25.5143L19.8726 31.7643C20.0814 31.9688 20.3314 32.1285 20.6066 32.2331C20.8819 32.3377 21.1763 32.385 21.4712 32.3719C21.7661 32.3587 22.055 32.2855 22.3194 32.1569C22.5838 32.0282 22.818 31.847 23.007 31.6247L33.632 19.1247Z"
        fill="#F9DD4A"
      />
    </svg>
  );
}
