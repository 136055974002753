import styled from 'styled-components';

export const StyledBadge = styled.label`
    display:inline-block;
    box-sizing: border-box;
    font-family: "KBFG Text";
    cursor:pointer;
    width: auto;
    color: black;
    transition: 0.3s;
    font-size: 14px;
    -webkit-font-smoothing: subpixel-antialiased;
    ${props => {
        var out = ``;
        switch(props.type) {
            case 'primary': // primary 버튼일때
                out += ` 
                    font-style: normal;
                    font-weight: 500;
                    border: 1px solid e9e7e5;
                            color: rgba(144, 136, 125, 0.6);
                            fonft-family: KBFG Display;
                            background: rgba(233, 231, 229, 0.5);
                    &:hover{
                                background-color: #FFBC00;
                                color: #fff;                                
                            } 
                `;
                if(props.size === 'medium')
                    out += `padding: 2px 17px;
                            font-size: 18px;
                            line-height: 34px;
                            `;
                if(props.size === 'small')
                    out += `padding: 0px 15px;
                            font-size: 15px;
                            line-height: 34px;
                            `;        
            break;
            case 'default': // default 버튼일때
                out += `
                font-style: normal;
                font-weight: 500;
                border: 1px solid e9e7e5;
                color: rgba(144, 136, 125, 0.6);
                fonf-family: KBFG Display;
                background: rgba(233, 231, 229, 0.5);
                &:hover{
                    color: rgba(255, 172, 0, 0.6);
                    background: rgba(255, 242, 204, 0.6);
                } 
            `;
                if(props.size === 'medium')
                    out += `padding: 2px 17px;
                            font-size: 18px;
                            line-height: 34px;
                            `;
                if(props.size === 'small')
                    out += `padding: 0px 15px;
                            font-size: 15px;
                            line-height: 34px;
                            `;
            break;
            case 'round': //round 버튼일때
                out += `
                    padding: 0px 24px;
                    font-style: normal;
                    font-family: "KBFG Text";
                    font-weight: bold;
                    font-size: 15px;
                    line-height: 34px;
                    height:34px;
                    text-align: center;
                    color: #ffac00;
                    border: 1px solid #FFAC00;
                    box-sizing: border-box;
                    &:hover{
                                background-color: #FFBC00;
                                color: #fff;
                                border: 1px solid #FFBC00 ;
                            }
                `;
                if(props.size === 'medium')
                    out += `padding: 2px 17px;
                            font-size: 18px;
                            line-height: 34px;
                            `;
                if(props.size === 'small')
                    out += `padding: 0px 15px;
                            font-size: 15px;
                            line-height: 34px;
                            `;
                    if(props.color === 'black')
                        out += `
                            color: black;
                            border-color: black;
                            border-radius: 100px;
                            &:hover{
                                    background-color: black;
                                    border-color: black;

                            }
                        `;
            break;
            case 'second': // second 버튼일때
                out += ` 
                    font-style: normal;
                    font-weight: 500;
                    border: 1px solid e9e7e5;
                    color: #FFBC00;
                    fonft-family: KBFG Display;
                    background: rgba(255, 242, 204, 0.5);
                    &:hover{
                                background: rgba(255, 204, 0, 0.2);
                                color: #FFBC00;                               
                            } 
                `;
                    if(props.size === 'medium')
                        out += `padding: 2px 17px;
                                font-size: 18px;
                                line-height: 34px;
                                `;
                    if(props.size === 'small')
                        out += `padding: 0px 15px;
                                font-size: 15px;
                                line-height: 34px;
                                `; 
            break;
            case 'Black': // Black 일때
                out += ` 
                    font-style: normal;
                    font-weight: 500;
                    font-family: KBFG Text;
                    border: 1px solid rgba(96, 88, 76, 0.2);
                    color: rgba(96, 88, 76, 0.88);
                    fonft-family: KBFG Display;
                    background: #F7F7F7;
                    border-radius: 50px;
                    &:hover{
                                background: rgba(255, 204, 0, 0.2);
                                color: #FFBC00;                               
                            } 
                `;
                    if(props.size === 'medium')
                        out += `padding: 2px 17px;
                                font-size: 18px;
                                line-height: 34px;
                                `;
                    if(props.size === 'small')
                        out += `padding: 0px 15px;
                                font-size: 15px;
                                line-height: 34px;
                                `; 
            break;

            default:
        }
        return out;
    }}
`;