export default function SauareIcon() {
  return (
    <>
      <svg
        width="73"
        height="73"
        viewBox="0 0 73 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.25 9.125C15.8299 9.125 13.5089 10.0864 11.7977 11.7977C10.0864 13.5089 9.125 15.8299 9.125 18.25V36.5C9.125 38.9201 10.0864 41.2411 11.7977 42.9523C13.5089 44.6636 15.8299 45.625 18.25 45.625H22.8125V41.0625H18.25C17.0399 41.0625 15.8795 40.5818 15.0238 39.7262C14.1682 38.8705 13.6875 37.7101 13.6875 36.5V18.25C13.6875 17.0399 14.1682 15.8795 15.0238 15.0238C15.8795 14.1682 17.0399 13.6875 18.25 13.6875H36.5C37.7101 13.6875 38.8705 14.1682 39.7262 15.0238C40.5818 15.8795 41.0625 17.0399 41.0625 18.25V36.5C41.0625 37.7101 40.5818 38.8705 39.7262 39.7262C38.8705 40.5818 37.7101 41.0625 36.5 41.0625V45.625C38.9201 45.625 41.2411 44.6636 42.9523 42.9523C44.6636 41.2411 45.625 38.9201 45.625 36.5V18.25C45.625 15.8299 44.6636 13.5089 42.9523 11.7977C41.2411 10.0864 38.9201 9.125 36.5 9.125H18.25ZM50.1875 27.375V31.9375H54.75C55.96 31.9375 57.1205 32.4182 57.9762 33.2738C58.8318 34.1295 59.3125 35.2899 59.3125 36.5V54.75C59.3125 55.96 58.8318 57.1205 57.9762 57.9762C57.1205 58.8318 55.96 59.3125 54.75 59.3125H36.5C35.2899 59.3125 34.1295 58.8318 33.2738 57.9762C32.4182 57.1205 31.9375 55.96 31.9375 54.75V36.5C31.9375 35.2899 32.4182 34.1295 33.2738 33.2738C34.1295 32.4182 35.2899 31.9375 36.5 31.9375V27.375C34.0799 27.375 31.7589 28.3364 30.0476 30.0476C28.3364 31.7589 27.375 34.0799 27.375 36.5V54.75C27.375 57.1701 28.3364 59.4911 30.0476 61.2023C31.7589 62.9136 34.0799 63.875 36.5 63.875H54.75C57.1701 63.875 59.4911 62.9136 61.2023 61.2023C62.9136 59.4911 63.875 57.1701 63.875 54.75V36.5C63.875 34.0799 62.9136 31.7589 61.2023 30.0476C59.4911 28.3364 57.1701 27.375 54.75 27.375H50.1875Z"
          fill="#3D2F00"
        />
      </svg>
    </>
  );
}
