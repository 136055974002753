export default function MoManual() {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 2V22.5714H4.57143C3.88944 22.5714 3.23539 22.3005 2.75315 21.8183C2.27092 21.336 2 20.682 2 20V4.57143C2 3.88944 2.27092 3.23539 2.75315 2.75315C3.23539 2.27092 3.88944 2 4.57143 2H20Z"
        stroke="black"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 17.4273H4.57143C3.88944 17.4273 3.23539 17.6982 2.75315 18.1805C2.27092 18.6627 2 19.3168 2 19.9987M7.14286 7.1416H14.8571"
        stroke="black"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
