import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { GetPosts } from '../../api/story';
import Card from '../../components/Card';
import { CommonContainer } from '../../components/Grid';
import LoadInView from '../../components/LoadInView';
import MainContainer from '../../components/MainContainer';
import Pagination from '../../components/Pagination';
import StoryCardSlider from './components/StorySlider';
import { CardSection } from './components/styled';
import Tab from './components/Tab';
import { MainWrapper } from './styled';

const Story = () => {
  let { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);
  const [active_tab, set_active_tab] = useState(
    query.get('tab') ? query.get('tab') : 5
  );
  const [paged, set_paged] = useState(
    query.get('paged') ? query.get('paged') : 1
  );
  const [list_cate, set_list_cate] = useState(0);
  const [post_list, update_post_list] = useState(0);
  const [current_paged, set_current_paged] = useState(0);
  const [list_tags, set_list_tags] = useState(0);
  const [total_page, set_total_page] = useState(0);
  const categories = [
    '기술-cop',
    '워라밸-소모임',
    '트렌드-생각',
    '회사소식',
    'daitso',
    'recommand',
    'event',
  ];
  useEffect(() => {
    // if(current_paged != paged) {
    GetPosts({
      order: 'DESC',
      orderby: 'ID',
      posts_per_page: '12',
      category_name: categories[active_tab - 1],
      paged: paged,
    })
      .then(response => {
        console.log(response);
        if (response.data.body.paged) {
          set_total_page(response.data.body.total);
          set_list_cate(active_tab);
          set_current_paged(response.data.body.paged);
          update_post_list(response.data.body.posts);
          set_list_tags(response.data.body.tags);
        }
      })
      .catch(error => {
        console.log(error);
      });
    // }
  }, [active_tab, paged]);

  useEffect(() => {
    // if(current_paged != paged) {
    GetPosts({
      order: 'DESC',
      orderby: 'ID',
      posts_per_page: '12',
      category_name: categories[active_tab - 1],
      paged: 1,
    })
      .then(response => {
        console.log(response);
        if (response.data.body.paged) {
          set_total_page(response.data.body.total);
          set_list_cate(active_tab);
          set_current_paged(response.data.body.paged);
          update_post_list(response.data.body.posts);
          set_list_tags(response.data.body.tags);
        }
      })
      .catch(error => {
        console.log(error);
      });

    // }
  }, [active_tab]);
  
  useEffect(() => {
    // if(current_paged != paged) {
    if (paged > 1) {
      GetPosts({
        order: 'DESC',
        orderby: 'ID',
        posts_per_page: '12',
        category_name: categories[active_tab - 1],
        paged: paged,
      })
        .then(response => {
          console.log(response);
          if (response.data.body.paged) {
            set_total_page(response.data.body.total);
            set_list_cate(active_tab);
            set_current_paged(response.data.body.paged);
            update_post_list(response.data.body.posts);
            set_list_tags(response.data.body.tags);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }

    // }
  }, [paged]);

  const onPaging = page => {
    console.log(page);
    set_paged(page);
    history.push(window.location.pathname + `?paged=${page}`);
    window.scrollTo({ top: document.querySelector('#post_archive').offsetTop });
  };

  const ChangeTab = e => {
    // e.preventDefault();
    set_paged(1);
    set_active_tab(e.target.dataset.id);
    console.log(query.get('tab'));
  };

  return (
    <MainContainer>
      <MainWrapper>
        <StoryCardSlider cate_slug={'daitso'} />
      </MainWrapper>
      <CommonContainer id="post_archive">
        <Tab>
          <li className={active_tab == 5 ? 'active' : ''}>
            <Link to={'/feed/'} data-id="5" onClick={ChangeTab}>
              전체
            </Link>
          </li>
          {/* <li className={active_tab == 6 ? 'active' : ''}>
            <Link to={'/feed/'} data-id="6" onClick={ChangeTab}>
              추천
            </Link>
          </li> */}
          <li className={active_tab == 1 ? 'active' : ''}>
            <Link to={'/feed/'} data-id="1" onClick={ChangeTab}>
              기술/cop
            </Link>
          </li>
          <li className={active_tab == 2 ? 'active' : ''}>
            <Link to={'/feed/?tab=2'} data-id="2" onClick={ChangeTab}>
              워라밸/소모임
            </Link>
          </li>
          <li className={active_tab == 3 ? 'active' : ''}>
            <Link to={'/feed/?tab=3'} data-id="3" onClick={ChangeTab}>
              트렌드/생각
            </Link>
          </li>
          <li className={active_tab == 4 ? 'active' : ''}>
            <Link to={'/feed/?tab=4'} data-id="4" onClick={ChangeTab}>
              회사소식
            </Link>
          </li>
          {/* <li className={active_tab == 7 ? 'active' : ''}>
            <Link to={'/feed/?tab=7'} data-id="7" onClick={ChangeTab}>
              이벤트
            </Link>
          </li> */}
        </Tab>
        <LoadInView>
          <CardSection>
            {post_list
              ? post_list?.map((post, key) => (
                  <Card item={post} key={key} width="33.3333%" />
                ))
              : '로딩'}
          </CardSection>
        </LoadInView>
        <LoadInView>
          <Pagination
            onPaging={onPaging}
            current={current_paged}
            max={total_page >= 12 ? Math.floor(total_page / 12) : 1}
          />
        </LoadInView>
      </CommonContainer>
    </MainContainer>
  );
};

export default Story;
