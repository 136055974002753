import React from 'react';

const Unliked = () => {
  return (
    <svg
      width="33"
      height="29"
      viewBox="0 0 33 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8661 0C20.8239 0 18.1603 1.34157 16.5 3.60924C14.8397 1.34157 12.1761 0 9.13393 0C6.7123 0.00279907 4.39063 0.990554 2.67828 2.74656C0.965928 4.50257 0.00272948 6.88342 0 9.36679C0 19.9422 15.2905 28.5022 15.9417 28.8558C16.1133 28.9504 16.3051 29 16.5 29C16.6949 29 16.8867 28.9504 17.0583 28.8558C17.7095 28.5022 33 19.9422 33 9.36679C32.9973 6.88342 32.0341 4.50257 30.3217 2.74656C28.6094 0.990554 26.2877 0.00279907 23.8661 0ZM16.5 26.4083C13.8099 24.8008 2.35714 17.4781 2.35714 9.36679C2.35948 7.52439 3.07421 5.75814 4.3446 4.45536C5.61499 3.15259 7.33733 2.41964 9.13393 2.41724C11.9993 2.41724 14.4051 3.9824 15.4098 6.49632C15.4986 6.718 15.6497 6.9076 15.8438 7.04103C16.0379 7.17446 16.2663 7.24571 16.5 7.24571C16.7337 7.24571 16.9621 7.17446 17.1562 7.04103C17.3503 6.9076 17.5014 6.718 17.5902 6.49632C18.5949 3.97786 21.0007 2.41724 23.8661 2.41724C25.6627 2.41964 27.385 3.15259 28.6554 4.45536C29.9258 5.75814 30.6405 7.52439 30.6429 9.36679C30.6429 17.466 19.1871 24.7993 16.5 26.4083Z"
        fill="black"
      />
    </svg>
  );
};

export default Unliked;
