import React from 'react';
import {TabStyle} from './styled';

const Tab = (props) => {
    return (
        <TabStyle>
            {props.children}
        </TabStyle>
    );
};

export default Tab;