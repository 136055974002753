import React from 'react';
import Badge from '../../../components/Badge';
import { CommonContainer } from '../../../components/Grid';
import {BadgeList,
        } from '../styled';

const LoopBadge = (props) => {
    return (
        <CommonContainer>
            <BadgeList>
                {props.list_tags ? props.list_tags.map((tag,key) => <Badge type="default" size="medium" onClick={(e)=>window.location.href='/tag/'+tag.slug}>#{tag.name}</Badge>) : ''}
            </BadgeList>
        </CommonContainer>
    )
};

export default LoopBadge;