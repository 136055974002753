export default function HomeIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99967 27.9998C7.26634 27.9998 6.63879 27.7389 6.11701 27.2172C5.59434 26.6945 5.33301 26.0665 5.33301 25.3332V13.3332C5.33301 12.9109 5.42767 12.5109 5.61701 12.1332C5.80545 11.7554 6.06634 11.4443 6.39967 11.1998L14.3997 5.19984C14.6441 5.02206 14.8997 4.88873 15.1663 4.79984C15.433 4.71095 15.7108 4.6665 15.9997 4.6665C16.2886 4.6665 16.5663 4.71095 16.833 4.79984C17.0997 4.88873 17.3552 5.02206 17.5997 5.19984L25.5997 11.1998C25.933 11.4443 26.1943 11.7554 26.3837 12.1332C26.5721 12.5109 26.6663 12.9109 26.6663 13.3332V25.3332C26.6663 26.0665 26.4055 26.6945 25.8837 27.2172C25.361 27.7389 24.733 27.9998 23.9997 27.9998H18.6663V18.6665H13.333V27.9998H7.99967Z"
        fill="white"
      />
    </svg>
  );
}
