import React, {useState, useEffect} from 'react';
import Card from '../../../../components/Card';
import { CardSection } from '../../../../components/Card/styled';
import { CommonContainer } from '../../../../components/Grid'; 
import {
        PostCardWrapper,
        } from '../styled';
import {GetRelatedPosts} from '../../../../api/story';


const SectionCard = (props) => {
    const [post_list, update_post_list] = useState(0);
    const [current_paged, set_current_paged] = useState(0);
    const [total_page, set_total_page] = useState(0);
    const [paged, set_paged] = useState(1);
    useEffect(() => {
        if(current_paged != paged) {
            GetRelatedPosts({
                posts_per_page: '6',
                post_id: props.item.ID,
                paged: paged
            })
            .then((response) => {
                console.log(response);
                if(response.data.body.paged) {
                    set_total_page(response.data.body.total);
                    set_current_paged(response.data.body.paged);
                    update_post_list(response.data.body.posts);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    });
    const onPaging = (page) => {
        console.log(page);
        set_paged(page);
    }
    return (
        <PostCardWrapper>
            <CommonContainer>
                <h3>관련 포스트</h3>
                    <CardSection> 
                        {post_list ? post_list.map((post, key) => <Card item={post} width="33%"/>) : '로딩'}
                    </CardSection>
            </CommonContainer>
        </PostCardWrapper>
    )
}


export default SectionCard;
