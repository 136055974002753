import styled from 'styled-components';

export const StyledButton = styled.a`
    display: inline-block;
    width: auto;
    position: relative;
    transition: 0.2s;
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    color: #000;
    text-decoration: none;
    background: none;
    ${props => {
        var out = ``;
        switch(props.type) {
            case 'primary': // primary 버튼일때
                out += `
                    border: none;
                    line-height:35px;
                    height:35px;
                    span {
                        position:relative;
                        z-index:2;
                    }
                    i {
                        display:inline-block;
                        vertical-align:middle;
                        margin-left: 8px;
                        border-radius: 50%;
                        width: 35px;
                        height: 35px;
                        trasition: .3s;
                        :after {
                            display: inline-block;
                            content: "";
                            position: absolute;
                            right: 0px;
                            top: 0px;
                            background-image: url(${props.theme.image_url}/common/icon-${props.icon}.svg);
                            background-size: ${props.iconSize ? props.iconSize : '18px 18px' };
                            background-repeat: no-repeat;
                            background-position: center center ;
                            width: 35px;
                            height: 35px;
                        }
                        :before {
                            content: '';
                            right: 0px;
                            top: 0px;
                            display: block;
                            position: absolute;
                            width: 35px;
                            height:35px;
                            background: #f7f7f7;
                            border-radius: 50%;
                            transition:.3s;
                        }
                    }
                    .load-in-view:hover &,
                    .section:hover &,
                    div:hover > & {
                        color:#000;
                        i {
                            :before {
                                // top: -3px;
                                display: block;
                                width: 120%;
                                // height:40px;
                                background: #FFBC00;
                                border-radius: 50px;
                            }
                        }
                    }
                    .load-in-view:hover &:hover,
                    .section:hover &:hover,
                    div:hover > &:hover
                    &:hover {
                        color:#000;
                        i {
                            :before {
                                // top: -3px;
                                display: block;
                                width: 120%;
                                // height:40px;
                                background: #f7f7f7;
                                border-radius: 50px;
                            }
                        }
                    }

                    @media ${props=>props.theme.tablet} {
                        font-size: 15px;
                    }
                `;
            if(props.color === 'white')
                out += `
                    color: #fff; 
                    i{
                        :before {
                            background: #fff;
                        }
                    }
                 `;
            if(props.color === 'bg-white')
                out += `
                    color: #000;
                    i{
                        :before {
                            background: #fff;
                        }
                    }
                    .section:hover &,
                    div:hover > &,
                    &:hover {
                        color:#000;
                        i {
                            :before {
                                background: #fff;
                            }
                        }
                    }
                 `;  
            break;
            case 'secondary': // secondary 버튼일때
                out += `
                    position: relative;
                    color: #000 !important;
                    background: #FFFFFF;
                    border: none;
                    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
                    border-radius: 40px;
                    padding: 20px 55px;
                    width: 200px;
                    height: 60px;
                    transition: .3s;
                    :after {
                        position: absolute;
                        display: inline-block;
                        content: "";
                        left: 0px;
                        top: 0px;
                        width: 300px;
                        height: 60px;
                        background-image: url(${props.theme.image_url}/common/icon-${props.icon}.svg);
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                    :hover{
                        opacity: .7;
                    }
                `;
            break; 
            case 'icon': // icon paly 버튼일때
                out += `
                    position: relative;
                    vertical-align: top;
                    border-radius: 50%;
                    background: #fff;
                    width:${props.size ? props.size : '70px'};
                    height:${props.size ? props.size : '70px'};
                    :after {
                        display: inline-block;
                        content: "";
                        left: 0px;
                        top: 0px;
                        width: ${props.size ? props.size : '70px'};
                        height: ${props.size ? props.size : '70px'};
                        background-image: url(${props.theme.image_url}/common/icon-${props.icon}.svg);
                        background-size: 25px;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }     
                `;    
                if(props.disable)
                    out += `color:#ccc;
                            border-color:#eee;
                            &:hover {
                                color:#ccc;
                                border-color:#eee;
                                cursor:not-allowed;
                            }
                            `;
            break; 
            case 'round': 
                out += `
                    position: relative;
                    vertical-align: top;
                    border-radius: 50%;
                    background: #fff;
                    width:50px;
                    height:50px;
                    text-align:center;
                    line-height:50px;
                `;    
                if(props.disable)
                    out += `color:#ccc;
                            border-color:#eee;
                            &:hover {
                                color:#ccc;
                                border-color:#eee;
                                cursor:not-allowed;
                            }
                            `;
            break;  
               
            default:
        }
        return out;
    }}
`;