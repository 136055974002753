import axios from 'axios';

// const jwt_token = "bbbG6Yv%|:hw3::6k>NA(U+9DD+{XMoskhJg=/-Rn9EGXT67Vpe@oK!I_p1dmaXD";

// axios.defaults.withCredentials = true;

const jwt_token =
  'jy%U`W OKyor)|f[C)34Y@6eC/uK5B-+ Gi+, -y4GEO!T{$ELS|xVt!`&uyOIZT';

const localToken = localStorage.getItem('user_token');

if (localToken) {
  axios.defaults.headers.common = { Authorization: `Bearer ${localToken}` };
  // axios.defaults.withCredentials = true;
}

const baseURL = (() => {
  return 'https://wp.daitso.kbds.co.kr/wp-json';
  // return 'https://kds.easypress.me/wp-json/';
})();

const defaultClient = axios.create({
  baseURL,
  // jwt_token,
  // withCredentials: true,
});

const TestbaseURL = (() => {
  return 'https://wp.daitso.kbds.co.kr/wp-json';
})();

export default defaultClient;
