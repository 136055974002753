import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import {
    UserSliderStyle,
    UserSlideItem 
} from '../styled';
import {GetUserRelatedPosts} from '../../../../api/story';
import Slider from 'react-slick';

const UserSlider = (props) => {
    const [post_list, update_post_list] = useState(0);
    const [current_paged, set_current_paged] = useState(0);
    const [total_page, set_total_page] = useState(0);
    const [list_tags, set_list_tags] = useState(0);
    const [paged, set_paged] = useState(1);
    useEffect(() => {
        if(current_paged != paged) {
            GetUserRelatedPosts({
                order: 'DESC',
                orderby: 'ID',
                posts_per_page: '15',
                post_author: props.user_id,
                paged: paged
            })
            .then((response) => {
                console.log(response);
                if(response.data.body.paged) {
                    set_total_page(response.data.body.total);
                    set_current_paged(response.data.body.paged);
                    update_post_list(response.data.body.posts);
                    set_list_tags(response.data.body.tags);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    });
    const onPaging = (page) => {
        console.log(page);
        set_paged(page);
        window.scrollTo({top:document.querySelector('#post_archive').offsetTop});
    }
    
    const settings = {
        infinite: true,
        autoplay: true,
        speed: 600,
        arrows: true,
        slidesToShow: 4,
        rows: 1,
        slidesToScroll: 1,
        dots : 0, 		// 스크롤바 아래 점으로 페이지네이션 여부
        autoplaySpeed : 10000, 		// 자동 스크롤 시 다음으로 넘어가는데 걸리는 시간 (ms)
        pauseOnHover : true,
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 770,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true
              }
            }
        ]
    };
    if(total_page < 4) {
        return (<></>);
    }
    return (
        <UserSliderStyle>
            <Slider {...settings}>
                {post_list ? post_list.map((post, key) => <UserSlideItem image={post.post_image} onClick={()=>{window.location.href=`/story/${post.ID}`}}><span dangerouslySetInnerHTML={{
                            __html:post.post_title }}/></UserSlideItem>) : ''}
            </Slider>
        </UserSliderStyle>
    )
};

export default UserSlider;