export default function Mainlogo() {
  return (
    <svg
      width="963"
      height="347"
      viewBox="0 0 963 347"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M460.67 81.1836C451.91 81.1836 443.5 85.7325 443.5 95.88V131.921H422.476C414.416 131.921 410.562 138.57 410.562 146.268C410.562 153.966 414.416 160.264 422.476 160.264H443.5V260.34C443.5 300.93 459.269 318.426 497.112 318.426C507.975 318.426 518.137 316.327 525.846 312.827C532.854 309.678 535.657 302.68 532.854 294.632C530.051 285.884 523.043 283.784 516.735 285.884C511.129 287.983 505.873 289.033 499.565 289.033C484.147 289.033 478.19 281.335 478.19 260.34V160.264H518.838C526.897 160.264 530.751 153.966 530.751 146.268C530.751 138.57 526.897 131.921 518.838 131.921H478.19V95.88C478.19 85.7325 469.781 81.1836 460.67 81.1836Z"
        fill="#111111"
      />
      <path
        d="M719.487 66.1455C710.54 66.1455 702.586 70.4535 702.586 80.3951V98.6214C702.586 126.789 694.633 142.033 670.111 158.271C650.227 170.864 629.35 183.125 607.81 195.055C601.182 199.032 600.851 208.642 604.827 215.27C609.135 222.229 617.42 225.874 624.379 221.897C645.588 210.299 666.797 197.706 685.354 185.776C707.557 171.526 717.167 159.928 719.487 150.981H719.819C721.807 159.265 731.417 170.864 753.62 185.113C772.178 197.043 793.718 209.636 814.595 221.234C821.886 225.211 829.839 221.566 834.147 214.938C837.792 208.642 837.792 199.032 831.164 195.055C809.624 183.125 788.747 170.864 768.864 158.271C744.341 142.033 736.388 126.789 736.388 98.6214V80.3951C736.388 70.4535 728.435 66.1455 719.487 66.1455ZM719.487 230.892C710.871 230.892 702.918 235.2 702.918 244.81V274.351H591.903C583.95 274.351 580.305 280.647 580.305 288.269C580.305 295.891 583.95 302.519 591.903 302.519H847.071C855.024 302.519 858.669 295.891 858.669 288.269C858.669 280.647 855.024 274.351 847.071 274.351H736.056V244.81C736.056 235.2 728.103 230.892 719.487 230.892Z"
        fill="#111111"
      />
      <path
        d="M140.157 67.7029H26.9129C6.64084 67.7029 0 73.9942 0 93.9167V230.029C0 249.951 6.64084 256.243 26.9129 256.243C94.7193 256.243 136.312 253.097 168.817 248.553C176.507 247.505 179.652 239.815 178.604 231.427C177.905 223.388 172.662 217.446 164.623 218.495C126.875 223.738 88.0785 226.184 42.2917 226.184C37.0489 226.184 34.9518 224.087 34.9518 218.844V105.101C34.9518 99.8585 37.0489 97.7614 42.2917 97.7614H140.157C148.545 97.7614 152.39 91.1205 152.39 83.0816C152.39 74.6932 148.545 67.7029 140.157 67.7029ZM218.099 44.2852C209.012 44.2852 200.623 48.8289 200.623 58.9649V331.589C200.623 341.725 209.012 346.268 218.099 346.268C227.187 346.268 235.575 341.725 235.575 331.589V142.599H274.022C283.109 142.599 286.954 135.609 286.954 127.221C286.954 118.832 283.109 112.191 274.022 112.191H235.575V58.9649C235.575 48.8289 227.187 44.2852 218.099 44.2852Z"
        fill="#111111"
      />
      <path
        d="M364.019 122.344C354.793 122.344 345.936 127.141 345.936 137.475V304.651C345.936 314.985 354.793 319.782 364.019 319.782C373.614 319.782 382.102 314.985 382.102 304.651V137.475C382.102 127.141 373.614 122.344 364.019 122.344Z"
        fill="#111111"
      />
      <circle cx="364.272" cy="74.3326" r="23.7242" fill="#FFBC00" />
      <rect
        x="881.866"
        width="27.4147"
        height="132.856"
        rx="13.7073"
        fill="#FFBC00"
      />
      <rect
        x="962.003"
        y="52.7129"
        width="27.4147"
        height="132.856"
        rx="13.7073"
        transform="rotate(90 962.003 52.7129)"
        fill="#FFBC00"
      />
    </svg>
  );
}
