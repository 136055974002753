import styled from 'styled-components';
import LinkBtn from '../../vector/LinkBtn';

const hubList = [
	{
		title: '웹 메일',
		link: 'https://webmail.kbfg.com/kbmail/account/extlogin.do',
	},
	{
		title: 'KB WITH',
		link: 'http://www.kbwith.com/',
	},
	{
		title: '패스트컴퍼스',
		link: 'https://b2bonline.formstack.com/forms/kbdatasystem',
	},
	{
		title: '인프런교육',
		link: 'https://inflearn.typeform.com/to/vG2C8DTa?typeform-source=litt.ly',
	},
	{
		title: 'KYOBO READING TREE',
		link: 'https://kds.kyoboreadingtree.co.kr/common/login.do',
	},
	{
		title: '베네카페',
		link: 'https://cert.benecafe.co.kr/member/login?&cmpyNo=EB9',
	},
	{
		title: '어떠케어',
		link: 'https://www.howcare.co.kr/main',
	},
	{
		title: 'KBDS FOCUS',
		link: 'https://padlet.com/kbdsstarters/dsvdhw1gzrm32npd',
	},
	{
		title: 'KBDS 홈페이지',
		link: 'https://www.kds.co.kr/',
	},
	{
		title: 'KBDS 유튜브',
		link: 'https://www.youtube.com/channel/UCFsYPVbeWWdg6zM7mDHpUJQ',
	},
	{
		title: 'KB STARTERS(멘토링) 프로세스',
		link: 'https://padlet.com/kbdsstarters/zm1rwxh2yh4qbppx',
	},
	{
		title: '채용 사이트',
		link: 'https://kbds.career.greetinghr.com/appsite/company/index',
	},
	{
		title: '메타모스트',
		link: 'https://dh.digitalkds.co.kr/login?redirect_to=%2Fstartalk%2Fchannels%2Ftown-square&extra=expired',
	},
	{
		title: 'KB STARTERS 멘토링 운영',
		link: 'https://padlet.com/kbdsstarters/23vaqsyzbly7qaec',
	},
	{
		title: 'KBDS 위키 신규입사자 가이드',
		link: 'https://padlet.com/kbdsstarters/b7w3ma8sfhcw44kf',
	},
	{
		title: 'KB FRIENDS',
		link: 'https://www.kbfriends.co.kr/Intro.asp',
	},
	{
		title: '비플레이제로',
		link: 'http://www.bizplaypay.co.kr/bppay/main.html',
	},
	{
		title: '소모임',
		link: 'https://padlet.com/kbdsstarters/tpp0bayl4ppa54xy',
	},
];

const Modal = styled.div`
	position: absolute;
	top: 74px;
	max-width: 330px;
	width: 100%;
	height: 342px;
	right: 0;
	z-index: 999;
	border-radius: 0 0 28px 28px;
	/* transform: translateX(calc(0px - 100%)); */
	background-color: #f9dd4a;
	overflow: auto;
	padding-top: 20px;
	padding-bottom: 20px;
	/* border-top: 1px solid #00000033; */
	.contents {
		display: flex;
		flex-wrap: wrap;
		padding: 0 10px;
		.content-box {
			position: relative;
			/* min-width: 250px; */
			width: 100%;
			margin: 10px auto;
			height: 70px;
			background: #ffffff;
			border-radius: 16px;
			border: none;
			padding: 0 20px;
			text-align: left;

			@media (max-width: 768px) {
				width: 100%;
			}
			transition: 0.15s ease-out;
			:hover {
				transform: translateY(-4px);
			}
			span {
				font-weight: 700;
				font-size: 16px;
				line-height: 20px;
				color: #000000;
				width: 100%;
				word-break: keep-all;
				i {
					margin-right: 8px;
				}
			}
		}
	}

	button {
		background-color: white;
		border-radius: 50%;
		width: 60px;
		height: 60px;
		border: none;
		margin: 0 10px;
	}
	@media ${(props) => props.theme.mobile} {
		width: 100%;
		height: 163px;
		border-radius: 0 0 10px 10px;
		padding: 0 12px 10px 12px;
		top: 168px;
		.contents {
			.content-box {
				min-width: 140px;
				height: 42px;
				margin: 6px auto;
				span {
					font-weight: 500;
					font-size: 11px;
					line-height: 16px;
					i {
						svg {
							width: 8px;
							height: auto;
						}
						margin-right: 6px;
					}
				}
			}
		}
	}
`;

export default function HubModal({ closeModal, disabled }) {
	return (
		<Modal>
			<div className="contents">
				{hubList.map((list, idx) => (
					<button
						disabled={disabled}
						onClick={() => window.open(`${list.link}`)}
						target="_blank"
						className="content-box"
						key={`list-${idx}`}
					>
						<span>
							<i>
								<LinkBtn />
							</i>
							{list.title}
						</span>
					</button>
				))}
			</div>
		</Modal>
	);
}
