import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import UserTitle from './components/UserTitle';
import Footer from '../../components/Footer';
import About from './components/About';
import Loop from './components/Loop';
import Like from './components/Like';
import Live from './components/Live';
import { UserWrapper, UserTab } from './styled';
import { GetUser } from '../../api/user';
import MainContainer from '../../components/MainContainer';

const User = (props) => {
	const params = useParams();
	const [user_data, set_user_data] = useState({ ID: 0 });

	const location = useLocation();
	const sch = location.search;
	const searchParams = new URLSearchParams(sch);
	const tab = searchParams.get('tab');
	const searchPaged = searchParams.get('paged');
	const [paged, set_paged] = useState(searchPaged || 1);

	const [active_tab, set_active_tab] = useState(tab || 2);

	const ChangeTab = (e) => {
		// e.preventDefault();
		set_active_tab(e.target.dataset.id);
	};
	useEffect(() => {
		set_active_tab(tab || 2);
		if (user_data.ID != params.id) {
			GetUser({
				user_id: params.id,
			})
				.then((response) => {
					if (response.data.code == '200') {
						set_user_data(response.data.body);
						console.log(response.data.body);
					}
				})
				.catch((response) => {
					console.log(response);
				});
		}
	}, []);

	const [total1, setTotal1] = useState(0);
	const [total2, setTotal2] = useState(0);
	const [total3, setTotal3] = useState(0);

	return (
		<>
			<MainContainer>
				{user_data ? (
					<UserWrapper>
						<UserTitle user={user_data} />
						<UserTab>
							{/* <li className={(active_tab == 1) ? 'active' : ''}><a href="#" data-id="1" onClick={ChangeTab}>소개</a></li> */}
							<li className={active_tab == 2 ? 'active' : ''}>
								<Link
									to={`/user/${user_data.ID}?tab=2`}
									data-id="2"
									onClick={ChangeTab}
								>
									쓴 글{' '}
								</Link>{' '}
								<span>{total1 ? total1 : 0}</span>
							</li>
							{/* <li className={active_tab == 3 ? 'active' : ''}>
                <Link
                  to={`/user/${user_data.ID}?tab=3`}
                  data-id="3"
                  onClick={ChangeTab}
                >
                  KBDS LIVE{' '}
                </Link>{' '}
                <span>{total2 ? total2 : 0}</span>
              </li> */}
							<li className={active_tab == 4 ? 'active' : ''}>
								<Link
									to={`/user/${user_data.ID}?tab=4`}
									data-id="4"
									onClick={ChangeTab}
								>
									관심 포스트{' '}
								</Link>{' '}
								<span>{total3 ? total3 : 0}</span>
							</li>
						</UserTab>
						{/* {active_tab == 1 ? <About user={user_data} /> : ''}
            {active_tab == 2 ? <Loop user_id={params.id} /> : ''} */}
						<div
							style={
								active_tab == 2 ? { display: 'block' } : { display: 'none' }
							}
						>
							<Loop user_id={params.id} setTotal1={setTotal1} />
						</div>
						{/* <div
							style={
								active_tab == 3 ? { display: 'block' } : { display: 'none' }
							}
						>
							<Live user_id={params.id} setTotal2={setTotal2} />
						</div> */}
						<div
							style={
								active_tab == 4 ? { display: 'block' } : { display: 'none' }
							}
						>
							<Like user_id={params.id} setTotal3={setTotal3} />
						</div>
						{/* {active_tab == 4 ? <Like user_id={params.id} /> : ''} */}
					</UserWrapper>
				) : (
					''
				)}
			</MainContainer>
		</>
	);
};

export default User;
