import { useRef, useState } from 'react';
import { useParams } from 'react-router';
import ModalClose from '../../../../../vector/ModalClose';
import ModalPrev from '../../../../../vector/ModalPrev';
import { Wrapper } from './styled';
import NoneIcon from '../../../../../vector/NoonIcon';
import { QRCodeCanvas } from 'qrcode.react';
import CryptoJS from 'crypto-js';
import { useSelector } from 'react-redux';

export default function Sharpopup({ modalToggle, user, user_data }) {
	const [isOpen2, setIsOpen2] = useState(false);
	const qrToggle = () => {
		setIsOpen2(!isOpen2);
	};

	const userInfo = useSelector((state) => state.auth.user_data);

	const copy_input = useRef('');

	const CopyLink = (e) => {
		e.preventDefault();
		copy_input.current.select();
		document.execCommand('copy');
		e.target.focus();
		alert('복사완료');
	};

	const params = useParams();

	const [encryptedText, setEncryptedText] = useState('');

	const encryptData = () => {
		if (user?.ID) {
			setTimeout(() => {
				const key = 'dnjdlek'; // Replace with your secret key
				const encrypted = CryptoJS.AES.encrypt(
					String(user?.ID),
					key
				).toString();
				setEncryptedText(encodeURIComponent(encrypted));
			}, 300);
		}
	};

	return (
		<Wrapper>
			<section className="modal-wrapper">
				<div className="overlay" onClick={modalToggle}></div>
				<div className="modal-wrap">
					<h2 className="modal-title">전자명함과 함께 공유하기</h2>
					<div className="flex">
						<div onClick={CopyLink}>
							<img src="/images/icon-copy.svg" alt="" />
							<p>링크복사</p>
						</div>
						<div onClick={qrToggle}>
							<img src="/images/icon-qr.svg" alt="" />
							<p>QR코드</p>
						</div>
					</div>
					<div onClick={modalToggle} className="close-btn">
						닫기
					</div>
				</div>
				{isOpen2 && (
					<div className="qr-box">
						<div onClick={qrToggle} className="overlay"></div>
						<div className="qrcode">
							<div className="qr">
								<p>전자명함과 함께 공유하기</p>
								<QRCodeCanvas
									value={`https://blog.kbds.co.kr/blog/${params.id}?author=${userInfo?.namecard_hash}`}
								/>
							</div>
							<div className="close" onClick={qrToggle}>
								<img src="/images/modal-close.svg" alt="" />
							</div>
						</div>
					</div>
				)}
			</section>
			<input
				type="text"
				ref={copy_input}
				className="copy_account"
				name="copy_account"
				value={
					user_data
						? `https://blog.kbds.co.kr/blog/${params.id}?author=${userInfo?.namecard_hash}`
						: ''
				}
			/>
		</Wrapper>
	);
}
