import React from 'react';
import { CommonContainer } from '../../../components/Grid';
import { UserContent, UserDetail, UserStatus, UserWrapper } from '../styled';

const UserTitle = (props) => {
	return (
		<UserWrapper className="top-section">
			<CommonContainer>
				{/* <Path>
                    <span>홈</span>
                    <span>Story</span>
                </Path> */}
				<UserContent user_image={props.user.user_avatar_url}>
					<div className="userimg" />
					<UserDetail>
						<strong>{props.user.user_name}</strong>
						<span>{props.user.user_department}</span>
						<div className="description">
							<img src={process.env.PUBLIC_URL + '/pencil-white.svg'} alt="" />
							{props.user.description}
						</div>
						<div></div>
					</UserDetail>
					{/* <small>그룹IT운영부<img src={process.env.PUBLIC_URL + '/Singitem7.svg'} alt=""/> */}
					{/* <span>차장</span></small></strong> */}
				</UserContent>
				<UserStatus>
					<li>
						<span>누적 포인트</span>
						<strong>
							{props.user.user_point_total ? props.user.user_point_total : 0}P
						</strong>
					</li>
					<li>
						<span>현재 포인트</span>
						<strong>
							{props.user.user_point ? props.user.user_point : 0}P
						</strong>
					</li>
					<li>
						<span>봉사시간</span>
						<strong>
							{props.user.volunteerTime ? props.user.volunteerTime : 0}
							시간
						</strong>
					</li>
				</UserStatus>
			</CommonContainer>
		</UserWrapper>
	);
};

export default UserTitle;
