import { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import { GetMyMenteeList } from '../../api/story';
import { GetUserOther } from '../../api/user';
import MainContainer from '../../components/MainContainer';
import Clubs from './components/Clubs';
import MenteeSection from './components/MenteeSection';
import MentorSection from './components/MentorSection';
import ProfilePageHeader from './components/ProfilePageHeader';
import ProfilePagePosts from './components/ProfilePagePosts';
import ProfilePageTopSection from './components/ProfilePageTopSection';
import { Wrapper } from './styled';
import { GetMentees } from '../../api/mentorings';

export default function ProfilePage() {
	const [otherUserInfo, setOtherUserInfo] = useState();
	const params = useParams();

	const user = useSelector((state) => state.auth.user_data);
	// useEffect(() => {
	// 	GetMyMenteeList().then((res) => {
	// 		console.log(555, res.data);
	// 		setMenteeList(res.data.body.mentees);
	// 	});
	// }, []);

	useEffect(() => {
		const paramsId = Number(params.id);
		if (user.ID === paramsId) {
			// window.location.href = '/';
		}
	}, [user.ID, params.id]);

	const [mentees, setMentees] = useState([]);

	useEffect(() => {
		GetMentees({})
			.then((res) => {
				console.log(res);
				setMentees(res?.data?.body?.mentees);
			})
			.then((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		GetUserOther({
			user_id: params.id,
		})
			.then((res) => {
				setOtherUserInfo(res.data.body);
				if (otherUserInfo.user_role === 'delete') {
					alert('퇴직자입니다.');
				}
			})
			.catch((err) => console.log(err));
	}, [params.id, otherUserInfo?.user_role]);

	SwiperCore.use([Navigation]);

	return (
		<MainContainer>
			<Wrapper>
				<ProfilePageHeader />
				<ProfilePageTopSection />
				{otherUserInfo?.role === 'mentee' && (
					<MenteeSection otherUserInfo={otherUserInfo} />
				)}
				{otherUserInfo?.role === 'mentor' && (
					<MentorSection otherUserInfo={otherUserInfo} mentees={mentees} />
				)}
				<ProfilePagePosts otherUserInfo={otherUserInfo} />
				<Clubs />
			</Wrapper>
		</MainContainer>
	);
}
