export default function Likeicon() {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.43922 2.39992C4.35817 0.533361 7.46941 0.533361 9.38837 2.39992L10.5 3.4812L11.6116 2.39992C13.5306 0.533361 16.6418 0.533361 18.5608 2.39992C20.4797 4.26648 20.4797 7.29276 18.5608 9.15932L10.5 17L2.43922 9.15932C0.520261 7.29276 0.520261 4.26648 2.43922 2.39992Z"
        stroke="white"
        stroke-width="1.36842"
        stroke-linecap="round"
      />
    </svg>
  );
}
