import styled from 'styled-components';
import { ColWrap } from '../../../components/Grid/styled';

export const TitleWrapper = styled.div`
	margin-top: 119px;
	@media ${(props) => props.theme.tablet} {
		margin-top: 149px;
	}
	@media ${(props) => props.theme.moblie} {
		margin-top: 82px;
	}
`;
export const TitleImage = styled.div`
	position: relative;
	margin-top: 35px;
	height: 500px;
	background-image: url('${(props) => props.bgImage}');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	&:after {
		background: rgba(0, 0, 0, 0.18);
		position: absolute;
		display: block;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	@media ${(props) => props.theme.laptop} {
		background-attachment: scroll;
	}
	@media ${(props) => props.theme.mobile} {
		height: 250px;
	}
`;

export const PostBadge = styled.div`
	label {
		margin-right: 10px;
	}
	@media ${(props) => props.theme.laptop} {
		padding-left: 20px;
	}
	@media ${(props) => props.theme.tablet} {
		padding-left: 0px;
	}
	@media ${(props) => props.theme.mobile} {
		label {
			padding: 0px 11px;
			font-size: 16px;
			line-height: 34px;
			margin-bottom: 10px;
		}
	}
`;

export const PostTitle = styled.div`
	margin-top: 30px;
	font-family: 'KBFG Display';
	font-style: normal;
	h2 {
		font-size: 38px;
		line-height: 64px;
		color: #000000;
	}
	p {
		color: #bfbfbf;
		margin-top: 12px;
		font-size: 14px;
		font-weight: 700;
		line-height: 160%;
	}
	@media ${(props) => props.theme.laptop} {
		padding-left: 20px;
	}
	@media ${(props) => props.theme.tablet} {
		padding-left: 0px;
		h2 {
			font-size: 42px;
			line-height: 62px;
		}
	}
	@media ${(props) => props.theme.mobile} {
		margin-top: 10px;
		h2 {
			font-size: 24px;
			line-height: 38px;
		}
	}
`;

export const PostSubTitle = styled.div`
	margin-top: 43px;
	font-family: 'KBFG Text';
	font-style: normal;
	font-weight: bold;
	transition: 0.3s;
	cursor: pointer;
	.top-menu-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.name-like-box {
		display: flex;
		gap: 15px;
	}
	.name {
		transition: 0.3s;
		:hover {
			opacity: 0.7;
		}
		padding: 10px 22px;
		background: #ffffff;
		border: 1px solid #eeeeee;
		box-sizing: border-box;
		border-radius: 40px;
		font-size: 17px;
		line-height: 22px;
		color: #444444;
		display: inline-block;

		span {
			padding-left: 16px;
		}
	}
	.like-box {
		display: flex;
		.like-btn {
			transition: 0.3s;
			:hover {
				opacity: 0.7;
			}
			padding: 13px 25px;
			background-color: #000;
			color: #fff;
			border: none;
			border-radius: 41px;
			display: flex;
			gap: 6px;
			align-items: center;
			font-weight: 500;
			font-size: 17px;
			line-height: 25px;
			.icon-like {
				svg {
					position: relative;
					top: 3px;
				}
			}
			&.active {
				.icon-like {
					svg path {
						fill: #f41717;
						stroke: #f41717;
					}
				}
			}
		}
	}
	.Sharing {
		display: none;
		border: 2px solid red;
		border-radius: 50px;
		border: 1px solid #eee;
		background: #f4f3f2;
		width: 46px;
		height: 46px;
		position: relative;
		cursor: pointer;
		transition: all 0.3s;
		&:hover {
			opacity: 0.8;
		}
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	p {
		margin-top: 15px;
		display: block;
		float: right;
		font-size: 15px;
		line-height: 19px;
		color: #bfbfbf;
	}
	.views {
		margin-right: 15px;
		padding-right: 15px;
		border-right: 1px solid #eeeeee;
	}
	@media ${(props) => props.theme.laptop} {
		padding: 0px 20px;
	}
	@media ${(props) => props.theme.tablet} {
		padding: 0px 0px;
		margin-top: 20px;
	}
	@media ${(props) => props.theme.mobile} {
		margin-top: 20px;
		.Sharing {
			display: block;
		}

		.name-like-box {
			gap: 8px;
			align-items: center;
			width: 80%;
		}
		.name {
			padding: 10px 15px;
			font-size: 18px;
			line-height: 23px;
			span {
				font-size: 15px;
			}
		}
		.like-box {
			.like-btn {
				padding: 10px 16px;
				border-radius: 30px;
				gap: 4px;
				font-size: 12px;
				line-height: 17px;
				height: 38px;
				.icon-like {
					svg {
						width: 14px;
					}
				}
			}
		}

		.views {
			border-right: none;
			margin-right: 0px;
			padding-right: 0px;
		}
		p {
			margin-top: 0px;
			font-size: 14px;
			line-height: 23px;
		}
	}
`;
export const Avatar = styled.div`
	background-image: url(${(props) => props.image});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: inline-block;
	vertical-align: middle;
	@media ${(props) => props.theme.laptop} {
		padding-left: 20px;
	}
	@media ${(props) => props.theme.tablet} {
		padding-left: 0px;
	}
	@media ${(props) => props.theme.mobile} {
		width: 20px;
		margin-top: 0px;
		height: 20px;
	}
`;

export const FigureIcon = styled.div`
	position: absolute;
	top: 0px;
	right: 0px;
	height: 100%;
	ul {
		position: sticky;
		top: 50px;
	}
	li {
		display: block;
		position: relative;
		padding-bottom: 80px;
		a {
			width: 52px;
			height: 52px;
			position: absolute;
			box-sizing: border-box;
			border: 1px solid #eeeeee;
			border-radius: 50%;
			text-align: center;
			line-height: 52px;
		}
		img {
			display: inline-block;
		}
	}
	@media ${(props) => props.theme.laptop} {
		right: 100px;
	}
	@media ${(props) => props.theme.tablet} {
		right: 80px;
	}
	@media ${(props) => props.theme.mobile} {
		position: static;
		margin-top: 50px;
		margin-bottom: 0px;
		margin-left: 20px;
		margin-right: 0px;
		li {
			padding-right: 60px;
			display: inline-block;
			position: relative;
			a {
				width: 52px;
				height: 52px;
				box-sizing: border-box;
				border: 1px solid #eeeeee;
				border-radius: 50%;
				display: block;
				text-align: center;
				line-height: 52px;
				img {
					display: inline-block;
					margin-top: 0px;
					margin-left: 0px;
				}
			}
		}
	}
`;

//SectionTitle End

export const ContentWrapper = styled.div`
	margin-bottom: 81px;
	padding-top: 50px;
	.container-post_content {
		position: relative;
		.download a {
			color: #111;
		}
	}
	@media ${(props) => props.theme.mobile} {
		.container-post_content {
			padding: 0px 0px;
			.download a {
				font-size: 14px;
			}
		}
	}
`;

export const PostContent = styled.div`
	font-family: 'KBFG Text';
	font-style: normal;
	position: relative;
	font-weight: normal;
	font-size: 17px;
	line-height: 32px;
	color: #444444;
	ol,
	ul {
		list-style: none;
	}

	p {
		font-weight: normal;
		font-size: 17px;
		line-height: 32px;
		color: #444444;
		padding: 0;
		min-height: 22px;
		margin-bottom: 12px;
	}
	h3 {
		margin-bottom: 10px;
		font-size: 20px;
		line-height: 32px;
		color: #000000;
	}
	a {
		margin-top: 9px;
		font-weight: normal;
		font-size: 17px;
		line-height: 32px;
		text-decoration-line: underline;
		color: #ffac00;
	}
	strong {
		white-space: initial;
	}
	.wp-block-code {
		background: #f7f7f7;
		padding: 30px;
		line-height: 20px;
		overflow-x: scroll;
	}

	figcaption {
		color: #555555;
		font-size: 15px;
		text-align: center;
	}

	.has-text-align-center {
		text-align: center;
	}
	.has-medium-font-size {
		font-size: 18px;
	}

	.has-pale-pink-color {
		color: #f78da7;
	}

	.has-vivid-red-color {
		color: #cf2e2e;
	}

	.has-luminous-vivid-orange-color {
		color: #ff6900;
	}

	.has-luminous-vivid-amber-color {
		color: #fcb900;
	}

	.has-light-green-cyan-color {
		color: #7bdcb5;
	}

	.has-vivid-green-cyan-color {
		color: #00d084;
	}

	.has-pale-cyan-blue-color {
		color: #8ed1fc;
	}

	.has-vivid-cyan-blue-color {
		color: #0693e3;
	}

	.has-vivid-purple-color {
		color: #9b51e0;
	}

	.has-white-color {
		color: #fff;
	}

	.has-very-light-gray-color {
		color: #eee;
	}

	:root .editor-styles-wrapper .has-cyan-bluish-gray-color {
		color: #abb8c3;
	}

	:root .editor-styles-wrapper .has-very-dark-gray-color {
		color: #313131;
	}

	:root .editor-styles-wrapper .has-black-color {
		color: #000;
	}
	.contentimg {
		position: relative;
		margin-bottom: 30.45px;
		height: 638.55px;
		background-image: url(${(props) =>
			props.theme.image_url}/common/Singitem3.png);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	img {
		max-width: 100%;
		height: auto;
		display: block;
		margin: 25px auto;
	}
	/* p img {
  } */
	iframe {
		max-width: 100%;
		height: auto;
		width: 100%;
		min-height: 500px;
	}
	@media ${(props) => props.theme.mobile} {
		iframe {
			min-height: 260px;
		}
	}
`;

export const Blockquote = styled.div`
	position: relative;
	font-family: 'KBFG Text';
	font-style: normal;
	font-weight: bold;
	font-size: 17px;
	line-height: 32px;
	color: #000000;
	text-align: center;
	height: 70px;
	margin-top: 30px;
	margin-bottom: 40px;
	padding: 19px 36px;
	background-color: #f7f7f7;
	border-left: 5px solid #ffdc00;
`;

//SectionContent End

export const CommentWrapper = styled.div`
	margin-top: 29px;
	@media ${(props) => props.theme.mobile} {
		border-top: 15px solid #eee;
		border-bottom: 15px solid #eee;
		padding-top: 50px;
		padding-bottom: 130px;
	}
`;

export const CommentTitle = styled.div`
	font-family: 'KBFG Text';
	font-style: normal;
	border-top: 1px solid #eeeeee;
	.comment-box {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
	}
	.like-box {
		display: flex;
		gap: 0 15px;
		.like-btn.white {
			font-size: 16px;
			background-color: #fff;
			color: #333;
			border: 1px solid #e9e9e9;
			min-width: 99px;
			text-align: center;
			justify-content: center;
			transition: 0.3s;
			:hover {
				opacity: 0.7;
			}
		}
		.like-btn {
			padding: 13px 25px;
			background-color: #000;
			color: #fff;
			border: none;
			border-radius: 41px;
			display: flex;
			gap: 6px;
			align-items: center;
			font-weight: 500;
			font-size: 17px;
			line-height: 25px;
			transition: 0.3s;
			:hover {
				opacity: 0.7;
			}
			.icon-like {
				svg {
					position: relative;
					top: 3px;
				}
			}
			&.active {
				.icon-like {
					svg path {
						fill: #f41717;
						stroke: #f41717;
					}
				}
			}
		}
	}
	h3 {
		padding-top: 30px;
		font-size: 20px;
		line-height: 32px;
		color: #222222;
	}
	:nth-of-type(1) {
		span {
			position: relative;
			display: inline-block;
			font-size: 20px;
			line-height: 32px;
			color: #ffac00;
			margin-left: -15px;
		}
	}
	@media ${(props) => props.theme.mobile} {
		padding-top: 40px;
		h3 {
			padding-top: 0px;
			border-top: 0px;
		}
		.like-box {
			.like-btn.white {
				font-size: 14px;
				min-width: 89px;
			}
			.like-btn {
				padding: 10px 16px;
				border-radius: 30px;
				gap: 4px;
				height: 38px;
				font-size: 12px;
				line-height: 17px;
				.icon-like {
					svg {
						width: 14px;
					}
				}
			}
		}
	}
`;

export const UserImg = styled.div`
	background-image: url(${(props) => props.bg});
	background-size: cover;
	cursor: pointer;
	background-position: center center;
	margin-top: 38px;
	width: 50px;
	height: 50px;
	border-radius: 100px;
	@media ${(props) => props.theme.mobile} {
		width: 35px;
		height: 35px;
		margin-top: 20px;
	}
`;
export const CommentItemStyle = styled.div`
	border-bottom: 1px solid #eeeeee;
	padding-bottom: 23px;
	position: relative;

	.reply_user_name {
		position: absolute;
		top: 10px;
		left: 20px;
	}
	.comment_reply_user_name {
		display: inline-block;
		color: #ffac00;
		font-family: 'KBFG Display';
		font-size: 15px;
		font-style: normal;
		font-weight: 300;
		line-height: 26px; /* 173.333% */
		margin: 0 6px 0 0;
		padding: 0;
	}
	strong {
		cursor: pointer;
	}
	small {
		color: #888;
		font-family: 'KBFG Display';
		font-size: 13px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		margin-left: 8px;
		cursor: pointer;
		transition: 0.2s;
		&:hover {
			opacity: 0.7;
		}
	}
`;
export const DeleteLink = styled.a`
	position: absolute;
	top: 45px;
	right: 0px;
	color: #ccc;
	text-decoration: none;
	font-size: 12px;
	transition: 0.3s;
	:hover {
		color: red;
	}
`;
export const PostComment = styled.div`
	font-family: 'KBFG Text';
	font-style: normal;

	strong {
		position: relative;
		display: block;
		margin-top: 45px;
		padding-left: 19px;
		font-size: 16px;
		font-weight: bold;
		line-height: 20px;
		color: #000000;
		small {
			padding-left: 6px;
			font-size: 13px;
			font-weight: normal;
			line-height: 17px;
			color: #888888;
		}
	}
	span {
		position: relative;
		display: block;
		font-weight: normal;
		padding-left: 19px;
		font-size: 13px;
		line-height: 20px;
		color: #888888;
	}
	p {
		padding-top: 23px;
		font-weight: normal;
		font-size: 15px;
		line-height: 26px;
		color: #222222;
	}
	@media ${(props) => props.theme.mobile} {
		${ColWrap} {
			display: inline-block;
		}
		strong {
			margin-top: 25px;
		}
	}
`;

export const PostCommnetBox = styled.div`
	margin-top: 61px;
	/* margin-bottom: 100px; */
	font-family: 'KBFG Text';
	font-style: normal;
	position: relative;
	${UserImg} {
		width: 75px;
		height: 75px;
		margin-top: 0px;
		margin-right: 25px;
	}

	.input-wrap {
		position: relative;
	}
	.reply_user_name {
		position: absolute;
		top: 24.5px;
		left: 30px;
		color: #000;
		font-family: 'KBFG Text';
		font-size: 17px;
		font-style: normal;
		font-weight: 300;
		line-height: 25px; /* 147.059% */
	}

	.cancel {
		position: absolute;
		top: -8px;
		left: 30px;
		transform: translateY(-100%);
		color: #888;
		font-family: 'KBFG Text';
		font-size: 15px;
		font-style: normal;
		font-weight: 300;
		line-height: 25px;
		svg {
			vertical-align: text-top;
			position: relative;
			top: -1.5px;
			padding: 6px;
			cursor: pointer;
			&:hover {
				opacity: 0.7;
			}
		}
	}

	.textcomment {
		border: 1px solid #e1e1e1;
		box-sizing: border-box;
		border-radius: 60px;
		width: 100%;
		height: 75px;
		padding-left: 30px;
		padding-right: 130px;
		font-weight: 300;
		font-size: 17px;
		line-height: 25px;
		color: #888888;
	}
	.reply {
		/* padding-left: 112px; */
		padding-left: ${(props) =>
			props.width ? `${props.width + 36}px` : '30px'};
	}
	.cancel {
		top: -6px;
		left: 20px;
		color: #888;
		font-family: 'KBFG Text';
		font-size: 12px;
		font-style: normal;
		font-weight: 300;
		line-height: 25px; /* 208.333% */
		svg {
			width: 10px;
			height: 10px;
			top: -1.5px;
			padding: 4px;
			padding-left: 6px;
			cursor: pointer;
			&:hover {
				opacity: 0.7;
			}
		}
	}
	button {
		position: absolute;
		top: 12px;
		right: 12px;
		width: 100px;
		height: 50px;
		background: #ffac00;
		border: 1px solid white;
		box-sizing: border-box;
		border-radius: 60px;
		font-weight: 500;
		font-size: 15px;
		line-height: 19px;
		text-align: center;
		color: #ffffff;
		float: right;
	}
	@media ${(props) => props.theme.mobile} {
		margin-top: 51px;
		.textcomment {
			/* position: absolute; */
			width: 100%;
			top: 0px;
			height: 50px;
			font-size: 14px;
			padding-left: 20px;
			padding-right: 100px;
		}
		.reply {
			/* padding-left: 112px; */
			padding-left: ${(props) =>
				props.width ? `${props.width + 26}px` : '26px'};
		}
		.reply_user_name {
			top: 12px;
			left: 20px;
			color: #000;
			font-family: 'KBFG Text';
			font-size: 14px;
			font-style: normal;
			font-weight: 300;
			line-height: 25px; /* 178.571% */
		}

		button {
			top: 5px;
			right: 5px;
			width: 80px;
			height: 40px;
			font-size: 13px;
		}
		${UserImg} {
			display: none;
		}
		${ColWrap} {
			display: block;
			width: 100%;
		}
	}
`;

//SectionCommnet End

export const UserWrapper = styled.div`
	padding-top: 150px;
	@media ${(props) => props.theme.laptop} {
		padding-top: 140px;
	}
	@media ${(props) => props.theme.tablet} {
		padding-top: 100px;
	}
	@media ${(props) => props.theme.mobile} {
		padding-top: 90px;
	}
`;

export const UserBox = styled.div`
	box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.05);
	border-radius: 0px 0px 20px 20px;
	margin-bottom: 20px;
	overflow: hidden;
`;
export const UserItemBackground = styled.div`
	position: relative;
	cursor: pointer;
	background: #000;
	border-radius: 20px 20px 0px 0px;
	height: 178px;

	@media ${(props) => props.theme.mobile} {
		height: auto;
	}
`;

export const UserItemUser = styled.div`
	font-family: 'KBFG Text';
	font-style: normal;
	text-align: left;
	position: relative;
	z-index: 1;
	.userimg {
		background-image: url(${(props) => props.image});
		background-color: #eee;
		width: 116px;
		height: 116px;
		margin: 0 auto;
		background-size: cover;
		background-repeat: no-repeat;
		border-radius: 100px;
		position: absolute;
		left: 50px;
		top: 30px;
	}
	.profile {
		padding-left: 190px;
	}
	strong {
		padding-top: 40px;
		font-size: 24px;
		line-height: 36px;
		color: #fff;
		display: block;
		small {
			margin-top: 5px;
			font-size: 14px;
			line-height: 23px;
			color: #fff;
			margin-left: 10px;
			font-weight: normal;
		}
		span {
			margin-top: 5px;
			font-weight: bold;
			font-size: 14px;
			line-height: 23px;
			color: #fff;
		}
		img {
			width: 3px;
			height: 3px;
			margin: 0px 5px;
			margin-bottom: 5px;
		}
	}
	p {
		margin-top: 5px;
		font-weight: normal;
		font-size: 14px;
		line-height: 30px;
		color: #fff;
		padding-bottom: 36px;
		img {
			margin-right: 5px;
		}
	}
	@media ${(props) => props.theme.tablet} {
		p {
			padding-bottom: 25px;
		}
	}
	@media ${(props) => props.theme.mobile} {
		.userimg {
			background-image: url(${(props) => props.image});
			background-color: #eee;
			width: 90px;
			height: 90px;
			border: 3px solid #fff;
			margin: 0 auto;
			background-size: cover;
			background-repeat: no-repeat;
			border-radius: 100px;
			position: absolute;
			right: 20px;
			left: auto;
			top: auto;
			bottom: 0px;
			transform: translateY(50%);
		}
		.profile {
			padding-left: 30px;
			padding-bottom: 30px;
		}
		p {
			font-size: 12px;
		}
		strong {
			padding-top: 20px;
		}
		.userimg {
			width: 100px;
			height: 100px;
		}
	}
`;
export const UserStatus = styled.ul`
	width: 100%;
	background: #fff;
	padding: 30px;
	text-align: center;

	li {
		display: inline-block;
		padding: 0px 60px;
		border-right: 1px solid #eee;
		span {
			color: #666;
			font-size: 14px;
			letter-spacing: -1px;
			margin-bottom: 5px;
			display: block;
		}
		strong {
			font-size: 16px;
			font-weight: bold;
			color: #000;
			display: block;
		}
		:last-child {
			border-right: none;
		}
	}

	@media ${(props) => props.theme.mobile} {
		width: 100%;
		display: flex;
		margin-top: 30px;
		justify-content: space-around;
		li {
			width: 100%;
			padding: 0px 0px;
		}
	}
`;

export const UserItemBadge = styled.div`
	margin-top: 20px;
	margin-bottom: 50px;
	text-align: center;
	position: relative;
	z-index: 1;
	label {
		margin-right: 10px;
	}
`;

export const UserItem = styled.div`
	z-index: 1;
	font-family: 'KBFG Dispaly';
	font-style: normal;
	position: relative;
	text-align: center;
	font-weight: bold;
	a {
		font-family: 'KBFG Display';
		font-style: normal;
		font-weight: bold;
		margin-top: 35px;
		font-size: 15px;
		line-height: 19px;
		color: #6c6c6c;
	}
	@media ${(props) => props.theme.laptop} {
		p {
			font-size: 18px;
		}
	}
`;

export const UserSliderStyle = styled.div`
	position: relative;
	.slick-arrow {
		z-index: 3;
	}
	.slick-prev,
	.slick-next {
		font-size: 0;
		position: absolute;
		bottom: 20px;
		border: 0;
		background: none;
		z-index: 1;
		cursor: pointer;
		background: #fff;
		width: 60px;
		height: 60px;
		border-radius: 50%;
	}
	.slick-prev {
		/* left: 50%; */
		margin-left: -60px;
		/* top: 506px; */
	}
	.slick-prev:before {
		content: '';
		background-image: url(${(props) =>
			props.theme.image_url}/common/arrow-before.svg);
		width: 20px;
		height: 20px;
		display: inline-block;
		background-size: 100%;
	}
	.slick-next {
		margin-right: -60px;
		/* left: 50%; */
		/* margin-left:50px; */
		/* top: 506px; */
		@media ${(props) => props.theme.tablet} {
			display: none !important;
		}
		@media ${(props) => props.theme.mobile} {
			display: none !important;
		}
	}
	.slick-next:before {
		content: '';
		background-image: url(${(props) =>
			props.theme.image_url}/common/arrow-next.svg);
		width: 20px;
		height: 20px;
		display: inline-block;
		background-size: 100%;
	}
	.slick-prev:hover:after,
	.slick-next:hover:after {
		color: red;
	}
	@media ${(props) => props.theme.laptop} {
		padding: 0px 20px;
		.slick-prev,
		.slick-next {
			border-radius: 0%;
			width: 0px;
			height: 0px;
		}
		.slick-prev:before {
			display: none;
		}
		.slick-next:before {
			display: none;
		}
	}
	@media ${(props) => props.theme.tablet} {
		padding: 0px 0px;
	}
`;
export const UserSlideItem = styled.div`
	margin-top: 20px;
	padding: 20px;
	background: rgba(0, 0, 0, 0.4);
	height: 180px;
	text-align: center;
	font-size: 19px;
	line-height: 32px;
	color: #ffffff;
	position: relative;
	background-image: url(${(props) => props.image});
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 180px;
		background: rgba(0, 0, 0, 0.5);
	}
	span {
		display: flex;
		position: relative;
		z-index: 1;
		justify-content: center;
		flex-direction: column;
		height: 100%;
		font-weight: 600;
	}
`;
export const UserSliderButtons = styled.div`
	z-index: 1;
	a {
		position: absolute;
		display: block;
		top: 50%;
		margin-top: -17px;
		width: 35px;
		height: 35px;
		text-align: center;
		line-height: 35px;
		background: #fff;
		border-radius: 50%;
		text-decoration: none;
	}
	a:first-child {
		left: -60px;
	}
	a:last-child {
		right: -60px;
	}
`;

//SectionUser End

export const PostCardWrapper = styled.div`
	margin-top: 225px;
	h3 {
		font-family: 'KBFG Display';
		font-style: normal;
		font-size: 28px;
		line-height: 35px;
		color: #000000;
		text-align: center;
	}
	@media ${(props) => props.theme.mobile} {
		margin-top: 120px;
		h3 {
			font-size: 24px;
			line-height: 30px;
		}
	}
`;
