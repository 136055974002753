import axios from './defaultClient';

const nameSpace = '/ep/v1/slider';
export const GetMainSlider = async (args) => {
    return await axios.get( nameSpace + '/main',{})
}
export const GetStorySlider = async (args) => {
    return await axios.get( nameSpace + '/story',{ 
        params:{
            cate_slug: args.cate_slug,
        }
    })
}