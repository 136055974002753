import styled from 'styled-components';

export const Wrapper = styled.div`
	.flex {
		display: flex;
		justify-content: space-between;
		.title {
			font-size: 15px;
			font-weight: 700;
			line-height: normal;
		}
		.option-title {
			font-size: 14px;
			font-weight: 500;
			line-height: normal;
			span {
				font-weight: 700;
			}
		}
	}
	.count-wrap {
		margin-top: 23px;
		display: flex;
		justify-content: space-between;
		border-radius: 10px;
		background: #f5f8fb;
		padding: 16px 18px;
		.count {
			display: flex;
			align-items: center;
			gap: 8px;
			p {
				width: 24px;
				height: 24px;
				border-radius: 50%;
				border: 1px solid #eee;
				position: relative;
				background-color: #fff;
				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
			span {
				font-size: 15px;
				font-weight: 700;
				line-height: normal;
			}
		}
	}
	.total {
		font-size: 15px;
		font-weight: 700;
		line-height: normal;
	}
	.total-wrap {
		border-top: 1px solid #eee;
		margin-top: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.title {
			font-size: 13px;
			font-weight: 700;
			line-height: normal;
			margin-top: 16px;
		}
		.total {
			font-size: 15px;
			font-weight: 700;
			line-height: normal;
		}
	}
	.btn-wrap {
		margin-top: 21px;
		display: flex;
		gap: 10px;
		.btn {
			border-radius: 30px;
			background: #000;
			width: 50%;
			height: 56px;
			color: #fff;
			text-align: center;
			font-size: 14px;
			font-weight: 700;
			line-height: 54px;
			letter-spacing: -0.28px;
			cursor: pointer;
			transition: all 0.3s;
			&:hover {
				opacity: 0.8;
			}
		}
		.cart {
			border: 1px solid #000;
			background: #fff;
			color: #000;
		}
	}
	@media ${(props) => props.theme.mobile} {
	}
`;
