import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  background: #f3f3f3;
  padding: 80px 10%;
  .see-all {
    background-color: transparent;
    border: none;
    border-top: 1px solid #eeeeee;
    padding-top: 16px;
    margin-top: 30px;
    padding-bottom: 14px;
    width: 100%;
  }
  .see-all-comments {
    background-color: transparent;
    border: none;
    border-top: 1px solid #eeeeee;
    padding-top: 16px;
    padding-bottom: 14px;
    width: 80%;
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
  }
  .general-post-wrapper {
    @media (max-width: 768px) {
      margin-top: 300px;
    }
    .right-mobile {
      width: 100%;
      height: 100%;
      min-height: 80px;
      margin: 0 auto;
      background-color: white;
      position: relative;
      display: flex !important;
      justify-content: space-between;
      margin-bottom: 16px;
      cursor: pointer;
      align-items: center;
      padding: 0 20px;
      border: none;
      border-radius: 10px;
      @media (min-width: 769px) {
        display: none !important;
      }
      span {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        align-items: center;
        i {
          margin-right: 10px;
        }
      }
      button {
        background-color: transparent;
        border: none;
        color: black;
      }
      @media (max-width: 768px) {
        display: block;
      }
    }
  }
  .post-wrapper {
    @media (max-width: 768px) {
      margin-top: 0px;
    }
    .right-mobile {
      width: 100%;
      height: 100%;
      min-height: 80px;
      margin: 0 auto;
      background-color: white;
      position: relative;
      display: flex !important;
      justify-content: space-between;
      margin-bottom: 16px;
      cursor: pointer;
      align-items: center;
      padding: 0 20px;
      border: none;
      border-radius: 10px;
      @media (min-width: 769px) {
        display: none !important;
      }
      span {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        align-items: center;
        i {
          margin-right: 10px;
        }
      }
      button {
        background-color: transparent;
        border: none;
        color: black;
      }
      @media (max-width: 768px) {
        display: block;
      }
    }
  }
  i {
    vertical-align: middle;
  }
  @media (max-width: 1920px) {
    /* padding: 80px 10%; */
  }
  @media (max-width: 1300px) {
    /* padding: 80px 5%; */
  }
  @media (max-width: 768px) {
    /* padding: 50px 5%; */
  }
  .see-alls {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    background-color: white;
    border-radius: 40px;
    border: none;
    display: block;
    margin: 0 auto;
    width: 104px;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    :hover {
      color: #9a9a9a;
      transition: 0.1s ease-out;
    }

    @media (max-width: 768px) {
      border-top: 1px solid #eeeeee;
      padding-top: 16px;
      width: 80%;
      border-radius: 0;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
  }

  h1 {
    font-weight: 700;
    font-size: 48px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
  .first {
    margin-top: 80px !important;
    @media (max-width: 768px) {
      margin-top: 20px !important;
    }
  }
  .post-title {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 25px;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 20%;

    @media (max-width: 768px) {
      margin-bottom: 10px;
    }

    .padlet-btn {
      background-color: transparent;
      border: none;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.02em;
      padding: 8px 16px;
      @media (max-width: 768px) {
        font-size: 14px;
      }
      :hover {
        transform: translateX(2px) translateY(-2px);
        transition: 0.1s ease-out;
      }
    }
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;

      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
    .new-post {
      position: absolute;
      right: 32%;
      padding-top: 8px;
      background-color: transparent;
      border: none;
      font-weight: 700;
      font-size: 17px;
      line-height: 15px;
      letter-spacing: -0.03em;
      align-items: center;
      i {
        vertical-align: middle;
      }

      @media (max-width: 768px) {
        right: 6%;
        font-size: 12px;
      }
      cursor: pointer;
      span {
        margin-left: 8px;
        padding-bottom: 10px;
      }
      :hover {
        transform: scale(1.04);
        transition: 0.1s ease-out;
      }
    }
    .comments {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      width: 100%;
      position: absolute;
      left: 71%;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`;
export const SliderWrapper = styled.div`
  position: relative;
  background-color: white;
  width: 100%;
  max-width: 1400px;
  margin: auto;
  border-radius: 28px;
  margin-top: 24px;
  padding: 50px;
  padding-bottom: 16px;
  padding-right: 0;
  overflow: hidden;

  .no-mentee-info {
    margin: 80px auto;
    width: 400px;
    display: flex;
    flex-direction: column;
    text-align: center;
    i {
      margin-bottom: 25px;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }

  .gradient {
    border-radius: 28px;
    position: absolute;
    right: 0;
    top: 0;
    width: 8%;
    height: 100%;
    z-index: 9;
    background: linear-gradient(
      270deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .kbds-box {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .shortcut-btn {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.02em;
        position: relative;
        top: -1px;
      }
      .padlet-btn {
        background-color: white;
        border: none;
        font-weight: 700;
        font-size: 16px;
        padding: 8px 16px;
        margin-right: 40px;
        line-height: 20px;
        letter-spacing: -0.02em;
        :hover {
          transform: translateX(2px) translateY(-2px);
          transition: 0.1s ease-out;
        }
        i {
          margin-right: 8px;
        }
      }
    }

    .title {
      display: flex;
      h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        margin-right: 16px;
      }
      span {
        border: 1px solid #ffac00;
        border-radius: 100px;
        padding: 6px 12px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.02em;
        color: #ffac00;
      }
    }
  }
  .ing-box {
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 26px;
    .mentoring-box {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 300;
      font-size: 15px;
      line-height: 19px;
      button {
        background: rgba(255, 188, 0, 0.12);
        border-radius: 100px;
        border: none;
        padding: 7px 11px;
      }
    }
    .new-add {
      border: 1px solid blue;
      border: none;
      background-color: #fff;
      color: #aaaaaa;
    }
  }
  .swiper-card {
    margin-top: 12px;
    width: 100%;
    background: white;
    border: 1px solid #e9e9e9;
    border-radius: 20px;
    padding: 32px 24px;
    display: block;
    overflow: hidden;
    :hover {
      opacity: 90%;
      transition: 0.1s ease-out;
      img {
        scale: 1.1;
        transition: 0.1s ease-out;
      }
    }
    .top,
    .bottom {
      display: flex;
      position: relative;
    }
    .top {
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
    .circular-progress-bar {
      position: absolute;
      right: 0;
      top: 4px;
      span {
        color: #ffbc00;
      }
    }
    .progress {
      text-align: center;
      position: absolute;
      padding-left: 18px;
      bottom: 18px;
      right: -8px;
      width: 64px;
      height: 64px;
      border-radius: 50% !important;
      border: 2px solid #999999;
      display: flex;
      align-items: center;
      span {
        color: #ffbc00;
      }
    }

    .person-info {
      span {
        display: block;
        margin-left: 16px;
      }
      span:first-child {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: black;
        margin-bottom: 10px;
      }
      span:last-child {
        font-weight: 300;
        font-size: 16px;
        line-height: 18px;
        color: #aaaaaa;
      }
    }
    .duration {
      margin-top: 42px;
      span {
        display: block;
        :first-child {
          font-weight: 300;
          font-size: 15px;
          line-height: 19px;
          margin-bottom: 8px;
          color: #aaaaaa;
        }
        :last-child {
          font-weight: 300;
          font-size: 15px;
          line-height: 19px;
          color: black;
        }
      }
    }
  }
`;
export const MobileSliderWrapper = styled.div`
  position: relative;
  background-color: white;
  width: 100%;
  max-width: 1400px;
  margin: auto;
  padding: 32px 18px;
  padding-bottom: 10px;
  padding-right: 0;
  border-radius: 10px;
  overflow: hidden;

  .no-mentee-info {
    margin: 80px auto;
    display: flex !important;
    flex-direction: column !important;
    text-align: center;
    i {
      margin-bottom: 25px;
    }
  }
  .circular-progress-bar {
    position: absolute;
    right: 0;
    top: 4px;
    span {
      color: #ffbc00;
    }
  }

  .gradient {
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0) 43.05%
    );
    width: 14%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
  }

  @media (max-width: 768px) {
    display: block;
    margin-top: 296px;
  }
  @media (min-width: 769px) {
    display: none;
  }
  .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      display: flex;
      align-items: center;
      h1 {
        font-size: 20px;
      }
      span {
        font-size: 14px;
        padding: 4px 8px;
      }
      .new-mentee {
        position: absolute;
        right: 5%;
        border: none;
        background-color: transparent;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.03em;
        :hover {
          transform: scale(1.02);
          transition: 0.1s ease-out;
        }
        i {
          margin-right: 4px;
        }
      }
      .mentor-status {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        margin-right: 16px;
      }
      span {
        border: 1px solid #ffac00;
        border-radius: 100px;
        padding: 6px 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.02em;
        color: #ffac00;
        margin-left: 12px;
      }
    }
    .padlet {
      margin-right: 30px;
      .padlet-btn {
        background-color: transparent;
        border: none;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  .swiper-card {
    background-color: white;
    border: 1px solid #e9e9e9;
    margin-top: 12px;
    width: 100%;
    min-width: 240px;
    border-radius: 10px;
    display: block;
    padding: 20px;
    overflow: hidden;
    .close-btn {
      position: absolute;
      right: 40px;
    }
    :hover {
      opacity: 90%;
      transition: 0.1s ease-out;
      img {
        scale: 1.1;
        transition: 0.1s ease-out;
      }
    }
    .top,
    .bottom {
      display: flex;
      position: relative;
    }
    .top {
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
    .progress {
      text-align: center;
      position: absolute;
      padding-left: 18px;
      bottom: 0;
      right: 0;
      width: 64px;
      height: 64px;
      border-radius: 50% !important;
      border: 2px solid #999999;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        bottom: 12px;
      }
      span {
        color: #ffbc00;
      }
    }

    .person-info {
      span {
        display: block;
        margin-left: 16px;
      }
      span:first-child {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: black;
        margin-bottom: 10px;
      }
      span:last-child {
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        color: #aaaaaa;
      }
    }
    .duration {
      margin-top: 42px;
      span {
        display: block;
        :first-child {
          font-weight: 300;
          font-size: 15px;
          line-height: 19px;
          margin-bottom: 8px;
          color: #aaaaaa;
        }
        :last-child {
          font-weight: 300;
          font-size: 15px;
          line-height: 19px;
          color: black;
        }
      }
    }
  }
`;
export const MiddleSectionWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1400px;
  margin: 24px auto;
  position: relative;
  margin-top: 300px;
  @media (max-width: 768px) {
    display: block;
  }
  @media (min-width: 769px) {
    display: flex;
    margin-top: 0;
  }
  .right-mobile {
    width: 100%;
    height: 100%;
    min-height: 80px;
    margin: 0 auto;
    background-color: white;
    position: relative;
    display: flex !important;
    justify-content: space-between;
    margin-bottom: 16px;
    cursor: pointer;
    padding: 0 20px;
    align-items: center;
    border: none;
    border-radius: 10px;
    @media (min-width: 769px) {
      display: none !important;
    }
    span {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      align-items: center;
      i {
        margin-right: 10px;
      }
    }
    button {
      background-color: transparent;
      border: none;
      color: black;
    }
    @media (max-width: 768px) {
      display: block;
    }
  }
  div {
    position: relative;
    border-radius: 28px;
    color: black;
    @media (max-width: 768px) {
      border-radius: 10px;
    }
  }

  span {
    display: block;
    margin: 8px 0;
    strong {
      font-weight: 900;
    }
  }

  .left {
    position: relative;
    background-color: white;
    padding: 30px 20px;
    width: 100%;
    margin: auto;
    padding-bottom: 10px;
    @media (min-width: 769px) {
      width: 70%;
      padding: 40px 70px;
    }

    .title-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        display: flex;
        align-items: center;
        h1 {
          font-weight: 700;
          font-size: 24px;
          @media (max-width: 768px) {
            font-size: 20px;
          }
        }
        span {
          padding: 6px 12px;
          font-weight: 500;
          font-size: 12px;
          color: #ffac00;
          margin-left: 20px;
          border: 1px solid #ffac00;
          border-radius: 100px;
          @media (max-width: 768px) {
            margin-left: 12px;
          }
        }
      }
      .padlet {
        i {
          margin-right: 8px;
          @media (max-width: 768px) {
            display: none;
          }
        }
        .padlet-btn {
          background-color: transparent;
          border: none;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.02em;
          padding: 8px 16px;
          @media (max-width: 768px) {
            font-size: 14px;
          }
          :hover {
            transform: translateX(2px) translateY(-2px);
            transition: 0.1s ease-out;
          }
        }
      }
    }

    .progress-wrap {
      width: 100%;
    }
    .progress-status {
      text-align: center;
      display: flex;
      align-items: end;
      justify-content: space-between;
      position: relative;
      height: 100%;
      overflow-x: scroll;
      overflow-y: visible;
      position: relative;
      margin-top: 64px;
      .progress-bar {
        position: absolute;
        width: 200%;
        border-bottom: 2px dashed #bebebe;
      }
      .count {
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #bebebe;
      }
      .start-date {
        font-weight: 500;
        font-size: 13px;
        line-height: 32px;
        min-width: 100px;
      }
      .progress-box {
        position: relative;
        :last-child {
          .progress-line,
          .unprogress-line,
          .progress-line-junior,
          .unprogress-line-junior {
            display: none;
          }
        }
      }
      .progress-line {
        border-bottom: 2px solid #f9dd4a;
        border-radius: 0;
        width: 100%;
        position: absolute;
        left: 50%;
        bottom: 94px;

        @media (min-width: 769px) {
          width: 110%;
          left: 60%;
        }
        @media (min-width: 1400px) {
          width: 140%;
          left: 60%;
        }
      }
      .unprogress-line {
        border-bottom: 2px dashed #bebebe;
        border-radius: 0;
        width: 100%;
        position: absolute;
        left: 50%;
        bottom: 94px;
        @media (min-width: 769px) {
          width: 110%;
          left: 60%;
        }
        @media (min-width: 1400px) {
          width: 140%;
          left: 60%;
        }
      }
      .progress-line-junior {
        border-bottom: 2px solid #f9dd4a;
        border-radius: 0;
        width: 100%;
        position: absolute;
        left: 50%;
        bottom: 94px;
        @media (min-width: 300px) {
          width: 110%;
          left: 60%;
        }
        @media (min-width: 400px) {
          width: 110%;
          left: 60%;
        }
        @media (min-width: 500px) {
          width: 150%;
          left: 60%;
        }

        @media (min-width: 620px) {
          width: 190%;
          left: 60%;
        }

        @media (min-width: 700px) {
          width: 210%;
          left: 60%;
        }
        @media (min-width: 769px) {
          width: 100%;
          left: 60%;
        }

        @media (min-width: 800px) {
          width: 110%;
          left: 60%;
        }
        @media (min-width: 900px) {
          width: 130%;
          left: 60%;
        }

        @media (min-width: 1000px) {
          width: 160%;
          left: 60%;
        }
        @media (min-width: 1100px) {
          width: 190%;
          left: 60%;
        }
        @media (min-width: 1200px) {
          width: 220%;
          left: 60%;
        }
        @media (min-width: 1400px) {
          width: 240%;
          left: 60%;
        }
        @media (min-width: 1550px) {
          width: 270%;
          left: 60%;
        }
        @media (min-width: 1650px) {
          width: 290%;
          left: 60%;
        }
        @media (min-width: 1920px) {
          width: 280%;
          left: 60%;
        }
        @media (min-width: 2080px) {
          width: 290%;
          left: 60%;
        }
      }
      .unprogress-line-junior {
        border-bottom: 2px dashed #bebebe;
        border-radius: 0;
        width: 100%;
        position: absolute;
        left: 50%;
        bottom: 94px;
        @media (min-width: 300px) {
          width: 110%;
          left: 60%;
        }
        @media (min-width: 400px) {
          width: 110%;
          left: 60%;
        }
        @media (min-width: 500px) {
          width: 150%;
          left: 60%;
        }

        @media (min-width: 620px) {
          width: 190%;
          left: 60%;
        }

        @media (min-width: 700px) {
          width: 210%;
          left: 60%;
        }
        @media (min-width: 769px) {
          width: 100%;
          left: 60%;
        }

        @media (min-width: 800px) {
          width: 110%;
          left: 60%;
        }
        @media (min-width: 900px) {
          width: 130%;
          left: 60%;
        }

        @media (min-width: 1000px) {
          width: 160%;
          left: 60%;
        }
        @media (min-width: 1100px) {
          width: 190%;
          left: 60%;
        }
        @media (min-width: 1200px) {
          width: 220%;
          left: 60%;
        }
        @media (min-width: 1400px) {
          width: 240%;
          left: 60%;
        }
        @media (min-width: 1550px) {
          width: 270%;
          left: 60%;
        }
        @media (min-width: 1650px) {
          width: 290%;
          left: 60%;
        }
        @media (min-width: 1920px) {
          width: 280%;
          left: 60%;
        }
        @media (min-width: 2080px) {
          width: 290%;
          left: 60%;
        }
      }
      .status-complete {
        background: rgba(249, 221, 74, 0.12);
        border-radius: 20px;
        font-weight: 400;
        font-size: 12px;
        line-height: 32px;
        color: #e4c000;
        width: 110px;
      }
      .status-incomplete {
        font-weight: 400;
        font-size: 12px;
        line-height: 32px;
        color: #bebebe;
        width: 110px;
      }
      .completed {
        display: flex;
        flex-direction: column;
        z-index: 1;
      }
      .not-completed {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .right {
    width: 30%;
    margin-left: 30px;
    padding: 40px;
    background-color: white;
    position: relative;
    cursor: pointer;
    min-height: 300px;
    @media (max-width: 768px) {
      display: none;
    }
    :hover {
      background-color: rgba(115, 108, 97, 0.174);
      transition: 0.1s ease-out;
      .hub-btn {
        transform: translateX(10%);
        transition: 0.1s ease-out;
      }
    }
    .hub-btn {
      width: 52px;
      height: 52px;
      border-radius: 50%;
      background: #f7f7f7;
      border: none;
      cursor: pointer;
      position: absolute;
      bottom: 40px;
      left: 40px;
      padding-top: 6px;
      @media (max-width: 768px) {
        transform: translateX(-20%);
      }
    }
    .book-btn {
      background-color: transparent;
      border: none;
      position: absolute;
      right: 40px;
      bottom: 40px;
      @media (max-width: 768px) {
        right: 14px;
        bottom: 14px;
      }
    }
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 35px;
      letter-spacing: -0.03em;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
    span {
      font-weight: 300;
      font-size: 18px;
      line-height: 35px;
      letter-spacing: -0.03em;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
  .faq {
    background-color: #736c61;
    display: flex;
    align-items: center;
    cursor: pointer;
    min-height: 96px;
    max-height: 96px;
    @media (max-width: 768px) {
      width: 69%;
      min-height: 90px;
      position: absolute;
      top: 260px;
      z-index: 999;
      padding-left: 18px;
    }
    span {
      color: white;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      margin-left: 8px;
      margin-right: 48%;
      @media (max-width: 768px) {
        margin-right: 40%;
      }
    }
    i {
      @media (max-width: 768px) {
        position: absolute;
        right: 20px;
      }
    }
    :hover {
      background: #232323;
      transition: 0.1s ease-out;
      i {
        transform: translateX(20%);
        transition: 0.1s ease-out;
      }
    }
  }
`;
