import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '../Button';
import { CgChevronRight, CgChevronLeft } from 'react-icons/cg';

const StylePagination = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-bottom: 70px;
	a {
		position: relative;
		border: 1px solid #eee;
		margin: 6px 0;
		margin-right: 15px;
		width: 36px;
		height: 36px;
		cursor: pointer;
		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	ul {
		display: flex;
		align-self: center;
		li {
			cursor: pointer;
			padding: 0 6px;
			a {
				border: none;
				transition: 0.4s;
				:hover {
					opacity: 0.6;
				}
			}
		}
		.active {
			color: #ffac00;
			font-weight: 600;
		}
	}
	@media ${(props) => props.theme.mobile} {
		a {
			margin-right: 8px;
		}
	}
`;

const Pagination = (props) => {
	// const [currentPage, setCurrentPage] = useState(props.current);
	const max = props.max;
	// console.log(max);
	//props : total, current, type:<button, paging>, onPaging:function

	// const [parentPage, setParentPage] = useState(0);
	let parentPage = Math.floor(props.current / 5);

	console.log('parentPage', parentPage);
	console.log('props.current', props.current);
	console.log('Math.floor(props.current / 5)', Math.floor(props.current / 5));

	// useEffect(() => {
	//   setParentPage(
	//     (5 - ((props.current - 1) % 5) + (props.current - 1)) / 5 - 1
	//   );
	// }, [props.current]);

	const pageArray = [];
	for (let i = 0; i < 5 && i + 1 + 5 * parentPage <= max; i += 1) {
		pageArray.push(
			<li
				onClick={(e) => props.onPaging(e.currentTarget.dataset.id)}
				data-id={i + 1 + 5 * parentPage}
				key={i}
			>
				<a
					className={
						i + 1 + 5 * parentPage === Number(props.current)
							? 'active'
							: 'pageNum'
					}
				>
					{i + 1 + 5 * parentPage}
				</a>
			</li>
		);
	}

	return (
		<StylePagination>
			{parseInt(props.current) - 1 ? (
				<Button
					type="round"
					size="70px"
					onClick={(e) => props.onPaging(Math.ceil(parentPage - 1) * 5 + 1)}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
					>
						<path
							d="M11.0401 14.2134C10.9105 14.3368 10.7372 14.4036 10.5583 14.3992C10.3794 14.3948 10.2096 14.3196 10.0861 14.19L5.58524 9.46502C5.46557 9.33949 5.39881 9.17271 5.39881 8.99927C5.39881 8.82584 5.46557 8.65905 5.58524 8.53352L10.0852 3.80852C10.1463 3.74428 10.2195 3.69271 10.3005 3.65675C10.3816 3.62079 10.4689 3.60114 10.5575 3.59892C10.6461 3.59671 10.7343 3.61197 10.8171 3.64384C10.8998 3.67571 10.9754 3.72357 11.0397 3.78467C11.1039 3.84577 11.1555 3.91893 11.1915 3.99997C11.2274 4.081 11.2471 4.16833 11.2493 4.25696C11.2515 4.34558 11.2362 4.43378 11.2044 4.51651C11.1725 4.59924 11.1246 4.67488 11.0635 4.73912L7.00544 8.99972L11.0644 13.2603C11.1877 13.3901 11.2543 13.5635 11.2498 13.7424C11.2452 13.9212 11.1698 14.091 11.0401 14.2143L11.0401 14.2134Z"
							fill="#444444"
						/>
					</svg>
				</Button>
			) : (
				<Button type="round" size="70px" disable="true">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
					>
						<path
							d="M11.0401 14.2134C10.9105 14.3368 10.7372 14.4036 10.5583 14.3992C10.3794 14.3948 10.2096 14.3196 10.0861 14.19L5.58524 9.46502C5.46557 9.33949 5.39881 9.17271 5.39881 8.99927C5.39881 8.82584 5.46557 8.65905 5.58524 8.53352L10.0852 3.80852C10.1463 3.74428 10.2195 3.69271 10.3005 3.65675C10.3816 3.62079 10.4689 3.60114 10.5575 3.59892C10.6461 3.59671 10.7343 3.61197 10.8171 3.64384C10.8998 3.67571 10.9754 3.72357 11.0397 3.78467C11.1039 3.84577 11.1555 3.91893 11.1915 3.99997C11.2274 4.081 11.2471 4.16833 11.2493 4.25696C11.2515 4.34558 11.2362 4.43378 11.2044 4.51651C11.1725 4.59924 11.1246 4.67488 11.0635 4.73912L7.00544 8.99972L11.0644 13.2603C11.1877 13.3901 11.2543 13.5635 11.2498 13.7424C11.2452 13.9212 11.1698 14.091 11.0401 14.2143L11.0401 14.2134Z"
							fill="rgb(204, 204, 204)"
						/>
					</svg>
				</Button>
			)}
			<ul>{pageArray}</ul>
			{parseInt(props.current) + 1 <= max ? (
				<Button
					type="round"
					size="70px"
					onClick={(e) => props.onPaging(Math.ceil(parentPage + 1) * 5 + 1)}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
					>
						<path
							d="M6.95987 3.78658C7.0895 3.66323 7.26282 3.5964 7.44171 3.60079C7.6206 3.60518 7.79043 3.68042 7.91387 3.80998L12.4148 8.53498C12.5344 8.66051 12.6012 8.82729 12.6012 9.00073C12.6012 9.17416 12.5344 9.34094 12.4148 9.46648L7.91477 14.1915C7.85366 14.2557 7.7805 14.3073 7.69947 14.3433C7.61843 14.3792 7.53111 14.3989 7.44248 14.4011C7.35385 14.4033 7.26565 14.388 7.18292 14.3562C7.10019 14.3243 7.02455 14.2764 6.96032 14.2153C6.89608 14.1542 6.84451 14.0811 6.80854 14C6.77258 13.919 6.75293 13.8317 6.75072 13.743C6.7485 13.6544 6.76377 13.5662 6.79564 13.4835C6.82751 13.4008 6.87536 13.3251 6.93647 13.2609L10.9946 9.00028L6.93557 4.73968C6.81233 4.60992 6.74566 4.43654 6.75022 4.25765C6.75478 4.07876 6.83018 3.90899 6.95987 3.78568V3.78658Z"
							fill="#444444"
						/>
					</svg>
				</Button>
			) : (
				<Button type="round" size="70px" disable="true">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
					>
						<path
							d="M6.95987 3.78658C7.0895 3.66323 7.26282 3.5964 7.44171 3.60079C7.6206 3.60518 7.79043 3.68042 7.91387 3.80998L12.4148 8.53498C12.5344 8.66051 12.6012 8.82729 12.6012 9.00073C12.6012 9.17416 12.5344 9.34094 12.4148 9.46648L7.91477 14.1915C7.85366 14.2557 7.7805 14.3073 7.69947 14.3433C7.61843 14.3792 7.53111 14.3989 7.44248 14.4011C7.35385 14.4033 7.26565 14.388 7.18292 14.3562C7.10019 14.3243 7.02455 14.2764 6.96032 14.2153C6.89608 14.1542 6.84451 14.0811 6.80854 14C6.77258 13.919 6.75293 13.8317 6.75072 13.743C6.7485 13.6544 6.76377 13.5662 6.79564 13.4835C6.82751 13.4008 6.87536 13.3251 6.93647 13.2609L10.9946 9.00028L6.93557 4.73968C6.81233 4.60992 6.74566 4.43654 6.75022 4.25765C6.75478 4.07876 6.83018 3.90899 6.95987 3.78568V3.78658Z"
							fill="#444444"
						/>
					</svg>
				</Button>
			)}
		</StylePagination>
	);
};

export default Pagination;
