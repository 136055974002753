import styled from 'styled-components';

export const RowWrap = styled.div`

    display:flex;
    flex-direction: row;
    margin: 0 0;
    padding: 0 0;
    max-width: auto;
    @media ${props => props.theme.tablet} {
        
    }
    @media ${props => props.theme.mobile} {
        display:block;

    }
`;
export const ColWrap = styled.div`
    padding: 0 0;
    flex: ${props => props.flex || "1"};
    @media ${props => props.theme.tablet} {
        
    }
    @media ${props => props.theme.mobile} {
        display:block;

    }
`;

export const ResponsiveBr = styled.span`
    display:block;
    @media ${props => props.theme.tablet} {
        ${props=>props.dnjdlek == 'tablet' ? `display:inline;` : ''}
    }
    @media ${props => props.theme.mobile} {
        display:inline;
    }
`;
export const Container = styled.div`
    ${props => {
        if(props.type == 'wide') {
            return `max-width:100%; padding-left: 50px; padding-right: 50px;`;
        } else if(props.type == 'full') {
            return `max-width:100%;`;
        } else if(props.type == 'small') {
            return `max-width:1000px;`;
        } else {
            return `max-width:1252px;`;
        }
    }}
    width:100%;
    margin: 0px auto;
    @media ${props => props.theme.laptop} {
        ${props => {
            if(props.type == 'wide') {
                return `max-width:100%; padding-left: 50px; padding-right: 50px;`;
            } else if(props.type == 'full') {
                return `max-width:100%;`;
            } else if(props.type == 'small') {
                return `max-width:800px;`;
            } else {
                return `max-width:1180px; padding-left: 20px; padding-right: 20px;`;
            }
        }}
        
    }
    @media ${props => props.theme.tablet} {
        max-width:100%;
        padding-left:50px;
        padding-right: 50px;
        ${props => {
            if(props.type == 'wide') {
                return `max-width:100%; padding: 0px 0px;`;
            } else if(props.type == 'full') {
                return `max-width:100%; padding: 0px 0px;`;
            } else if(props.type == 'small') {
                return `max-width:800px; padding: 0px 0px;`;
            }
        }}
    }
    @media ${props => props.theme.mobile} {
        max-width:100%;
        padding-left:20px;
        padding-right: 20px;
        ${props => {
            if(props.type == 'wide') {
                return `max-width:100%; padding: 0px 0px;`;
            } else if(props.type == 'full') {
                return `max-width:100%; padding: 0px 0px;`;
            }
        }}
    }
`;

