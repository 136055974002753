import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { GetPosts } from '../../../api/story';
import Badge from '../../../components/Badge';
import Card from '../../../components/Card';
import { CommonContainer } from '../../../components/Grid';
import LoadInView from '../../../components/LoadInView';
import MainContainer from '../../../components/MainContainer';
import Pagination from '../../../components/Pagination';
import { CardSection } from '../components/styled';
import PenIcon from '../../../vector/IconPen';
import Eventmodal from '../../Dashboard/mentor/modal/eventmodal/inedx';
import {
	BadgeSection,
	Path,
	SearchCount,
	SearchWrapper,
	Title,
} from '../Search/styled';
import { LoadingContainer } from '../Popup/styled';

const Archive = (props) => {
	const [current_cate_id, set_current_cate_id] = useState(0);
	const [cate_name, update_cate_name] = useState('');
	const [post_list, update_post_list] = useState(0);
	const [current_paged, set_current_paged] = useState(0);
	const [total_page, set_total_page] = useState(0);
	const [list_tags, set_list_tags] = useState(0);
	let { search } = useLocation();
	const query = new URLSearchParams(search);
	const [paged, set_paged] = useState(
		query.get('paged') ? query.get('paged') : 1
	);
	const params = useParams();
	const history = useHistory();
	// useEffect(() => {
	//   if (params.id != current_cate_id || current_paged != paged) {
	//     set_current_cate_id(params.id);
	//     GetPosts({
	//       cate: params.id,
	//       posts_per_page: '12',
	//       paged: paged,
	//     })
	//       .then(response => {
	//         console.log(response);
	//         if (response.data.body.paged) {
	//           set_total_page(response.data.body.total);
	//           set_current_paged(response.data.body.paged);
	//           update_post_list(response.data.body.posts);
	//           set_list_tags(response.data.body.tags);
	//           update_cate_name(response.data.body.cate_name);
	//         }
	//       })
	//       .catch(error => {
	//         console.log(error);
	//       });
	//   }
	// });
	useEffect(() => {
		set_paged(1);
		GetPosts({
			cate: params.id,
			posts_per_page: '12',
			paged: 1,
		})
			.then((response) => {
				console.log(response);
				if (response.data.body.paged) {
					set_total_page(response.data.body.total);
					set_current_paged(response.data.body.paged);
					update_post_list(response.data.body.posts);
					set_list_tags(response.data.body.tags);
					update_cate_name(response.data.body.cate_name);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}, [params.id]);
	useEffect(() => {
		set_current_cate_id(params.id);
		if (paged > 1) {
			GetPosts({
				cate: params.id,
				posts_per_page: '12',
				paged: paged,
			})
				.then((response) => {
					console.log(response);
					if (response.data.body.paged) {
						set_total_page(response.data.body.total);
						set_current_paged(response.data.body.paged);
						update_post_list(response.data.body.posts);
						set_list_tags(response.data.body.tags);
						update_cate_name(response.data.body.cate_name);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [paged]);
	const onPaging = (page) => {
		console.log(page);
		set_paged(page);
		history.push(window.location.pathname + `?paged=${page}`);
		window.scrollTo({ top: document.querySelector('#post_archive').offsetTop });
	};

	const [show10, setShow10] = useState(false);
	const eventToggle = () => {
		setShow10((p) => !p);
	};

	return (
		<>
			<MainContainer>
				<SearchWrapper>
					<CommonContainer>
						<Path>
							<span>홈</span>
							{cate_name ? cate_name : ''}
						</Path>
						<Title id="post_archive">{cate_name ? cate_name : ''}</Title>
						<SearchCount>
							총 <span>{total_page ? total_page : 0}</span>개의 포스트가
							있습니다.
						</SearchCount>

						<BadgeSection>
							{list_tags
								? list_tags.map((tag, key) => (
										<Badge
											key={key}
											onClick={(e) =>
												(window.location.href = '/tag/' + tag.slug)
											}
										>
											#{tag.name}
										</Badge>
								  ))
								: '로딩'}
							{params.id == 4850 ? (
								<div className="registration-btn">
									<button className="registr-btn" onClick={eventToggle}>
										<i className="icon-pen">
											<PenIcon />
										</i>
										간편 작성
									</button>
								</div>
							) : null}
						</BadgeSection>
						<CardSection>
							{post_list
								? post_list.map((post, key) => (
										<Card item={post} width="33.3333%" />
								  ))
								: '로딩'}
						</CardSection>
						<LoadInView>
							<Pagination
								onPaging={onPaging}
								current={current_paged}
								max={total_page >= 12 ? Math.floor(total_page / 12) : 1}
							/>
						</LoadInView>
					</CommonContainer>
				</SearchWrapper>
			</MainContainer>
			{show10 ? <Eventmodal eventToggle={eventToggle} /> : ''}
		</>
	);
};

export default Archive;
