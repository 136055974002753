export default function SmallBookIcon() {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1772 1.34814V22.2773H4.35379C3.65435 22.2773 2.98356 22.0016 2.48898 21.511C1.9944 21.0204 1.71655 20.355 1.71655 19.6611V3.96428C1.71655 3.27044 1.9944 2.60502 2.48898 2.11439C2.98356 1.62377 3.65435 1.34814 4.35379 1.34814H20.1772Z"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.1772 17.0451H4.35379C3.65435 17.0451 2.98356 17.3208 2.48898 17.8114C1.9944 18.302 1.71655 18.9674 1.71655 19.6613M6.99103 6.58057H14.9027"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
