import React from 'react';
import PropTypes from 'prop-types';
import {StyledBadge} from './styled'

const Badge = (props) => {
    return (
        <StyledBadge type={props.type} onClick={props.onClick} color={props.color} label={props.label} size={props.size}>
            {props.children}
        </StyledBadge>
    )
}

export default Badge;

Badge.propTypes = {
    backgroundColor: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'default']),
    size: PropTypes.oneOf(['small', 'medium',]),
    color: PropTypes.oneOf(['primary', 'secondary', 'hover', 'point', 'black', 'white']),
    label: PropTypes.string.isRequired,
  };
  
Badge.defaultProps = {
    backgroundColor: null,
    type: 'default',
    size: 'medium',
    color: 'primary',
    label: ''
};