import styled from 'styled-components';

export const UserWrapper = styled.div`
  position: relative;
  .top-section {
    background-color: #000;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 47px 0 93px 0;
    > div {
      max-width: 1255px;
      margin: 0 auto;
    }
  }
  @media ${props => props.theme.mobile} {
    .top-section {
      padding: 31px 0 73px 0;
    }
  }
`;

export const Path = styled.div`
  margin-bottom: 45px;
  font-family: 'KBFG Text';
  font-style: normal;
  font-size: 13px;
  line-height: 17px;
  color: #bfbfbf;
  span {
    color: #bfbfbf;
    :after {
      content: '>';
      margin: 0 14px;
    }
  }
  @media ${props => props.theme.laptop} {
    padding-left: 20px;
  }
  @media ${props => props.theme.tablet} {
    padding-left: 0px;
  }
`;

export const UserContent = styled.div`
  display: flex;
  align-items: center;
  font-family: 'KBFG Text';
  font-style: normal;
  text-align: left;
  position: relative;
  z-index: 1;
  .userimg {
    background-image: url(${props => props.user_image});
    width: 140px;
    height: 140px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 100px;
    position: relative;
    margin-right: 50px;
  }
  strong {
    img {
      width: 3px;
      height: 3px;
      margin: 0px 5px;
      margin-bottom: 5px;
    }
  }
  @media ${props => props.theme.mobile} {
    display: block;
    text-align: center;
    .userimg {
      margin: 0 auto;
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
    }
  }
`;

export const UserDetail = styled.div`
  font-family: 'KBFG Display';
  strong {
    display: inline-block;
    font-size: 24px;
    font-weight: 500;
    color: #fff;
    line-height: 30px;
    margin: 0 7px 11px 0;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
  .description {
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    line-height: 17px;
    img {
      margin-right: 5px;
      vertical-align: bottom;
    }
  }
  @media ${props => props.theme.mobile} {
    strong {
      font-size: 20px;
      margin: 0 7px 6px 0;
    }
    span {
      font-size: 12px;
    }
    .description {
      font-size: 12px;
      font-weight: 300;
      line-height: 15px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: bottom;
      }
    }
  }
`;

export const UserTab = styled.ul`
  margin-top: 145px;
  /* border-top: 1px solid #eeeeee; */
  font-family: 'KBFG Display';
  text-align: center;
  font-style: normal;
  position: relative;
  transition: 0.3s;
  li {
    display: inline-block;
    text-decoration: none;
  }
  a {
    transition: 0.3s;
    margin-left: 120px;
    padding-top: 20px;
    font-size: 23px;
    line-height: 34px;
    font-weight: 600;
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: #bfbfbf;
    border-top: 3px solid transparent;
    letter-spacing: -0.55px;
    :focus {
      outline: none;
    }
    :hover {
      color: #000000;
    }
  }
  span {
    /* position: absolute; */
    display: inline-block;
    margin-left: 5px;
    margin-top: 24px;
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
    font-weight: normal;
    color: #9e9e9e;
    vertical-align: top;
  }
  li:first-child a {
    margin-left: 0px;
  }
  li:hover a,
  li.active a {
    border-top: 3px solid #ffcc00;
    color: #000;
  }
  @media ${props => props.theme.tablet} {
    a {
      font-size: 22px;
    }
  }
  @media ${props => props.theme.mobile} {
    margin-top: 95px;
    a {
      margin-left: 80px;
    }
  }
`;

//index styled end

export const AboutWrapper = styled.div`
  margin-top: 85px;
`;

export const AboutContent = styled.div`
  font-family: 'KBFG Display';
  font-style: normal;
  position: relative;
  padding-bottom: 40px;
  h2 {
    font-size: 32px;
    line-height: 30px;
    color: #000000;
  }
  p {
    margin-top: 36px;
    font-size: 18px;
    line-height: 31px;
    color: #888888;
  }
`;

export const AboutContentBadge = styled.div`
  position: relative;
  margin-top: 50px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 83px;
  font-family: 'KBFG Text';
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  label {
    margin-right: 20px;
  }
`;

export const AboutKeyWord = styled.div`
  font-family: 'KBFG Display';
  font-style: normal;
  margin-top: 65px;
  h2 {
    font-size: 32px;
    line-height: 30px;
    color: #000000;
  }
`;

export const TagBox = styled.div`
  margin-top: 36px;
  border: 1px solid #eeeeee;
  width: 100%;
  height: 497px;
  @media ${props => props.theme.mobile} {
  }
`;

export const AboutWebSite = styled.div`
  margin-top: 145px;
  margin-bottom: 380px;
  font-family: 'KBFG Display';
  font-style: normal;
  position: relative;
  h2 {
    font-size: 32px;
    line-height: 30px;
    color: #000000;
  }
  @media ${props => props.theme.tablet} {
    margin-bottom: 280px;
  }
  @media ${props => props.theme.mobile} {
    margin-bottom: 180px;
  }
`;
export const FigureIcon = styled.div`
  position: absolute;
  top: 60px;
  li {
    padding-right: 70px;
    display: inline-block;
    position: relative;
    a {
      width: 52px;
      height: 52px;
      position: absolute;
      box-sizing: border-box;
      border: 1px solid #eeeeee;
      border-radius: 50%;
      text-align: center;
      line-height: 52px;
    }
    img {
      display: inline-block;
    }
  }
`;

//about styled end

export const LoopWrapper = styled.div`
  margin-top: 43px;
`;

export const BadgeList = styled.div`
  margin-bottom: 27px;
  label {
    margin-right: 10px;
    margin-bottom: 13px;
  }
  @media ${props => props.theme.mobile} {
    label {
      padding: 0px 11px;
    }
  }
  @media ${props => props.theme.laptop} {
    padding: 0px 20px;
  }
  @media ${props => props.theme.tablet} {
    padding: 0px 0px;
  }
`;

export const LoopRelevant = styled.div`
  margin-bottom: 100px;
  font-family: 'KBFG Display';
  font-style: normal;
  .RelevantBox {
    border-top: 1px solid #eeeeee;
    padding: 30px 0px;
    display: block;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background: #f7f7f7;
    }
    div:last-child {
      position: relative;
    }
    a {
      text-decoration: none;
    }
  }

  strong {
    margin-top: 18px;
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 26px;
    display: block;
    color: #ffac00;
  }
  h3 {
    font-size: 24px;
    line-height: 1.5;
    color: #000000;
    small {
      color: #ffac00;
      font-weight: normal;
      font-size: 14px;
      margin-left: 5px;
    }
  }
  p {
    font-family: 'KBFG Display';
    font-style: normal;
    margin-top: 15px;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #7a7a7a;
    display: inline-block;
  }
  span {
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-family: 'KBFG Text';
    font-style: normal;
    float: right;
    font-size: 15px;
    line-height: 32px;
    color: #c1c1c1;
  }
  @media ${props => props.theme.labtop} {
    ${props => {
      if (props.type == 'small') {
        return `max-width:800px;`;
      } else {
        return `max-width:1180px;`;
      }
    }}
    .RelevantBox {
      padding-left: 20px;
      padding-right: 20px;
    }
    span {
      bottom: 15px;
    }
  }
  @media ${props => props.theme.tablet} {
    .RelevantBox {
      padding-left: 0px;
      padding-right: 0px;
    }
    h3 {
      margin-top: 10px;
      margin-bottom: 5px;
      font-size: 22px;
      line-height: 1.2;
    }
    strong {
      font-size: 16px;
      line-height: 28px;
    }
    p {
      margin-top: 5px;
    }
    span {
      bottom: 15px;
    }
  }
  @media ${props => props.theme.mobile} {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    h3 {
      font-size: 20px;
      line-height: 26px;
    }
    strong {
      font-size: 14px;
    }
    span {
      position: relative;
      float: left;
    }
  }
`;
export const RelevantImg = styled.div`
  ${props =>
    props.url
      ? `background-image: url("${props.url}");`
      : 'background-color:#f3f3f3;'}
  background-repeat: no-repeat;
  background-size: cover;
  width: 345px;
  height: 230px;
  margin-right: 40px;
  display: inline-block;
  position: relative;
  @media ${props => props.theme.mobile} {
    width: 100%;
    height: 190px;
  }
`;

export const RelevantBadge = styled.div`
  margin-top: 21px;
  display: block;
  position: absolute;
  bottom: 15px;
  label {
    margin-right: 10px;
  }
  @media ${props => props.theme.tablet} {
    label {
      padding: 0px 11px;
    }
  }
  @media ${props => props.theme.mobile} {
    position: static;
    label {
      padding: 0px 11px;
      margin-top: 0px;
      margin-bottom: 25px;
    }
  }
`;

export const UserStatus = styled.ul`
  position: absolute;
  bottom: -45px;
  max-width: 1255px;
  width: 100%;
  background: #fff;
  padding: 23px 0 26px 0;
  box-sizing: border-box;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.05);
  text-align: center;
  li {
    display: inline-block;
    padding: 0px 80px;
    border-right: 1px solid #eee;
    span {
      display: inline-block;
      font-size: 14px;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -0.28px;
      color: #666;
      margin-bottom: 8px;
    }
    strong {
      font-size: 16px;
      font-weight: bold;
      color: #000;
      display: block;
    }
    :last-child {
      border-right: none;
    }
  }
  @media ${props => props.theme.mobile} {
    left: 20px;
    max-width: calc(100% - 40px);
    width: 100%;
    display: flex;
    justify-content: space-around;
    li {
      width: 100%;
      padding: 0px 0px;
    }
  }
`;
