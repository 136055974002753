import styled from 'styled-components';

export const IFrameWrapper = styled.div`
	position: fixed;
	/* z-index: 99999999999999999; */
	z-index: 100000000000000;
	left: 0;
	max-width: 414px;
	width: 100%;
	max-height: 780px;
	height: calc(100vh - 120px);
	left: 50%;
	bottom: 0;
	transform: translate(-50%, 100%);
	transition: 0.6s;
	border-radius: 30px 30px 0 0;
	overflow: hidden;
	iframe {
		max-height: 780px !important;
		height: calc(100vh - 121px) !important;
		/* height: calc(100vh - 325px) !important; */
	}
	.bar {
		position: absolute;
		left: 50%;
		top: 0px;
		transform: translateX(-50%);
		width: 100%;
		height: 28px;
		cursor: pointer;
	}
	.bar2 {
		position: absolute;
		left: 50%;
		top: 0px;
		transform: translateX(-50%);
		width: 50%;
		height: 40px;
		cursor: pointer;
	}

	&.active {
		transform: translate(-50%, 0%);
	}
	.overlay {
		width: 100%;
		height: 100%;
		background-color: #00000030;
	}
	.modal-wrapper {
		width: 100%;
		/* height: 100vh; */
		.frame {
			z-index: 999;
			/* height: 780px; */
			height: calc(100vh - 120px);
			max-width: 414px;
			width: 100%;
			background-color: #fff;
		}
	}

	@media ${(props) => props.theme.mobile} {
		transition: 0.6s;
		.frame {
			height: 500px;
			max-height: calc(100% - 100px);
		}
	}
`;
