import { useEffect, useRef, useState } from 'react';
import ModalClose from '../../../../../vector/ModalClose';
import ModalPrev from '../../../../../vector/ModalPrev';
import { Wrapper } from './styled';
import { GetAllUsers } from '../../../../../api/user';
import { AddMentor } from '../../../../../api/mentorings';

export default function Modal2({ modalToggle2, depart, setP }) {
	const [hide, setHide] = useState(true);
	const [hide2, setHide2] = useState(true);
	const selectHide = () => {
		setHide((p) => !p);
	};
	const selectHide2 = () => {
		setHide2((p) => !p);
	};
	const ref = useRef();

	const [searchText, setSearchText] = useState('');
	const [users, setUsers] = useState([]);

	useEffect(() => {
		GetAllUsers()
			.then((res) => {
				console.log(res);
				setUsers(res?.data.body.users);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const filteredOptions = users?.filter((option) =>
		option.display_name.toLowerCase().includes(searchText.toLowerCase())
	);

	const handleInputChange = (e) => {
		setSearchText(e.target.value);
	};

	const [mentee, setMentee] = useState({
		name: '',
		login: '',
	});

	const onChangeMentee = (name, login) => {
		setMentee({
			name: name,
			login: login,
		});
		ref.current.value = name;
		selectHide2();
	};

	const [padlet, setPadlet] = useState('');

	const DoAdd = () => {
		if (!mentee?.login) {
			alert('멘티를 선택하세요.');
			return;
		}

		AddMentor({
			padlet: padlet,
			department: depart,
			mentorId: mentee?.login,
			// menteeId: user?.ID,
		})
			.then((res) => {
				console.log(res);
				if (res.data.code == 200) {
					alert('멘토가 추가되었습니다.');
					setP((p) => !p);
					modalToggle2();
				} else {
					alert(res.data.body.message);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Wrapper>
			<section className="modal-wrapper">
				<div className="overlay" onClick={modalToggle2}></div>
				<div className="modal-wrap">
					<i className="icon-close" onClick={modalToggle2}>
						<ModalClose />
					</i>
					<h2 className="modal-title">[{depart}] 에 멘토를 추가합니다</h2>
					<div className="modal-box">
						<div className="select-wrap">
							<p className="department-title">부서</p>
							<article className="cont-select">
								{/* <button onClick={selectHide} className="btn-select">
									부서를 선택하세요
								</button> */}
								<button className="btn-select a">{depart}</button>
								{/* <ul className="list-member"> */}
								{/* <ul className={hide ? 'list-member' : 'active list-member'}>
									<li>
										<button>신기술사업부</button>
									</li>
									<li>
										<button>신기술사업부</button>
									</li>
								</ul> */}
							</article>
						</div>
						<div className="select-wrap">
							<p className="department-title">Padlet</p>
							<div className="input-box">
								<input
									onChange={(e) => setPadlet(e.target.value)}
									type="text"
									placeholder="Padlet 주소를 입력하세요"
								/>
							</div>
						</div>
						<div className="name-select-wrap">
							<p className="department-title">멘토 선택</p>
							<article className="cont-select">
								<input
									type="text"
									onFocus={selectHide2}
									// onBlur={selectHide2}
									placeholder="이름을 검색하세요"
									onChange={handleInputChange}
									ref={ref}
									// value={mentee}
								></input>
								{/* <button onClick={selectHide2} className="btn-name-select">
									이름을 검색하세요
								</button> */}
								<ul
									className={
										hide2 ? 'list-name-member' : 'active list-name-member'
									}
								>
									{filteredOptions?.map((item, key) => (
										<li
											key={key}
											onClick={() =>
												onChangeMentee(item?.display_name, item?.user_login)
											}
										>
											<button>
												<p className="name-title">{item?.display_name}</p>
												<p className="number">d{item?.user_login}</p>
											</button>
										</li>
									))}
									<div className="bg"></div>
								</ul>
							</article>
							{/* <article className="cont-select">
                <button onClick={selectHide2} className="btn-name-select">
                  홍길동
                </button>
                <ul
                  className={
                    hide2 ? "list-name-member" : "active list-name-member"
                  }
                >
                  <li>
                    <button>
                      <p className="name-title">홍길동</p>
                      <p className="number">d142310</p>
                    </button>
                  </li>
                  <li>
                    <button>
                      <p className="name-title">홍길동</p>
                      <p className="number">d142310</p>
                    </button>
                  </li>
                  <li>
                    <button>
                      <p className="name-title">홍길동</p>
                      <p className="number">d142310</p>
                    </button>
                  </li>
                  <div className="bg"></div>
                </ul>
              </article> */}
						</div>
					</div>
					<div className="btn-wrap">
						<button onClick={modalToggle2}>취소</button>
						<button onClick={DoAdd} className="yellow-btn">
							추가
						</button>
					</div>
				</div>
			</section>
		</Wrapper>
	);
}
