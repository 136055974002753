import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Likeicon from '../../../../vector/LikeIcon';
import Unliked from '../../../../vector/UnLiked';
import {
	DeleteComment,
	GetComments,
	InsertComment,
	InsertLiked,
} from '../../../../api/story';
import { Col, CommonContainer, Row } from '../../../../components/Grid';
import {
	CommentItemStyle,
	CommentTitle,
	CommentWrapper,
	DeleteLink,
	PostComment,
	PostCommnetBox,
	UserImg,
} from '../styled';
import { useParams } from 'react-router-dom';

const SectionComment = (props) => {
	const [comment_list, update_comment_list] = useState(0);
	const [current_post_id, set_current_post_id] = useState(0);
	const [comment_count, update_comment_count] = useState(0);
	const [errorMsg, SetErrorMsg] = useState('');
	const [comment_content, set_comment_content] = useState('');
	const user_data = useSelector((state) => state.auth.user_data);
	const { id } = useParams();

	const [reply_user_id, set_reply_user_id] = useState('');
	const [reply_user_name, set_reply_user_name] = useState('');
	const ref = useRef();
	const divRef = useRef(null);
	const [divWidth, setDivWidth] = useState(0);

	useEffect(() => {
		if (divRef.current) {
			const width = divRef.current.offsetWidth;
			setDivWidth(width);
		}
	}, [reply_user_id]);

	const DoReply = (args) => {
		console.log(args);
		ref.current.focus();
		set_reply_user_id(args?.user_id);
		set_reply_user_name(args?.author_name);
	};

	const CancelReply = () => {
		set_reply_user_id('');
		set_reply_user_name('');
		set_comment_content('');
	};

	const CommentItem = (props) => {
		return (
			<CommentItemStyle>
				<Row>
					<Col flex="0">
						<UserImg
							bg={props.item.author_img ? props.item.author_img : ''}
							onClick={() => {
								window.location.href = '/' + props.item.user_id;
							}}
						/>
					</Col>
					<Col>
						<strong
							onClick={() => {
								window.location.href = '/' + props.item.user_id;
							}}
						>
							{props.item.author_name ? props.item.author_name : ''}
						</strong>
						<span>
							{props.item.comment_date ? props.item.comment_date : ''}
							<small onClick={() => DoReply(props?.item)}>답글쓰기</small>
						</span>
					</Col>
				</Row>
				<p>
					{props?.item?.reply_user_name ? (
						<p className="comment_reply_user_name">
							@{props?.item?.reply_user_name}
						</p>
					) : null}
					{props.item.content}
				</p>
				{props.item.user_id == user_data.ID ? (
					<DeleteLink href="#" data-comment_id={props.item.ID} onClick={Delete}>
						삭제
					</DeleteLink>
				) : (
					''
				)}
			</CommentItemStyle>
		);
	};
	useEffect(() => {
		if (current_post_id != props.post_id) {
			LoadComments();
		}
	}, []);
	const LoadComments = () => {
		GetComments({ post_id: props.post_id })
			.then((response) => {
				if (response.data.code == '200') {
					update_comment_list(response.data.body.comment_list);
					set_current_post_id(props.post_id);
					update_comment_count(response.data.body.count);
				} else {
					SetErrorMsg('통신 실패!');
				}
			})
			.catch((err) => {
				console.log(err);
				SetErrorMsg('통신 실패!');
			});
	};

	const [loading, setLoading] = useState(false);

	const SubmitComment = () => {
		setLoading(true);
		InsertComment({
			post_id: props.post_id,
			content: comment_content,
			reply_user_id: reply_user_id,
		})
			.then((response) => {
				if (response.data.code == '200') {
					LoadComments();
					set_comment_content('');
					set_reply_user_id('');
					set_reply_user_name('');
					alert('댓글이 작성되었습니다.');
				} else {
					alert('사용자 인증에 실패했습니다. 다시 로그인해주세요.');
				}
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};
	const Delete = (e) => {
		e.preventDefault();
		if (window.confirm('정말로 삭제하시겠습니까?')) {
			DeleteComment({
				comment_ID: e.target.dataset.comment_id,
			})
				.then((response) => {
					if (response.data.code == '200') {
						LoadComments();
					}
				})
				.catch((err) => console.log(err));
		}
	};
	return (
		<CommentWrapper>
			<CommonContainer type="small">
				<PostComment>
					<CommentTitle>
						<div className="comment-box">
							<h3>
								댓글<span>{comment_count ? comment_count : '0'}</span>
							</h3>
							<div className="like-box">
								{props?.isSimple && props.is_author ? (
									<button
										onClick={props.doDeletePost}
										className="white like-btn"
									>
										<img src={process.env.PUBLIC_URL + '/trash.svg'} alt="" />
										삭제
									</button>
								) : null}
								{props?.isSimple && props.is_author ? (
									<button
										onClick={props.eventToggle}
										className="white like-btn"
									>
										<img src={process.env.PUBLIC_URL + '/pencil.svg'} alt="" />
										수정
									</button>
								) : null}
								<button
									className={
										props.show
											? props.isLiked
												? 'like-btn active'
												: 'like-btn'
											: props?.is_liked
											? 'like-btn active'
											: 'like-btn'
									}
									onClick={props.doLiked}
								>
									<i className="icon-like">
										<Likeicon />
									</i>
									{props.show
										? props.likedCount
										: props?.liked_count
										? props?.liked_count
										: 0}
								</button>
							</div>
						</div>
					</CommentTitle>
					{comment_list
						? comment_list.map((item, index) => <CommentItem item={item} />)
						: ''}
				</PostComment>
				<PostCommnetBox width={divWidth ? divWidth : '33'}>
					<Row>
						{/* {divRef?.current?.offsetWidth}
						{divWidth} */}
						<Col flex="0">
							<UserImg bg={user_data.user_avatar_url} />
						</Col>
						<Col>
							<div className="input-wrap">
								<input
									ref={ref}
									class={reply_user_name ? 'textcomment reply' : 'textcomment'}
									type="text"
									value={comment_content}
									onChange={(e) => set_comment_content(e.target.value)}
									placeholder={reply_user_name ? '' : '댓글을 입력해주세요.'}
								/>
								{reply_user_name ? (
									<div className="cancel">
										{reply_user_name} 님께 답글쓰기
										<svg
											onClick={CancelReply}
											xmlns="http://www.w3.org/2000/svg"
											width="10"
											height="10"
											viewBox="0 0 10 10"
											fill="none"
										>
											<path d="M1 1L9 9" stroke="#888888" />
											<path d="M9 1L0.999999 9" stroke="#888888" />
										</svg>
									</div>
								) : null}
								{reply_user_name ? (
									<div ref={divRef} className="reply_user_name">
										@{reply_user_name} |
									</div>
								) : null}
								<button onClick={() => (loading ? null : SubmitComment())}>
									댓글달기
								</button>
							</div>
						</Col>
					</Row>
				</PostCommnetBox>
			</CommonContainer>
		</CommentWrapper>
	);
};

export default SectionComment;
