import styled from 'styled-components';

export const EmptyWrapper = styled.div`
	position: relative;
	width: 100%;
	padding: 10vw 0;
	.empty-wrap {
		text-align: center;
		p {
			margin-top: 20px;
			color: #888;
			font-family: KBFG Display;
			font-size: 16px;
			font-weight: 300;
			line-height: normal;
		}
	}
	@media ${(props) => props.theme.mobile} {
		padding: 18vw 0;
		.empty-wrap {
			img {
				width: 34px;
			}
		}
	}
`;
