import styled from 'styled-components';
import SwiperCore, { Navigation } from 'swiper';
import QuestionIcon from '../../../vector/QuestionMark';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { GetUserPosts } from '../../../api/story';

const PostWrapper = styled.div`
	border-radius: 28px;
	display: flex;
	width: 100%;
	margin: auto;
	max-width: 1400px;

	@media (max-width: 768px) {
		display: none;
	}
	@media (min-width: 769px) {
		display: flex;
	}
	.post-box {
		display: flex;
		width: 100%;
		margin: auto;
		max-width: 1400px;
	}
	.left-box {
		display: block;
		width: 70%;
	}
	.title {
		display: block;
	}
	.left {
		position: relative;
		border-radius: 28px;
		position: relative;
		padding: 32px;
		padding-bottom: 16px;
		padding-right: 0;
		background-color: white;
		.gradient {
			position: absolute;
			right: 0;
			top: 0;
			border-radius: 28px;
			background: linear-gradient(
				270deg,
				#ffffff 0%,
				rgba(255, 255, 255, 0) 100%
			);
			width: 8%;
			height: 100%;
			z-index: 999;
		}
		.see-alls {
			margin-top: 24px;
		}

		.liked-post {
			text-align: center;
			width: 100%;
			margin: auto;
			padding: 120px;
			background-image: url('/dummy-liked.png');
			background-size: cover;
			span {
				margin-top: 16px;
				font-weight: 300;
				font-size: 16px;
				line-height: 20px;
				color: #696969;
				display: block;
			}
		}
		.written-post {
			text-align: center;
			width: 100%;
			margin: auto;
			padding: 120px;
			span {
				margin-top: 16px;
				font-weight: 300;
				font-size: 16px;
				line-height: 20px;
				color: #696969;
				display: block;
			}
		}

		.swiper-card {
			border-radius: 20px;
			color: white;
			display: block;
			overflow: hidden;
			height: 280px;
			min-width: 280px;
			position: relative;
			.post-desc {
				border-radius: 12px;
				height: 280px;
				min-width: 280px;
				padding: 50px 8px;
				padding-bottom: 10px;
				width: 100%;
				height: 100%;
				z-index: 9;
				background-color: rgba(0, 0, 0, 0.3);
			}
			:hover {
				opacity: 90%;
				transition: 0.1s ease-out;
			}
			.top,
			.bottom {
				display: flex;
				position: relative;
			}
			.bottom {
				position: absolute;
				bottom: 8%;
			}
			.author-thumbnail {
				padding-top: 40px;
				position: absolute;
				right: 8%;
				bottom: 8%;
				img {
					width: 50px;
					height: 50px;
					border-radius: 50%;
				}
			}
			.post-info {
				span {
					margin-left: 8px;
				}
				span:first-child {
					background: rgba(255, 255, 255, 0.4);
					padding: 4px 14px;
				}
				span:nth-child(2) {
					display: block;
					font-weight: 700;
					font-size: 19px;
					line-height: 27px;
					margin: 18px 8px;
					margin-top: 24px;
				}
				span:last-child {
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: #e9e9e9;
				}
			}
			.skills {
				margin-top: 42px;
				span {
					display: block;
					font-weight: 300;
					font-size: 15px;
					line-height: 19px;
					margin-bottom: 8px;
					margin-left: 8px;
					color: white;
					font-weight: 300;
					font-size: 15px;
					line-height: 19px;
				}
			}
		}
	}
	.right {
		border-radius: 28px;
		margin-left: 30px;
		width: 30%;
		padding: 12px;
		background-color: white;
		position: relative;

		.liked-post {
			text-align: center;
			width: 100%;
			margin: auto;
			padding: 120px 0;
			span {
				margin-top: 16px;
				font-weight: 300;
				font-size: 16px;
				line-height: 20px;
				color: #696969;
				display: block;
			}
		}

		.article-title {
			align-items: center;
			img {
				width: 28px;
			}
			span {
				margin-left: 8px;
				font-weight: 700;
				font-size: 14px;
				line-height: 18px;
				letter-spacing: -0.03em;
				color: #3d3d3d;
			}
		}
		.comment-box {
			position: relative;
			width: 100%;
			height: 120px;
			max-height: 160px;
			background: rgba(96, 88, 76, 0.88);
			border-radius: 27px 27px 27px 8px !important;
			margin: 16px 0;
			color: white;
			span {
				position: absolute;
				padding-right: 24px;
				font-weight: 300;
				font-size: 15px;
				line-height: 19px;
				color: white;
				i {
					margin-right: 4px;
					margin-top: 4px;
				}
				:first-child {
					left: 8%;
					top: 20%;
				}
				:last-child {
					bottom: 12%;
					right: 0;
				}
			}
		}
	}
`;
const MobilePostWrapper = styled.div`
	border-radius: 28px;
	display: flex;
	width: 100%;
	margin: auto;
	max-width: 1400px;

	@media (max-width: 768px) {
		display: block;
	}
	@media (min-width: 769px) {
		display: none;
	}
	.comments {
		margin-top: 28px;
		font-size: 20px;
	}
	.post-box {
		display: block;
		width: 100%;
		margin: auto;
		max-width: 1400px;
	}
	.left-box {
		display: block;
		width: 100%;
	}
	.title {
		display: block;
	}
	.written-post {
		position: relative;
		border-radius: 10px;
		padding: 16px;
		padding-right: 0;
		padding-bottom: 10px;
		background-color: white;
		display: block;
		.gradient {
			position: absolute;
			top: 0;
			right: 0;
			width: 14%;
			height: 100%;
			z-index: 9;
			background: linear-gradient(
				270deg,
				rgba(255, 255, 255, 0.6) 0%,
				rgba(255, 255, 255, 0) 43.05%
			);
		}
		:first-child {
			.see-all {
				margin-top: 16px;
				width: 100%;
				padding-top: 12px;
				border-top: 1px solid #eeeeee;
			}
		}
		.see-all {
			width: 100%;
			border-top: 1px solid #eeeeee;
			padding-top: 12px;
		}
		.liked-post {
			text-align: center;
			width: 100%;
			margin: auto;
			padding: 120px 0;
			span {
				display: block;
				font-weight: 300;
				font-size: 16px;
				line-height: 20px;
				color: #696969;
				margin-top: 16px;
			}
		}

		.swiper-card {
			border-radius: 20px;
			color: white;
			display: block;
			overflow: hidden;
			height: 440px;
			max-height: 240px;
			max-width: 330px;
			position: relative;
			.post-desc {
				border-radius: 12px;
				height: 440px;
				padding: 32px 8px;
				padding-bottom: 10px;
				width: 100%;
				height: 100%;
				z-index: 9;
				background-color: rgba(0, 0, 0, 0.3);
			}
			:hover {
				opacity: 90%;
				transition: 0.1s ease-out;
			}
			.top,
			.bottom {
				display: flex;
				position: relative;
			}
			.bottom {
				position: absolute;
				bottom: 4%;
			}
			.author-thumbnail {
				padding-top: 40px;
				position: absolute;
				right: 6%;
				bottom: 6%;
				img {
					width: 50px;
					height: 50px;
					border-radius: 50%;
				}
			}

			.post-info {
				position: relative;
				span {
					margin-left: 8px;
				}
				span:first-child {
					background: rgba(255, 255, 255, 0.4);
					padding: 4px 14px;
					/* color: red; */
				}
				span:nth-child(2) {
					display: block;
					font-weight: 600;
					font-size: 16px;
					letter-spacing: 0.02em;
					line-height: 28px;
					margin-top: 32px;
				}
				span:last-child {
					font-weight: 300;
					font-size: 12px;
					line-height: 26px;
					color: #e9e9e9;
				}
			}
		}
	}
	.left {
		position: relative;
		border-radius: 10px;
		/* margin: 0 8px; */
		padding: 16px;
		padding-right: 0;
		padding-bottom: 10px;
		background-color: white;
		display: block;
		.gradient {
			position: absolute;
			top: 0;
			right: 0;
			width: 14%;
			height: 100%;
			z-index: 9;
			background: linear-gradient(
				270deg,
				rgba(255, 255, 255, 0.6) 0%,
				rgba(255, 255, 255, 0) 43.05%
			);
		}
		:first-child {
			.see-all {
				margin-top: 16px;
				width: 100%;
				padding-top: 12px;
				border-top: 1px solid #eeeeee;
			}
		}
		.see-all {
			width: 100%;
			border-top: 1px solid #eeeeee;
			padding-top: 12px;
		}
		.liked-post {
			text-align: center;
			width: 100%;
			margin: auto;
			padding: 120px 0;
			background-image: url('/dummy-liked.png');
			background-size: cover;
			span {
				display: block;
				font-weight: 300;
				font-size: 16px;
				line-height: 20px;
				color: #696969;
				margin-top: 16px;
			}
		}

		.swiper-card {
			border-radius: 20px;
			color: white;
			display: block;
			overflow: hidden;
			height: 440px;
			max-height: 240px;
			max-width: 330px;
			position: relative;
			.post-desc {
				border-radius: 12px;
				height: 440px;
				padding: 32px 8px;
				padding-bottom: 10px;
				width: 100%;
				height: 100%;
				z-index: 9;
				background-color: rgba(0, 0, 0, 0.3);
			}
			:hover {
				opacity: 90%;
				transition: 0.1s ease-out;
			}
			.top,
			.bottom {
				display: flex;
				position: relative;
			}
			.bottom {
				position: absolute;
				bottom: 4%;
			}
			.author-thumbnail {
				padding-top: 40px;
				position: absolute;
				right: 6%;
				bottom: 6%;
				img {
					width: 50px;
					height: 50px;
					border-radius: 50%;
				}
			}

			.post-info {
				position: relative;
				span {
					margin-left: 8px;
				}
				span:first-child {
					background: rgba(255, 255, 255, 0.4);
					padding: 4px 14px;
					/* color: red; */
				}
				span:nth-child(2) {
					display: block;
					font-weight: 600;
					font-size: 16px;
					letter-spacing: 0.02em;
					line-height: 28px;
					margin-top: 32px;
				}
				span:last-child {
					font-weight: 300;
					font-size: 12px;
					line-height: 26px;
					color: #e9e9e9;
				}
			}
			.skills {
				margin-top: 42px;
				span {
					display: block;
					font-weight: 300;
					font-size: 15px;
					line-height: 19px;
					margin-bottom: 8px;
					margin-left: 8px;
					color: white;
					font-weight: 300;
					font-size: 15px;
					line-height: 19px;
				}
			}
		}
	}
	.right {
		display: block;
		border-radius: 10px;
		margin-top: 16px;
		width: 100%;
		padding: 12px;
		padding-bottom: 2px;
		background-color: white;
		position: relative;
		.see-alls {
			position: absolute;
			bottom: 16px;
		}
		.liked-post {
			text-align: center;
			width: 100%;
			margin: auto;
			padding: 120px 0;
			span {
				display: block;
				font-weight: 300;
				font-size: 16px;
				line-height: 20px;
				color: #696969;
				margin-top: 16px;
			}
		}

		.gradient {
			position: absolute;
			bottom: 8%;
			left: 0;
			width: 100%;
			height: 10%;
			background: linear-gradient(
				180deg,
				rgba(255, 255, 255, 0) 0%,
				#ffffff 100%
			);
		}

		.article-title {
			align-items: center;
			img {
				width: 28px;
			}
			span {
				margin-left: 8px;
				font-weight: 700;
				font-size: 12px;
				line-height: 18px;
				letter-spacing: -0.03em;
				color: #3d3d3d;
			}
		}
		.comment-box {
			position: relative;
			width: 100%;
			height: 120px;
			max-height: 160px;
			background: rgba(96, 88, 76, 0.88);
			border-radius: 27px 27px 27px 8px !important;
			margin: 16px 0;
			color: white;
			span {
				position: absolute;
				padding-right: 24px;
				font-weight: 300;
				font-size: 14px;
				line-height: 19px;
				color: white;
				i {
					margin-right: 4px;
					margin-top: 4px;
				}
				:first-child {
					left: 8%;
					top: 20%;
				}
				:last-child {
					display: flex;
					align-items: center;
					bottom: 12%;
					right: 0;
					font-size: 12px;
				}
			}
		}
	}
`;

export default function Posts() {
	const [posts, setPosts] = useState([]);
	const user = useSelector((state) => state.auth.user_data);
	const params = useParams();

	useEffect(() => {
		GetUserPosts({
			order: 'DESC',
			orderby: 'ID',
			posts_per_page: '12',
			post_author: user.ID,
			paged: 1,
		})
			.then((res) => setPosts(res.data.body.posts))
			.catch((err) => console.log('포스트 정보 오류', err));
	}, [user.ID]);

	const seeAllPosts = () => {
		window.location.href = `/user/${user.ID}?tab=2`;
	};

	SwiperCore.use([Navigation]);
	return (
		<>
			<div
				className={
					(user?.role === '' && 'general-post-wrapper') ||
					(user?.role === 'mentor' && '') ||
					(user?.role === 'mentee' && 'post-wrapper')
				}
			>
				<div className="post-title first">
					<h1 className="title">작성 포스트</h1>
					<h1 className="comments">내 댓글</h1>
				</div>
				<PostWrapper>
					<div className="post-box">
						<div className="left-box">
							<div className="left">
								{posts?.length === 0 ? (
									<div className="written-post">
										<QuestionIcon />
										<span>아직 작성된 포스트가 없습니다.</span>
									</div>
								) : (
									<Swiper slidesPerView={2.5} spaceBetween={20}>
										{posts?.map((post, idx) => (
											<SwiperSlide>
												<div
													key={`post-${idx}`}
													className="swiper-card"
													style={{
														backgroundImage: `url(${post.post_image})`,
													}}
													onClick={() => window.open(`/story/${post.ID}`)}
												>
													<div className="post-desc">
														<div className="top">
															<div className="post-info">
																<span>{post.cate_name}</span>
																<span>{post.post_title}</span>
																<span>{post.post_date}</span>
															</div>
														</div>
														<div className="bottom">
															<div className="skills">
																<span>#devops</span>
																<span>#Consulting</span>
															</div>
														</div>
														<div className="author-thumbnail">
															<img
																src={`${post.post_author_img}`}
																alt="profile thumbnail"
															/>
														</div>
													</div>
												</div>
											</SwiperSlide>
										))}
									</Swiper>
								)}

								<button onClick={seeAllPosts} className="see-all">
									모두 보기
								</button>
								<div className="gradient" />
							</div>
							<div className="post-title">
								<h1 className="title">관심 포스트</h1>
							</div>
							<div className="left">
								<div className="liked-post">
									<QuestionIcon />
									<span>아직 관심 포스트가 없습니다.</span>
									<div className="gradient" />
								</div>
								<button className="see-all">모두 보기</button>
							</div>
						</div>

						<div className="right">
							<div className="liked-post">
								<QuestionIcon />
								<span>오픈 준비중입니다.</span>
							</div>
							<button className="see-all-comments">모두 보기</button>
						</div>
					</div>
				</PostWrapper>
				<MobilePostWrapper>
					<div className="post-box">
						<div className="left-box">
							<div className="written-post">
								{posts?.length === 0 ? (
									<div className="liked-post">
										<QuestionIcon />
										<span>아직 작성된 포스트가 없습니다.</span>
									</div>
								) : (
									<Swiper slidesPerView={1.5} spaceBetween={20}>
										{posts.map((post, idx) => (
											<SwiperSlide>
												<div
													key={`post-${idx}`}
													className="swiper-card"
													style={{
														backgroundImage: `url(${post.post_image})`,
													}}
													onClick={() => window.open(`/story/${post.ID}`)}
												>
													<div className="post-desc">
														<div className="top">
															<div className="post-info">
																<span>{post.cate_name}</span>
																<span>{post.post_title}</span>
																<span>{post.post_date}</span>
															</div>
														</div>
														<div className="bottom">
															<div className="skills">
																<span>#devops</span>
																<span>#Consulting</span>
															</div>
														</div>
														<div className="author-thumbnail">
															<img
																src={`${post.post_author_img}`}
																alt="profile thumbnail"
															/>
														</div>
													</div>
												</div>
											</SwiperSlide>
										))}
									</Swiper>
								)}

								<div className="gradient" />
								<button onClick={seeAllPosts} className="see-all">
									모두 보기
								</button>
							</div>
							<div className="post-title">
								<h1 className="title">관심 포스트</h1>
							</div>
							<div className="left">
								<div className="liked-post">
									<QuestionIcon />
									<span>아직 관심 포스트가 없습니다.</span>
								</div>
								<button className="see-all">모두 보기</button>
							</div>
						</div>
						<h1 className="comments">내 댓글</h1>
						<div className="right">
							<div className="liked-post">
								<QuestionIcon />
								<span>오픈 준비중입니다.</span>
								<button className="see-alls">모두 보기</button>
							</div>
						</div>
					</div>
				</MobilePostWrapper>
			</div>
		</>
	);
}
