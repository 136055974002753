import React, { useEffect, useState } from 'react';
import { GetUser } from '../../../../api/user';
import Button from '../../../../components/Button';
import { CommonContainer } from '../../../../components/Grid';
import LoadInView from '../../../../components/LoadInView';
import {
	UserBox,
	UserItem,
	UserItemBackground,
	UserItemUser,
	UserStatus,
	UserWrapper,
} from '../styled';
import UserSlider from './UserSlider';

const SectionUser = (props) => {
	const [user_data, set_user_data] = useState({ ID: null });
	useEffect(() => {
		if (user_data.ID != props.item.post_author) {
			GetUser({
				user_id: props.item.post_author,
			})
				.then((response) => {
					if (response.data.code == '200') {
						set_user_data(response.data.body);
					}
				})
				.catch((response) => {
					console.log(response);
				});
		}
	});

	const goToProfile = (user) => {
		if (user.user_role === 'delete') {
			alert('퇴직자입니다.');
			return;
		} else {
			window.location.href = `/${user_data.ID}`;
		}
	};
	return (
		<UserWrapper>
			<CommonContainer type="wide">
				<LoadInView>
					<CommonContainer>
						<UserBox>
							{user_data.ID ? (
								<UserItemBackground>
									<UserItemUser
										image={props.item.post_author_img}
										onClick={() => goToProfile(user_data)}
									>
										<div className="userimg" />
										<div className="profile">
											<strong>
												{user_data ? user_data.user_name : ''}
												<small>{user_data.user_department}</small>
											</strong>
											<p>
												<img
													src="/profile_desc.png"
													width="18px"
													height="18px"
												/>
												{user_data ? user_data.description : ''}
											</p>
										</div>
									</UserItemUser>
								</UserItemBackground>
							) : null}

							<UserStatus>
								<li>
									<span>누적 포인트</span>
									<strong>
										{user_data.user_point_total
											? user_data.user_point_total
											: 0}
										P
									</strong>
								</li>
								<li>
									<span>현재 포인트</span>
									<strong>
										{user_data.user_point ? user_data.user_point : 0}P
									</strong>
								</li>
								<li>
									<span>봉사시간</span>
									<strong>
										{user_data.volunteerTime ? user_data.volunteerTime : 0}
										시간
									</strong>
								</li>
							</UserStatus>
						</UserBox>
						<UserItem>
							<UserSlider user_id={user_data.ID} />
							<Button
								type="primary"
								color="bg-white"
								icon="arrow"
								onClick={() =>
									(window.location.href = '/user/' + user_data.ID + '?tab=2')
								}
							>
								<span>{props.item.post_author_name}</span>님의 포스트
							</Button>
						</UserItem>
					</CommonContainer>
				</LoadInView>
			</CommonContainer>
		</UserWrapper>
	);
};

export default SectionUser;
