export default function ModalplusIcon() {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 10H14"
          stroke="#FFBC00"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M10 6L10 14"
          stroke="#FFBC00"
          stroke-width="2"
          stroke-linecap="round"
        />
        <circle cx="10" cy="10" r="9" stroke="#FFBC00" stroke-width="2" />
      </svg>
    </>
  );
}
