import { useState } from 'react';
import MainContainer from '../../../components/MainContainer';
import Header from '../../../components/daitsoHeader';
import { Wrapper } from './styled';
import CloseBtnIcon from '../../../vector/IconCloseBtn';
import ArrowTop from '../../../vector/LinkBtn';
import PlusIcon from '../../../vector/PlusIcon';
import MenteeArrow from '../../../vector/MenteeArrow';
import Modal1 from '../mentor/modal/modal1';
import Modal2 from '../mentor/modal/modal2';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Empty from '../../../components/empty';

export default function Test3() {
	const [show, setShow] = useState(false);
	const modalToggle = () => {
		setShow((p) => !p);
	};

	const [show2, setShow2] = useState(false);
	const modalToggle2 = () => {
		setShow2((pptato) => !pptato);
	};

	const [hide, setHide] = useState(true);
	const [hide2, setHide2] = useState(true);
	const [hide3, setHide3] = useState(true);
	const selectHide = () => {
		setHide((p) => !p);
	};
	const selectHide2 = () => {
		setHide2((p) => !p);
	};
	const selectHide3 = () => {
		setHide3((p) => !p);
	};

	const dummy = [
		{
			profile: '../profile-img.png',
			name: '홍길동',
			department: '신기술사업부',
			date: '2022.12.24 ~ 2023.2.24',
		},
		{
			profile: '../profile-img.png',
			name: '홍길동',
			department: '신기술사업부',
			date: '2022.12.24 ~ 2023.2.24',
		},
		{
			profile: '../profile-img.png',
			name: '홍길동',
			department: '신기술사업부',
			date: '2022.12.24 ~ 2023.2.24',
		},
		{
			profile: '../profile-img.png',
			name: '홍길동',
			department: '신기술사업부',
			date: '2022.12.24 ~ 2023.2.24',
		},
		{
			profile: '../profile-img.png',
			name: '홍길동',
			department: '신기술사업부',
			date: '2022.12.24 ~ 2023.2.24',
		},
		{
			profile: '../profile-img.png',
			name: '홍길동',
			department: '신기술사업부',
			date: '2022.12.24 ~ 2023.2.24',
		},
		{
			profile: '../profile-img.png',
			name: '홍길동',
			department: '신기술사업부',
			date: '2022.12.24 ~ 2023.2.24',
		},
	];
	return (
		<>
			<MainContainer>
				<Wrapper>
					<div className="wrapper">
						<section className="top-menu-wrap">
							<div className="top-menu-box">
								<h1 className="top-title">멘토링 대시보드</h1>
								<div className="select-box">
									<article className="cont-select">
										<button onClick={selectHide} className="btn-select">
											2023년
										</button>
										<ul className={hide ? 'list-member' : 'active list-member'}>
											<li>
												<button>2021년</button>
											</li>
											<li>
												<button>2022년</button>
											</li>
											<li>
												<button>2023년</button>
											</li>
											<div className="bg"></div>
										</ul>
									</article>

									<article className="department-select">
										<button onClick={selectHide2} className="btn-select">
											신기술사업부
										</button>
										<ul
											className={hide2 ? 'list-member' : 'active list-member'}
										>
											<li>
												<button>신기술사업부</button>
											</li>
											<li>
												<button>신기술사업부</button>
											</li>
											<li>
												<button>신기술사업부</button>
											</li>
											<div className="bg"></div>
										</ul>
									</article>
								</div>
							</div>
						</section>
					</div>
					<div className="wrapper">
						<section className="saas-wrap">
							<div className="saas-top-menu">
								<h1 className="saas-title">신기술사업부</h1>
								<div className="btn-box">
									<article className="cont-select">
										<button onClick={selectHide3} className="btn-select">
											졸업자
										</button>
										<ul
											className={hide3 ? 'list-member' : 'active list-member'}
										>
											<li>
												<button>졸업자</button>
											</li>
											<li>
												<button>졸업자</button>
											</li>
											<div className="bg"></div>
										</ul>
									</article>
									<button onClick={modalToggle2} className="add-btn">
										<i className="icon-plus">
											<PlusIcon />
										</i>
										멘토추가
									</button>
								</div>
							</div>

							<div className="department-wrap">
								<div className="saas-list">
									<ul className="info-list">
										{dummy?.length ? (
											dummy.map((item, key) => (
												<Link>
													<li key={key}>
														<div className="profile-box">
															<div
																className="user-img"
																style={{
																	backgroundImage: `url(${item.profile})`,
																}}
															></div>

															<div className="profile-info-box">
																<div className="mentor-info-box">
																	<h2 className="mentor-name">{item.name}</h2>
																</div>
																<p className="department-text">
																	{item.department}
																</p>
															</div>
															<i className="icon-close">
																<CloseBtnIcon />
															</i>
														</div>
														<div className="period-wrap">
															<div className="period-box">
																<p className="period-text">참여기간</p>
																<p className="day">{item.date}</p>
															</div>
															<div className="circular-progress-bar">
																<span>0/5</span>
															</div>
														</div>
													</li>
												</Link>
											))
										) : (
											<Empty>rr</Empty>
										)}
									</ul>
								</div>
							</div>
						</section>
					</div>
				</Wrapper>
			</MainContainer>
			{show ? <Modal1 modalToggle={modalToggle} /> : ''}
			{show2 ? <Modal2 modalToggle2={modalToggle2} /> : ''}
		</>
	);
}
