import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
// import "swiper/css";
import 'swiper/swiper.min.css';
import { ReactSwiperWrapper } from './styled';
import CloseBtnIcon from '../../vector/IconCloseBtn';
import Empty from '../../components/empty';
import NoneIcon from '../../vector/NoonIcon';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ReactSwiper = ({ user, mentees }) => {
	return (
		<ReactSwiperWrapper>
			<div className="card-wrapper">
				<Swiper
					spaceBetween={10}
					slidesPerView={1.1}
					centeredSlides={true}
					onSlideChange={() => console.log('slide change')}
					onSwiper={(swiper) => console.log(swiper)}
					breakpoints={{
						1024: {
							slidesPerView: 3,
							spaceBetween: 30,
							centeredSlides: false,
						},
					}}
				>
					{mentees?.length ? (
						mentees.map((item, key) => (
							<SwiperSlide>
								<div className="profile-card-list">
									<div className="profile-card">
										<div className="profile-box">
											<div
												className="user-img"
												style={{ backgroundImage: `url(${item?.avatar})` }}
											></div>

											<div className="profile-info-box">
												<div className="mentor-info-box">
													<h2 className="mentor-name">{item?.name}</h2>
												</div>
												<p className="department-text">
													{user?.user_department}
												</p>
											</div>
											{/* <i className="icon-close">
													<CloseBtnIcon />
												</i> */}
										</div>
										<div className="period-wrap">
											<div className="period-box">
												<p className="period-text">참여기간</p>
												<p className="day">
													{item?.startDate} ~ {item?.endDate}
												</p>
											</div>
											{/* <div className="circular-progress-bar">
												<span>
													{item?.stamp ? item?.stamp : 0}/
													{item?.career == 'junior' ? 5 : 3}
												</span>
											</div> */}
											<CircularProgressbarWithChildren
												value={
													(item?.stamp / (item?.career == 'junior' ? 5 : 3)) *
													100
												}
												strokeWidth={5}
												styles={{
													root: { height: '50px', width: '50px' },
													path: {
														stroke: '#FFBC00',
														strokeLinecap: 'butt',
														transition: 'stroke-dashoffset 0.5s ease 0s',
													},

													trail: {
														stroke: '#E9E9E9',
													},
													text: {
														fill: '#333333',
														fontSize: '18px',
													},
												}}
											>
												<span>
													{item?.stamp ? item?.stamp : 0}/
													{item?.career == 'junior' ? 5 : 3}
												</span>
											</CircularProgressbarWithChildren>
										</div>
									</div>
								</div>
							</SwiperSlide>
						))
					) : (
						<div className="none-box">
							<i className="icon-none">
								<NoneIcon />
							</i>
							<p className="none-text">멘토링 활동 내역이 없습니다.</p>
						</div>
					)}
					<div className="bg"></div>
				</Swiper>
			</div>
		</ReactSwiperWrapper>
	);
};

export default ReactSwiper;
